import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:items-start px-4" }
const _hoisted_2 = { class: "text-[#4375D9] max-w-lg tracking-wider" }
const _hoisted_3 = { class: "font-bold text-4xl text-adv-blue-800 mb-4 max-w-lg" }
const _hoisted_4 = { class: "text-justify max-w-lg mb-6" }
const _hoisted_5 = { class: "flex justify-center gap-4" }
const _hoisted_6 = ["src"]

import { h } from 'vue';
import { lightButtonStyle } from '@/assets/css/styles';
import { useRouter } from 'vue-router';

interface IProps {
  section: {
    id: number;
    plan: string;
    title: string;
    text: string;
    image: string;
    link: string;
    reverse: boolean;
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultSection',
  props: {
    section: {}
  },
  setup(__props: any) {

const router = useRouter();

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([[props.section.reverse ? '!bg-[#E7EEFF] rounded-tr-[100px]' : ''], "flex flex-col lg:flex-row items-center justify-center lg:justify-center py-16 bg-[#F4F7FF]"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col lg:flex-row items-center gap-10 lg:gap-20", [props.section.reverse ? 'lg:!flex-row-reverse' : '']])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(props.section.plan), 1),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(props.section.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(props.section.text), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass([_unref(lightButtonStyle), "lg:whitespace-nowrap"]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(props.section.link)))
          }, " SAIBA MAIS ", 2)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([props.section.reverse ? 'pr-6 lg:pr-0' : 'pl-6 lg:pl-0'])
      }, [
        _createElementVNode("img", {
          src: require(`@/assets/img/${props.section.image}`),
          alt: "ADVSERVICE"
        }, null, 8, _hoisted_6)
      ], 2)
    ], 2)
  ], 2))
}
}

})