import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-col w-full rounded-full" }
const _hoisted_2 = { class: "mt-2 w-full py-4 px-8 flex justify-start items-center z-10 overflow-hidden" }
const _hoisted_3 = ["innerHTML"]

import { FaqProps } from '@/types/cardTypes';
import { ref } from 'vue';
import ChevronDown from '@/components/icons/ChevronDown.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestionBox',
  props: {
    id: {},
    title: {},
    answer: {}
  },
  setup(__props: any) {

const show = ref(false);

function onClickAway() {
  show.value = false;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value = !show.value)),
      class: _normalizeClass([[show.value ? 'bg-[#F3F4F6]' : 'font-regular lg:text-black bg-[#F5F5F5] lg:bg-white'], "flex items-center justify-between w-full py-4 px-8 text-xl focus:outline-none cursor-pointer gap-9 lg:rounded-xl z-30 lg:border-b font-medium text-[#888888] lg:text-black"])
    }, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createVNode(ChevronDown, {
        class: _normalizeClass(["min-h-[12px] min-w-[12px] hidden lg:block", show.value ? 'transform rotate-180' : ''])
      }, null, 8, ["class"]),
      (!show.value)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: ['fas', 'plus'],
            class: "h-[12px] w-[12px] lg:hidden"
          }))
        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            icon: ['fas', 'minus'],
            class: "h-[12px] w-[12px] lg:hidden"
          }))
    ], 2),
    _createVNode(_Transition, { name: "show-q" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "font-light text-base post-content",
            innerHTML: _ctx.answer
          }, null, 8, _hoisted_3)
        ], 512), [
          [_vShow, show.value]
        ])
      ]),
      _: 1
    })
  ])), [
    [_directive_click_away, onClickAway]
  ])
}
}

})