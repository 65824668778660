import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height", "width"]
const _hoisted_2 = ["fill"]

interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'YellowTickIcon',
  props: {
    color: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: _ctx.height ? _ctx.height : '2em',
    width: _ctx.width ? _ctx.width : '2em',
    viewBox: "0 0 512 512"
  }, [
    _createElementVNode("path", {
      d: "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z",
      fill: _ctx.color || '#e5bb61'
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})