<script setup lang="ts">
import { lightButtonStyle } from '@/assets/css/styles';
import { FaqProps } from '@/types/cardTypes';
import FaqQuestionBox from './FaqQuestionBox.vue';
import { onMounted } from 'vue';
import { useFaqStore } from '@/store/faqStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { useRouter } from 'vue-router';

interface FaqCardProps {
  title: string;
  questions: FaqProps[];
}

const router = useRouter();
const store = useFaqStore();

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<FaqCardProps>();

onMounted(() => {
  store.getQuestions();
});
</script>

<template>
  <div class="w-full flex justify-center items-center">
    <LoadingSpinner v-if="store.loading" width="2rem" height="2rem" color="#4375D9" />
    <div v-else class="h-max w-full flex justify-center flex-col items-center lg:p-28 sm:p-16 p-6 lg:bg-[#F6F6F6]">
      <h2 class="font-bold text-4xl text-adv-blue-800 mb-16 max-w-lg text-center tracking-wider">{{ title }}</h2>
      <div class="mb-9 w-full lg:border rounded-xl bg-white flex flex-col lg:block gap-2">
        <FaqQuestionBox v-for="q in questions" :key="q.id" :id="q.id" :title="q.title" :answer="q.answer"/>
      </div>
      <button type="button" :class="lightButtonStyle" @click="router.push('/contato')">FALE CONOSCO</button>
    </div>
  </div>
</template>
