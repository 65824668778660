import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute w-full h-full opacity-75 bg-gradient-to-br from-[#253170] to-[#3660B6]"
}

interface TitleProps {
  height: number;
  imgUrl: string;
  withFilter: boolean;
}
// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'Title',
  props: {
    height: { default: 32 },
    imgUrl: { default: '' },
    withFilter: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([`h-[${_ctx.height}rem] bg-[${_ctx.imgUrl}]`, "w-full bg-cover p-2 text-center lg:text-start flex justify-around flex-col sm:flex-row lg:justify-center items-center relative"])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.withFilter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})