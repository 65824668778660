import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import Benefits from '@/components/Benefits/Benefits.vue';
import PlansComparison from '@/components/Plans/PlansComparison.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Depoimentos from '@/components/Depoimentos/Depoimentos.vue';
import Faq from '@/components/Faq/Faq.vue';
import { useFaqStore } from '@/store/faqStore';
import About from '@/components/About/About.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const faqStore = useFaqStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(Title, {
        height: 28,
        imgUrl: "url('@/assets/img/bg-serv-white-title.svg')"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: "https://advservice-images.s3.amazonaws.com/solutions/Logo%2B1%20%281%29.png",
            class: "w-72",
            alt: "Gestor Service logo"
          }, null, -1)
        ])),
        _: 1
      }),
      _createVNode(About, {
        title: "Com nosso gestor o seu escritório funciona com o máximo de eficiência",
        text: "Para ter sucesso não basta apenas ter bons profissionais, também é preciso uma ótima gestão para alcançar os maiores resultados.",
        service: "gestor",
        "video-url": "https://advservice-images.s3.amazonaws.com/videos/Gestor+Service+final+.mp4",
        "thumbnail-url": "https://advservice-images.s3.amazonaws.com/solutions/gestor%20service.png"
      }),
      _createVNode(Benefits, { solutionId: 2 }),
      _createVNode(PlansComparison, { "solution-id": 2 }),
      _createVNode(Solutions, { title: "Mais soluções" }),
      _createVNode(Depoimentos, { title: "Depoimentos" }),
      _createVNode(Faq, {
        title: "Perguntas Frequentes",
        questions: _unref(faqStore).questions
      }, null, 8, ["questions"])
    ]),
    _: 1
  }))
}
}

})