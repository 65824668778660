<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | ADV Service` : `ADV Service` }}</template>
  </metainfo>
  <router-view />

  <a
    href="https://api.whatsapp.com/send?phone=5555991763315&text=Ol%C3%A1%2C+gostaria+de+conhecer+mais+sobre+o+ADV+SERVICE."
    class="float"
    target="_blank"
  >
    <whatsapp-icon />
  </a>

  <preloader />
</template>

<script lang="ts" setup>
import { useMeta } from 'vue-meta';
import preloader from '@/components/Preloader.vue';
import WhatsappIcon from '@/components/icons/WhatsappIcon.vue';

useMeta({
  title: 'ADV Service',
  description: 'Serviços de facilitação para gestão de escritórios e advocacia trabalhista.',
  htmlAttrs: { lang: 'pt-BR', amp: true },
  og: {
    title: 'ADV Service',
    image: 'https://i.imgur.com/jYPTkUf.png',
    type: 'website',
    description: 'Serviços de facilitação para gestão de escritórios e advocacia trabalhista.',
    url: 'https://development.advservice.com.br/',
  },
  twitter: {
    title: 'ADV Service',
    image: 'https://i.imgur.com/jYPTkUf.png',
    card: 'summary_large_image',
    description: 'Serviços de facilitação para gestão de escritórios e advocacia trabalhista.',
    url: 'https://development.advservice.com.br/',
  },
});
</script>

<style lang="css" scoped>
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
