<script lang="ts" setup>
import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import BlogAside from '@/components/Asides/BlogAside.vue';
import { useRouter, useRoute } from 'vue-router';
import { useBlogStore } from '@/store/blogStore';
import { lightButtonStyle } from '@/assets/css/styles';
import Related from '@/components/Related/Related.vue';
import { PostNav } from '@/types/postTypes';

const blogStore = useBlogStore();
const router = useRouter();
const route = useRoute();

const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

const navToPost = (item: { id: number; slug: string }) => {
  window.scrollTo(0, 0);
  router.push(`/blog/${item.id}/${item.slug}`);
  blogStore.getPostById(Number(item.id));
};

onMounted(() => {
  blogStore.getPostById(Number(route.params.id));
});
</script>

<template>
  <Layout>
    <div class="flex flex-col lg:flex-row justify-between gap-12 items-center lg:items-start lg:p-28 p-16">
      <div v-show="blogStore.loading" class="flex justify-center items-center w-full">
        <LoadingSpinner color="#4375D9" />
      </div>
      <article
        v-show="!blogStore.loading"
        class="h-max w-full flex gap-12 justify-center flex-col items-start lg:border-r border-adv-blue-100 lg:pr-12"
      >
        <p class="font-light italic text-sm text-adv-blue-800">Início / Blog / {{ blogStore.selectedPost?.title }}</p>
        <img :src="blogStore.selectedPost?.cover" alt="Imagem de Capa" class="max-h-96 w-full object-cover" />
        <h2 class="font-bold text-adv-blue-800 text-4xl">{{ blogStore.selectedPost?.title }}</h2>
        <div
          class="font-sm text-black text-justify lg:text-left post-content"
          v-html="blogStore.selectedPost?.content"
        />
        <div class="flex flex-col md:flex-row justify-between items-center gap-3 w-full">
          <button
            type="button"
            :class="lightButtonStyle"
            class="mt-3 max-w-[12rem]"
            :disabled="!blogStore.selectedPost?.prev"
            @click="navToPost(blogStore.selectedPost?.prev as PostNav)"
          >
            &lt; ANTERIOR
          </button>
          <button
            type="button"
            :class="lightButtonStyle"
            class="mt-3 max-w-[12rem]"
            :disabled="!blogStore.selectedPost?.next"
            @click="navToPost(blogStore.selectedPost?.next as PostNav)"
          >
            PRÓXIMO &gt;
          </button>
        </div>
        <nav class="flex w-full justify-center items-center">
          <a class="text-adv-blue-800 text-2xl cursor-pointer" @click="routeRoll('/blog')">Voltar para o Blog</a>
        </nav>
      </article>
      <BlogAside />
    </div>
    <Related />
  </Layout>
</template>

<style lang="scss">
.post-content > p {
  margin-bottom: 1rem;
  text-align: justify;
}
</style>
