import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-center gap-14" }

import Layout from '@/components/BaseLayout/Layout.vue';
  import PlanPriceDisplay from '@/components/Plans/LoginPriceDisplay.vue';
  import PlansContratacao from '@/components/Plans/PlansContratacao.vue';
  import FormSign from '@/components/SignUp/Form.vue';
  import AddressForm from '@/components/SignUp/Address.vue';
  import PayForm from '@/components/SignUp/PayForm.vue';
  import PaymentConfirm from '@/components/SignUp/PaymentConfirm.vue';
  import { useServicePlansStore } from '@/store/servicePlansStore';
  import { useSignUpStore } from '@/store/signUpStore';
  import { onBeforeMount } from 'vue';
  import PaymentResume from '@/components/SignUp/PaymentResume.vue';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'Confirmado',
  setup(__props) {

  const store = useSignUpStore();
  const storePlans = useServicePlansStore();
  
  onBeforeMount(() => {
    storePlans.getPlans();
  });
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(PlansContratacao),
      _createElementVNode("section", _hoisted_1, [
        _createVNode(PaymentConfirm)
      ])
    ]),
    _: 1
  }))
}
}

})