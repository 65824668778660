import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-end flex-col md:flex-row justify-center gap-4 md:gap-12 lg:gap-16" }
const _hoisted_2 = { class: "flex flex-col justify-between items-start gap-7" }
const _hoisted_3 = {
  class: "mb-3 font-Open text-3xl font-bold text-white",
  style: { letterSpacing: '0.095em' }
}
const _hoisted_4 = { class: "grid md:grid-cols-2 grid-rows-2 gap-x-10 gap-y-7 h-fit md:h-max w-fit md:w-max" }
const _hoisted_5 = { class: "ml-2 font-Open text-xl font-normal text-white" }
const _hoisted_6 = { class: "flex gap-1" }
const _hoisted_7 = {
  class: "self-start font-Open text-8xl font-extrabold text-white",
  style: { lineHeight: '0.8 !important' }
}
const _hoisted_8 = { class: "font-Open text-4xl font-extrabold text-white" }

import CardsContainer from '@/components/CardDysplay/CardsContainer.vue';
import GreenTickIcon from '@/components/icons/GreenTickIcon.vue';
// import RedTickIcon from '@/components/icons/RedTickIcon.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlansContratacao',
  setup(__props) {

const storePlans = useServicePlansStore();
console.log('plans', storePlans.selectedPlan)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardsContainer, { ContainerTitle: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, " Cadastro - Plano " + _toDisplayString(_unref(storePlans).selectedPlan?.name), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(storePlans).selectedPlan?.solutions, (service, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "flex flex-row items-center w-max"
              }, [
                _createVNode(GreenTickIcon),
                _createElementVNode("p", _hoisted_5, _toDisplayString(service.name), 1)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-right font-Open text-xl font-extrabold text-white" }, "R$", -1)),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(storePlans).selectedPlan?.price.toFixed(2).split('.')[0]), 1),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_8, " ," + _toDisplayString(_unref(storePlans).selectedPlan?.price.toFixed(2).split('.')[1]), 1),
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-Open text-sm font-normal text-white" }, "por mês", -1))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})