<script setup lang="ts">
import { computed } from 'vue';
import { useSignUpStore } from '@/store/signUpStore';
import { useServicePlansStore } from '@/store/servicePlansStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { storeToRefs } from 'pinia';
import { planType } from '@/types/cardTypes';

const store = useSignUpStore();
const planStore = useServicePlansStore();
const { selectedPlan } = storeToRefs(planStore);
const { discount, days, type } = storeToRefs(store);

const applyCoupon = async () => {
  await store.validateCoupon(planStore.selectedPlan as planType);
};

const totalDiscount = computed(() => {
  return selectedPlan?.value && discount.value
    ? (selectedPlan?.value?.price * selectedPlan.value?.periodicity * (discount.value / 100)).toFixed(2)
    : '';
});

const subTotalPlanPrice = computed(() => {
  return selectedPlan?.value ? (selectedPlan?.value?.price * selectedPlan.value?.periodicity).toFixed(2) : '';
});

const totalPlanPrice = computed(() => {
  return selectedPlan?.value
    ? discount.value
      ? (selectedPlan?.value?.price * selectedPlan.value?.periodicity * ((100 - discount.value) / 100)).toFixed(2)
      : (selectedPlan?.value?.price * selectedPlan.value?.periodicity).toFixed(2)
    : '';
});
</script>

<template>
  <div class="flex gap-5 flex-col md:flex-row w-full mb-8">
    <div class="w-full flex flex-col gap-5">
      <p class="text-adv-blue-800 font-bold tracking-widest text-xl mb-4">Cupom de desconto</p>

      <input
        type="text"
        placeholder="#CUPOM10"
        v-model="store.signUpForm.coupon"
        class="border border-solid border-dark-grey rounded-lg h-10 w-full max-w-xl p-2"
        @keyup.enter.prevent="applyCoupon"
      />
      <button
        type="button"
        class="txt-btn text-base text-white bg-adv-blue-100 py-2 px-4 w-full md:w-fit tracking-[0.21em]"
        :disabled="store.loadingCoupon"
        @click.prevent="applyCoupon"
      >
        <p v-show="!store.loadingCoupon">Aplicar</p>
        <loading-spinner v-show="store.loadingCoupon" />
      </button>
    </div>
    <div
      class="hidden md:inline-block h-[250px] min-h-[1em] w-px self-stretch bg-adv-gray-500 opacity-100 dark:opacity-50"
    />
    <hr class="md:hidden w-full border-t-px border-adv-gray-500 my-3" />
    <div class="w-full p-2 flex flex-col gap-4">
      <div class="flex w-full justify-between">
        <p class="font-bold tracking-widest">Valor mensal</p>
        <p class="tracking-widest">R$ {{ selectedPlan?.price.toFixed(2).replace('.', ',') }}</p>
      </div>
      <div class="flex w-full justify-between">
        <p class="font-bold tracking-widest">Duração</p>
        <p class="tracking-widest">{{ selectedPlan?.periodicity }} meses</p>
      </div>
      <div class="flex w-full justify-between">
        <p class="font-bold tracking-widest">Subtotal</p>
        <p class="tracking-widest">R$ {{ subTotalPlanPrice.replace('.', ',') }}</p>
      </div>
      <div v-if="type === 'DAYS'" class="flex w-full justify-between">
        <p class="font-bold tracking-widest">Dias grátis antes da primeira cobrança:</p>
        <p class="tracking-widest">{{ days }} dias</p>
      </div>
      <div v-if="discount" class="flex w-full justify-between">
        <p class="font-bold tracking-widest">Desconto</p>
        <p class="tracking-widest">{{ discount }} %</p>
      </div>
      <div v-if="discount" class="flex w-full justify-between">
        <p class="font-bold tracking-widest">Valor do desconto</p>
        <p class="font-bold text-lg text-adv-blue-100 tracking-widest">R$ -{{ totalDiscount.replace('.', ',') }}</p>
      </div>
      <hr class="w-full border-t-px border-adv-gray-500 my-3" />
      <div class="flex w-full flex-col gap-5 md:flex-row justify-between">
        <p class="font-extrabold w-full md:w-fit text-end text-2xl text-adv-blue-800 tracking-widest">
          Pagamento Total
        </p>
        <p class="font-extrabold w-full md:w-fit text-end text-2xl text-adv-blue-800 tracking-widest">
          R$ {{ totalPlanPrice.replace('.', ',') }}
        </p>
      </div>
    </div>
  </div>
</template>
