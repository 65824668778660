import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row justify-center gap-12 items-center lg:items-start lg:p-28 p-16" }
const _hoisted_2 = { class: "h-max w-full flex gap-12 justify-center flex-col items-start lg:pr-12" }
const _hoisted_3 = { class: "font-light italic text-sm text-adv-blue-800" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "font-bold text-adv-blue-800 text-4xl" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col md:flex-row justify-between items-center gap-3 w-full" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]

import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { useRoute, useRouter } from 'vue-router';
import { useNewsStore } from '@/store/newsStore';

import { lightButtonStyle } from '@/assets/css/styles';
import Related from '@/components/Related/Related.vue';
import { PostNav } from '@/types/postTypes';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsPost',
  setup(__props) {

const newsStore = useNewsStore();
const router = useRouter();
const route = useRoute();

const navToPost = (item: { id: number; slug: string }) => {
  window.scrollTo(0, 0);
  router.push(`/blog/${item.id}/${item.slug}`);
  newsStore.getNewsById(Number(item.id));
};

onMounted(() => {
  newsStore.getNewsById(Number(route.params.id));
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(LoadingSpinner, { color: "#4375D9" }, null, 512), [
          [_vShow, _unref(newsStore).loading]
        ]),
        _withDirectives(_createElementVNode("article", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, " Início / Notícias / " + _toDisplayString(_unref(newsStore).selectedPost?.title), 1),
          _createElementVNode("img", {
            src: _unref(newsStore).selectedPost?.cover,
            alt: "Imagem de Capa",
            class: "max-h-96 w-full object-cover"
          }, null, 8, _hoisted_4),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_unref(newsStore).selectedPost?.title), 1),
          _createElementVNode("div", {
            class: "font-sm text-black text-justify lg:text-left post-content",
            innerHTML: _unref(newsStore).selectedPost?.content
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([_unref(lightButtonStyle), "mt-3 max-w-[12rem]"]),
              disabled: !_unref(newsStore).selectedPost?.prev,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (navToPost(_unref(newsStore).selectedPost?.prev as PostNav)))
            }, " < ANTERIOR ", 10, _hoisted_8),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([_unref(lightButtonStyle), "mt-3 max-w-[12rem]"]),
              disabled: !_unref(newsStore).selectedPost?.next,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (navToPost(_unref(newsStore).selectedPost?.next as PostNav)))
            }, " PRÓXIMO > ", 10, _hoisted_9)
          ])
        ], 512), [
          [_vShow, !_unref(newsStore).loading]
        ])
      ]),
      _createVNode(Related)
    ]),
    _: 1
  }))
}
}

})