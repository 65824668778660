<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<!-- eslint-disable no-undef -->
<script setup lang="ts">
import Modal from '@/components/Modal/index.vue';
import { useModalStore } from '@/store/modalStore';

const store = useModalStore();
</script>

<template>
  <Modal
    modal-title="Contrato de prestação de serviços"
    :open="store.contractModalOpen"
    @close="store.contractModalOpen = false"
  >
    <template #modal-content>
      <div class="flex flex-col justify-center items-center gap-12 w-full mt-12">
        <div class="w-full">
          <div class="border md:w-full max-h-[80vh] lg:max-h-[300px] overflow-auto">
            <div class="p-0 md:p-6 text-justify">
              <p><strong>POL&Iacute;TICA DE PAGAMENTO DO ADV SERVICE</strong></p>
              <br />
              <ol>
                <li><strong> INTRODU&Ccedil;&Atilde;O</strong></li>
              </ol>
              <p>
                1.1. Esta POL&Iacute;TICA DE PAGAMENTO constitui parte integrante dos TERMOS DE USO da Adv Service, o
                qual regula a funcionalidade da plataforma. Ao realizar uma transa&ccedil;&atilde;o pela Adv Service,
                mesmo que na vers&atilde;o de teste, voc&ecirc; concorda com todas as disposi&ccedil;&otilde;es aqui
                descritas.
              </p>
              <br />
              <p>1.2. Terminologias.</p>
              <br />
              <p>1.2.1. Voc&ecirc;: usu&aacute;rio da Adv Sercive que se enquadra no art. 2&ordm; do CDC.</p>
              <br />
              <p>
                1.2.2. Adv Service: Trabalhista Service Ltda., pessoa jur&iacute;dica de direito privado, inscrita no
                CNPJ sob n&ordm; 41.867.655/0001-49.
              </p>
              <br />
              <ol start="2">
                <li><strong> COMUNICA&Ccedil;&Atilde;O </strong></li>
              </ol>
              <p>
                2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ao aceitar essa POL&Iacute;TICA, Voc&ecirc; declara que conhece
                e concorda com todas as demais Pol&iacute;ticas aplic&aacute;veis, vez que todas s&atilde;o partes
                integrantes dos TERMOS DE USO da Adv Service.
              </p>
              <br />
              <p>
                2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Caso Voc&ecirc; precise de qualquer informa&ccedil;&atilde;o,
                esclarecimento ou atendimento com rela&ccedil;&atilde;o a esta POL&Iacute;TICA, a Adv Service
                disponibiliza o e-mail: contato@advservice.com.br.
              </p>
              <br />
              <p>
                2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A Adv Service tamb&eacute;m disponibiliza no site uma
                sess&atilde;o de FAQ - Frequently Asked Questions que pode ser &uacute;til para voc&ecirc; esclarecer
                suas d&uacute;vidas. O FAQ concentra as respostas sobre as d&uacute;vidas mais comuns dos clientes.
              </p>
              <br />
              <ol start="3">
                <li><strong> PLANOS </strong></li>
              </ol>
              <p>
                3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Os planos disponibilizados pela Adv Service e seus respectivos
                pre&ccedil;os est&atilde;o dispon&iacute;veis na p&aacute;gina inicial do site da Adv Service no
                seguinte link &ldquo;https://advservice.com.br/planos&rdquo; e todos podem ser testados pelo prazo de 7
                dias &uacute;teis, desde que seja realizado o cadastro disposto nos Termos de Uso e cadastrado o meio de
                pagamento.
              </p>
              <br />
              <p>
                3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A Adv Service poder&aacute; oferecer planos especiais para
                campanhas de marketing com prazo definido. Esses planos t&ecirc;m suas pr&oacute;prias regras, que
                n&atilde;o seguem os padr&otilde;es dos planos deste link no momento da contrata&ccedil;&atilde;o.
                &Eacute; sua responsabilidade entender e seguir as regras aplic&aacute;veis ao plano que voc&ecirc;
                contratou, mesmo depois da finaliza&ccedil;&atilde;o da referida campanha.
              </p>
              <br />
              <ol start="4">
                <li><strong> MEIOS DE PAGAMENTO </strong></li>
              </ol>
              <p>
                4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A assinatura ou compra individual de qualquer da Adv Service
                poder&aacute; ser feita por meio de cart&atilde;o de cr&eacute;dito.
              </p>
              <br />
              <p>
                4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; O seu acesso &agrave; Adv Service, conforme modalidade de plano
                contratada, ser&aacute; liberado imediatamente ap&oacute;s a confirma&ccedil;&atilde;o do pagamento da
                assinatura, a qual pode demorar at&eacute; 72 horas.
              </p>
              <br />
              <p>
                4.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cart&atilde;o De Cr&eacute;dito. Voc&ecirc; poder&aacute;
                utilizar 1 cart&atilde;o de cr&eacute;dito emitido no Brasil em seu nome.
              </p>
              <br />
              <p>
                4.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Parcelamento.&nbsp;&nbsp; As compras podem necessitar de uma
                confirma&ccedil;&atilde;o ou valida&ccedil;&atilde;o adicional &agrave; crit&eacute;rio da Adv Service e
                neste procedimento a Adv Service ou o processador de pagamentos poder&atilde;o requerer o envio de
                documentos pessoais para verifica&ccedil;&atilde;o da sua identidade.
              </p>
              <br />
              <p>
                4.4.1.&nbsp;&nbsp;&nbsp; No plano anual o parcelamento se limita a 12 vezes e no plano semestral o
                parcelamente se limita a 6 meses, em ambos a Adv Service poder&aacute; atualizar o valor e
                benef&iacute;cios dentro do mesmo per&iacute;odo sem a coleta do seu consentimento.
              </p>
              <br />
              <p>
                4.4.2.&nbsp;&nbsp;&nbsp; No plano mensal n&atilde;o haver&aacute; parcelamento sendo pr&eacute;-pago, a
                Adv Service poder&aacute; atualizar o valor e benef&iacute;cios a partir do m&ecirc;s seguinte de
                renova&ccedil;&atilde;o autom&aacute;tica sem a coleta do seu consentimento.
              </p>
              <br />
              <ol start="5">
                <li><strong> CANCELAMENTO E REEMBOLSO </strong></li>
              </ol>
              <p>
                5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; O Usu&aacute;rio poder&aacute; solicitar reembolso da sua conta
                manifestando sua vontade diretamente na plataforma e no local direcionado para o reembolso dentro do
                per&iacute;odo de 7 dias que passam a contar a partir da disponibiliza&ccedil;&atilde;o do
                servi&ccedil;o, mesmo que no per&iacute;odo de teste.
              </p>
              <br />
              <p>
                5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ap&oacute;s o prazo de 7 dias estabelecidos acima, o
                Usu&aacute;rio pode solicitar o cancelamento diretamente pela plataforma e o cancelamento ser&aacute;
                realizado.
              </p>
              <br />
              <p>5.2.1.&nbsp;&nbsp;&nbsp; CART&Atilde;O DE CR&Eacute;DITO</p>
              <br />
              <p>
                5.2.1.1.&nbsp; Em casos de reembolsos de pagamentos realizados por cart&atilde;o de cr&eacute;dito, seja
                &agrave; vista ou a prazo, a quantia total ser&aacute; reembolsada em um prazo de at&eacute; 90 dias,
                dependendo da data de fechamento do seu cart&atilde;o e das regras de reembolso do seu Banco.
              </p>
              <br />
              <p>
                5.2.1.2.&nbsp; O Usu&aacute;rio ser&aacute; respons&aacute;vel por qualquer equ&iacute;voco ou atraso em
                rela&ccedil;&atilde;o ao cadastramento dos dados banc&aacute;rios para reembolso dos valores.
              </p>
              <br />
              <p>
                5.2.1.3.&nbsp; Se o Usu&aacute;rios escolheu o PLANO ANUAL e SEMESTRAL com descontos na Adv Service,
                declara desde j&aacute; que tem conhecimento que n&atilde;o faremos reembolso, porque esse desconto
                reflete a sua escolha de pagamento para usar a plataforma e voc&ecirc; ter&aacute; acesso durante o
                per&iacute;odo de 12 meses correspondente com possibilidade de renova&ccedil;&atilde;o
                autom&aacute;tica, caso n&atilde;o pretenda cancelar.
              </p>
              <br />
              <ol start="6">
                <li><strong> CHARGEBACKS E ESTORNOS</strong></li>
              </ol>
              <p>
                6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Os Chargebacks reportados ser&atilde;o analisados pela
                operadora de cart&atilde;o de cr&eacute;dito, por nossos processadores de pagamentos, ou pela
                pr&oacute;pria Adv Service.
              </p>
              <br />
              <p>
                6.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A Adv Service ir&aacute; conduzir as disputas abertas por
                Voc&ecirc;, tais como, mas n&atilde;o somente, Chargebacks, reclama&ccedil;&otilde;es diversas,
                Cancelamento e Estornos.
              </p>
              <br />
              <ol start="7">
                <li><strong> COMBATE &Agrave; FRAUDE E A LAVAGEM DE DINHEIRO </strong></li>
              </ol>
              <p>
                7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A Adv Service cumpre rigorosamente todas as leis
                cab&iacute;veis, incluindo, mas n&atilde;o se limitando &agrave; legisla&ccedil;&atilde;o brasileira
                anticorrup&ccedil;&atilde;o e &agrave; legisla&ccedil;&atilde;o brasileira contra a lavagem de dinheiro.
              </p>
              <br />
              <ol start="8">
                <li><strong> ATUALIZA&Ccedil;&Otilde;ES DA POL&Iacute;TICA DE PAGAMENTO </strong></li>
              </ol>
              <p>
                8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A qualquer tempo a Adv Service poder&aacute; alterar esta
                Pol&iacute;tica, sendo responsabilidade do Usu&aacute;rio consultar o documento regularmente, bem como
                manter cadastrado e-mail v&aacute;lido para recebimento de comunica&ccedil;&otilde;es. A continuidade
                do&nbsp; uso&nbsp; ser&aacute; compreendida como concord&acirc;ncia e aceita&ccedil;&atilde;o integral.
              </p>
              <br />
              <p>
                8.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aplica-se, no que couber, de forma complementar e/ou
                acess&oacute;ria os demais Termos e Pol&iacute;ticas da Adv Service.
              </p>
              <br />
              <ol start="9">
                <li><strong> LEGISLA&Ccedil;&Atilde;O </strong></li>
              </ol>
              <p>
                9.1.&nbsp; Esta Pol&iacute;tica de Privacidade &eacute; regida e interpretada pelas leis brasileiras.
                Qualquer disputa decorrente de sua interpreta&ccedil;&atilde;o e aplica&ccedil;&atilde;o, caso
                n&atilde;o resolvido de forma amig&aacute;vel, dever&aacute; ser solucionada perante o Foro da Comarca
                de Santa Maria, Estado do Rio Grande do Sul, com ren&uacute;ncia expressa a qualquer outro, por mais
                privilegiado que seja.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
