import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withModifiers as _withModifiers, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '../../assets/img/adv-logo-white.png'
import _imports_1 from '@/assets/img/insta-white-icon.svg'
import _imports_2 from '@/assets/img/face-white-icon.svg'


const _hoisted_1 = { class: "bg-adv-blue-800 p-5" }
const _hoisted_2 = { class: "flex flex-col w-full h-auto items-center lg:flex-row justify-between" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row items-start justify-evenly w-full lg:items-start" }
const _hoisted_4 = { class: "flex flex-col items-start justify-start h-full my-5 lg:my-0 xl:my-0" }
const _hoisted_5 = { class: "flex flex-col items-start justify-start h-full my-5 lg:w-[100px] xl:my-0 sm:w-[200px]" }
const _hoisted_6 = { class: "flex flex-col items-start justify-start h-full my-5 lg:w-[100px] xl:my-0 sm:w-[200px]" }
const _hoisted_7 = { class: "flex items-start justify-start h-full flex-col" }
const _hoisted_8 = { class: "flex gap-3 w-full" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "flex justify-start items-center" }

import { http } from '@/core/api';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { getActiveSolutions } from '@/services/solutions';
import { useModalStore } from '@/store/modalStore';
import ContractModal from '@/components/Terms/contract.vue';
import TermsModal from '@/components/Terms/terms.vue';
import PrivacyModal from '@/components/Terms/privacy.vue';

const aClass = 'txt-btn text-white text-base mb-2 whitespace-nowrap';

export default /*@__PURE__*/_defineComponent({
  __name: 'Foot',
  setup(__props) {

const modalStore = useModalStore();
const router = useRouter();
const newsState = ref('toSend');
const newsInput = ref('');

const activeSolutions = ref<number[]>([]);

async function signNewsletter() {
  newsState.value = 'loading';
  try {
    await http.post('/newsletter', { email: newsInput.value });
    newsState.value = 'confirmed';
  } catch (err) {
    console.error(err);
    newsState.value = 'error';
  }
}

const handleRedirect = (url: string) => {
  window.open(url);
};

const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

onMounted(async () => {
  const { data } = await getActiveSolutions();

  activeSolutions.value = data.body.result.map((item: any) => {
    return item.id;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("footer", _hoisted_2, [
      _createElementVNode("div", { class: "flex flex-col gap-10 items-center m-20" }, [
        _cache[19] || (_cache[19] = _createElementVNode("img", {
          src: _imports_0,
          alt: "adv-white-logo",
          class: "w-42 lg:w-64 h-32 xl:w-64"
        }, null, -1)),
        _createElementVNode("button", {
          type: "button",
          onClick: goToSign,
          class: "txt-btn text-base font-medium border lg:whitespace-nowrap border-white text-adv-blue-800 bg-white py-3 px-7 tracking-[0.21em] hover:scale-75 animation-shrink hover:"
        }, " INICIAR GRÁTIS ")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _cache[20] || (_cache[20] = _createElementVNode("h3", { class: "text-adv-blue-100 text-xl font-bold font-Open mb-4" }, "Explorar", -1)),
          _createElementVNode("a", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (routeRoll('/'))),
            class: _normalizeClass(aClass)
          }, "Início"),
          _createElementVNode("a", {
            type: "button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (routeRoll('/adv-service'))),
            class: _normalizeClass(aClass)
          }, "ADV Service"),
          _createElementVNode("a", {
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (routeRoll('/planos'))),
            class: _normalizeClass(aClass)
          }, "Planos"),
          _createElementVNode("a", {
            type: "button",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (routeRoll('/blog'))),
            class: _normalizeClass(aClass)
          }, "Blog"),
          _createElementVNode("a", {
            type: "button",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (routeRoll('/noticias'))),
            class: _normalizeClass(aClass)
          }, "Notícias")
        ]),
        _createElementVNode("nav", _hoisted_5, [
          _cache[21] || (_cache[21] = _createElementVNode("h3", { class: "text-adv-blue-100 text-xl font-bold font-Open mb-4" }, "Soluções", -1)),
          (activeSolutions.value.includes(1))
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                type: "button",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (routeRoll('/trabalhista-service'))),
                class: _normalizeClass(aClass)
              }, "Trabalhista Service"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(2))
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                type: "button",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (routeRoll('/gestor-service'))),
                class: _normalizeClass(aClass)
              }, "Gestor Service"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(3))
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                type: "button",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (routeRoll('/pergunte-ao-especialista'))),
                class: _normalizeClass(aClass)
              }, "Pergunte ao Especialista"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(7))
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                type: "button",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (routeRoll('/mentoria-service'))),
                class: _normalizeClass(aClass)
              }, "Mentoria Service"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(8))
            ? (_openBlock(), _createElementBlock("a", {
                key: 4,
                type: "button",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (routeRoll('/buscador-service'))),
                class: _normalizeClass(aClass)
              }, "Buscador Service"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(9))
            ? (_openBlock(), _createElementBlock("a", {
                key: 5,
                type: "button",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (routeRoll('/pdf-service'))),
                class: _normalizeClass(aClass)
              }, "PDF Service"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(10))
            ? (_openBlock(), _createElementBlock("a", {
                key: 6,
                type: "button",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (routeRoll('/assinador-service'))),
                class: _normalizeClass(aClass)
              }, "Assinador Service"))
            : _createCommentVNode("", true),
          (activeSolutions.value.includes(11))
            ? (_openBlock(), _createElementBlock("a", {
                key: 7,
                type: "button",
                onClick: _cache[12] || (_cache[12] = ($event: any) => (routeRoll('/contratualista-service'))),
                class: _normalizeClass(aClass)
              }, "Contratualista Service"))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("nav", _hoisted_6, [
          _cache[22] || (_cache[22] = _createElementVNode("h3", { class: "text-adv-blue-100 text-xl font-bold font-Open mb-4" }, "Institucional", -1)),
          _createElementVNode("a", {
            type: "button",
            class: _normalizeClass(aClass),
            onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_unref(modalStore).termsModalOpen = true), ["prevent"]))
          }, "Termos de uso"),
          _createElementVNode("a", {
            type: "button",
            class: _normalizeClass(aClass),
            onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_unref(modalStore).privacyModalOpen = true), ["prevent"]))
          }, "Política de Privacidade"),
          _createElementVNode("a", {
            type: "button",
            class: _normalizeClass(aClass),
            onClick: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_unref(modalStore).contractModalOpen = true), ["prevent"]))
          }, "Contrato de compra")
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[25] || (_cache[25] = _createElementVNode("h3", { class: "text-adv-blue-100 text-xl font-bold font-Open mb-4" }, [
            _createTextVNode(" Inscreva-se e "),
            _createElementVNode("br"),
            _createTextVNode(" receba as novidades ")
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "email",
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((newsInput).value = $event)),
              placeholder: "E-mail",
              class: "h-12 p-2 w-64 rounded-sm focus:border-0 placeholder:font-Open placeholder:font-base"
            }, null, 512), [
              [_vModelText, newsInput.value]
            ]),
            _createElementVNode("button", {
              type: "button",
              title: "Enviar",
              disabled: newsState.value === 'loading' || newsState.value === 'confirmed',
              onClick: signNewsletter
            }, [
              _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
                [_vShow, newsState.value === 'loading']
              ]),
              _withDirectives(_createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'circle-up'],
                size: "2xl",
                style: {"color":"#ffffff"}
              }, null, 512), [
                [_vShow, newsState.value === 'toSend']
              ]),
              _withDirectives(_createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'circle-check'],
                size: "2xl",
                style: {"color":"#ffffff"}
              }, null, 512), [
                [_vShow, newsState.value === 'confirmed']
              ]),
              _withDirectives(_createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'circle-xmark'],
                size: "2xl",
                style: {"color":"#ffffff"}
              }, null, 512), [
                [_vShow, newsState.value === 'error']
              ])
            ], 8, _hoisted_9)
          ]),
          _cache[26] || (_cache[26] = _createElementVNode("h3", { class: "text-adv-blue-100 text-xl font-bold font-Open mb-1 mt-5" }, "Redes Sociais", -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              type: "button",
              class: "txt-btn mr-4",
              onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (handleRedirect('https://www.instagram.com/adv.service/')), ["prevent"]))
            }, _cache[23] || (_cache[23] = [
              _createElementVNode("img", {
                class: "h-9 w-auto",
                src: _imports_1,
                alt: "Instagram Icon"
              }, null, -1)
            ])),
            _createElementVNode("button", {
              type: "button",
              class: "txt-btn mr-4",
              onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (handleRedirect('https://www.facebook.com/trabalhista.service/')), ["prevent"]))
            }, _cache[24] || (_cache[24] = [
              _createElementVNode("img", {
                class: "h-9 w-auto",
                src: _imports_2,
                alt: "Facebook Icon"
              }, null, -1)
            ]))
          ])
        ])
      ]),
      _createVNode(ContractModal),
      _createVNode(TermsModal),
      _createVNode(PrivacyModal)
    ])
  ]))
}
}

})