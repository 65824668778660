import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row justify-between gap-12 items-center lg:items-start lg:p-28 p-16" }
const _hoisted_2 = { class: "flex justify-center items-center w-full" }
const _hoisted_3 = { class: "h-max w-full flex gap-12 justify-center flex-col items-start lg:border-r border-adv-blue-100 lg:pr-12" }
const _hoisted_4 = { class: "font-light italic text-sm text-adv-blue-800" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "font-bold text-adv-blue-800 text-4xl" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "flex flex-col md:flex-row justify-between items-center gap-3 w-full" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "flex w-full justify-center items-center" }

import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import BlogAside from '@/components/Asides/BlogAside.vue';
import { useRouter, useRoute } from 'vue-router';
import { useBlogStore } from '@/store/blogStore';
import { lightButtonStyle } from '@/assets/css/styles';
import Related from '@/components/Related/Related.vue';
import { PostNav } from '@/types/postTypes';


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogPost',
  setup(__props) {

const blogStore = useBlogStore();
const router = useRouter();
const route = useRoute();

const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

const navToPost = (item: { id: number; slug: string }) => {
  window.scrollTo(0, 0);
  router.push(`/blog/${item.id}/${item.slug}`);
  blogStore.getPostById(Number(item.id));
};

onMounted(() => {
  blogStore.getPostById(Number(route.params.id));
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(LoadingSpinner, { color: "#4375D9" })
        ], 512), [
          [_vShow, _unref(blogStore).loading]
        ]),
        _withDirectives(_createElementVNode("article", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, "Início / Blog / " + _toDisplayString(_unref(blogStore).selectedPost?.title), 1),
          _createElementVNode("img", {
            src: _unref(blogStore).selectedPost?.cover,
            alt: "Imagem de Capa",
            class: "max-h-96 w-full object-cover"
          }, null, 8, _hoisted_5),
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_unref(blogStore).selectedPost?.title), 1),
          _createElementVNode("div", {
            class: "font-sm text-black text-justify lg:text-left post-content",
            innerHTML: _unref(blogStore).selectedPost?.content
          }, null, 8, _hoisted_7),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([_unref(lightButtonStyle), "mt-3 max-w-[12rem]"]),
              disabled: !_unref(blogStore).selectedPost?.prev,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (navToPost(_unref(blogStore).selectedPost?.prev as PostNav)))
            }, " < ANTERIOR ", 10, _hoisted_9),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass([_unref(lightButtonStyle), "mt-3 max-w-[12rem]"]),
              disabled: !_unref(blogStore).selectedPost?.next,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (navToPost(_unref(blogStore).selectedPost?.next as PostNav)))
            }, " PRÓXIMO > ", 10, _hoisted_10)
          ]),
          _createElementVNode("nav", _hoisted_11, [
            _createElementVNode("a", {
              class: "text-adv-blue-800 text-2xl cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (routeRoll('/blog')))
            }, "Voltar para o Blog")
          ])
        ], 512), [
          [_vShow, !_unref(blogStore).loading]
        ]),
        _createVNode(BlogAside)
      ]),
      _createVNode(Related)
    ]),
    _: 1
  }))
}
}

})