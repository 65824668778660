import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {
      background: 'radial-gradient(74.81% 444.1% at 13.56% 38.21%, #F7F7F7 0%, rgba(231, 231, 231, 0.92) 100%)',
    },
  class: "w-full p-6 lg:p-28 flex flex-col justify-center items-center gap-14"
}
const _hoisted_2 = {
  key: 1,
  class: "grid grid-cols-1 lg:grid-cols-2 gap-8 items-center justify-center"
}

import { onMounted, ref } from 'vue';
import BenefitCard from './BenefitCard.vue';
import { http } from '@/core/api';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

interface BenefitsProps {
  solutionId: number;
}

interface ResourcesType {
  id: number;
  name: string;
  description: string;
  solutionId?: 1;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Benefits',
  props: {
    solutionId: { default: 1 }
  },
  setup(__props: any) {

const benefits = ref<ResourcesType[]>([]);
const loading = ref<boolean>(false);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = __props;

async function getBenefitsList() {
  loading.value = true;
  try {
    const { data } = await http.get(`solutions/${props.solutionId}`);

    benefits.value = data.body.resources;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getBenefitsList();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "font-bold tracking-wider text-adv-blue-800 text-4xl" }, "Benefícios", -1)),
    (loading.value)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(benefits.value, (resource) => {
            return (_openBlock(), _createBlock(BenefitCard, {
              key: resource.id,
              title: resource.name,
              text: resource.description
            }, null, 8, ["title", "text"]))
          }), 128))
        ]))
  ]))
}
}

})