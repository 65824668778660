<script lang="ts" setup>
import { ref } from 'vue';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import Layout from '@/components/BaseLayout/Layout.vue';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { http } from '@/core/api';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { Notyf } from 'notyf';

const notyf = new Notyf();
const loading = ref(false);

const validationSchema = toTypedSchema(
  zod.object({
    name: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    email: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    phone: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    subject: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    description: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    answered: zod.boolean(),
  })
);
const { handleSubmit, errors } = useForm({
  validationSchema,
  initialValues: {
    name: '',
    email: '',
    answered: false,
    phone: '',
    subject: '',
    description: '',
  },
});

const { value: name } = useField('name');
const { value: email } = useField('email');
const { value: phone } = useField('phone');
const { value: subject } = useField('subject');
const { value: description } = useField<string>('description');

const handleSignup = handleSubmit(async (values) => {
  if (loading.value) return;
  loading.value = true;

  try {
    await http.post('/contact', values);
    notyf.success('Mensagem enviada com sucesso');
  } catch (err) {
    notyf.error('Houve um erro durante o envio, tente novamente mais tarde!');
    console.error(err);
  }

  loading.value = false;
});
</script>

<template>
  <Layout>
    <section class="bg-white p-12 min-h-[39rem] w-full flex flex-col items-center justify-center gap-14">
      <h3 class="font-bold text-adv-blue-800 text-2xl">ENTRE EM CONTATO</h3>
      <p class="text-base text-adv-blue-800 text-center">Informe os dados para contato</p>
      <form @submit.prevent="handleSignup" class="flex flex-col gap-4">
        <div class="flex gap-5 w-full flex-col md:flex-row">
          <div class="w-full">
            <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-1">
              Nome completo <strong class="text-red-600">*</strong>
            </p>
            <input
              name="name"
              type="text"
              v-model="name"
              :class="formInput + `${errors.name ? 'border-2 border-rose-500' : ''}`"
            />
            <span class="mt-1 text-xs text-rose-500">{{ errors.name }}</span>
          </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
          <div class="w-full">
            <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-1">
              E-mail <strong class="text-red-600">*</strong>
            </p>
            <input
              name="email"
              type="email"
              v-model="email"
              :class="formInput + `${errors.email ? 'border-2 border-rose-500' : ''}`"
            />
            <span class="mt-1 text-xs text-rose-500">{{ errors.email }}</span>
          </div>
          <div class="w-full">
            <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-1">
              Telefone <strong class="text-red-600">*</strong>
            </p>
            <input
              type="phone"
              v-maska
              data-maska="['(##) #####-####', '(##) ####-####']"
              v-model="phone"
              :class="formInput + `${errors.phone ? 'border-2 border-rose-500' : ''}`"
            />
            <span class="mt-1 text-xs text-rose-500">{{ errors.phone }}</span>
          </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
          <div class="w-full">
            <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-1">
              Assunto <strong class="text-red-600">*</strong>
            </p>
            <input
              name="subject"
              type="text"
              v-model="subject"
              :class="formInput + `${errors.subject ? 'border-2 border-rose-500' : ''}`"
            />
            <span class="mt-1 text-xs text-rose-500">{{ errors.subject }}</span>
          </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
          <div class="w-full">
            <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-1">
              Descreva sua dúvida <strong class="text-red-600">*</strong>
            </p>
            <textarea
              name="description"
              type="text"
              v-model="description"
              class="h-80"
              :class="formInput + `${errors.description ? 'border-2 border-rose-500' : ''}`"
            ></textarea>
            <span class="mt-1 text-xs text-rose-500">{{ errors.description }}</span>
          </div>
        </div>
        <button type="submit" :class="lightButtonStyle" class="max-w-[30rem] w-full flex justify-center items-center">
          <p v-show="!loading">ENVIAR</p>
          <LoadingSpinner v-show="loading" />
        </button>
      </form>
    </section>
  </Layout>
</template>
