import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Header  from '@/components/Header/Head.vue';
  import Footer from '@/components/Footer/Foot.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Header),
    _createElementVNode("main", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(Footer)
  ], 64))
}
}

})