import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex justify-center items-center" }
const _hoisted_2 = {
  key: 1,
  class: "h-max w-full flex justify-center flex-col items-center lg:p-28 sm:p-16 p-6 lg:bg-[#F6F6F6]"
}
const _hoisted_3 = { class: "font-bold text-4xl text-adv-blue-800 mb-16 max-w-lg text-center tracking-wider" }
const _hoisted_4 = { class: "mb-9 w-full lg:border rounded-xl bg-white flex flex-col lg:block gap-2" }

import { lightButtonStyle } from '@/assets/css/styles';
import { FaqProps } from '@/types/cardTypes';
import FaqQuestionBox from './FaqQuestionBox.vue';
import { onMounted } from 'vue';
import { useFaqStore } from '@/store/faqStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { useRouter } from 'vue-router';

interface FaqCardProps {
  title: string;
  questions: FaqProps[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Faq',
  props: {
    title: {},
    questions: {}
  },
  setup(__props: any) {

const router = useRouter();
const store = useFaqStore();

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

onMounted(() => {
  store.getQuestions();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).loading)
      ? (_openBlock(), _createBlock(LoadingSpinner, {
          key: 0,
          width: "2rem",
          height: "2rem",
          color: "#4375D9"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (q) => {
              return (_openBlock(), _createBlock(FaqQuestionBox, {
                key: q.id,
                id: q.id,
                title: q.title,
                answer: q.answer
              }, null, 8, ["id", "title", "answer"]))
            }), 128))
          ]),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(_unref(lightButtonStyle)),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/contato')))
          }, "FALE CONOSCO", 2)
        ]))
  ]))
}
}

})