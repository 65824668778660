import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "29",
  height: "30",
  viewBox: "0 0 29 30",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

interface IProps {
  color?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'RedTickIcon',
  props: {
    color: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M14.1583 29.2415C22.1455 29.2415 28.6204 22.772 28.6204 14.7914C28.6204 6.81088 22.1455 0.34137 14.1583 0.34137C6.17117 0.34137 -0.303711 6.81088 -0.303711 14.7914C-0.303711 22.772 6.17117 29.2415 14.1583 29.2415Z",
      fill: _ctx.color || '#EC2024'
    }, null, 8, _hoisted_2),
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      d: "M16.9991 15.0474C16.7356 14.7899 16.7311 14.3678 16.9888 14.1046L21.2314 9.77031C21.808 9.1942 21.9955 8.18763 21.3312 7.52391C20.6669 6.86019 19.6265 6.96828 19.0503 7.54401L14.6764 11.8601C14.4171 12.1157 14.0011 12.1168 13.7407 11.8623L9.41806 7.63693C8.84147 7.06083 7.839 6.86816 7.18308 7.52391C6.52717 8.17928 6.64066 9.21316 7.21725 9.78889L11.4682 14.0886C11.7271 14.3507 11.7248 14.7728 11.4625 15.0319L7.36377 19.0768C6.78718 19.6529 6.64142 20.8745 7.30569 21.5378C7.96996 22.2015 9.19259 22.0559 9.76879 21.4798L13.7749 17.3871C14.0357 17.1205 14.4646 17.1197 14.7269 17.3848L18.7607 21.465C19.3373 22.0411 20.5865 22.2266 21.2424 21.5708C21.8983 20.9154 21.7127 19.6673 21.1365 19.0912L16.9991 15.0474Z",
      fill: "white"
    }, null, -1))
  ]))
}
}

})