<script setup lang="ts">
import { planType } from '@/types/cardTypes';
import Card from '@/components/CardDysplay/Card.vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useRouter } from 'vue-router';

interface CardCarouselProps {
    plans: planType[];
    isComparsion: boolean;
}

const storePlans = useServicePlansStore();
const router = useRouter();

function goToSignUp(sPlan: planType): void {
    storePlans.setSelected(sPlan);
    if (!window.location.href.includes('cadastro')) {
        window.scrollTo(0, 0);
        router.push('/planos/cadastro');
    }
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = withDefaults(defineProps<CardCarouselProps>(), {
    plans: () => [],
    isComparsion: false,
});
</script>

<template>
    <Carousel :items-to-show="1" :snap-align="'center'" :breakpoints="{
        700: {
            itemsToShow: isComparsion ? 1 : 2,
        },
        1024: {
            itemsToShow: isComparsion ? 1 : 3,
        },
    }">
        <Slide v-for="(plan, index) in plans" :key="index">
            <Card :class="storePlans.selectedPlan?.id === plan.id ? 'border-8 border-adv-blue-100 shadow-sm' : ''"
                :id="plan.id" :periodicity="plan.periodicity" :cardTitle="plan.name" :price="plan.price"
                :solutions="plan.solutions" :solutions-benefits="plan.planSolutionBenefits"
                @go-to-sign-up="goToSignUp" />
        </Slide>

        <template #addons>
            <Navigation />

        </template>
    </Carousel>
</template>

<style lang="css">
.carousel__slide {
    align-items: start !important;
}

.carousel__prev {
    left: -10% !important;
    color: #222222 !important;
}

.carousel__next {
    right: -10% !important;
    color: #222222 !important;
}

@media screen and (min-width: 1024px) {
    .carousel__prev {
        left: -15% !important;
    }

    .carousel__next {
        right: -15% !important;
    }
}
</style>
