<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useSignUpStore } from '@/store/signUpStore';
import { darkButtonStyle, lightButtonStyle } from '@/assets/css/styles';
import GreenTickIcon from '../icons/GreenTickIcon.vue';
import RedTickIcon from '../icons/RedTickIcon.vue';
import YellowTickIcon from '../icons/YellowTickIcon.vue';

const store = useSignUpStore();
const router = useRouter();
</script>

<template>
  <div class="contents">
    <div
      class="bg-adv-gray-300 p-20 max-w-5xl w-full flex flex-col rounded-3xl relative justify-around gap-11 items-center"
    >
      <GreenTickIcon v-if="store.validPayment === 'SUCCESS'" class="absolute -top-1/4" width="8rem" height="8rem" />
      <YellowTickIcon
        v-else-if="store.validPayment === 'PROCESSING'"
        class="absolute -top-1/4"
        width="8rem"
        height="8rem"
      />
      <RedTickIcon v-else class="absolute -top-1/4" width="8rem" height="8rem" />
      <h2 v-if="store.validPayment === 'SUCCESS'" class="text-adv-blue-800 text-center font-bold text-3xl">
        Seu pagamento foi confirmado!
      </h2>
      <h2 v-else-if="store.validPayment === 'PROCESSING'" class="text-adv-blue-800 text-center font-bold text-3xl">
        Seu pagamento está sendo processado!
      </h2>
      <h2 v-else class="text-adv-blue-800 text-center font-bold text-3xl">Infelizmente seu pagamento foi recusado</h2>
      <p
        v-if="store.validPayment === 'PROCESSING' || store.validPayment === 'SUCCESS'"
        class="text-adv-gray-400 text-center text-lg font-bold"
      >
        O e-mail de confirmação e detalhes do seu pedido foram enviados para
        <strong class="text-black">{{ store.signUpForm.eMail }}</strong>
      </p>
      <p
        v-if="store.validPayment !== 'PROCESSING' && store.validPayment !== 'SUCCESS'"
        class="text-adv-gray-400 text-center text-lg font-bold"
      >
        Algo deu errado e não conseguimos processar o seu pedido. Revise as informações ou tente usar outro cartão de
        crédito.
      </p>
      <button
        v-if="store.validPayment === 'SUCCESS' || store.validPayment === 'PROCESSING'"
        :class="lightButtonStyle"
        @click="router.push('/login')"
      >
        ENTRAR
      </button>
    </div>
    <button
      v-if="store.validPayment !== 'PROCESSING' && store.validPayment !== 'SUCCESS'"
      :class="darkButtonStyle"
      @click="store.step = 2"
    >
      TENTAR NOVAMENTE
    </button>
  </div>
</template>
