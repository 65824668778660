import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex h-fit w-full flex-col items-center justify-center rounded-3xl bg-adv-blue-900 max-w-xs min-w-[320px]" }
const _hoisted_2 = { class: "bg-[#0D1643] w-full rounded-t-3xl p-6 mb-4" }
const _hoisted_3 = { class: "bg-[#0C364D] rounded-full px-2 py-0.5" }
const _hoisted_4 = {
  key: 0,
  class: "text-[#34D399] text-xs"
}
const _hoisted_5 = {
  key: 1,
  class: "text-[#34D399] text-xs"
}
const _hoisted_6 = {
  key: 2,
  class: "text-[#34D399] text-xs"
}
const _hoisted_7 = { class: "my-3 font-Open text-3xl font-bold text-white tracking-wider" }
const _hoisted_8 = { class: "p-6 pt-0 w-full" }
const _hoisted_9 = {
  key: 0,
  class: "text-center font-Open text-xl font-normal text-white"
}
const _hoisted_10 = { class: "flex justify-center gap-1 mt-4" }
const _hoisted_11 = {
  class: "self-start font-Open text-8xl font-extrabold text-white",
  style: { lineHeight: '0.8 !important' }
}
const _hoisted_12 = { class: "font-Open text-4xl font-extrabold text-white" }
const _hoisted_13 = {
  key: 1,
  class: "font-Open text-sm font-normal text-center text-white mt-6"
}
const _hoisted_14 = { class: "flex justify-center items-center" }
const _hoisted_15 = ["open"]
const _hoisted_16 = { class: "flex h-max w-full flex-col justify-start" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "ml-2 font-Open text-xl text-start font-normal text-white" }
const _hoisted_19 = {
  key: 0,
  class: "flex flex-col justify-between items-start gap-1 w-fit"
}
const _hoisted_20 = { class: "text-adv-light-blue text-sm text-start" }

import type { planType, serviceType, PlanSolutionBenefitsType } from '@/types/cardTypes';
import GreenTickIcon from '@/components/icons/GreenTickIcon.vue';
import { ref, computed } from 'vue';
import { useSignUpStore } from '@/store/signUpStore';
import { useRoute } from 'vue-router';
// import RedTickIcon from '@/components/icons/RedTickIcon.vue';
interface CardProps {
    id: number | null;
    cardTitle: string;
    toSolutionId: number | null;
    solutions: serviceType[];
    price: number;
    periodicity: number;
    solutionsBenefits: PlanSolutionBenefitsType[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Card',
  props: {
    id: { default: null },
    cardTitle: { default: '' },
    toSolutionId: { default: null },
    solutions: { default: () => [] },
    price: { default: 49.9 },
    periodicity: { default: 0 },
    solutionsBenefits: {}
  },
  emits: ['goToSignUp'],
  setup(__props: any, { emit: __emit }) {

const route = useRoute()
const store = useSignUpStore();

const toggleOpen = (item: number) => {
    const index = opened.value.indexOf(item);

    if (index > -1) {
        return opened.value.splice(index, 1);
    }

    return opened.value.push(item);
};

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

const opened = ref<number[]>(props.solutions.map((_, index) => index));

const emit = __emit;


const computedSolutions = computed(() => {
    const mappedSolutions = props.solutions.map(solution => {
        const solutionBenefit = props.solutionsBenefits?.find(item => item.solutionId === solution.id);
        return {
            ...solution,
            order: solutionBenefit ? solutionBenefit.order : null
        };
    });


    const sortedSolutions = mappedSolutions.sort((a, b) => a.order - b.order);

    return sortedSolutions;
});


const handleHire = () => {
    if (store.type === 'DAYS')
        store.clearCoupon();

    emit('goToSignUp', {
        id: props.id,
        periodicity: props.periodicity,
        toSolutionId: props.toSolutionId,
        title: props.cardTitle,
        name: props.cardTitle,
        solutions: props.solutions,
        products: props.products,
        price: props.price,
        planSolutionBenefits: props.solutionsBenefits,
    } as planType
    );
};

const findBenefits = (index) => {
    const { id } = computedSolutions.value[index] || {}
    if (!id)
        return
    return props.solutionsBenefits.find(benefits => benefits.solutionId === id)?.benefits || []

}

const allowedPaths = ['/gestor-service', '/planos', '/trabalhista-service', '/pergunte-ao-especialista', '/mentoria-service', '/buscador-service', '/assinador-service'];
const isOpen = allowedPaths.includes(route.path);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        (_ctx.periodicity == 1)
          ? (_openBlock(), _createElementBlock("label", _hoisted_4, "Teste grátis!"))
          : _createCommentVNode("", true),
        (_ctx.periodicity == 6)
          ? (_openBlock(), _createElementBlock("label", _hoisted_5, "Economize 10%"))
          : _createCommentVNode("", true),
        (_ctx.periodicity == 12)
          ? (_openBlock(), _createElementBlock("label", _hoisted_6, "Economize 20%"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.cardTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.periodicity > 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.periodicity) + "x de ", 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-right font-Open text-xl font-extrabold text-white" }, "R$", -1)),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.price.toFixed(2).split('.')[0]), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_12, "," + _toDisplayString(_ctx.price.toFixed(2).split('.')[1]), 1),
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-Open text-sm font-normal text-white" }, "por mês", -1))
        ])
      ]),
      (_ctx.periodicity > 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_13, " faturado " + _toDisplayString(_ctx.periodicity >= 1 ? (_ctx.periodicity === 6 ? 'semestralmente' : 'anualmente') : ''), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          type: "button",
          style: { letterSpacing: '0.21em' },
          class: "txt-btn mt-8 flex h-11 w-44 items-center justify-center bg-adv-blue-100 text-white cursor-pointer",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                        handleHire()
                        ), ["prevent","stop"]))
        }, " CONTRATAR ")
      ]),
      _createElementVNode("details", {
        class: "mt-5 mx-2",
        open: _unref(isOpen)
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("summary", { class: "text-white text-center" }, "Detalhes ", -1)),
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedSolutions.value, (service, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "my-4 flex flex-col justify-center items-start w-full"
            }, [
              _createElementVNode("div", {
                class: "flex flex-row items-center cursor-pointer",
                onClick: _withModifiers(($event: any) => (toggleOpen(index)), ["stop"])
              }, [
                _createElementVNode("p", _hoisted_18, _toDisplayString(service.name), 1)
              ], 8, _hoisted_17),
              _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "my-4 w-full border-t border-adv-blue-100" }, null, -1)),
              (opened.value.includes(index))
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(findBenefits(index), (benefit, idx) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: `bene-$${idx}`,
                        class: "text-adv-light-blue text-sm flex justify-start items-start w-fit gap-2"
                      }, [
                        _cache[3] || (_cache[3] = _createTextVNode(" • ")),
                        _createElementVNode("p", _hoisted_20, _toDisplayString(benefit), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ], 8, _hoisted_15)
    ])
  ]))
}
}

})