<script setup lang="ts">
import CardsContainer from '@/components/CardDysplay/CardsContainer.vue';
import GreenTickIcon from '@/components/icons/GreenTickIcon.vue';
// import RedTickIcon from '@/components/icons/RedTickIcon.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';

const storePlans = useServicePlansStore();
console.log('plans', storePlans.selectedPlan)
</script>

<template>
    <CardsContainer ContainerTitle="">
        <div class="w-full flex items-end flex-col md:flex-row justify-center gap-4 md:gap-12 lg:gap-16">
            <div class="flex flex-col justify-between items-start gap-7">
                <h2 class="mb-3 font-Open text-3xl font-bold text-white" :style="{ letterSpacing: '0.095em' }">
                    Cadastro - Plano {{ storePlans.selectedPlan?.name }}
                </h2>
                <div class="grid md:grid-cols-2 grid-rows-2 gap-x-10 gap-y-7 h-fit md:h-max w-fit md:w-max">
                    <div v-for="(service, index) in storePlans.selectedPlan?.solutions" :key="index"
                        class="flex flex-row items-center w-max">
                        <GreenTickIcon />
                        <!-- <RedTickIcon v-if="!service.included" /> -->
                        <p class="ml-2 font-Open text-xl font-normal text-white">{{ service.name }}</p>
                    </div>
                </div>
            </div>
            <div class="flex gap-1">
                <p class="text-right font-Open text-xl font-extrabold text-white">R$</p>
                <p class="self-start font-Open text-8xl font-extrabold text-white"
                    :style="{ lineHeight: '0.8 !important' }">
                    {{ storePlans.selectedPlan?.price.toFixed(2).split('.')[0] }}
                </p>
                <div>
                    <p class="font-Open text-4xl font-extrabold text-white">
                        ,{{ storePlans.selectedPlan?.price.toFixed(2).split('.')[1] }}
                    </p>
                    <p class="font-Open text-sm font-normal text-white">por mês</p>
                </div>
            </div>
        </div>
    </CardsContainer>
</template>
