<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { lightButtonStyle } from '@/assets/css/styles';
import { useBlogStore } from '@/store/blogStore';

const blogStore = useBlogStore();

const active = ref<number>();

const handleCat = async (catId: number | undefined) => {
  blogStore.catId = catId;
  if (catId === undefined) {
    active.value = undefined;
    await blogStore.getPostList();
  } else {
    active.value = catId;
    await blogStore.getPostList();
  }
};

const isActive = (catId: number) => {
  return active.value === catId ? 'text-adv-blue-800 font-semibold' : 'text-adv-gray-500 ';
};

onMounted(async () => {
  await blogStore.getCategorieList();
});
</script>

<template>
  <aside class="flex flex-col items-center lg:items-start justify-center lg:justify-start h-full w-96">
    <h3 class="font-bold text-2xl text-adv-blue-800">Categorias</h3>
    <hr class="w-20 border-2 border-adv-blue-100 my-3" />
    <nav class="flex flex-col gap-3">
      <a
        v-for="cat in blogStore.categories"
        :key="cat.id"
        class="text-xl cursor-pointer"
        :class="isActive(cat.id)"
        @click.prevent="handleCat(cat.id)"
        >{{ cat.title }}</a
      >
      <a
        v-if="active !== undefined"
        class="text-adv-blue-100 text-xl cursor-pointer"
        @click.prevent="handleCat(undefined)"
        >Limpar Filtros</a
      >
    </nav>
    <hr class="w-full border border-t-0 border-adv-blue-100 my-9" />
    <h3 class="font-bold text-2xl text-adv-blue-800">
      Inscreva-se em <br />
      nosso blog.
    </h3>
    <hr class="w-20 border-2 border-adv-blue-100 my-3" />
    <form name="inscrever-blog-form">
      <input
        type="text"
        placeholder="NOME:"
        class="h-12 mb-3 mt-6 font-FuturaBkBt p-2 pl-6 w-full border border-adv-gray-350 bg-adv-gray-300 focus:border-0 placeholder:font-FuturaBkBt placeholder:font-base placeholder:text-black"
      />
      <input
        type="email"
        placeholder="E-MAIL:"
        class="h-12 mb-6 font-FuturaBkBt p-2 pl-6 w-full border border-adv-gray-350 bg-adv-gray-300 focus:border-0 placeholder:font-FuturaBkBt placeholder:font-base placeholder:text-black"
      />
      <button type="submit" :class="lightButtonStyle">INSCREVER</button>
    </form>
  </aside>
</template>
