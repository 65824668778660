<script lang="ts" setup>
import Layout from '@/components/BaseLayout/Layout.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Title from '@/components/Title/Title.vue';
import Founders from '@/components/Founders/Founders.vue';
import { useRouter } from 'vue-router';
import { lightButtonStyle } from '@/assets/css/styles';

const router = useRouter();
function goTo(el: string): void {
  document.getElementById(el)?.scrollIntoView({ behavior: 'smooth' });
}
function goPlans(): void {
  window.scrollTo(0, 0);
  router.push('/planos');
}
</script>

<template>
  <Layout>
    <Title :height="28" imgUrl="url('@/assets/img/bg-serv-title.svg')">
      <h1 class="text-white font-Open font-bold text-6xl z-10">Quem Somos</h1>
    </Title>
    <section
      :style="{
        background: 'radial-gradient(70.4% 239.58% at 9.86% 89.57%, #FFFFFF 15.57%, rgba(181, 181, 181, 0.35) 100%)',
      }"
      class="h-max w-full flex gap-12 justify-center flex-col lg:flex-row-reverse items-center p-8 lg:p-28 md:p-16"
    >
      <div class="max-w-md flex flex-col justify-around items-center lg:items-start">
        <h2 class="font-bold text-adv-blue-800 text-justify lg:text-left tracking-wider text-4xl">ADV Service</h2>
        <p class="font-normal text-adv-gray-500 text-base my-8">
          Nossa história começou com o Trabalhista Service, partindo da iniciativa de 3 advogados acostumados com o
          ambiente digital. <br />
          <br />

          Eles perceberam que o mercado carecia de algumas soluções especializadas com um grande potencial para tornar o
          trabalho de profissionais do direito mais eficiente.<br />
          <br />

          Com o tempo e a alta adesão de advogados trabalhistas à plataforma, nós percebemos que era hora de crescer.<br />
          <br />

          Hoje, somos a ADV Service, uma plataforma completa com soluções tecnológicas para a área do Direito.
          Idealizada por advogados, para advogados.<br />
          <br />

          Contamos com diversas ferramentas que auxiliam advogados a alcançar o máximo de seu potencial com mais
          agilidade.
        </p>
        <button type="button" @click="goTo('solutionEl')" :class="lightButtonStyle" class="w-fit">SOLUÇÕES</button>
      </div>
      <img
        src="@/assets/img/adv-logo-blue.svg"
        alt="ADVSERVICE"
        class="-translate-x-4 -translate-y-4 max-h-96 w-auto"
      />
    </section>
    <Founders />
    <section
      class="bg-[url('@/assets/img/bg-mvv-banner.svg')] p-12 min-h-[39rem] w-full bg-cover flex flex-col lg:flex-row items-center justify-center gap-14"
    >
      <div class="bg-adv-blue-900 w-72 h-[22rem] gap-4 items-center flex flex-col py-12 px-10">
        <h3 class="font-bold text-white text-2xl">MISSÃO</h3>
        <hr class="border-adv-blue-100 w-full mb-5" />
        <p class="text-base text-white text-center">
          Alavancar resultados de advogados e escritórios, facilitando tarefas do dia a dia.
        </p>
      </div>
      <div class="bg-adv-blue-900 w-72 h-[22rem] gap-4 items-center flex flex-col py-12 px-10">
        <h3 class="font-bold text-white text-2xl">VISÃO</h3>
        <hr class="border-adv-blue-100 w-full mb-5" />
        <p class="text-base text-white text-center">
          Ser uma empresa líder em inovação na Advocacia 4.0, otimizando serviços jurídicos e sendo a maior aliada dos
          advogados.
        </p>
      </div>
      <div class="bg-adv-blue-900 w-72 h-[22rem] gap-4 items-center flex flex-col py-12 px-10">
        <h3 class="font-bold text-white text-2xl">VALORES</h3>
        <hr class="border-adv-blue-100 w-full mb-5" />
        <p class="text-base text-white text-center">Inovação, Excelência, Ética, Praticidade e Transparência.</p>
      </div>
    </section>
    <Solutions id="solutionEl" title="Soluções" />
    <section
      class="bg-[url('@/assets/img/bg-conheca-banner.svg')] w-full min-h-[33rem] bg-cover flex items-center justify-center p-16"
    >
      <button @click="goPlans" :class="lightButtonStyle" class="max-w-[30rem] w-full">
        DESCUBRA O PLANO IDEAL PARA VOCÊ
      </button>
    </section>
  </Layout>
</template>
