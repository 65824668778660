<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import Card from '@/components/CardDysplay/Card.vue';
import CardsContainer from '@/components/CardDysplay/CardsContainer.vue';
import CardsCarousel from '@/components/CardDysplay/CardsCarousel.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useRouter } from 'vue-router';
import { planType } from '@/types/cardTypes';
import { useSignUpStore } from '@/store/signUpStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const router = useRouter();

const unfPlan = ref<planType[]>([]);

const filteredPlans = computed(() => {
  return unfPlan.value
    ?.filter((x) => {
      return x.toSolutionId === null;
    })
    .filter((y) => {
      return y.periodicity === storePlans.activePer;
    })
    .sort(function (a, b) {
      return parseFloat(a.price.toString()) - parseFloat(b.price.toString());
    });
});

function goToSignUp(sPlan: planType): void {
  storePlans.setSelected(sPlan);
  window.scrollTo(0, 0);
  router.push('/planos/cadastro');
}

function handleActivePer(per: number): void {
  console.log(store.type, 'DAYS')
  if(store.type === 'DAYS')
  store.clearCoupon();

  storePlans.activePer = per
}

onBeforeMount(async () => {
  unfPlan.value = await storePlans.getPlans();
});
</script>

<template>
  <CardsContainer ContainerTitle="Planos">
    <div class="flex justify-center items-center mb-24 -mt-3">
      <button
        class="w-fit border-2 border-r-0 border-white py-2 px-6 rounded-s-full active:bg-adv-blue-100"
        :class="storePlans.activePer === 1 ? 'bg-adv-blue-100' : ''"
        @click="handleActivePer(1)"
      >
        <p class="text-white text-sm">Mensal</p>
      </button>
      <button
        class="w-fit border-2 border-white border-r-0 py-2 px-6 active:bg-adv-blue-100"
        :class="storePlans.activePer === 6 ? 'bg-adv-blue-100' : ''"
        @click="handleActivePer(6)"
      >
        <p class="text-white text-sm">Semestral</p>
      </button>
      <button
        class="w-fit border-2 border-white py-2 px-6 rounded-e-full active:bg-adv-blue-100"
        :class="storePlans.activePer === 12 ? 'bg-adv-blue-100' : ''"
        @click="handleActivePer(12)"
      >
        <p class="text-white text-sm">Anual</p>
      </button>
    </div>
    <LoadingSpinner v-if="storePlans.loading" />
    <div class="contents" v-else>
      <CardsCarousel :plans="filteredPlans" class="lg:hidden" />
      <div class="hidden w-full grid-flow-col justify-center gap-12 lg:gap-16 lg:grid">
        <Card
          v-for="plan in filteredPlans"
          :key="plan.id"
          :id="plan.id"
          :periodicity="plan.periodicity"
          :cardTitle="plan.name"
          :price="plan.price"
          :solutions="plan.solutions"
          :solutions-benefits="plan.planSolutionBenefits"
          @go-to-sign-up="goToSignUp"
        />
      </div>
    </div>
  </CardsContainer>
</template>
