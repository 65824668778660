import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "fixed top-28 left-0 z-20 mt-2 w-full rounded-md bg-header-grey py-2 flex flex-col justify-center items-center" }

import { onBeforeMount, ref } from 'vue';
import { http } from '@/core/api';
import { useRouter } from 'vue-router';
import { RoutesEnum } from '@/types/routesEnum';

type SolutionObj = {
  id: number;
  active: boolean;
  description: string;
  image: string;
  name: string;
  serviceLink: string;
};

const txtClass =
  'txt-btn font-bold block px-4 py-2 text-xl text-dark-grey hover:bg-adv-blue-800 hover:text-header-grey';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropDownMenu',
  setup(__props) {

const router = useRouter();
const solutions = ref<SolutionObj[]>([]);
const loading = ref(false);

const show = ref(false);
function onClickAway() {
  show.value = false;
}

async function getSolutionsList() {
  loading.value = true;
  try {
    const { data } = await http.get('solutions/all?active=true');

    solutions.value = data.body.result;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
    console.log(solutions.value);
  }
}

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

onBeforeMount(async () => {
  await getSolutionsList();
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value = !show.value)),
      class: "txt-btn lg:hidden flex items-center rounded-md bg-transparent text-xs text-dark-grey focus:outline-none"
    }, [
      _createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'bars'],
        size: "2xl",
        style: {"color":"#253170"}
      })
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: _normalizeClass(txtClass)
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" INÍCIO ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/adv-service",
        class: _normalizeClass(txtClass)
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" QUEM SOMOS ")
        ])),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(solutions.value, (sol) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: sol.id,
          to: _unref(RoutesEnum)[sol.id],
          class: _normalizeClass(txtClass)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(sol.name.toUpperCase()), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128)),
      _createVNode(_component_router_link, {
        to: "/planos",
        class: _normalizeClass(txtClass)
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" PLANOS ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/blog",
        class: _normalizeClass(txtClass)
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" BLOG ")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/noticias",
        class: _normalizeClass(txtClass)
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" NOTÍCIAS ")
        ])),
        _: 1
      }),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/login'))),
        class: "w-40 h-10 m-3 border border-adv-blue-800 flex justify-center items-center bg-transparent text-adv-blue-800 cursor-pointer ml-4"
      }, " ENTRAR "),
      _createElementVNode("button", {
        type: "button",
        onClick: goToSign,
        class: "w-40 h-10 m-3 border-0 flex justify-center items-center bg-adv-blue-800 text-white cursor-pointer ml-4"
      }, " TESTE GRÁTIS ")
    ], 512), [
      [_vShow, show.value]
    ])
  ])), [
    [_directive_click_away, onClickAway]
  ])
}
}

})