import { ref } from 'vue';
import { defineStore } from 'pinia';
import { formType } from '@/types/formTypes';
import { http } from '@/core/api';
import { planType } from '@/types/cardTypes';
import { Notyf } from 'notyf';

export const useSignUpStore = defineStore('signUpStore', () => {
  const loading = ref(false);
  const loadingCoupon = ref(false);
  const validPayment = ref<string>('');
  const userId = ref(0);
  const userPlanId = ref<number>();
  const step = ref(0);
  const ocupations = ref<any[]>([]);
  const discount = ref<number>();
  const couponId = ref<number>();
  const days = ref<number>();
  const type = ref<string>();
  const signUpForm = ref<formType>({
    name: '',
    prof: 0,
    oab: '',
    eMail: '',
    confirmEmail: '',
    phone: '',
    password: '',
    confirmPassword: '',
    coupon: '',
    cardName: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    cpf: '',
    streetName: '',
    zipCode: '',
    state: '',
    city: '',
    supplement: '',
    district: '',
    houseNumber: 0,
    acceptTerms: false,
    acceptContract: false,
  });

  const notyf = new Notyf();

  const apiError = ref<string>('');

  const clearCoupon = () => {
    signUpForm.value.coupon = '';
    days.value = undefined;
    discount.value = undefined;
    type.value = undefined;
    couponId.value = undefined;
  };

  const nextStep = () => {
    step.value++;
  };

  async function getOcupations() {
    loading.value = true;
    try {
      const { data } = await http.get('/ocupations/all');

      ocupations.value = data.body.result;
    } catch (err) {
      console.error(err);
    }
    loading.value = false;
  }

  async function submitUserSignUp(values: any) {
    signUpForm.value.name = values.name;
    signUpForm.value.eMail = values.email;
    // signUpForm.value.password = values.password;
    signUpForm.value.cpf = values.cpf;
    signUpForm.value.phone = values.phone;
    signUpForm.value.acceptTerms = values.acceptTerms;

    apiError.value = '';

    try {
      await profileSignUp();

      if (apiError.value.length > 0) {
        notyf.error(apiError.value);
        return;
      }

      nextStep();
    } catch (err: any) {
      apiError.value = typeof err.message === 'string' ? err.message : err.message.join(', ');
      // notyf.error(apiError.value);
      throw new Error(apiError.value);
    }
  }

  async function submitAddresInfo(values: any) {
    signUpForm.value.streetName = values.name;
    signUpForm.value.zipCode = values.zipCode;
    signUpForm.value.state = values.state;
    signUpForm.value.city = values.city;
    signUpForm.value.supplement = values.supplement;
    signUpForm.value.district = values.district;
    signUpForm.value.houseNumber = values.number;

    apiError.value = '';

    try {
      await postAddress();

      if (apiError.value.length > 0) {
        notyf.error(apiError.value);
        return;
      }

      nextStep();
    } catch (err: any) {
      apiError.value = typeof err.message === 'string' ? err.message : err.message.join(', ');
      // notyf.error(apiError.value);
      throw new Error(apiError.value);
    }
  }

  async function submitPaymentInfo(values: any, selectedPlan: planType) {
    signUpForm.value.cardName = values.cardName;
    signUpForm.value.cardNumber = values.cardNumber.replace(/\s/g, '');
    signUpForm.value.cvv = values.cvv;
    signUpForm.value.expirationDate = values.expirationDate;
    signUpForm.value.acceptContract = values.acceptContract;
    signUpForm.value.cpf = values.cpf;
    signUpForm.value.postalCode = values.postalCode;
    signUpForm.value.addressNumber = values.addressNumber;

    apiError.value = '';
    try {
      if (!userPlanId.value) {
        await userPlanSubmit(selectedPlan);
      }

      if (apiError.value.length > 0) {
        notyf.error(apiError.value);
        return;
      }

      await paymentPost(selectedPlan);

      if (apiError.value.length > 0) {
        notyf.error(apiError.value);
        return;
      }

      nextStep();
    } catch (err: any) {
      apiError.value = typeof err.message === 'string' ? err.message : err.message.join(', ');
      throw new Error(apiError.value);
    }
  }

  async function profileSignUp() {
    loading.value = true;

    // birthday: '2023-07-25T14:23:04.405Z',
    // password: signUpForm.value.password,
    // ocupationId: signUpForm.value.prof,
    try {
      const { data } = await http.post('/users', {
        name: signUpForm.value.name,
        email: signUpForm.value.eMail,
        cpf: signUpForm.value.cpf,
        oab: signUpForm.value.oab,
        phone: signUpForm.value.phone,
        acceptTerms: signUpForm.value.acceptTerms,
      });

      if (data.statusCode !== 200) {
        apiError.value = typeof data.message === 'string' ? data.message : data.message.join(', ');
        notyf.error(apiError.value);
        throw new Error(apiError.value);
      }

      userId.value = data.body.id;
    } catch (err: any) {
      apiError.value = typeof err.message === 'string' ? err.message : err.message.join(', ');
      throw new Error(apiError.value);
    } finally {
      loading.value = false;
    }
  }

  async function postAddress() {
    loading.value = true;
    try {
      const { data } = await http.post('/address', {
        name: signUpForm.value.streetName,
        userId: userId.value,
        zipCode: signUpForm.value.zipCode,
        state: signUpForm.value.state,
        city: signUpForm.value.city,
        supplement: signUpForm.value.supplement,
        district: signUpForm.value.district,
        number: signUpForm.value.houseNumber,
      });

      if (data.statusCode !== 200) {
        apiError.value =
          typeof data.message.message === 'string' ? data.message.message : data.message.message.join(', ');
        notyf.error(apiError.value);
        throw new Error(apiError.value);
      }
    } catch (err: any) {
      apiError.value = typeof err.message === 'string' ? err.message : err.message.join(', ');
      throw new Error(apiError.value);
    } finally {
      loading.value = false;
    }
  }

  async function paymentPost(selectedPlan: planType) {
    loading.value = true;
    try {
      const { data } = await http.post('/payment/createSubscription', {
        name: signUpForm.value.cardName,
        number: signUpForm.value.cardNumber,
        expireIn: signUpForm.value.expirationDate,
        cvv: signUpForm.value.cvv,
        userPlanId: userPlanId.value,
        cpf: signUpForm.value.cpf,
        postalCode: signUpForm.value.postalCode,
        addressNumber: signUpForm.value.addressNumber,
      });

      if (data.statusCode !== 200) {
        apiError.value =
          typeof data.message === 'string'
            ? data.message
            : data.message.errors
                .map((err: { code: string; description: string }) => {
                  return err.description;
                })
                .join(', ');
        notyf.error(apiError.value);
        throw new Error(apiError.value);
      }

      validPayment.value = data.body.paymentStatus;
    } catch (err: any) {
      apiError.value =
        typeof err.message === 'string'
          ? err.message
          : err.message.errors
              .map((err: { code: string; description: string }) => {
                return err.description;
              })
              .join(', ');
      throw new Error(apiError.value);
    } finally {
      loading.value = false;
    }
  }

  async function userPlanSubmit(selectedPlan: planType) {
    loading.value = true;
    try {
      const { data } = await http.post('/userPlans', {
        name: selectedPlan.name,
        userId: userId.value,
        planId: selectedPlan.id,
        couponId: couponId.value,
        contractTerms: signUpForm.value.acceptContract,
      });

      if (data.statusCode !== 200) {
        apiError.value = typeof data.message === 'string' ? data.message : data.message.join(', ');
        notyf.error(apiError.value);
        throw new Error(apiError.value);
      }

      userPlanId.value = data.body.id;
    } catch (err: any) {
      apiError.value = typeof err.message === 'string' ? err.message : err.message.join(', ');
      throw new Error(apiError.value);
    } finally {
      loading.value = false;
    }
  }

  async function validateCoupon(selectedPlan) {
    loadingCoupon.value = true;
    try {
      const { data } = await http.get(`/coupons/validate/${signUpForm.value.coupon}`);
      if (data.body.type === 'DAYS' && selectedPlan && selectedPlan.periodicity !== 1) {
        signUpForm.value.coupon = '';
        days.value = undefined;
        discount.value = undefined;
        type.value = undefined;
        couponId.value = undefined;
        notyf.error('Cupom não é válido para este plano');
        return;
      }
      if (data.statusCode !== 200) {
        throw new Error('Cupom não encontrado ou inválido');
      }
      if (data.body.usage >= data.body.maxUsage) {
        throw new Error('Cupom esgotado');
      }
      discount.value = data.body.discount;
      couponId.value = data.body.id;
      days.value = data.body.days;
      type.value = data.body.type;
      notyf.success('Cupom aplicado!');
    } catch (err: any) {
      signUpForm.value.coupon = '';
      notyf.error('Cupom não encontrado ou inválido');
      // throw new Error('Cupom não encontrado ou inválido');
    } finally {
      loadingCoupon.value = false;
    }
  }

  function clearForm() {
    userId.value = 0;
    discount.value = undefined;
    couponId.value = undefined;
    days.value = undefined;
    type.value = undefined;
    apiError.value = '';
    step.value = 0;
    signUpForm.value = {
      name: '',
      prof: 0,
      oab: '',
      eMail: '',
      confirmEmail: '',
      phone: '',
      password: '',
      confirmPassword: '',
      coupon: '',
      cardName: '',
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      cpf: '',
      streetName: '',
      zipCode: '',
      state: '',
      city: '',
      supplement: '',
      district: '',
      houseNumber: 0,
      acceptTerms: false,
      acceptContract: false,
    };
  }

  return {
    loading,
    discount,
    couponId,
    days,
    type,
    loadingCoupon,
    ocupations,
    step,
    signUpForm,
    validPayment,
    clearCoupon,
    getOcupations,
    submitUserSignUp,
    submitAddresInfo,
    validateCoupon,
    submitPaymentInfo,
    profileSignUp,
    paymentPost,
    nextStep,
    clearForm,
  };
});
