<script setup lang="ts">
import { useDepoStore } from '@/store/depoimentosStore';
import DepoimentosCarousel from './DepoimentosCarousel.vue';
import { onMounted } from 'vue';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { lightButtonStyle } from '@/assets/css/styles';
import { useRouter } from 'vue-router';

const router = useRouter();

interface DepoimentosProps {
  title: string;
}

const depoStore = useDepoStore();

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<DepoimentosProps>();
/* <div class="flex flex-col sm:flex-row items-center justify-center gap-9 lg:justify-around w-full">
      
    </div> */

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};
onMounted(() => {
  depoStore.getTestimonials();
});
</script>

<template>
  <section
    :style="{
      background: 'radial-gradient(108.02% 270.63% at -6.77% 127.48%, #FFFFFF 15.57%, rgba(226, 226, 226, 0.35) 100%)',
    }"
    class="h-max w-full flex justify-center flex-col items-center p-10 lg:p-28 overflow-hidden"
  >
    <h2 class="font-bold text-4xl text-adv-blue-800 mb-14 max-w-lg text-center tracking-wider">{{ title }}</h2>
    <DepoimentosCarousel v-if="!depoStore.loading" :depoimentos="depoStore.depoimentos" />
    <!-- <div v-if="!depoStore.loading" class="hidden w-full grid-flow-col justify-center gap-12 lg:grid lg:gap-16">
      <DepoimentoItem
        v-for="depo in depoStore.depoimentos"
        :key="depo.id"
        :id="depo.id"
        :author="depo.author"
        :company-or-occupation="depo.companyOrOccupation"
        :text="depo.text"
        :thumbnail-photo="depo.thumbnailPhoto"
      />
    </div> -->
    <LoadingSpinner v-if="depoStore.loading" color="#1E2A66" />
    <button type="button" @click="goToSign" :class="lightButtonStyle" class="lg:whitespace-nowrap mt-6">
      TESTE GRÁTIS POR 7 DIAS
    </button>
  </section>
</template>
