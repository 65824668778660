<script setup lang="ts">
import { DepoimentoType } from '@/types/cardTypes';
import DepoimentoItem from '@/components/Depoimentos/DepoimentoItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow } from 'swiper/modules';
import { ref } from 'vue';
import 'vue3-carousel/dist/carousel.css';

interface DepoCarouselProps {
  depoimentos: DepoimentoType[];
}

const myCarousel = ref<any>(null);

const activeClass = '!bg-white';
const nextClass = '!opacity-50';
const prevClass = 'opacity-50';

const modules = ref([Pagination, Autoplay]);

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<DepoCarouselProps>();
</script>

<template>
  <swiper
    :autoplay="{
      delay: 2500,
      disableOnInteraction: true,
    }"
    :loop="true"
    :slidesPerView="1"
    :centeredSlides="true"
    :spaceBetween="30"
    :pagination="{
      clickable: true,
      bulletClass: 'hidden',
    }"
    :breakpoints="{
      '320': {
        slidesPerView: 1,
        centeredSlides: false,
      },
      '640': {
        slidesPerView: 1,
        centeredSlides: false,
      },
      '1020': {
        slidesPerView: 2,
      },
      '1440': {
        slidesPerView: 3,
      },
    }"
    :slide-active-class="activeClass"
    :slide-next-class="nextClass"
    :slide-prev-class="prevClass"
    class="w-full"
    :modules="modules"
  >
    <swiper-slide
      v-for="depo in depoimentos"
      :key="depo.id"
      v-slot="{ isActive }"
      class="!flex !justify-center rounded-3xl bg-[#E5ECFF] !h-auto"
    >
      <DepoimentoItem
        :id="depo.id"
        :author="depo.author"
        :company-or-occupation="depo.companyOrOccupation"
        :text="depo.text"
        :thumbnail-photo="depo.thumbnailPhoto"
        :active-carousel="isActive"
      />
    </swiper-slide>
  </swiper>
</template>

<style lang="css">
.carousel__next.nav-black {
  right: -10% !important;
  color: #000000 !important;
}
.carousel__prev.nav-black {
  left: -10% !important;
  color: #000000 !important;
}
</style>
