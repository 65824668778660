import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "contents" }
const _hoisted_2 = { class: "bg-adv-gray-300 p-20 max-w-5xl w-full flex flex-col rounded-3xl relative justify-around gap-11 items-center" }
const _hoisted_3 = {
  key: 3,
  class: "text-adv-blue-800 text-center font-bold text-3xl"
}
const _hoisted_4 = {
  key: 4,
  class: "text-adv-blue-800 text-center font-bold text-3xl"
}
const _hoisted_5 = {
  key: 5,
  class: "text-adv-blue-800 text-center font-bold text-3xl"
}
const _hoisted_6 = {
  key: 6,
  class: "text-adv-gray-400 text-center text-lg font-bold"
}
const _hoisted_7 = { class: "text-black" }
const _hoisted_8 = {
  key: 7,
  class: "text-adv-gray-400 text-center text-lg font-bold"
}

import { useRouter } from 'vue-router';
import { useSignUpStore } from '@/store/signUpStore';
import { darkButtonStyle, lightButtonStyle } from '@/assets/css/styles';
import GreenTickIcon from '../icons/GreenTickIcon.vue';
import RedTickIcon from '../icons/RedTickIcon.vue';
import YellowTickIcon from '../icons/YellowTickIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentConfirm',
  setup(__props) {

const store = useSignUpStore();
const router = useRouter();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(store).validPayment === 'SUCCESS')
        ? (_openBlock(), _createBlock(GreenTickIcon, {
            key: 0,
            class: "absolute -top-1/4",
            width: "8rem",
            height: "8rem"
          }))
        : (_unref(store).validPayment === 'PROCESSING')
          ? (_openBlock(), _createBlock(YellowTickIcon, {
              key: 1,
              class: "absolute -top-1/4",
              width: "8rem",
              height: "8rem"
            }))
          : (_openBlock(), _createBlock(RedTickIcon, {
              key: 2,
              class: "absolute -top-1/4",
              width: "8rem",
              height: "8rem"
            })),
      (_unref(store).validPayment === 'SUCCESS')
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, " Seu pagamento foi confirmado! "))
        : (_unref(store).validPayment === 'PROCESSING')
          ? (_openBlock(), _createElementBlock("h2", _hoisted_4, " Seu pagamento está sendo processado! "))
          : (_openBlock(), _createElementBlock("h2", _hoisted_5, "Infelizmente seu pagamento foi recusado")),
      (_unref(store).validPayment === 'PROCESSING' || _unref(store).validPayment === 'SUCCESS')
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _cache[2] || (_cache[2] = _createTextVNode(" O e-mail de confirmação e detalhes do seu pedido foram enviados para ")),
            _createElementVNode("strong", _hoisted_7, _toDisplayString(_unref(store).signUpForm.eMail), 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(store).validPayment !== 'PROCESSING' && _unref(store).validPayment !== 'SUCCESS')
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, " Algo deu errado e não conseguimos processar o seu pedido. Revise as informações ou tente usar outro cartão de crédito. "))
        : _createCommentVNode("", true),
      (_unref(store).validPayment === 'SUCCESS' || _unref(store).validPayment === 'PROCESSING')
        ? (_openBlock(), _createElementBlock("button", {
            key: 8,
            class: _normalizeClass(_unref(lightButtonStyle)),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/login')))
          }, " ENTRAR ", 2))
        : _createCommentVNode("", true)
    ]),
    (_unref(store).validPayment !== 'PROCESSING' && _unref(store).validPayment !== 'SUCCESS')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(_unref(darkButtonStyle)),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(store).step = 2))
        }, " TENTAR NOVAMENTE ", 2))
      : _createCommentVNode("", true)
  ]))
}
}

})