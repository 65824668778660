import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "36",
  height: "36",
  viewBox: "0 0 36 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

interface IProps {
  color?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'FacebookIcon',
  props: {
    color: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M22.0606 12.2476C18.7546 12.2476 19.1505 12.3534 19.1505 15.9021C22.7811 15.9021 22.5096 15.5156 22.2339 17.4966C22.186 17.8412 22.1582 18.1418 22.1246 18.4701C22.0951 18.7591 22.0342 19.1505 21.7222 19.1505H19.1505V26.1888C19.1505 26.5048 18.9928 26.6626 18.6768 26.6626C17.9699 26.6626 16.6614 26.7402 16.0375 26.5949C15.8703 25.8773 15.9698 20.3393 15.9698 19.1505H14.0072C13.7879 19.1505 13.6011 18.8564 13.6011 18.6091C13.6011 17.8605 13.4993 16.4642 13.7365 15.9698C14.3473 15.8275 15.3082 15.9021 15.9698 15.9021C15.9698 13.9432 15.6762 11.8351 17.1365 10.3689C18.2972 9.20346 19.9457 9.33757 21.6546 9.33757C21.8999 9.33757 22.0323 9.39438 22.196 9.4729C22.3406 10.0938 22.2636 11.2224 22.2636 11.9092C22.2636 12.1021 22.1534 12.0762 22.0606 12.2476ZM17.2524 0.675049H18.8189C19.9772 0.727297 21.1404 0.895947 22.2917 1.18838C23.2534 1.43265 24.5277 1.87019 25.377 2.29901L27.4202 3.4365C28.3444 4.01443 29.5196 4.98709 30.2663 5.73382C31.7288 7.19633 32.8185 8.78116 33.7243 10.6676C34.6896 12.6781 35.2463 15.1002 35.3251 17.5349V18.7351C35.2655 20.536 34.9367 22.3079 34.31 23.8879C34.1097 24.3927 33.9339 24.9067 33.7019 25.3777C31.8055 29.2275 29.0938 31.9183 25.3325 33.7243C22.7796 34.95 20.5004 35.3251 17.391 35.3251C13.1916 35.3251 8.71139 33.2439 5.73382 30.2663C4.27322 28.8057 3.18051 27.2167 2.27585 25.3325C1.05154 22.7826 0.675049 20.4971 0.675049 17.391C0.675049 14.2315 2.03735 10.424 3.84525 7.97347C4.43656 7.17197 5.03366 6.4338 5.73382 5.73382C8.79847 2.66995 12.9366 0.870486 17.2524 0.675049Z",
      fill: _ctx.color ? _ctx.color : '#FEFEFE'
    }, null, 8, _hoisted_2)
  ]))
}
}

})