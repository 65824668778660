import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Modal from '@/components/Modal/index.vue';
import { useModalStore } from '@/store/modalStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'privacy',
  setup(__props) {

const store = useModalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    "modal-title": "Política de privacidade",
    open: _unref(store).privacyModalOpen,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).privacyModalOpen = false))
  }, {
    "modal-content": _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "flex flex-col justify-center items-center gap-12 w-full mt-12" }, [
        _createElementVNode("div", { class: "w-full" }, [
          _createElementVNode("div", { class: "border md:w-full max-h-[80vh] lg:max-h-[300px] overflow-auto" }, [
            _createElementVNode("div", { class: "p-0 md:p-6 text-justify" }, [
              _createElementVNode("p", null, [
                _createElementVNode("strong", null, "POLÍTICA DE PRIVACIDADE")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, "  A presente Política de Privacidade (“Política de Privacidade”) estabelece as regras relativas à coleta, ao uso, ao armazenamento, à proteção, ao compartilhamentos e direitos dos Usuários em relação a seus dados (“Informações”), em decorrência da utilização da Plataforma denominada Adv Service desenvolvido pela TRABALHISTA SERVICE LTDA., inscrito no CNPJ sob nº 41.867.655/0001-49, com sede na Rua João Zwetsch, nº 80, apto 702, Bairro Nossa Senhora das Dores, no Município de Santa Maria/RS , conforme permite o art. 1.142, § 2º do Código Cível (“CC”). "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " O Adv Service poderá desenvolver novos produtos e/ou funcionalidades que sejam úteis à Plataforma. Salvo disposição em sentido contrário, esta Política de Privacidade aplicar-se-á integralmente aos novos produtos e/ou funcionalidades que forem posteriormente desenvolvidos. Contudo, se houver mudanças que implique em alteração em relação à coleta, ao uso, ao armazenamento, à proteção, ao compartilhamento  de dados pessoais, o Usuário será informado, de maneira prévia, por meio de uma nova política de privacidade, de forma que a continuidade da utilização dos serviços da Plataforma, será interpretada como seu consentimento aos novos termos apresentados. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " Ao utilizar de quaisquer formas os produtos oferecidos pela Plataforma, o Usuário concorda com a presente Política de Privacidade, que se aplica a todas as relações envolvendo a Adv Service e seus Usuários. Caso você não concorde com a politica de privacidade, por favor não utilize a plataforma. "),
              _createElementVNode("br"),
              _createElementVNode("ol", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Objeto")
                ])
              ]),
              _createElementVNode("p", null, " 1.1. Esta Política de Privacidade, em conjunto com os Termos de Uso da Plataforma, tem como objetivo conferir publicidade e esclarecer acerca do funcionamento da Plataforma Adv Service, nos termos do exigido pela Lei nº 12.965/2014 (Marco Civil da Internet) e Lei nº 13.709/2018 (Lei Geral de Proteção de Dados - LGPD) mais especificamente em relação: i)  às informações e dados pessoais que são coletadas pelo Adv Service; ii) à forma de utilização das Informações coletadas pelo Adv Service; iii) a forma de tratamento destes dados, os possíveis compartilhamentos de dados; e iv)  as finalidades para que determinados dados tenham sido coletados e os direitos do Usuário em relação aos dados pessoais que por ele forem fornecidos ou coletados diretamente pela  plataforma. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "2" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Informações Coletadas pelo Adv Service")
                ])
              ]),
              _createElementVNode("p", null, " 2.1. Para regular a utilização e operacionalização da Plataforma, bem como para melhorar a experiência de navegação, o Adv Service coleta algumas Informações importantes de seus Usuários, listadas abaixo: "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.2. Informações de acesso: A utilização dos serviços fornecidos pelo Adv Service está somente para os Usuários que estiverem cadastrados na Plataforma. Para o cadastro, serão necessárias as seguintes informações, para fins de identificação e individualização dos Usuários na Plataforma: i) "),
                _createElementVNode("em", null, "Informações Obrigatórias do Usuário"),
                _createTextVNode(": Nome, CPF, e-mail, profissão e senha de acesso; ii) "),
                _createElementVNode("em", null, "Informações Opcionais: a"),
                _createTextVNode("lém das informações obrigatórias, o Usuário poderá fornecer informações complementares, mas que não impeçam a utilização da Plataforma. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 2.3. O Usuário tem o direito de alterar e atualizar seus dados a qualquer momento, autorizando o Adv Service a manter o registro das informações e dados previamente fornecidos, por motivos de segurança e controle de acessos, pelo período mínimo de 10 anos. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "3" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, [
                    _createElementVNode("em", null, "Informações de Terceiros Inseridas pelo Usuário na Plataforma")
                  ])
                ])
              ]),
              _createElementVNode("p", null, " 3.1. Os serviços do Adv Service possibilitam ao Usuário inserir informações de terceiros para fins de elaboração de peças processuais, assim como para abastecer o Gestor Service com informações das partes envolvidas em casos judicias e extrajudiciais. A Plataforma permite a inserção das seguintes informações de clientes do Usuário: "),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Nome Completo"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Gênero"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "CPF"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Data de Nascimento"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Endereço Residencial"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Nacionalidade"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Estado civil"),
              _createElementVNode("br"),
              _createElementVNode("p", null, "Profissão"),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2. Além de tais informações, é possível que o Usuário disponibilize na Plataforma outras informações de seus clientes. Tendo em vista o teor pessoal das informações, ao utilizar a Plataforma, o Usuário declara expressamente que as pessoas, cujas informações foram inseridas, autorizaram previamente o uso de tais dados, bem como estão cientes e concordam com as disposições desta Política de Dados. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "4" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Informações de Acesso")
                ])
              ]),
              _createElementVNode("p", null, " 4.1. Além dos dados pessoais e informações que o Usuário fornece voluntariamente, o Adv Service, por meio de cookies e tecnologias semelhantes, inclusive de terceiros, como o Google Analytics, também coletará e armazenará informações que serão recebidas automaticamente toda vez que o Usuário interagir com a Plataforma, dentre elas, por exemplo, Internet Protocol (IP), tipo de navegador, detalhes sobre as interações com a Plataforma (por exemplo, páginas visualizadas e dados e hora de acesso), histórico de navegação, IMEI e UDID. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "5" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, "5. Finalidade do Uso dos dados pessoais pelo Adv Service")
                ])
              ]),
              _createElementVNode("p", null, " 5.1. Na razão da interação do Usuário com a Plataforma, o Adv Service coleta e armazena uma série de Informações, conforme descrito acima, seja pela necessidade de cumprimento da legislação, seja em razão de interesses legítimos do Adv Service em relação à execução dos serviços e cumprimento de suas obrigações contratuais. As Informações dos Usuários são utilizadas pelo Adv Service e suas declarações de serviço para as seguintes finalidades (Finalidade motivada): "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "5.1.1. Cumprimento Contratual"),
                _createTextVNode(": As Informações de terceiros são utilizadas pelo Adv Service com o interesse legítimo de garantir o cumprimento de suas obrigações contratuais com o Usuário. Sendo assim, os dados inseridos na Plataforma são utilizados para fins de elaboração das peças processuais para os clientes do Usuário, assim como para uso do gestor de escritório de advocacia e suas funcionalidades. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "5.1.2. Personalização e melhoria da experiência de uso da Plataforma"),
                _createTextVNode(": As informações coletadas pela Plataforma, em especial aquelas coletadas automaticamente (“Informações de Acesso”), têm como objetivo permitir ao Adv Service personalizar e melhorar a experiência do Usuário dentro da Plataforma. As informações poderão ser utilizadas ainda para realização de testes, solução de problemas e melhorias no funcionamento do sistema do Adv Service. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "5.1.3. Análise de desempenho e segurança"),
                _createTextVNode(": As informações de utilização da Plataforma também têm a finalidade de permitir que o Adv Service compreenda a forma como o Usuário interage com a Plataforma e tem como objetivo o interesse legítimo de garantir a funcionalidade e aprimoramento da Plataforma, o desenvolvimento de novos produtos/funcionalidades, a identificação de problemas de navegabilidade, erros e falhas operacionais. Além disso, as Informações poderão ser objeto de análise para identificação e prevenção de fraudes e atividades ilegais, bem como avaliação de riscos e aprimoramento na segurança da Plataforma. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "5.1.4. Atendimento da legislação para proteção de dados"),
                _createTextVNode(": O Adv Service coleta e armazena as informações de cadastro, informações inseridas pelo Usuário e informações de acesso também em razão da necessidade de atender a legislação no que se refere à proteção dos dados de seus Usuários. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "5.1.4. Comunicação"),
                _createTextVNode(": As informações de cadastro são utilizadas para permitir a comunicação entre o Adv Service e o Usuário. Por exemplo, para a atualização dos termos de uso e política de privacidade, comunicação de perguntas do sistema, manutenções programadas, dentre outras. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "5.1.5. Oferetas e publicidade"),
                _createTextVNode(": As informações cadastrais poderão ser utilizadas, desde que expressamente autorizadas pelo Usuário, para envio de publicidade, ofertas e serviços do Adv Service ou de terceiros, permitindo, por exemplo, o oferecimento de conteúdo relacionado aos seus interesses específicos, como novas funcionalidades, conteúdo individualizado e adaptado à sua localização geográfica, por meio do serviço Google AdSense. Para informações sobre como o Google trata os maiores dados coletados em nosso site, acesse: https://www.google.com/policies/technologies/partner-sites/. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "6" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Compartilhamento de informações com terceiros")
                ])
              ]),
              _createElementVNode("p", null, " 6.1. O funcionamento regular do Adv Service depende do compartilhamento de dados e Informações com terceiros. Abaixo, elencamos em quais situações poderemos compartilhar as Informações de nossos Usuários: "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "6.1.1. Prestadores de serviço e terceirizados:"),
                _createTextVNode(" No exercício de suas atividades, o Adv Service contrata serviço para a realização de diversas atividades, tais como: hospedagem do site, comentários de acesso, armazenamento de dados, peritos, contadores, dentre outros. Essas informações de serviços, na medida do necessário para a execução das atividades para os quais foram contratados, terão acesso às informações fornecidas pelo Usuário, estando sujeitas a cláusulas de confidencialidade ou outros meios para garantir a segurança dos dados fornecidos. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "6.1.2. Cumprimento de lei ou solicitação às autoridades:"),
                _createTextVNode(" As Informações dos Usuários poderão ser compartilhadas com Órgãos públicos e órgãos públicos em caso de ordem judicial que obrigue o Adv Service a fornecer tais dados ou, sempre que o Adv Service entenda que o procedimento é necessário para o cumprimento da determinação legal. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("em", null, "6.1.3. Investidores:"),
                _createTextVNode(" As Informações dos Usuários poderão ser compartilhadas com terceiros, caso o Adv Service seja, total ou parcialmente, vendido ou se iniciem tratativas para venda de participação societária no negócio, sendo resguardado o direito de proteção e confidencialidade sobre tais informações. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "7" }, [
                _createElementVNode("li", null, [
                  _createTextVNode(" 7"),
                  _createElementVNode("strong", null, ". Direitos do Usuário sobre as Informações coletadas pelo Adv Service")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 7.1. A Adv Service adotará medidas técnicas e organizacionais apropriadas para cumprir as suas obrigações em relação aos direitos dos Usuários enquanto titulares dos dados  pessoais,  nos  termos  da  Lei  Geral  de  Proteção  de  Dados  (Lei  n.  13.709/2018).  Nesse sentido, a Adv Service se compromete a viabilizar da melhor forma possível os seus direitos, tais quais previstos em lei, quais sejam: i) "),
                _createElementVNode("em", null, "Armazenamento:"),
                _createTextVNode(" O Adv Service armazena as Informações de seus Usuários durante o período em que for necessário para a prestação do serviço, e para fins comerciais e empresariais legítimos, nos termos do definido pela legislação. Independente disso, em atenção ao disposto na Lei nº 12.965/2014, o Adv Service é obrigado a manter as Informações de Cadastro e de Acesso por um período mínimo de 06 (seis) meses, contados de sua coleta. ii) "),
                _createElementVNode("em", null, "Direitos dos Usuários. "),
                _createTextVNode("Nos termos do artigo 18 da Lei n. 13.709/18, são direitos dos Usuários: a confirmação pelo Adv Service do tratamento de dados, o acesso aos dados fornecidos, a correção dos dados incompletos, inexatos ou desatualizados; a anonimização e eliminação de dados desnecessários; a portabilidade de dados, dentre outros. Iii) "),
                _createElementVNode("em", null, "Alteração e Revisão:"),
                _createTextVNode(" O Usuário poderá, por meio da Plataforma, revisar e alterar suas Informações. Ainda assim, poderá solicitar a exclusão de todas as suas Informações não anonimizadas, bem como solicitar o encerramento de sua conta, por meio de ferramenta disponível na área do Usuário na Plataforma. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 7.2. Caso queira exercer algum dos direitos previstos nesta Política de Privacidade ou na legislação aplicável, ou tenha dúvidas sobre este documento e as práticas nele descritas, você deverá entrar em contato com a  pessoa encarregada na Adv Service pela comunicação com titulares de dados pessoais, através do email: “"),
                _createElementVNode("u", null, "contato @advservice.com.br"),
                _createTextVNode("”. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "8" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Segurança das Informações")
                ])
              ]),
              _createElementVNode("p", null, " 8.1. O Adv Service preza pela segurança das Informações de seus Usuários, utilizando-se de todos os meios disponíveis para a defesa e proteção dos dados pessoais de seus usuários ou de qualquer um que venha a utilizar nossos serviços, por meio de práticas e serviços de segurança atualizados e eficientes.   "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "9" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Sites de terceiros")
                ])
              ]),
              _createElementVNode("p", null, " 9.1. A plataforma do Adv Service poderá conter links para produtos de parceiro, que possuem termos e politicas próprias. Ou seja, esta Política se limita à Plataforma e aos Serviços oferecidos  pela  própria  Adv Service.  Embora  a  Adv Service busque constantemente firmar relações com parceiros confiáveis, a Adv Service não se responsabiliza pelas práticas de tratamento de dados pessoais realizadas exclusivamente por esses terceiros. Por essa razão, o Usuário deve ter pleno conhecimento dos termos e políticas de privacidade de terceiros externos à Plataforma antes de fornecer qualquer dado pessoal. Em relação aos dados obtidos de fontes oficiais, considerando que sua coleta e atualização são realizadas de forma automatizada e sem qualquer ingerência da Adv Service quanto à sua precisão, a Adv Service não se responsabiliza por eventuais inexatidões, omissões ou erros em relação a esses dados. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "10" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, "Fontes Oficiais")
                ])
              ]),
              _createElementVNode("p", null, " 10.1.    Dados pessoais são extraídos  pela  Adv Service,  de  forma   automatizada,  de  fontes  publicamente disponíveis, como diários oficiais, dados oriundos de tribunais referentes a processos, informações de salas de imprensa de órgãos públicos e legislação, a fim de ampliar o acesso a esse conteúdo por parte dos seus Usuários. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "11" }, [
                _createElementVNode("li", null, [
                  _createTextVNode("11"),
                  _createElementVNode("strong", null, ". Alteração da Política de Privacidade")
                ])
              ]),
              _createElementVNode("p", null, " 11.1. O Adv Service poderá alterar esta Política de Privacidade a qualquer momento. O aceite das novas regras é uma decisão que cabe exclusivamente ao Usuário, sendo o acesso a plataforma bloqueado a partir da entrada em vigor da nova redação, caso o usuário não a aceite. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "12" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Legislação")
                ])
              ]),
              _createElementVNode("p", null, " 12.1.  Esta Política de Privacidade é regida e interpretada pelas leis brasileiras. Qualquer disputa decorrente de sua interpretação e aplicação, caso não resolvido de forma amigável, deverá ser solucionada perante o Foro da Comarca de Santa Maria, Estado do Rio Grande do Sul, com renúncia expressa a qualquer outro, por mais privilegiado que seja. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "13" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Contatos e dúvidas")
                ])
              ]),
              _createElementVNode("p", null, " 13.1. Caso o Usuário tenha qualquer dúvida referente a esta Política de Privacidade ou, ainda, pretendente realizar qualquer solicitação em relação às suas Informações deverá entrar em contato com o Adv Service. "),
              _createElementVNode("br")
            ])
          ])
        ])
      ], -1)
    ])),
    _: 1
  }, 8, ["open"]))
}
}

})