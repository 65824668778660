import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/adv-login-logo.svg'


const _hoisted_1 = { class: "w-full h-screen overflow-auto overflow-x-hidden flex flex-col gap-8 bg-[url('@/assets/img/bg-login.svg')] bg-cover" }
const _hoisted_2 = { class: "w-full flex flex-col justify-center items-center gap-20" }
const _hoisted_3 = {
  key: 0,
  class: "max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full p-6 flex flex-col justify-center items-center gap-14"
}
const _hoisted_5 = { class: "max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14" }
const _hoisted_6 = { class: "max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14" }
const _hoisted_7 = { class: "max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14" }
const _hoisted_8 = {
  key: 2,
  class: "w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-center gap-14"
}

import Head from '@/components/Header/Head.vue';
import PlanPriceDisplay from '@/components/Plans/LoginPriceDisplay.vue';
import FormSign from '@/components/SignUp/Form.vue';
import PayForm from '@/components/SignUp/PayForm.vue';
import AddressForm from '@/components/SignUp/Address.vue';
import PaymentConfirm from '@/components/SignUp/PaymentConfirm.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useSignUpStore } from '@/store/signUpStore';
import { watch, onBeforeMount, onUnmounted } from 'vue';
import PaymentResume from '@/components/SignUp/PaymentResume.vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Cadastro',
  setup(__props) {

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const router = useRouter();


watch(() => store.step, (newStep) => {
    if (newStep === 3 && (store.validPayment === 'PROCESSING' || store.validPayment === 'SUCCESS')) {
        router.push('/planos/confirmado');
    }
});

onBeforeMount(() => {
    storePlans.getPlans();
});
onUnmounted(() => {
    store.clearForm();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Head),
    _createElementVNode("main", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        alt: "login logo",
        class: "h-28 w-auto hidden z-10 lg:block"
      }, null, -1)),
      (_unref(store).step === 0)
        ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
            _createVNode(FormSign)
          ]))
        : _createCommentVNode("", true),
      (_unref(store).step === 1)
        ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold text-center text-2xl" }, "Escolha seu plano", -1)),
              _createVNode(PlanPriceDisplay)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold text-center text-2xl" }, "Detalhes de pagamento", -1)),
              _createVNode(PaymentResume)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(PayForm)
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(store).step === 3)
        ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
            _createVNode(PaymentConfirm)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})