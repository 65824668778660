<script setup lang="ts">
import { Notyf } from 'notyf';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { useSignUpStore } from '@/store/signUpStore';
import { useServicePlansStore } from '@/store/servicePlansStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { planType } from '@/types/cardTypes';
import { useModalStore } from '@/store/modalStore';
import ContractModal from '@/components/Terms/contract.vue';

const notyf = new Notyf();
const store = useSignUpStore();
const planStore = useServicePlansStore();
const modalStore = useModalStore();

console.log('cpf', store.signUpForm.cpf)

const validationSchema = toTypedSchema(
    zod.object({
        cpf: zod.string({
            required_error: 'Campo obrigatório',
        }).default(store.signUpForm.cpf),
        cardName: zod.string({
            required_error: 'Campo obrigatório',
        }),
        cardNumber: zod.string({
            required_error: 'Campo obrigatório',
        }),
        expirationDate: zod.string({
            required_error: 'Campo obrigatório',
        }),
        cvv: zod.string({
            required_error: 'Campo obrigatório',
        }),
        postalCode: zod.string({
            required_error: 'Campo obrigatório',
        }),
        addressNumber: zod.string({
            required_error: 'Campo obrigatório',
        }),
        acceptContract: zod.boolean(),
    })
);
const { handleSubmit, errors, setFieldError } = useForm({
    validationSchema,
});

const { value: cpf } = useField('cpf',);
const { value: cardName } = useField('cardName');
const { value: cardNumber } = useField('cardNumber');
const { value: cvv } = useField('cvv');
const { value: postalCode } = useField('postalCode');
const { value: addressNumber } = useField('addressNumber');
const { value: expirationDate } = useField('expirationDate');
const { value: acceptContract } = useField('acceptContract');

const handleSignup = handleSubmit(async (values) => {
    if (store.loading) return;

    const actualYear = new Date().toLocaleDateString('en', { year: '2-digit' });

    if (Number(values.expirationDate.split('/')[1]) < Number(actualYear)) {
        return setFieldError('expirationDate', 'Validade expirada');
    }
    if(!Number(values.addressNumber)){
        return  setFieldError('addressNumber', 'Número deve ser maior do que zero!');
    }

    store.loading = true;
    console.log(values)

    try {
        await store.submitPaymentInfo(values, planStore.selectedPlan as planType);
    } catch (err: any) {
        console.log('err', err);
    } finally {
        store.loading = false;
    }
});
</script>

<template>
    <form @submit.prevent="handleSignup" class="w-full gap-8 flex flex-col">
        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">CPF</p>
                <input type="text" v-model="cpf" v-maska data-maska="###.###.###-##"
                    :class="formInput + `${errors.cpf ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.cpf }}</span>
            </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">Nome impresso no cartão</p>
                <input type="text" v-model="cardName"
                    :class="formInput + `${errors.cardName ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.cardName }}</span>
            </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">Número do cartão</p>
                <input type="text" v-maska data-maska="#### #### #### ####" v-model="cardNumber"
                    placeholder="0000 0000 0000 0000"
                    :class="formInput + `${errors.cardNumber ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.cardNumber }}</span>
            </div>
            <div class="w-full max-w-xs">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">Vencimento (MM/AA)</p>
                <input type="text" v-maska data-maska="##/##" v-model="expirationDate"
                    :class="formInput + `${errors.expirationDate ? 'border-2 border-rose-500' : ''}`" class="max-w-xs"
                    placeholder="(MM/AA)" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.expirationDate }}</span>
            </div>
            <div class="w-full max-w-xs">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">CVV</p>
                <input type="text" v-maska data-maska="###" v-model="cvv" placeholder="000"
                    :class="formInput + `${errors.cvv ? 'border-2 border-rose-500' : ''}`" class="max-w-xs" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.cvv }}</span>
            </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">

            <div class="w-full max-w-xs">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">CEP</p>
                <input type="text" v-maska data-maska="#####-###" v-model="postalCode" placeholder="00000-000"
                    :class="formInput + `${errors.postalCode ? 'border-2 border-rose-500' : ''}`" class="max-w-xs" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.postalCode }}</span>
            </div>
            <div class="w-full max-w-xs">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">Número</p>
                <input type="text" v-model="addressNumber" placeholder="000"
                    :class="formInput + `${errors.addressNumber ? 'border-2 border-rose-500' : ''}`" class="max-w-xs" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.addressNumber }}</span>
            </div>
        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full flex justify-start items-center gap-2">
                <input v-model="acceptContract" type="checkbox" id="contract" name="contract" />
                <label class="text-[#A8A8A8] font-bold tracking-widest text-sm" for="contract">Eu aceito e concordo com
                    o
                    <a class="text-gray-700 cursor-pointer"
                        @click.prevent="modalStore.contractModalOpen = true">contrato</a></label>
            </div>
        </div>
        <button type="submit" :class="lightButtonStyle" class="w-full flex justify-center items-center"
            :disabled="!acceptContract">
            <p v-show="!store.loading">FINALIZAR</p>
            <loading-spinner v-show="store.loading" />
        </button>
        <ContractModal />
    </form>
</template>
