<script setup lang="ts">
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

interface AboutProps {
  title: string;
  text: string;
  videoUrl: string;
  thumbnailUrl: string;
  service: 'trabalhista' | 'gestor' | 'buscador' | 'pergunte' | 'mentoria' | 'pdf' | 'assinador' | 'contratualista';
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = withDefaults(defineProps<AboutProps>(), {
  title: 'Praticidade na sua rotina trabalhista.',
  text: 'Gaste menos tempo elaborando petições e foque na conquista e atendimento ao cliente. Monte sua petição trabalhista personalizada através do nosso completo banco de teses e jurisprudências.',
  videoUrl: 'https://v.ftcdn.net/05/20/81/52/700_F_520815266_bYTb1l7tG8M4loZJ0kz2yEQrrsHGP3Hf_ST.mp4',
  service: 'trabalhista',
  thumbnailUrl: '',
});

const videoOptions = {
  autoplay: false,
  controls: true,
  poster: props.thumbnailUrl,
  sources: [
    {
      src: props.videoUrl,
      type: 'video/mp4',
    },
  ],
};

enum BG_COLOR {
  trabalhista = 'radial-gradient(55.79% 86.9% at 44.51% 32.48%, #FFAA51 0%, #FF9423 100%)',
  gestor = 'radial-gradient(55.79% 86.9% at 44.51% 32.48%, #33B88C 0%, #4FE3B2 100%)',
  buscador = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #F54327 0%, #FF5A40 100%)',
  pergunte = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #328CB5 0%, #3FA2CF 100%)',
  mentoria = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #8D5BC2 0%, #7A44B2 100%)',
  pdf = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #ED2853 0%, #FC3C66 100%)',
  assinador = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #ED9230 0%, #FFAF58 100%)',
  contratualista = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #FFC382 0.01%, #FFC07C 100%)',
}

function goPlans(): void {
  window.scrollTo(0, 0);
  router.push('/planos');
}
</script>

<template>
  <section  :style="{
      background: BG_COLOR[service],
    }"
    class="flex px-10 flex-col items-center justify-center gap-8 lg:flex-row lg:px-28 lg:items-start py-16">
   <div class="lg:w-[380px] lg:flex-col" >
    <h2 class="my-10 font-bold text-white text-3xl lg:my-10">{{ title }}</h2>
      <p class="text-base text-[#363636] my-10 lg:my-7" v-html="text"></p>
      <button class="bg-white py-3 px-6 text-base tracking-widest" @click.stop="goPlans">VER PLANOS</button>
   </div>
   <div class="bg-gray-700/25 w-[100%] my-5 lg:w-[36rem] md:w-[36rem] ">
    <video-player class="-translate-x-0 -translate-y-0" :options="videoOptions" />
   </div>
  </section>
</template>
