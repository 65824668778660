import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/adv-login-logo.svg'


const _hoisted_1 = { class: "w-full h-screen flex flex-col gap-8 sm:gap-40 bg-[url('@/assets/img/bg-login.svg')] bg-cover" }
const _hoisted_2 = { class: "w-full flex justify-center items-center gap-20" }
const _hoisted_3 = { class: "bg-white rounded-3xl py-16 px-8 sm:p-16 flex flex-col gap-6" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "bg-white rounded-3xl py-16 px-8 sm:p-16 flex flex-col gap-6" }
const _hoisted_7 = { class: "font-bold text-adv-blue-800 tracking-wider" }
const _hoisted_8 = { class: "flex items-center flex-col gap-4 justify-between" }
const _hoisted_9 = ["disabled"]

import { reactive, onMounted } from 'vue';
import Header from '@/components/Header/Head.vue';
import { lightButtonStyle } from '@/assets/css/styles';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const userStore = useUserStore();
const router = useRouter();

const login = reactive({
  email: '',
  password: '',
});

const signIn = () => {
  userStore.loginAction(login);
};

onMounted(() => {
  userStore.checkUserAction();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Header),
    _createElementVNode("main", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("img", {
        src: _imports_0,
        alt: "login logo",
        class: "h-28 w-auto hidden z-10 lg:block"
      }, null, -1)),
      _withDirectives(_createElementVNode("form", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "font-bold text-adv-blue-800 text-4xl tracking-wider" }, "Entrar", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((login.email) = $event)),
          placeholder: "E-mail",
          class: "border border-adv-gray-400 py-5 px-9 rounded-[2.5rem] placeholder:font-bold placeholder:text-lg placeholder:tracking-wider w-80 sm:w-[27rem]"
        }, null, 512), [
          [_vModelText, login.email]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((login.password) = $event)),
          placeholder: "Senha",
          class: "border border-adv-gray-400 py-5 px-9 rounded-[2.5rem] placeholder:font-bold placeholder:text-lg placeholder:tracking-wider w-80 sm:w-[27rem]"
        }, null, 512), [
          [_vModelText, login.password]
        ]),
        _createElementVNode("button", {
          type: "button",
          disabled: _unref(userStore).loading,
          class: _normalizeClass([_unref(lightButtonStyle), "rounded-[2.5rem] flex justify-center items-center"]),
          onClick: signIn
        }, [
          _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
            [_vShow, _unref(userStore).loading]
          ]),
          _withDirectives(_createElementVNode("p", null, "LOGIN", 512), [
            [_vShow, !_unref(userStore).loading]
          ])
        ], 10, _hoisted_4),
        _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "border-t border-adv-blue-800 mt-7" }, null, -1)),
        _createElementVNode("nav", _hoisted_5, [
          _createElementVNode("button", {
            type: "button",
            class: "font-bold text-adv-blue-800 tracking-wider",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/login/recuperar')))
          }, " Esqueci a senha "),
          _createElementVNode("button", {
            type: "button",
            class: "font-bold text-adv-blue-800 tracking-wider",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/login/cadastro')))
          }, " Cadastre-se aqui ")
        ])
      ], 512), [
        [_vShow, !_unref(userStore).user.username]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createElementVNode("h3", _hoisted_7, "Bem vindo, " + _toDisplayString(_unref(userStore).user.username), 1),
        _createElementVNode("nav", _hoisted_8, [
          _createElementVNode("button", {
            type: "button",
            disabled: _unref(userStore).loading,
            class: _normalizeClass([_unref(lightButtonStyle), "rounded-[2.5rem] flex justify-center items-center"]),
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(userStore).goToHub && _unref(userStore).goToHub(...args)))
          }, [
            _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
              [_vShow, _unref(userStore).loading]
            ]),
            _withDirectives(_createElementVNode("p", null, "Entrar no ADV Service", 512), [
              [_vShow, !_unref(userStore).loading]
            ])
          ], 10, _hoisted_9),
          _createElementVNode("button", {
            type: "button",
            class: "font-bold text-adv-blue-100 tracking-wider",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(userStore).logoutAction && _unref(userStore).logoutAction(...args)))
          }, " Sair ")
        ])
      ], 512), [
        [_vShow, _unref(userStore).user.username]
      ])
    ])
  ]))
}
}

})