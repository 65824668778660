import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-5 flex-col md:flex-row w-full mb-8" }
const _hoisted_2 = { class: "w-full flex flex-col gap-5" }
const _hoisted_3 = ["onKeyup"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "w-full p-2 flex flex-col gap-4" }
const _hoisted_6 = { class: "flex w-full justify-between" }
const _hoisted_7 = { class: "tracking-widest" }
const _hoisted_8 = { class: "flex w-full justify-between" }
const _hoisted_9 = { class: "tracking-widest" }
const _hoisted_10 = { class: "flex w-full justify-between" }
const _hoisted_11 = { class: "tracking-widest" }
const _hoisted_12 = {
  key: 0,
  class: "flex w-full justify-between"
}
const _hoisted_13 = { class: "tracking-widest" }
const _hoisted_14 = {
  key: 1,
  class: "flex w-full justify-between"
}
const _hoisted_15 = { class: "tracking-widest" }
const _hoisted_16 = {
  key: 2,
  class: "flex w-full justify-between"
}
const _hoisted_17 = { class: "font-bold text-lg text-adv-blue-100 tracking-widest" }
const _hoisted_18 = { class: "flex w-full flex-col gap-5 md:flex-row justify-between" }
const _hoisted_19 = { class: "font-extrabold w-full md:w-fit text-end text-2xl text-adv-blue-800 tracking-widest" }

import { computed } from 'vue';
import { useSignUpStore } from '@/store/signUpStore';
import { useServicePlansStore } from '@/store/servicePlansStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { storeToRefs } from 'pinia';
import { planType } from '@/types/cardTypes';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentResume',
  setup(__props) {

const store = useSignUpStore();
const planStore = useServicePlansStore();
const { selectedPlan } = storeToRefs(planStore);
const { discount, days, type } = storeToRefs(store);

const applyCoupon = async () => {
  await store.validateCoupon(planStore.selectedPlan as planType);
};

const totalDiscount = computed(() => {
  return selectedPlan?.value && discount.value
    ? (selectedPlan?.value?.price * selectedPlan.value?.periodicity * (discount.value / 100)).toFixed(2)
    : '';
});

const subTotalPlanPrice = computed(() => {
  return selectedPlan?.value ? (selectedPlan?.value?.price * selectedPlan.value?.periodicity).toFixed(2) : '';
});

const totalPlanPrice = computed(() => {
  return selectedPlan?.value
    ? discount.value
      ? (selectedPlan?.value?.price * selectedPlan.value?.periodicity * ((100 - discount.value) / 100)).toFixed(2)
      : (selectedPlan?.value?.price * selectedPlan.value?.periodicity).toFixed(2)
    : '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-adv-blue-800 font-bold tracking-widest text-xl mb-4" }, "Cupom de desconto", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "#CUPOM10",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(store).signUpForm.coupon) = $event)),
        class: "border border-solid border-dark-grey rounded-lg h-10 w-full max-w-xl p-2",
        onKeyup: _withKeys(_withModifiers(applyCoupon, ["prevent"]), ["enter"])
      }, null, 40, _hoisted_3), [
        [_vModelText, _unref(store).signUpForm.coupon]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "txt-btn text-base text-white bg-adv-blue-100 py-2 px-4 w-full md:w-fit tracking-[0.21em]",
        disabled: _unref(store).loadingCoupon,
        onClick: _withModifiers(applyCoupon, ["prevent"])
      }, [
        _withDirectives(_createElementVNode("p", null, "Aplicar", 512), [
          [_vShow, !_unref(store).loadingCoupon]
        ]),
        _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
          [_vShow, _unref(store).loadingCoupon]
        ])
      ], 8, _hoisted_4)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "hidden md:inline-block h-[250px] min-h-[1em] w-px self-stretch bg-adv-gray-500 opacity-100 dark:opacity-50" }, null, -1)),
    _cache[11] || (_cache[11] = _createElementVNode("hr", { class: "md:hidden w-full border-t-px border-adv-gray-500 my-3" }, null, -1)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-bold tracking-widest" }, "Valor mensal", -1)),
        _createElementVNode("p", _hoisted_7, "R$ " + _toDisplayString(_unref(selectedPlan)?.price.toFixed(2).replace('.', ',')), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-bold tracking-widest" }, "Duração", -1)),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(selectedPlan)?.periodicity) + " meses", 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-bold tracking-widest" }, "Subtotal", -1)),
        _createElementVNode("p", _hoisted_11, "R$ " + _toDisplayString(subTotalPlanPrice.value.replace('.', ',')), 1)
      ]),
      (_unref(type) === 'DAYS')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-bold tracking-widest" }, "Dias grátis antes da primeira cobrança:", -1)),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(days)) + " dias", 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(discount))
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-bold tracking-widest" }, "Desconto", -1)),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(discount)) + " %", 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(discount))
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _cache[7] || (_cache[7] = _createElementVNode("p", { class: "font-bold tracking-widest" }, "Valor do desconto", -1)),
            _createElementVNode("p", _hoisted_17, "R$ -" + _toDisplayString(totalDiscount.value.replace('.', ',')), 1)
          ]))
        : _createCommentVNode("", true),
      _cache[9] || (_cache[9] = _createElementVNode("hr", { class: "w-full border-t-px border-adv-gray-500 my-3" }, null, -1)),
      _createElementVNode("div", _hoisted_18, [
        _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-extrabold w-full md:w-fit text-end text-2xl text-adv-blue-800 tracking-widest" }, " Pagamento Total ", -1)),
        _createElementVNode("p", _hoisted_19, " R$ " + _toDisplayString(totalPlanPrice.value.replace('.', ',')), 1)
      ])
    ])
  ]))
}
}

})