import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  style: { background: 'radial-gradient(55.79% 86.9% at 44.51% 32.48%, #254689 0%, #253170 100%)' },
  class: "flex h-max w-full flex-col items-center justify-center p-8 md:p-32 overflow-hidden"
}
const _hoisted_2 = {
  key: 0,
  style: { letterSpacing: '0.095em' },
  class: "font-Open text-4xl mb-16 font-bold text-white"
}

interface CardContainerProps {
  ContainerTitle: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'CardsContainer',
  props: {
    ContainerTitle: { default: 'Planos' }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ContainerTitle)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.ContainerTitle), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})