<template>
  <Layout>
    <Title :height="28" imgUrl="url('@/assets/img/bg-serv-white-title.svg')">
      <img src="@/assets/img/pergunte-title-logo.svg" class="h-44 w-auto" alt="Pergunte Service logo" />
    </Title>
    <About
      title="O lugar definitivo para tirar todas as suas dúvidas."
      text="Nunca mais passe horas procurando a resposta para uma dúvida muita específica nos livros, google, ou colegas. Pergunte ao especialista e receba respostas claras e embasadas de profissionais experientes."
      service="pergunte"
      video-url="https://advservice-images.s3.amazonaws.com/videos/PERGUNTE+AO+ESPECIALISTA+final+.mp4"
      thumbnail-url="https://advservice-images.s3.amazonaws.com/solutions/pergunte%20ao%20especialista.png"
    />
    <Benefits :solutionId="3" />
    <PlansComparison :solution-id="3" />
    <Solutions title="Mais soluções" />
    <Depoimentos title="Depoimentos" />
    <Faq title="Perguntas Frequentes" :questions="faqStore.questions" />
  </Layout>
</template>

<script lang="ts" setup>
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import Benefits from '@/components/Benefits/Benefits.vue';
import PlansComparison from '@/components/Plans/PlansComparison.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Depoimentos from '@/components/Depoimentos/Depoimentos.vue';
import Faq from '@/components/Faq/Faq.vue';
import { useFaqStore } from '@/store/faqStore';
import About from '@/components/About/About.vue';

const faqStore = useFaqStore();
</script>
