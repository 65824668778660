<script setup lang="ts">
import { FaqProps } from '@/types/cardTypes';
import { ref } from 'vue';
import ChevronDown from '@/components/icons/ChevronDown.vue';

const show = ref(false);

function onClickAway() {
  show.value = false;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<FaqProps>();
</script>

<template>
  <div class="relative flex flex-col w-full rounded-full" v-click-away="onClickAway">
    <!-- Dropdown toggle button -->
    <div
      @click="show = !show"
      :class="[show ? 'bg-[#F3F4F6]' : 'font-regular lg:text-black bg-[#F5F5F5] lg:bg-white']"
      class="flex items-center justify-between w-full py-4 px-8 text-xl focus:outline-none cursor-pointer gap-9 lg:rounded-xl z-30 lg:border-b font-medium text-[#888888] lg:text-black"
    >
      <h3>{{ title }}</h3>
      <ChevronDown class="min-h-[12px] min-w-[12px] hidden lg:block" :class="show ? 'transform rotate-180' : ''" />
      <font-awesome-icon v-if="!show" :icon="['fas', 'plus']" class="h-[12px] w-[12px] lg:hidden" />
      <font-awesome-icon v-else :icon="['fas', 'minus']" class="h-[12px] w-[12px] lg:hidden" />
    </div>

    <!-- Dropdown menu -->
    <Transition name="show-q">
      <div v-show="show" class="mt-2 w-full py-4 px-8 flex justify-start items-center z-10 overflow-hidden">
        <div class="font-light text-base post-content" v-html="answer"></div>
      </div>
    </Transition>
  </div>
</template>

<style lang="css">
.show-q-enter-active {
  transition: all 0.5s linear;
}

.show-q-enter-to,
.show-q-leave-from {
  max-height: 300px;
}

.show-q-leave-active {
  transition: all 0.3s linear;
}

.show-q-enter-from,
.show-q-leave-to {
  max-height: 0;
}
</style>
