<!-- eslint-disable no-undef -->
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import BenefitCard from './BenefitCard.vue';
import { http } from '@/core/api';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

interface BenefitsProps {
  solutionId: number;
}

interface ResourcesType {
  id: number;
  name: string;
  description: string;
  solutionId?: 1;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

const benefits = ref<ResourcesType[]>([]);
const loading = ref<boolean>(false);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = withDefaults(defineProps<BenefitsProps>(), {
  solutionId: 1,
});

async function getBenefitsList() {
  loading.value = true;
  try {
    const { data } = await http.get(`solutions/${props.solutionId}`);

    benefits.value = data.body.resources;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getBenefitsList();
});
</script>

<template>
  <section
    :style="{
      background: 'radial-gradient(74.81% 444.1% at 13.56% 38.21%, #F7F7F7 0%, rgba(231, 231, 231, 0.92) 100%)',
    }"
    class="w-full p-6 lg:p-28 flex flex-col justify-center items-center gap-14"
  >
    <h2 class="font-bold tracking-wider text-adv-blue-800 text-4xl">Benefícios</h2>
    <LoadingSpinner v-if="loading" />
    <div v-else class="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center justify-center">
      <BenefitCard
        v-for="resource in benefits"
        :key="resource.id"
        :title="resource.name"
        :text="resource.description"
      />
    </div>
  </section>
</template>
