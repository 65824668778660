<script setup lang="ts">
import { DepoimentoType } from '@/types/cardTypes';

const contentBox =
  'flex flex-col justify-start items-center max-w-[480px] px-11 pb-12 bg-transparent rounded-3xl h-full';
const textPClass = 'text-black text-center text-base font-light italic mt-8 post-content';

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<DepoimentoType>();
</script>

<template>
  <div :class="contentBox">
    <img
      v-if="props.thumbnailPhoto"
      :src="props.thumbnailPhoto"
      alt="avatar"
      class="h-44 w-44 rounded-full object-cover shadow-2xl my-8"
    />
    <img
      v-else
      src="@/assets/img/avatar.png"
      alt="avatar"
      class="h-44 w-44 rounded-full object-cover shadow-2xl my-8 p-4"
    />

    <p class="font-bold text-xl text-adv-blue-800 tracking-wider">{{ author }}</p>
    <p class="font-bold text-xl text-adv-blue-800 tracking-wider">{{ companyOrOccupation }}</p>
    <div :class="textPClass" v-html="text"></div>
  </div>
</template>
