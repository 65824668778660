<script lang="ts" setup>
import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import GridContainer from '@/components/NewsGrid/GridContainer.vue';
import PostCard from '@/components/NewsGrid/PostCard.vue';
import BlogAside from '@/components/Asides/BlogAside.vue';
import { useBlogStore } from '@/store/blogStore';
import { lightButtonStyle } from '@/assets/css/styles';
import { postType } from '@/types/postTypes';
import { useRouter } from 'vue-router';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';

const router = useRouter();
const blogStore = useBlogStore();

onMounted(() => {
  blogStore.getPostList();
});

const navToPost = (selected: postType) => {
  const normTitle = selected.title
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  window.scrollTo(0, 0);
  router.push(`/blog/${selected.id}/${normTitle}`);
  console.log('normalized title', normTitle);
};
</script>

<template>
  <Layout>
    <Title :height="28" imgUrl="url('@/assets/img/bg-blog-title.svg')">
      <h1 class="z-10 font-Open text-6xl font-bold text-white">Blog</h1>
    </Title>
    <div class="flex flex-col lg:flex-row justify-between gap-12 items-center lg:items-start lg:p-28 p-16">
      <section class="h-max w-full flex gap-12 justify-center flex-col items-center">
        <GridContainer>
          <PostCard
            v-for="post in blogStore.posts"
            :key="post.id"
            :id="post.id"
            :title="post.title"
            :cover="post.cover"
            :summary="post.summary"
            @go-to-post="navToPost"
          />
        </GridContainer>
        <div
          v-if="blogStore.posts.length === 0 && !blogStore.loading"
          class="flex justify-center items-center w-full p-8 border border-adv-blue-800 rounded-lg"
        >
          <h3 class="font-bold text-2xl text-adv-blue-800">Não há posts para este filtro</h3>
        </div>
        <button
          type="button"
          :class="lightButtonStyle"
          class="mt-3"
          :disabled="blogStore.loading"
          @click="blogStore.getMorePosts"
        >
          <p v-show="!blogStore.loading">CARREGAR MAIS</p>
          <LoadingSpinner v-show="blogStore.loading" />
        </button>
      </section>
      <BlogAside />
    </div>
  </Layout>
</template>
