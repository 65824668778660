import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  href: "https://api.whatsapp.com/send?phone=5555991763315&text=Ol%C3%A1%2C+gostaria+de+conhecer+mais+sobre+o+ADV+SERVICE.",
  class: "float",
  target: "_blank"
}

import { useMeta } from 'vue-meta';
import preloader from '@/components/Preloader.vue';
import WhatsappIcon from '@/components/icons/WhatsappIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

useMeta({
  title: 'ADV Service',
  description: 'Serviços de facilitação para gestão de escritórios e advocacia trabalhista.',
  htmlAttrs: { lang: 'pt-BR', amp: true },
  og: {
    title: 'ADV Service',
    image: 'https://i.imgur.com/jYPTkUf.png',
    type: 'website',
    description: 'Serviços de facilitação para gestão de escritórios e advocacia trabalhista.',
    url: 'https://development.advservice.com.br/',
  },
  twitter: {
    title: 'ADV Service',
    image: 'https://i.imgur.com/jYPTkUf.png',
    card: 'summary_large_image',
    description: 'Serviços de facilitação para gestão de escritórios e advocacia trabalhista.',
    url: 'https://development.advservice.com.br/',
  },
});

return (_ctx: any,_cache: any) => {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | ADV Service` : `ADV Service`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_router_view),
    _createElementVNode("a", _hoisted_1, [
      _createVNode(WhatsappIcon)
    ]),
    _createVNode(preloader)
  ], 64))
}
}

})