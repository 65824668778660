<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<!-- eslint-disable no-undef -->
<script setup lang="ts">
import Modal from '@/components/Modal/index.vue';
import { useModalStore } from '@/store/modalStore';

const store = useModalStore();
</script>

<template>
  <Modal modal-title="Política de privacidade" :open="store.privacyModalOpen" @close="store.privacyModalOpen = false">
    <template #modal-content>
      <div class="flex flex-col justify-center items-center gap-12 w-full mt-12">
        <div class="w-full">
          <div class="border md:w-full max-h-[80vh] lg:max-h-[300px] overflow-auto">
            <div class="p-0 md:p-6 text-justify">
              <p><strong>POL&Iacute;TICA DE PRIVACIDADE</strong></p>
              <br />
              <p>
                &nbsp;A presente Pol&iacute;tica de Privacidade (&ldquo;Pol&iacute;tica de Privacidade&rdquo;)
                estabelece as regras relativas &agrave; coleta, ao uso, ao armazenamento, &agrave;
                prote&ccedil;&atilde;o, ao compartilhamentos e direitos dos Usu&aacute;rios em rela&ccedil;&atilde;o a
                seus dados (&ldquo;Informa&ccedil;&otilde;es&rdquo;), em decorr&ecirc;ncia da utiliza&ccedil;&atilde;o
                da Plataforma denominada Adv Service desenvolvido pela TRABALHISTA SERVICE LTDA., inscrito no CNPJ sob
                n&ordm; 41.867.655/0001-49, com sede na Rua Jo&atilde;o Zwetsch, n&ordm; 80, apto 702, Bairro Nossa
                Senhora das Dores, no Munic&iacute;pio de Santa Maria/RS , conforme permite o art.&nbsp;1.142, &sect;
                2&ordm; do C&oacute;digo C&iacute;vel (&ldquo;CC&rdquo;).
              </p>
              <br />
              <p>
                O Adv Service poder&aacute; desenvolver novos produtos e/ou funcionalidades que sejam &uacute;teis
                &agrave; Plataforma.&nbsp;Salvo disposi&ccedil;&atilde;o em sentido contr&aacute;rio, esta
                Pol&iacute;tica de Privacidade aplicar-se-&aacute; integralmente aos novos produtos e/ou funcionalidades
                que forem posteriormente desenvolvidos. Contudo, se houver mudan&ccedil;as que implique em
                altera&ccedil;&atilde;o em rela&ccedil;&atilde;o &agrave; coleta, ao uso, ao armazenamento, &agrave;
                prote&ccedil;&atilde;o, ao compartilhamento &nbsp;de dados pessoais, o Usu&aacute;rio ser&aacute;
                informado, de maneira pr&eacute;via, por meio de uma nova pol&iacute;tica de privacidade, de forma que a
                continuidade da utiliza&ccedil;&atilde;o dos servi&ccedil;os da Plataforma, ser&aacute; interpretada
                como seu consentimento aos novos termos apresentados.
              </p>
              <br />
              <p>
                Ao utilizar de quaisquer formas os produtos oferecidos pela Plataforma, o Usu&aacute;rio concorda com a
                presente Pol&iacute;tica de Privacidade, que se aplica a todas as rela&ccedil;&otilde;es envolvendo a
                Adv Service e seus Usu&aacute;rios. Caso voc&ecirc; n&atilde;o concorde com a politica de privacidade,
                por favor n&atilde;o utilize a plataforma.
              </p>
              <br />
              <ol>
                <li><strong> Objeto</strong></li>
              </ol>
              <p>
                1.1. Esta Pol&iacute;tica de Privacidade, em conjunto com os Termos de Uso da Plataforma, tem como
                objetivo conferir publicidade e esclarecer acerca do funcionamento da Plataforma Adv Service, nos termos
                do exigido pela Lei n&ordm; 12.965/2014 (Marco Civil da Internet) e Lei n&ordm; 13.709/2018 (Lei Geral
                de Prote&ccedil;&atilde;o de Dados - LGPD) mais especificamente em rela&ccedil;&atilde;o: i)
                &nbsp;&agrave;s informa&ccedil;&otilde;es e dados pessoais que s&atilde;o coletadas pelo Adv Service;
                ii) &agrave; forma de utiliza&ccedil;&atilde;o das Informa&ccedil;&otilde;es coletadas pelo Adv Service;
                iii) a forma de tratamento destes dados, os poss&iacute;veis compartilhamentos de dados; e iv)&nbsp; as
                finalidades para que determinados dados tenham sido coletados e os direitos do Usu&aacute;rio em
                rela&ccedil;&atilde;o aos dados pessoais que por ele forem fornecidos ou coletados diretamente
                pela&nbsp; plataforma.
              </p>
              <br />
              <ol start="2">
                <li><strong> Informa&ccedil;&otilde;es Coletadas pelo Adv Service</strong></li>
              </ol>
              <p>
                2.1. Para regular a utiliza&ccedil;&atilde;o e operacionaliza&ccedil;&atilde;o da Plataforma, bem como
                para melhorar a experi&ecirc;ncia de navega&ccedil;&atilde;o, o Adv Service coleta algumas
                Informa&ccedil;&otilde;es importantes de seus Usu&aacute;rios, listadas abaixo:
              </p>
              <br />
              <p>
                2.2. Informa&ccedil;&otilde;es de acesso: A utiliza&ccedil;&atilde;o dos servi&ccedil;os fornecidos pelo
                Adv Service est&aacute; somente para os Usu&aacute;rios que estiverem cadastrados na
                Plataforma.&nbsp;Para o cadastro, ser&atilde;o necess&aacute;rias as seguintes
                informa&ccedil;&otilde;es, para fins de identifica&ccedil;&atilde;o e individualiza&ccedil;&atilde;o dos
                Usu&aacute;rios na Plataforma: i)
                <em>Informa&ccedil;&otilde;es Obrigat&oacute;rias do Usu&aacute;rio</em>: Nome, CPF, e-mail,
                profiss&atilde;o e senha de acesso; ii) <em>Informa&ccedil;&otilde;es Opcionais: a</em>l&eacute;m das
                informa&ccedil;&otilde;es obrigat&oacute;rias, o Usu&aacute;rio poder&aacute; fornecer
                informa&ccedil;&otilde;es complementares, mas que n&atilde;o impe&ccedil;am a utiliza&ccedil;&atilde;o
                da Plataforma.
              </p>
              <br />
              <p>
                2.3. O Usu&aacute;rio tem o direito de alterar e atualizar seus dados a qualquer momento, autorizando o
                Adv Service a manter o registro das informa&ccedil;&otilde;es e dados previamente fornecidos, por
                motivos de seguran&ccedil;a e controle de acessos, pelo per&iacute;odo m&iacute;nimo de 10 anos.
              </p>
              <br />
              <ol start="3">
                <li>
                  <strong>
                    <em>Informa&ccedil;&otilde;es de Terceiros Inseridas pelo Usu&aacute;rio na Plataforma</em></strong
                  >
                </li>
              </ol>
              <p>
                3.1.&nbsp;Os servi&ccedil;os do Adv Service possibilitam ao Usu&aacute;rio inserir
                informa&ccedil;&otilde;es de terceiros para fins de elabora&ccedil;&atilde;o de pe&ccedil;as
                processuais, assim como para abastecer o Gestor Service com informa&ccedil;&otilde;es das partes
                envolvidas em casos judicias e extrajudiciais.&nbsp;A Plataforma permite a inser&ccedil;&atilde;o das
                seguintes informa&ccedil;&otilde;es de clientes do Usu&aacute;rio:
              </p>
              <br />
              <p>Nome Completo</p>
              <br />
              <p>G&ecirc;nero</p>
              <br />
              <p>CPF</p>
              <br />
              <p>Data de Nascimento</p>
              <br />
              <p>Endere&ccedil;o Residencial</p>
              <br />
              <p>Nacionalidade</p>
              <br />
              <p>Estado civil</p>
              <br />
              <p>Profiss&atilde;o</p>
              <br />
              <p>
                3.2. Al&eacute;m de tais informa&ccedil;&otilde;es, &eacute; poss&iacute;vel que o Usu&aacute;rio
                disponibilize na Plataforma outras informa&ccedil;&otilde;es de seus clientes.&nbsp;Tendo em vista o
                teor pessoal das informa&ccedil;&otilde;es, ao utilizar a Plataforma, o Usu&aacute;rio declara
                expressamente que as pessoas, cujas informa&ccedil;&otilde;es foram inseridas, autorizaram previamente o
                uso de tais dados, bem como est&atilde;o cientes e concordam com as disposi&ccedil;&otilde;es desta
                Pol&iacute;tica de Dados.
              </p>
              <br />
              <ol start="4">
                <li><strong> Informa&ccedil;&otilde;es de Acesso</strong></li>
              </ol>
              <p>
                4.1. Al&eacute;m dos dados pessoais e informa&ccedil;&otilde;es que o Usu&aacute;rio fornece
                voluntariamente, o Adv Service, por meio de cookies e tecnologias semelhantes, inclusive de terceiros,
                como o Google Analytics, tamb&eacute;m coletar&aacute; e armazenar&aacute; informa&ccedil;&otilde;es que
                ser&atilde;o recebidas automaticamente toda vez que o Usu&aacute;rio interagir com a Plataforma, dentre
                elas, por exemplo, Internet Protocol (IP), tipo de navegador, detalhes sobre as intera&ccedil;&otilde;es
                com a Plataforma (por exemplo, p&aacute;ginas visualizadas e dados e hora de acesso), hist&oacute;rico
                de navega&ccedil;&atilde;o, IMEI e UDID.
              </p>
              <br />
              <ol start="5">
                <li><strong>5. Finalidade do Uso dos dados pessoais pelo Adv Service</strong></li>
              </ol>
              <p>
                5.1. Na raz&atilde;o da intera&ccedil;&atilde;o do Usu&aacute;rio com a Plataforma, o Adv Service coleta
                e armazena uma s&eacute;rie de Informa&ccedil;&otilde;es, conforme descrito acima, seja pela necessidade
                de cumprimento da legisla&ccedil;&atilde;o, seja em raz&atilde;o de interesses leg&iacute;timos do Adv
                Service em rela&ccedil;&atilde;o &agrave; execu&ccedil;&atilde;o dos servi&ccedil;os e cumprimento de
                suas obriga&ccedil;&otilde;es contratuais.&nbsp;As Informa&ccedil;&otilde;es dos Usu&aacute;rios
                s&atilde;o utilizadas pelo Adv Service e suas declara&ccedil;&otilde;es de servi&ccedil;o para as
                seguintes finalidades (Finalidade motivada):
              </p>
              <br />
              <p>
                <em>5.1.1. Cumprimento Contratual</em>: As Informa&ccedil;&otilde;es de terceiros s&atilde;o utilizadas
                pelo Adv Service com o interesse leg&iacute;timo de garantir o cumprimento de suas
                obriga&ccedil;&otilde;es contratuais com o Usu&aacute;rio.&nbsp;Sendo assim, os dados inseridos na
                Plataforma s&atilde;o utilizados para fins de elabora&ccedil;&atilde;o das pe&ccedil;as processuais para
                os clientes do Usu&aacute;rio, assim como para uso do gestor de escrit&oacute;rio de advocacia e suas
                funcionalidades.
              </p>
              <br />
              <p>
                <em>5.1.2. Personaliza&ccedil;&atilde;o e melhoria da experi&ecirc;ncia de uso da Plataforma</em>: As
                informa&ccedil;&otilde;es coletadas pela Plataforma, em especial aquelas coletadas automaticamente
                (&ldquo;Informa&ccedil;&otilde;es de Acesso&rdquo;), t&ecirc;m como objetivo permitir ao Adv Service
                personalizar e melhorar a experi&ecirc;ncia do Usu&aacute;rio dentro da Plataforma.&nbsp;As
                informa&ccedil;&otilde;es poder&atilde;o ser utilizadas ainda para realiza&ccedil;&atilde;o de testes,
                solu&ccedil;&atilde;o de problemas e melhorias no funcionamento do sistema do Adv Service.
              </p>
              <br />
              <p>
                <em>5.1.3. An&aacute;lise de desempenho e seguran&ccedil;a</em>: As informa&ccedil;&otilde;es de
                utiliza&ccedil;&atilde;o da Plataforma tamb&eacute;m t&ecirc;m a finalidade de permitir que o Adv
                Service compreenda a forma como o Usu&aacute;rio interage com a Plataforma e tem como objetivo o
                interesse leg&iacute;timo de garantir a funcionalidade e aprimoramento da Plataforma, o desenvolvimento
                de novos produtos/funcionalidades, a identifica&ccedil;&atilde;o de problemas de navegabilidade, erros e
                falhas operacionais.&nbsp;Al&eacute;m disso, as Informa&ccedil;&otilde;es poder&atilde;o ser objeto de
                an&aacute;lise para identifica&ccedil;&atilde;o e preven&ccedil;&atilde;o de fraudes e atividades
                ilegais, bem como avalia&ccedil;&atilde;o de riscos e aprimoramento na seguran&ccedil;a da Plataforma.
              </p>
              <br />
              <p>
                <em>5.1.4. Atendimento da legisla&ccedil;&atilde;o para prote&ccedil;&atilde;o de dados</em>: O Adv
                Service coleta e armazena as informa&ccedil;&otilde;es de cadastro, informa&ccedil;&otilde;es inseridas
                pelo Usu&aacute;rio e informa&ccedil;&otilde;es de acesso tamb&eacute;m em raz&atilde;o da necessidade
                de atender a legisla&ccedil;&atilde;o no que se refere &agrave; prote&ccedil;&atilde;o dos dados de seus
                Usu&aacute;rios.
              </p>
              <br />
              <p>
                <em>5.1.4. Comunica&ccedil;&atilde;o</em>: As informa&ccedil;&otilde;es de cadastro s&atilde;o
                utilizadas para permitir a comunica&ccedil;&atilde;o entre o Adv Service e o Usu&aacute;rio.&nbsp;Por
                exemplo, para a atualiza&ccedil;&atilde;o dos termos de uso e pol&iacute;tica de privacidade,
                comunica&ccedil;&atilde;o de perguntas do sistema, manuten&ccedil;&otilde;es programadas, dentre outras.
              </p>
              <br />
              <p>
                <em>5.1.5. Oferetas e publicidade</em>: As informa&ccedil;&otilde;es cadastrais poder&atilde;o ser
                utilizadas, desde que expressamente autorizadas pelo Usu&aacute;rio, para envio de publicidade, ofertas
                e servi&ccedil;os do Adv Service ou de terceiros, permitindo, por exemplo, o oferecimento de
                conte&uacute;do relacionado aos seus interesses espec&iacute;ficos, como novas funcionalidades,
                conte&uacute;do individualizado e adaptado &agrave; sua localiza&ccedil;&atilde;o geogr&aacute;fica, por
                meio do servi&ccedil;o Google AdSense.&nbsp;Para informa&ccedil;&otilde;es sobre como o Google trata os
                maiores dados coletados em nosso site, acesse:
                https://www.google.com/policies/technologies/partner-sites/.
              </p>
              <br />
              <ol start="6">
                <li><strong> Compartilhamento de informa&ccedil;&otilde;es com terceiros</strong></li>
              </ol>
              <p>
                6.1. O funcionamento regular do Adv Service depende do compartilhamento de dados e
                Informa&ccedil;&otilde;es com terceiros.&nbsp;Abaixo, elencamos em quais situa&ccedil;&otilde;es
                poderemos compartilhar as Informa&ccedil;&otilde;es de nossos Usu&aacute;rios:
              </p>
              <br />
              <p>
                <em>6.1.1. Prestadores de servi&ccedil;o e terceirizados:</em>&nbsp;No exerc&iacute;cio de suas
                atividades, o Adv Service contrata servi&ccedil;o para a realiza&ccedil;&atilde;o de diversas
                atividades, tais como: hospedagem do site, coment&aacute;rios de acesso, armazenamento de dados,
                peritos, contadores, dentre outros.&nbsp;Essas informa&ccedil;&otilde;es de servi&ccedil;os, na medida
                do necess&aacute;rio para a execu&ccedil;&atilde;o das atividades para os quais foram contratados,
                ter&atilde;o acesso &agrave;s informa&ccedil;&otilde;es fornecidas pelo Usu&aacute;rio, estando sujeitas
                a cl&aacute;usulas de confidencialidade ou outros meios para garantir a seguran&ccedil;a dos dados
                fornecidos.
              </p>
              <br />
              <p>
                <em>6.1.2. Cumprimento de lei ou solicita&ccedil;&atilde;o &agrave;s autoridades:</em>&nbsp;As
                Informa&ccedil;&otilde;es dos Usu&aacute;rios poder&atilde;o ser compartilhadas com &Oacute;rg&atilde;os
                p&uacute;blicos e &oacute;rg&atilde;os p&uacute;blicos em caso de ordem judicial que obrigue o Adv
                Service a fornecer tais dados ou, sempre que o Adv Service entenda que o procedimento &eacute;
                necess&aacute;rio para o cumprimento da determina&ccedil;&atilde;o legal.
              </p>
              <br />
              <p>
                <em>6.1.3. Investidores:</em>&nbsp;As Informa&ccedil;&otilde;es dos Usu&aacute;rios poder&atilde;o ser
                compartilhadas com terceiros, caso o Adv Service seja, total ou parcialmente, vendido ou se iniciem
                tratativas para venda de participa&ccedil;&atilde;o societ&aacute;ria no neg&oacute;cio, sendo
                resguardado o direito de prote&ccedil;&atilde;o e confidencialidade sobre tais
                informa&ccedil;&otilde;es.
              </p>
              <br />
              <ol start="7">
                <li>
                  7<strong
                    >. Direitos do Usu&aacute;rio sobre as Informa&ccedil;&otilde;es coletadas pelo Adv Service</strong
                  >
                </li>
              </ol>
              <p>
                7.1. A Adv Service adotar&aacute; medidas t&eacute;cnicas e organizacionais apropriadas para cumprir as
                suas obriga&ccedil;&otilde;es em rela&ccedil;&atilde;o aos direitos dos Usu&aacute;rios enquanto
                titulares dos dados&nbsp; pessoais,&nbsp; nos&nbsp; termos&nbsp; da&nbsp; Lei&nbsp; Geral&nbsp; de&nbsp;
                Prote&ccedil;&atilde;o&nbsp; de&nbsp; Dados&nbsp; (Lei&nbsp; n.&nbsp; 13.709/2018).&nbsp; Nesse sentido,
                a Adv Service se compromete a viabilizar da melhor forma poss&iacute;vel os seus direitos, tais quais
                previstos em lei, quais sejam: i) <em>Armazenamento:</em>&nbsp;O Adv Service armazena as
                Informa&ccedil;&otilde;es de seus Usu&aacute;rios durante o per&iacute;odo em que for necess&aacute;rio
                para a presta&ccedil;&atilde;o do servi&ccedil;o, e para fins comerciais e empresariais
                leg&iacute;timos, nos termos do definido pela legisla&ccedil;&atilde;o.&nbsp;Independente disso, em
                aten&ccedil;&atilde;o ao disposto na Lei n&ordm; 12.965/2014, o Adv Service &eacute; obrigado a manter
                as Informa&ccedil;&otilde;es de Cadastro e de Acesso por um per&iacute;odo m&iacute;nimo de 06 (seis)
                meses, contados de sua coleta. ii) <em>Direitos dos Usu&aacute;rios.&nbsp;</em>Nos termos do artigo 18
                da Lei n.&nbsp;13.709/18, s&atilde;o direitos dos Usu&aacute;rios: a confirma&ccedil;&atilde;o pelo Adv
                Service do tratamento de dados, o acesso aos dados fornecidos, a corre&ccedil;&atilde;o dos dados
                incompletos, inexatos ou desatualizados;&nbsp;a anonimiza&ccedil;&atilde;o e elimina&ccedil;&atilde;o de
                dados desnecess&aacute;rios;&nbsp;a portabilidade de dados, dentre outros. Iii)
                <em>Altera&ccedil;&atilde;o e Revis&atilde;o:</em>&nbsp;O Usu&aacute;rio poder&aacute;, por meio da
                Plataforma, revisar e alterar suas Informa&ccedil;&otilde;es.&nbsp;Ainda assim, poder&aacute; solicitar
                a exclus&atilde;o de todas as suas Informa&ccedil;&otilde;es n&atilde;o anonimizadas, bem como solicitar
                o encerramento de sua conta, por meio de ferramenta dispon&iacute;vel na &aacute;rea do Usu&aacute;rio
                na Plataforma.
              </p>
              <br />
              <p>
                7.2. Caso queira exercer algum dos direitos previstos nesta Pol&iacute;tica de Privacidade ou na
                legisla&ccedil;&atilde;o aplic&aacute;vel, ou tenha d&uacute;vidas sobre este documento e as
                pr&aacute;ticas nele descritas, voc&ecirc; dever&aacute; entrar em contato com a&nbsp; pessoa
                encarregada na Adv Service pela comunica&ccedil;&atilde;o com titulares de dados pessoais,
                atrav&eacute;s do email: &ldquo;<u>contato @advservice.com.br</u>&rdquo;.
              </p>
              <br />
              <ol start="8">
                <li><strong> Seguran&ccedil;a das Informa&ccedil;&otilde;es</strong></li>
              </ol>
              <p>
                8.1. O Adv Service preza pela seguran&ccedil;a das Informa&ccedil;&otilde;es de seus Usu&aacute;rios,
                utilizando-se de todos os meios dispon&iacute;veis para a defesa e prote&ccedil;&atilde;o dos dados
                pessoais de seus usu&aacute;rios ou de qualquer um que venha a utilizar nossos servi&ccedil;os, por meio
                de pr&aacute;ticas e servi&ccedil;os de seguran&ccedil;a atualizados e eficientes.&nbsp;&nbsp;
              </p>
              <br />
              <ol start="9">
                <li><strong> Sites de terceiros</strong></li>
              </ol>
              <p>
                9.1. A plataforma do Adv Service poder&aacute; conter links para produtos de parceiro, que possuem
                termos e politicas pr&oacute;prias. Ou seja, esta Pol&iacute;tica se limita &agrave; Plataforma e aos
                Servi&ccedil;os oferecidos&nbsp; pela&nbsp; pr&oacute;pria&nbsp; Adv Service.&nbsp; Embora&nbsp; a&nbsp;
                Adv Service busque constantemente firmar rela&ccedil;&otilde;es com parceiros confi&aacute;veis, a Adv
                Service n&atilde;o se responsabiliza pelas pr&aacute;ticas de tratamento de dados pessoais realizadas
                exclusivamente por esses terceiros. Por essa raz&atilde;o, o Usu&aacute;rio deve ter pleno conhecimento
                dos termos e pol&iacute;ticas de privacidade de terceiros externos &agrave; Plataforma antes de fornecer
                qualquer dado pessoal. Em rela&ccedil;&atilde;o aos dados obtidos de fontes oficiais, considerando que
                sua coleta e atualiza&ccedil;&atilde;o s&atilde;o realizadas de forma automatizada e sem qualquer
                inger&ecirc;ncia da Adv Service quanto &agrave; sua precis&atilde;o, a Adv Service n&atilde;o se
                responsabiliza por eventuais inexatid&otilde;es, omiss&otilde;es ou erros em rela&ccedil;&atilde;o a
                esses dados.
              </p>
              <br />
              <ol start="10">
                <li><strong>Fontes Oficiais</strong></li>
              </ol>
              <p>
                10.1.&nbsp;&nbsp;&nbsp; Dados pessoais s&atilde;o extra&iacute;dos&nbsp; pela&nbsp; Adv Service,&nbsp;
                de&nbsp; forma&nbsp;&nbsp; automatizada,&nbsp; de&nbsp; fontes&nbsp; publicamente dispon&iacute;veis,
                como di&aacute;rios oficiais, dados oriundos de tribunais referentes a processos,
                informa&ccedil;&otilde;es de salas de imprensa de &oacute;rg&atilde;os p&uacute;blicos e
                legisla&ccedil;&atilde;o, a fim de ampliar o acesso a esse conte&uacute;do por parte dos seus
                Usu&aacute;rios.
              </p>
              <br />
              <ol start="11">
                <li>11<strong>. Altera&ccedil;&atilde;o da Pol&iacute;tica de Privacidade</strong></li>
              </ol>
              <p>
                11.1. O Adv Service poder&aacute; alterar esta Pol&iacute;tica de Privacidade a qualquer momento.&nbsp;O
                aceite das novas regras &eacute; uma decis&atilde;o que cabe exclusivamente ao Usu&aacute;rio, sendo o
                acesso a plataforma bloqueado a partir da entrada em vigor da nova reda&ccedil;&atilde;o, caso o
                usu&aacute;rio n&atilde;o a aceite.
              </p>
              <br />
              <ol start="12">
                <li><strong> Legisla&ccedil;&atilde;o</strong></li>
              </ol>
              <p>
                12.1.&nbsp; Esta Pol&iacute;tica de Privacidade &eacute; regida e interpretada pelas leis
                brasileiras.&nbsp;Qualquer disputa decorrente de sua interpreta&ccedil;&atilde;o e
                aplica&ccedil;&atilde;o, caso n&atilde;o resolvido de forma amig&aacute;vel, dever&aacute; ser
                solucionada perante o Foro da Comarca de Santa Maria, Estado do Rio Grande do Sul, com ren&uacute;ncia
                expressa a qualquer outro, por mais privilegiado que seja.
              </p>
              <br />
              <ol start="13">
                <li><strong> Contatos e d&uacute;vidas</strong></li>
              </ol>
              <p>
                13.1. Caso o Usu&aacute;rio tenha qualquer d&uacute;vida referente a esta Pol&iacute;tica de Privacidade
                ou, ainda, pretendente realizar qualquer solicita&ccedil;&atilde;o em rela&ccedil;&atilde;o &agrave;s
                suas Informa&ccedil;&otilde;es dever&aacute; entrar em contato com o Adv Service.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
