import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "36",
  height: "36",
  viewBox: "0 0 36 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

interface IProps {
  color?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'InstaIcon',
  props: {
    color: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M7.30439 22.4518V13.562C7.30439 12.6858 7.5409 11.7131 7.79993 11.071C8.10121 10.3244 8.40545 9.78082 8.95356 9.23823C10.0175 8.18499 11.5891 7.45016 13.2078 7.45016H22.7923C24.3426 7.45016 25.9623 8.16034 27.0117 9.20364C27.5546 9.74349 27.8665 10.2436 28.1801 11.0216C28.4564 11.707 28.6957 12.6464 28.6957 13.562V22.4518C28.6957 24.3778 27.834 26.3501 26.2654 27.453C25.3917 28.0673 23.9395 28.5636 22.7923 28.5636H13.2078C11.6575 28.5636 10.0378 27.8535 8.98844 26.8102C7.93537 25.7632 7.30439 24.05 7.30439 22.4518ZM0.636963 17.3818C0.636963 19.4494 0.638068 20.233 1.15096 22.2851C1.91929 25.3592 3.4942 28.0525 5.72434 30.2826C7.16809 31.7263 8.18268 32.4455 9.92076 33.3787C12.1369 34.5685 14.7924 35.37 17.375 35.37C20.0826 35.37 21.3648 35.2264 23.8782 34.3723C24.4293 34.1851 24.8468 33.9679 25.3523 33.7628C26.0698 33.4718 27.293 32.7029 27.9882 32.2316C29.9248 30.9185 31.8311 28.8012 33.0054 26.7615C33.1291 26.5466 33.2373 26.329 33.3783 26.0927C34.512 24.194 35.3631 20.8336 35.3631 18.632C35.3631 15.9244 35.2195 14.6421 34.3655 12.1288C33.8707 10.6726 33.0831 9.2847 32.2247 8.01878C30.3584 5.26626 27.1336 2.77495 23.9304 1.65874C23.37 1.46347 22.9403 1.32263 22.334 1.17157C19.4795 0.460365 16.5804 0.443357 13.7219 1.15781C9.77948 2.14318 6.09857 4.65634 3.80339 7.97739C3.50066 8.41546 3.25778 8.78824 2.98441 9.24196C2.49621 10.0523 1.95701 11.2009 1.65189 12.0766C1.45662 12.6369 1.31578 13.0667 1.16472 13.673C0.890414 14.7739 0.636963 16.0494 0.636963 17.3818Z",
      fill: _ctx.color ? _ctx.color : '#FEFEFE'
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12.3744 18.0764C12.3744 14.8427 14.8358 12.3813 18.0695 12.3813C21.096 12.3813 23.6257 14.8748 23.6257 18.0764C23.6257 21.1125 21.1358 23.6325 17.9306 23.6325C14.9147 23.6325 12.3744 21.0889 12.3744 18.0764ZM23.6951 13.4925C22.1833 13.4925 22.232 11.27 23.6951 11.27C25.0836 11.27 25.1844 13.4925 23.6951 13.4925ZM9.1796 16.0622L9.17939 19.3267C9.17674 20.0455 9.24906 20.6253 9.24906 21.4101C9.24906 23.183 9.37206 24.4179 10.479 25.5279C11.0093 26.0597 11.9611 26.4301 12.7324 26.5388C13.7033 26.6757 17.8921 26.6884 19.1113 26.6884C19.5981 26.6884 20.0855 26.6915 20.5722 26.6908C21.0511 26.6902 21.3962 26.6179 21.9588 26.619C22.9787 26.6208 23.8129 26.5294 24.5063 26.18C24.8819 25.9909 25.1049 25.8848 25.4119 25.6271C25.5984 25.4706 25.9646 25.009 26.0984 24.7856C26.4294 24.2328 26.751 23.3043 26.751 22.4518V13.562C26.751 12.0047 25.9596 10.4826 24.5588 9.85074C23.8102 9.51312 23.1543 9.39287 22.0978 9.39479L16.8194 9.32537C15.3302 9.32537 13.1615 9.25713 11.8336 9.6874C10.483 10.125 9.65548 11.2571 9.359 12.6301C9.26661 13.0578 9.1796 15.4453 9.1796 16.0622Z",
      fill: _ctx.color ? _ctx.color : '#FEFEFE'
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M14.3191 18.0069C14.3191 21.1587 18.2154 22.967 20.5878 20.5946C22.9634 18.219 21.1483 14.3259 18.0001 14.3259C16.0052 14.3259 14.3191 16.0121 14.3191 18.0069Z",
      fill: _ctx.color ? _ctx.color : '#FEFEFE'
    }, null, 8, _hoisted_4)
  ]))
}
}

})