import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/adv-login-logo.svg'


const _hoisted_1 = { class: "w-full h-screen overflow-auto flex flex-col gap-8 bg-[url('@/assets/img/bg-login.svg')] bg-cover" }
const _hoisted_2 = { class: "w-full flex flex-col justify-center items-center gap-20" }
const _hoisted_3 = { class: "max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14" }
const _hoisted_4 = { class: "w-full" }

import { ref } from 'vue';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import Head from '@/components/Header/Head.vue';
import { http } from '@/core/api';
import { Notyf } from 'notyf';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PassRecover',
  setup(__props) {

const notyf = new Notyf();

const email = ref('');
const loading = ref(false);

async function getRecoveryToken() {
  loading.value = true;
  try {
    await http.post(`/recoverTokens?email=${email.value}`);

    notyf.success('E-mail enviado com sucesso!');
  } catch (err: any) {
    notyf.error(err.message);
    console.error(err);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Head),
    _createElementVNode("main", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: "login logo",
        class: "h-28 w-auto hidden z-10 lg:block"
      }, null, -1)),
      _createElementVNode("section", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold text-2xl tracking-wide text-center" }, "Recuperação de Senha", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-adv-gray-400 text-lg text-center" }, " Para recuperar a sua senha, informe seu endereço de e-mail que nós enviaremos um link para alteração da senha. ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "E-mail", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            class: _normalizeClass(_unref(formInput))
          }, null, 2), [
            [_vModelText, email.value]
          ])
        ]),
        _createElementVNode("button", {
          class: _normalizeClass([_unref(lightButtonStyle), "w-full flex justify-center items-center"]),
          onClick: getRecoveryToken
        }, [
          _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
            [_vShow, loading.value]
          ]),
          _withDirectives(_createElementVNode("p", null, "ENVIAR", 512), [
            [_vShow, !loading.value]
          ])
        ], 2)
      ])
    ])
  ]))
}
}

})