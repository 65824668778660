import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, createTextVNode as _createTextVNode, vShow as _vShow, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_4 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_7 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_10 = { class: "w-full max-w-xs" }
const _hoisted_11 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_12 = { class: "w-full max-w-xs" }
const _hoisted_13 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_14 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_15 = { class: "w-full max-w-xs" }
const _hoisted_16 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_17 = { class: "w-full max-w-xs" }
const _hoisted_18 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_19 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_20 = { class: "w-full flex justify-start items-center gap-2" }
const _hoisted_21 = {
  class: "text-[#A8A8A8] font-bold tracking-widest text-sm",
  for: "contract"
}
const _hoisted_22 = ["disabled"]

import { Notyf } from 'notyf';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { useSignUpStore } from '@/store/signUpStore';
import { useServicePlansStore } from '@/store/servicePlansStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { planType } from '@/types/cardTypes';
import { useModalStore } from '@/store/modalStore';
import ContractModal from '@/components/Terms/contract.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PayForm',
  setup(__props) {

const notyf = new Notyf();
const store = useSignUpStore();
const planStore = useServicePlansStore();
const modalStore = useModalStore();

console.log('cpf', store.signUpForm.cpf)

const validationSchema = toTypedSchema(
    zod.object({
        cpf: zod.string({
            required_error: 'Campo obrigatório',
        }).default(store.signUpForm.cpf),
        cardName: zod.string({
            required_error: 'Campo obrigatório',
        }),
        cardNumber: zod.string({
            required_error: 'Campo obrigatório',
        }),
        expirationDate: zod.string({
            required_error: 'Campo obrigatório',
        }),
        cvv: zod.string({
            required_error: 'Campo obrigatório',
        }),
        postalCode: zod.string({
            required_error: 'Campo obrigatório',
        }),
        addressNumber: zod.string({
            required_error: 'Campo obrigatório',
        }),
        acceptContract: zod.boolean(),
    })
);
const { handleSubmit, errors, setFieldError } = useForm({
    validationSchema,
});

const { value: cpf } = useField('cpf',);
const { value: cardName } = useField('cardName');
const { value: cardNumber } = useField('cardNumber');
const { value: cvv } = useField('cvv');
const { value: postalCode } = useField('postalCode');
const { value: addressNumber } = useField('addressNumber');
const { value: expirationDate } = useField('expirationDate');
const { value: acceptContract } = useField('acceptContract');

const handleSignup = handleSubmit(async (values) => {
    if (store.loading) return;

    const actualYear = new Date().toLocaleDateString('en', { year: '2-digit' });

    if (Number(values.expirationDate.split('/')[1]) < Number(actualYear)) {
        return setFieldError('expirationDate', 'Validade expirada');
    }
    if(!Number(values.addressNumber)){
        return  setFieldError('addressNumber', 'Número deve ser maior do que zero!');
    }

    store.loading = true;
    console.log(values)

    try {
        await store.submitPaymentInfo(values, planStore.selectedPlan as planType);
    } catch (err: any) {
        console.log('err', err);
    } finally {
        store.loading = false;
    }
});

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_unref(handleSignup) && _unref(handleSignup)(...args)), ["prevent"])),
    class: "w-full gap-8 flex flex-col"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "CPF", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(cpf) ? (cpf).value = $event : null)),
          "data-maska": "###.###.###-##",
          class: _normalizeClass(_unref(formInput) + `${_unref(errors).cpf ? 'border-2 border-rose-500' : ''}`)
        }, null, 2), [
          [_vModelText, _unref(cpf)],
          [_directive_maska]
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(errors).cpf), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "Nome impresso no cartão", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(cardName) ? (cardName).value = $event : null)),
          class: _normalizeClass(_unref(formInput) + `${_unref(errors).cardName ? 'border-2 border-rose-500' : ''}`)
        }, null, 2), [
          [_vModelText, _unref(cardName)]
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(errors).cardName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "Número do cartão", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "data-maska": "#### #### #### ####",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(cardNumber) ? (cardNumber).value = $event : null)),
          placeholder: "0000 0000 0000 0000",
          class: _normalizeClass(_unref(formInput) + `${_unref(errors).cardNumber ? 'border-2 border-rose-500' : ''}`)
        }, null, 2), [
          [_directive_maska],
          [_vModelText, _unref(cardNumber)]
        ]),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(errors).cardNumber), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "Vencimento (MM/AA)", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "data-maska": "##/##",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(expirationDate) ? (expirationDate).value = $event : null)),
          class: _normalizeClass([_unref(formInput) + `${_unref(errors).expirationDate ? 'border-2 border-rose-500' : ''}`, "max-w-xs"]),
          placeholder: "(MM/AA)"
        }, null, 2), [
          [_directive_maska],
          [_vModelText, _unref(expirationDate)]
        ]),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(errors).expirationDate), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "CVV", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "data-maska": "###",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(cvv) ? (cvv).value = $event : null)),
          placeholder: "000",
          class: _normalizeClass([_unref(formInput) + `${_unref(errors).cvv ? 'border-2 border-rose-500' : ''}`, "max-w-xs"])
        }, null, 2), [
          [_directive_maska],
          [_vModelText, _unref(cvv)]
        ]),
        _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(errors).cvv), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "CEP", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "data-maska": "#####-###",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(postalCode) ? (postalCode).value = $event : null)),
          placeholder: "00000-000",
          class: _normalizeClass([_unref(formInput) + `${_unref(errors).postalCode ? 'border-2 border-rose-500' : ''}`, "max-w-xs"])
        }, null, 2), [
          [_directive_maska],
          [_vModelText, _unref(postalCode)]
        ]),
        _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(errors).postalCode), 1)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "Número", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(addressNumber) ? (addressNumber).value = $event : null)),
          placeholder: "000",
          class: _normalizeClass([_unref(formInput) + `${_unref(errors).addressNumber ? 'border-2 border-rose-500' : ''}`, "max-w-xs"])
        }, null, 2), [
          [_vModelText, _unref(addressNumber)]
        ]),
        _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(errors).addressNumber), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(acceptContract) ? (acceptContract).value = $event : null)),
          type: "checkbox",
          id: "contract",
          name: "contract"
        }, null, 512), [
          [_vModelCheckbox, _unref(acceptContract)]
        ]),
        _createElementVNode("label", _hoisted_21, [
          _cache[17] || (_cache[17] = _createTextVNode("Eu aceito e concordo com o ")),
          _createElementVNode("a", {
            class: "text-gray-700 cursor-pointer",
            onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_unref(modalStore).contractModalOpen = true), ["prevent"]))
          }, "contrato")
        ])
      ])
    ]),
    _createElementVNode("button", {
      type: "submit",
      class: _normalizeClass([_unref(lightButtonStyle), "w-full flex justify-center items-center"]),
      disabled: !_unref(acceptContract)
    }, [
      _withDirectives(_createElementVNode("p", null, "FINALIZAR", 512), [
        [_vShow, !_unref(store).loading]
      ]),
      _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
        [_vShow, _unref(store).loading]
      ])
    ], 10, _hoisted_22),
    _createVNode(ContractModal)
  ], 32))
}
}

})