import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, vShow as _vShow, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white p-12 min-h-[39rem] w-full flex flex-col items-center justify-center gap-14" }
const _hoisted_2 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_5 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_10 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_11 = { class: "w-full" }
const _hoisted_12 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_13 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_14 = { class: "w-full" }
const _hoisted_15 = { class: "mt-1 text-xs text-rose-500" }

import { ref } from 'vue';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import Layout from '@/components/BaseLayout/Layout.vue';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { http } from '@/core/api';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { Notyf } from 'notyf';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const notyf = new Notyf();
const loading = ref(false);

const validationSchema = toTypedSchema(
  zod.object({
    name: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    email: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    phone: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    subject: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    description: zod
      .string({
        required_error: 'Campo obrigatório',
      })
      .nonempty('Campo obrigatório'),
    answered: zod.boolean(),
  })
);
const { handleSubmit, errors } = useForm({
  validationSchema,
  initialValues: {
    name: '',
    email: '',
    answered: false,
    phone: '',
    subject: '',
    description: '',
  },
});

const { value: name } = useField('name');
const { value: email } = useField('email');
const { value: phone } = useField('phone');
const { value: subject } = useField('subject');
const { value: description } = useField<string>('description');

const handleSignup = handleSubmit(async (values) => {
  if (loading.value) return;
  loading.value = true;

  try {
    await http.post('/contact', values);
    notyf.success('Mensagem enviada com sucesso');
  } catch (err) {
    notyf.error('Houve um erro durante o envio, tente novamente mais tarde!');
    console.error(err);
  }

  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "font-bold text-adv-blue-800 text-2xl" }, "ENTRE EM CONTATO", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-base text-adv-blue-800 text-center" }, "Informe os dados para contato", -1)),
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_unref(handleSignup) && _unref(handleSignup)(...args)), ["prevent"])),
          class: "flex flex-col gap-4"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-1" }, [
                _createTextVNode(" Nome completo "),
                _createElementVNode("strong", { class: "text-red-600" }, "*")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                name: "name",
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(name) ? (name).value = $event : null)),
                class: _normalizeClass(_unref(formInput) + `${_unref(errors).name ? 'border-2 border-rose-500' : ''}`)
              }, null, 2), [
                [_vModelText, _unref(name)]
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(errors).name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-1" }, [
                _createTextVNode(" E-mail "),
                _createElementVNode("strong", { class: "text-red-600" }, "*")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                name: "email",
                type: "email",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(email) ? (email).value = $event : null)),
                class: _normalizeClass(_unref(formInput) + `${_unref(errors).email ? 'border-2 border-rose-500' : ''}`)
              }, null, 2), [
                [_vModelText, _unref(email)]
              ]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(errors).email), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-1" }, [
                _createTextVNode(" Telefone "),
                _createElementVNode("strong", { class: "text-red-600" }, "*")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                type: "phone",
                "data-maska": "['(##) #####-####', '(##) ####-####']",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(phone) ? (phone).value = $event : null)),
                class: _normalizeClass(_unref(formInput) + `${_unref(errors).phone ? 'border-2 border-rose-500' : ''}`)
              }, null, 2), [
                [_directive_maska],
                [_vModelText, _unref(phone)]
              ]),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(errors).phone), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-1" }, [
                _createTextVNode(" Assunto "),
                _createElementVNode("strong", { class: "text-red-600" }, "*")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                name: "subject",
                type: "text",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(subject) ? (subject).value = $event : null)),
                class: _normalizeClass(_unref(formInput) + `${_unref(errors).subject ? 'border-2 border-rose-500' : ''}`)
              }, null, 2), [
                [_vModelText, _unref(subject)]
              ]),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(errors).subject), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-1" }, [
                _createTextVNode(" Descreva sua dúvida "),
                _createElementVNode("strong", { class: "text-red-600" }, "*")
              ], -1)),
              _withDirectives(_createElementVNode("textarea", {
                name: "description",
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(description) ? (description).value = $event : null)),
                class: _normalizeClass(["h-80", _unref(formInput) + `${_unref(errors).description ? 'border-2 border-rose-500' : ''}`])
              }, null, 2), [
                [_vModelText, _unref(description)]
              ]),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(errors).description), 1)
            ])
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass([_unref(lightButtonStyle), "max-w-[30rem] w-full flex justify-center items-center"])
          }, [
            _withDirectives(_createElementVNode("p", null, "ENVIAR", 512), [
              [_vShow, !loading.value]
            ]),
            _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
              [_vShow, loading.value]
            ])
          ], 2)
        ], 32)
      ])
    ]),
    _: 1
  }))
}
}

})