import axios, { InternalAxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';
// import store from "../store";
// import { appInstance } from '../main'
// import router from '../../router'
import * as storage from '../../store/storage';
const url = process.env.VUE_APP_BASE_URL;

//https://api-development.advservice.com.br/api/

export const http = axios.create({
  baseURL: url ? url.toString() : 'https://api-development.advservice.com.br/api/',
});

export const viaCepApi = axios.create({
  baseURL: 'https://viacep.com.br/ws/',
});

http.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (config.headers === undefined) {
    return config;
  }
  const token = storage.getAccessToken();
  config.headers.Authorization = 'Bearer ' + token;
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!error.response.status || error.response.status == 401) {
      console.log(error.response.data.message.message);
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text:
          error.response.status == 401
            ? 'Seu login expirou!'
            : error.response.data.message.message || 'Ocorreu um erro inesperado, favor contactar o suporte.',
      });
      // .then(() => {

      //     router.push({ name: "Login" });

      // });
    }

    // const isLogged = JSON.parse(localStorage.getItem("usuario"));
    // if (error.status === 401) {
    //     // store.dispatch("auth/ActionSingnout");
    //     Swal
    //         .fire({
    //             icon: "warning",
    //             title: "Aviso",
    //             text: error.data.error.message || "Sessão expirada!",
    //         })
    //         .then(() => {
    //             if (appInstance.$route.name !== "Login") {
    //                 appInstance.$router.push({ name: "Login" });
    //             }
    //         });
    // } else if (error.status != 200) {
    //     if (error.data) {
    //         Swal.fire({
    //             icon: "error",
    //             title: error.data.error.title || "Oops...",
    //             text: error.data.error.message,
    //         });
    //         // store.dispatch("preloader/ActionSetPayment", false);
    //         // store.dispatch("preloader/ActionSetLoad", false);
    //     } else {
    //         Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             text: "Ocorreu um erro inesperado, favor contactar o suporte.",
    //         });
    //     }
    // }
    // if (isLogged) {
    //     const routerPath = appInstance.$router.history.current.fullPath;
    //     const routerLocal = localStorage.getItem("avisoTrocar");
    //     if (
    //         isLogged.alt_proximo_login == true &&
    //         routerLocal !== routerPath &&
    //         routerPath != "/login" &&
    //         routerPath != `/cadastro/${isLogged.id}`
    //     ) {
    //         localStorage.setItem("avisoTrocar", routerPath);

    //         // store.dispatch("preloader/ActionSetPreloader", {
    //         //     text: "",
    //         //     status: false,
    //         // });
    //         Swal
    //             .fire({
    //                 icon: "warning",
    //                 title: "Aviso",
    //                 text:
    //                     "Seja bem vindo! Você será redirecionado para alterar sua senha. ",
    //             })
    //             .then(() => {
    //                 appInstance.$router.push(`/cadastro/${isLogged.id}`);
    //             });
    //     }
    // }
    return { data: error.response.data, code: error.response.status };
  }
);

export const setBearerToken = (token: string) => {
  if (token) {
    http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common['Authorization'];
  }
};
