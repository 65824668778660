import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-max w-full flex justify-center flex-col items-center lg:p-28 py-16 overflow-hidden bg-[#F4F7FF]" }
const _hoisted_2 = { class: "font-bold text-4xl text-adv-blue-800 mb-16 max-w-lg text-center tracking-wider" }
const _hoisted_3 = { class: "flex flex-col items-center h-[400px] bg-white p-10 rounded-2xl border-2 mr-3" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "mt-auto" }
const _hoisted_6 = ["onClick"]

import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { lightButtonStyle } from '@/assets/css/styles';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { http } from '@/core/api';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

interface SolutionsProps {
  title: string;
}

type SolutionObj = {
  id: number;
  active: boolean;
  description: string;
  image: string;
  name: string;
  serviceLink: string;
};

const textPClass = 'text-adv-gray-500 text-center my-10 text-base';

export default /*@__PURE__*/_defineComponent({
  __name: 'Solutions',
  props: {
    title: {}
  },
  setup(__props: any) {

const router = useRouter();
const solutions = ref<SolutionObj[]>([]);
const loading = ref(false);

const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

async function getSolutionsList() {
  loading.value = true;
  try {
    const { data } = await http.get('solutions/all?active=true');

    solutions.value = data.body.result;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
    console.log(solutions.value);
  }
}
// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

onBeforeMount(async () => {
  await getSolutionsList();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (loading.value)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
      : (_openBlock(), _createBlock(_unref(Splide), {
          key: 1,
          extensions: { AutoScroll: _unref(AutoScroll) },
          options: {
        clones: 4,
        trimSpace: 'move',
        omitEnd: true,
        autoplay: false,
        pagination: false,
        arrows: false,
        type: 'loop',
        drag: 'free',
      },
          "aria-label": "Soluções"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(solutions.value, (sol, index) => {
              return (_openBlock(), _createBlock(_unref(SplideSlide), { key: index }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: sol.image,
                      alt: sol.name,
                      class: "max-w-[300px] max-h-[60px] px-10"
                    }, null, 8, _hoisted_4),
                    _createElementVNode("p", {
                      class: _normalizeClass(textPClass)
                    }, _toDisplayString(sol.description), 1),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("button", {
                        onClick: ($event: any) => (
                routeRoll(
                  sol.name
                    .replace(/ /g, '-')
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                )
              ),
                        class: _normalizeClass(_unref(lightButtonStyle))
                      }, " ACESSAR ", 10, _hoisted_6)
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["extensions"]))
  ]))
}
}

})