import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vShow as _vShow, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/adv-login-logo.svg'


const _hoisted_1 = { class: "w-full h-screen overflow-auto flex flex-col gap-8 bg-[url('@/assets/img/bg-login.svg')] bg-cover" }
const _hoisted_2 = { class: "w-full flex flex-col justify-center items-center gap-20" }
const _hoisted_3 = { class: "max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14" }
const _hoisted_4 = { class: "w-full sm:w-4/5" }
const _hoisted_5 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_6 = { class: "w-full sm:w-4/5" }
const _hoisted_7 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_8 = { class: "max-w-5xl w-full rounded-3xl p-6 lg:p-24 flex flex-col justify-center bg-white items-center gap-14" }

import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { http } from '@/core/api';
import { Notyf } from 'notyf';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import Head from '@/components/Header/Head.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PassChange',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const notyf = new Notyf();

const loading = ref(false);
const success = ref(false);

const validationSchema = toTypedSchema(
  zod
    .object({
      password: zod
        .string({
          required_error: 'Campo obrigatório',
        })
        .nonempty('Campo obrigatório')
        .min(8, 'Sua senha deve conter, ao menos, 8 caracteres'),
      passwordCheck: zod.string(),
    })
    .refine((data) => data.password === data.passwordCheck, {
      message: 'As senhas devem ser iguais',
      path: ['passwordCheck'],
    })
);
const { handleSubmit, errors } = useForm({
  validationSchema,
});

const { value: password } = useField('password');
const { value: passwordCheck } = useField('passwordCheck');

const setNewPassword = handleSubmit(async (values) => {
  loading.value = true;
  try {
    await http.put(`/users/updatePassword/${route.params.token}`, {
      password: values.password,
    });

    success.value = true;
  } catch (err: any) {
    notyf.error(err.message);
    console.error(err);
  }
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Head),
    _createElementVNode("main", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("img", {
        src: _imports_0,
        alt: "login logo",
        class: "h-28 w-auto hidden z-10 lg:block"
      }, null, -1)),
      _withDirectives(_createElementVNode("section", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold text-2xl tracking-wide text-center" }, "Criar nova senha", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-adv-gray-400 text-lg text-center" }, "Insira e confirme sua nova senha.", -1)),
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_unref(setNewPassword) && _unref(setNewPassword)(...args)), ["prevent"])),
          class: "contents"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "Nova senha", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(password) ? (password).value = $event : null)),
              class: _normalizeClass(_unref(formInput) + `${_unref(errors).password ? 'border-2 border-rose-500' : ''}`)
            }, null, 2), [
              [_vModelText, _unref(password)]
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(errors).password), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "Confirmar nova senha", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(passwordCheck) ? (passwordCheck).value = $event : null)),
              class: _normalizeClass(_unref(formInput) + `${_unref(errors).passwordCheck ? 'border-2 border-rose-500' : ''}`)
            }, null, 2), [
              [_vModelText, _unref(passwordCheck)]
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(errors).passwordCheck), 1)
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass([_unref(lightButtonStyle), "w-full flex justify-center items-center"])
          }, [
            _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
              [_vShow, loading.value]
            ]),
            _withDirectives(_createElementVNode("p", null, "ENVIAR", 512), [
              [_vShow, !loading.value]
            ])
          ], 2)
        ], 32)
      ], 512), [
        [_vShow, !success.value]
      ]),
      _withDirectives(_createElementVNode("section", _hoisted_8, [
        _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold text-2xl tracking-wide text-center" }, "Senha alterada com sucesso!", -1)),
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([_unref(lightButtonStyle), "flex justify-center items-center"]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/login')))
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("p", null, "Entrar", -1)
        ]), 2)
      ], 512), [
        [_vShow, success.value]
      ])
    ])
  ]))
}
}

})