<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import DropDownSolutions from '../DropDown/DropDownSolutions.vue';
import DropDownMenu from '@/components/DropDown/DropDownMenu.vue';
import { useUserStore } from '@/store/user';

const userStore = useUserStore();

const router = useRouter();
const activeText = 'txt-btn text-adv-blue-800 font-bold text-xs';
const inactiveText = 'txt-btn text-xs text-dark-grey';

const isActiveClass = (route: string): string => {
  if (route === '/' && window.location.pathname === route) return activeText;
  if (route !== '/' && window.location.pathname.includes(route)) return activeText;

  return inactiveText;
};

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

onMounted(() => {
  userStore.checkUserAction();
});
</script>

<template>
  <header class="relative z-20 w-full h-32 p-7 bg-header-grey flex justify-center items-center">
    <div class="max-w-6xl w-full flex flex-row justify-between items-center">
      <img
        src="@/assets/img/adv-logo-header.svg"
        alt="ADV Service Logo"
        class="h-4/5 w-auto mr-4 cursor-pointer"
        @click="router.push('/')"
      />
      <div class="hidden lg:flex lg:w-full lg:justify-evenly">
        <nav class="flex flex-row justify-around items-center w-1/2">
          <!-- <a @click="router.push('/')" :class="isActiveClass('/')">INÍCIO</a> -->
          <a @click="router.push('/adv-service')" :class="isActiveClass('/adv-service')">QUEM SOMOS</a>
          <DropDownSolutions />
          <a @click="router.push('/planos')" :class="isActiveClass('/planos')">PLANOS</a>
          <a @click="router.push('/blog')" :class="isActiveClass('/blog')">BLOG</a>
          <a @click="router.push('/noticias')" :class="isActiveClass('/noticias')">NOTÍCIAS</a>
        </nav>
        <div class="flex gap-1 justify-center items-center">
          <button
            type="button"
            @click="router.push('/login')"
            class="w-40 h-10 m-0 border border-adv-blue-800 flex justify-center items-center bg-transparent text-adv-blue-800 cursor-pointer"
          >
            ENTRAR
          </button>
          <button
            type="button"
            @click="goToSign"
            class="w-40 h-10 m-0 border-0 flex justify-center items-center bg-adv-blue-800 text-white cursor-pointer"
          >
            TESTE GRÁTIS
          </button>
        </div>
      </div>
      <DropDownMenu />
    </div>
  </header>
</template>
