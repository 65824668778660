<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { getActiveSolutions } from '@/services/solutions';

const show = ref(false);
const activeText = 'txt-btn text-adv-blue-800 font-bold text-xs';
const inactiveText = 'txt-btn text-xs text-dark-grey';
const activeSolution = 'bg-adv-blue-800 text-header-grey';
const inactiveSolution = 'text-dark-grey';

const activeSolutions = ref<number[]>([]);

// const isDevelopment = computed(() => {
//   return window.location.href.includes('development') || window.location.href.includes('localhost');
// });

const isActiveClass = (): string => {
  if (window.location.pathname === '/trabalhista-service' || window.location.pathname === '/gestor-service')
    return activeText;

  return inactiveText;
};

const isActiveSolution = (route: string): string => {
  if (window.location.pathname === route) return activeSolution;

  return inactiveSolution;
};

function onClickAway() {
  show.value = false;
}

onMounted(async () => {
  const { data } = await getActiveSolutions();

  activeSolutions.value = data.body.result.map((item: any) => {
    return item.id;
  });
});
</script>

<template>
  <div class="relative" v-click-away="onClickAway">
    <!-- Dropdown toggle button -->
    <button
      @click="show = !show"
      @mouseenter="show = true"
      :class="isActiveClass()"
      class="flex items-center rounded-md bg-transparent focus:outline-none"
    >
      <span class="mr-4">SOLUÇÕES</span>
      <svg class="h-5 w-5 text-indigo-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#656565">
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <!-- Dropdown menu -->
    <div
      v-show="show"
      @mouseleave="onClickAway"
      class="absolute -left-1/3 z-50 mt-2 w-max rounded-md bg-header-grey py-2"
    >
      <router-link
        v-if="activeSolutions.includes(1)"
        :class="isActiveSolution('/trabalhista-service')"
        to="/trabalhista-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        TRABALHISTA SERVICE
      </router-link>
      <router-link
        v-if="activeSolutions.includes(2)"
        :class="isActiveSolution('/gestor-service')"
        to="/gestor-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        GESTOR SERVICE
      </router-link>
      <router-link
        v-if="activeSolutions.includes(3)"
        :class="isActiveSolution('/pergunte-ao-especialista')"
        to="/pergunte-ao-especialista"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        PERGUNTE AO ESPECIALISTA
      </router-link>
      <router-link
        v-if="activeSolutions.includes(7)"
        :class="isActiveSolution('/mentoria-service')"
        to="/mentoria-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        MENTORIA SERVICE
      </router-link>

      <router-link
        v-if="activeSolutions.includes(8)"
        :class="isActiveSolution('/buscador-service')"
        to="/buscador-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        BUSCADOR SERVICE
      </router-link>
      <router-link
        v-if="activeSolutions.includes(9)"
        :class="isActiveSolution('/pdf-service')"
        to="/pdf-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        PDF SERVICE
      </router-link>
      <router-link
        v-if="activeSolutions.includes(10)"
        :class="isActiveSolution('/assinador-service')"
        to="/assinador-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        ASSINADOR SERVICE
      </router-link>
      <router-link
        v-if="activeSolutions.includes(11)"
        :class="isActiveSolution('/contratualista-service')"
        to="/contratualista-service"
        class="txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"
      >
        CONTRATUALISTA SERVICE
      </router-link>
    </div>
  </div>
</template>
