import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/oab-multi.png'
import _imports_1 from '@/assets/img/landing-banner.png'
import _imports_2 from '@/assets/img/mobile.gif'
import _imports_3 from '@/assets/img/section-image-planos.png'


const _hoisted_1 = { class: "bg-[#F4F7FF]" }
const _hoisted_2 = { class: "bg-[#F4F7FF] h-max flex flex-col items-center p-8 lg:p-28 mx-auto gap-10" }
const _hoisted_3 = { class: "w-full flex flex-col lg:flex-row justify-between items-center max-w-screen-xl" }
const _hoisted_4 = { class: "sm:px-10 w-[100%] py-6 lg:w-[70%]" }
const _hoisted_5 = { class: "gap-3 flex flex-col md:flex-row items-start md:items-start justify-start" }
const _hoisted_6 = { class: "w-full bg-[#27479D] flex flex-col items-center justify-center gap-10" }

import Layout from '@/components/BaseLayout/Layout.vue';
import PlansCards from '@/components/Plans/PlansCards.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Depoimentos from '@/components/Depoimentos/Depoimentos.vue';
import Faq from '@/components/Faq/Faq.vue';
import DefaultSection from '@/components/DefaultSection.vue';

import { useRouter } from 'vue-router';
import { useFaqStore } from '@/store/faqStore';
import { lightButtonStyle } from '@/assets/css/styles';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const router = useRouter();
const faqStore = useFaqStore();

const sectionList = [
  {
    id: 1,
    plan: 'GESTOR SERVICE',
    title: 'Gestão completa para escritório de advocacia',
    text: "Para ter sucesso não basta apenas ter bons profissionais, também é preciso uma ótima gestão para alcançar os maiores resultados. Nosso sistema permite gerenciar a agenda do seu escritório, os processos, a equipe, a produção operacional assim como o setor financeiro.",
    image: 'section-1.png',
    link: '/gestor-service',
    reverse: false,
  },
  {
    id: 2,
    plan: 'BUSCADOR SERVICE',
    title: 'Faça consulta de dados por CPF e CNPJ',
    text: "Precisa de alguma informação sobre um CPF ou CNPJ e não consegue achar em nenhum lugar? No Buscador Service você vai encontrar! Busca de endereço, patrimônio, sócios de empresas, grupo societários e partes relacionadas. Traga agilidade ao seu trabalho e nunca mais atrase um processo por falta de informações.",
    image: 'section-2.png',
    link: '/buscador-service',
    reverse: true,
  },
  {
    id: 3,
    plan: 'TRABALHISTA SERVICE',
    title: 'Acesse teses, petições, contratos e documentos de rotina',
    text: "Gaste menos tempo com a produção de petições e teses, e tenha mais tempo para atender e conquistar o seu cliente, através do nosso sistema de documentos jurídicos. No Trabalhista Service você encontra petições, teses, jurisprudências, súmulas e OJs ideais para o seu caso, podendo adaptar facilmente para os seus casos.",
    image: 'section-3.png',
    link: '/trabalhista-service',
    reverse: false,
  },
  {
    id: 4,
    plan: 'PERGUNTE AO ESPECIALISTA',
    title: 'Tire suas dúvidas jurídicas com profissionais experientes',
    text: "Nunca mais gaste horas procurando resposta para suas dúvidas jurídicas na área cível, previdenciária e trabalhista. Pergunte aos nossos especialista e receba respostas claras e embasadas de profissionais experientes.",
    image: 'section-4.png',
    link: '/pergunte-ao-especialista',
    reverse: true,
  },
  {
    id: 5,
    plan: 'MENTORIA SERVICE',
    title: 'Receba orientações de mentores qualificados',
    text: "Seja um recém-formado ou um profissional experiente, a Mentoria Service está aqui para impulsionar seu crescimento. Receba orientações individualizadas para levar sua carreira ao próximo patamar, assim como para interpretar os seus casos jurídicos, lhe gerando confiança e tranquilidade na condução dos suas demandas.",
    image: 'section-5.png',
    link: '/mentoria-service',
    reverse: false,
  },
  {
    id: 6,
    plan: 'ASSINADOR SERVICE',
    title: 'Assine contratos de forma eletrônica.',
    text: "Envie, assine e gerencie todos os seus contratos de maneira rápida, segura e centralizada. Aumente sua eficiência e reduza a papelada com nossa solução de assinatura eletrônica, tornando seus processos mais ágeis e profissionais. Experimente a facilidade de ter tudo sob controle em um só lugar.",
    image: 'section-6.png',
    link: '/assinador-service',
    reverse: true,
  },
];

const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("section", { class: "w-full bg-[#253170] overflow-visible lg:max-h-[581px] rounded-b-[60px] flex flex-col items-center justify-center gap-10 mb-48" }, [
          _createElementVNode("h1", { class: "font-Open text-4xl text-center font-bold text-white leading-10 max-w-4xl pt-20 lg:pt-80" }, " A ferramenta definitiva para seu escritório de advocacia "),
          _createElementVNode("div", null, [
            _createElementVNode("h1", { class: "font-Open text-xl text-center text-[#6A7ACD] font-bold mb-4" }, " PARCERIAS QUE CRIAM IMPACTO "),
            _createElementVNode("div", { class: "m-auto flex items-center justify-center gap-10" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "ADVSERVICE",
                style: {"opacity":"0.5"}
              })
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "ADVSERVICE"
            })
          ])
        ], -1)),
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "font-bold text-adv-blue-800 tracking-wider text-4xl" }, " Revolucionando a Advocacia 4.0 ", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-normal text-adv-gray-500 text-base my-8 max-w-[39rem] text-justify" }, " Nossa missão é trazer mais eficiência ao trabalho de advogados e escritórios jurídicos por meio da tecnologia, otimizando soluções tradicionais e inovando no que o mercado peca. ", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: goToSign,
                  class: _normalizeClass([_unref(lightButtonStyle), "lg:whitespace-nowrap"])
                }, " TESTE POR 7 DIAS ", 2),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (routeRoll('/adv-service'))),
                  class: "txt-btn text-base border lg:whitespace-nowrap border-adv-blue-100 text-adv-blue-100 bg-transparent py-3 px-7 tracking-[0.21em] disabled:bg-adv-blue-100/25"
                }, " SAIBA MAIS ")
              ])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mt-4 lg:mt-0 flex justify-center w-[75%] lg:w-full" }, [
              _createElementVNode("img", {
                src: _imports_2,
                alt: "Celular com tela de aplicativo"
              })
            ], -1))
          ])
        ])
      ]),
      _createVNode(Solutions, { title: "Soluções" }),
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(sectionList, (section) => {
        return _createElementVNode("div", {
          key: section.id,
          class: "bg-[#F4F7FF]"
        }, [
          _createVNode(DefaultSection, { section: section }, null, 8, ["section"])
        ])
      }), 64)),
      _createElementVNode("section", _hoisted_6, [
        _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "font-Open text-4xl text-center font-bold text-white leading-10 max-w-4xl px-4 pt-10 lg:pt-32" }, " A ferramenta mais completa que seu escritório de advocacia pode ter ", -1)),
        _createElementVNode("button", {
          type: "button",
          onClick: goToSign,
          class: _normalizeClass([_unref(lightButtonStyle), "lg:whitespace-nowrap"])
        }, " TESTE POR 7 DIAS GRÁTIS ", 2),
        _cache[6] || (_cache[6] = _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _imports_3,
            alt: "ADVSERVICE"
          })
        ], -1))
      ]),
      _createVNode(PlansCards),
      _createVNode(Depoimentos, { title: "Depoimentos" }),
      _createVNode(Faq, {
        title: "Perguntas Frequentes",
        questions: _unref(faqStore).questions,
        class: "mt-5 lg:mt-0"
      }, null, 8, ["questions"])
    ]),
    _: 1
  }))
}
}

})