<script setup lang="ts">
import { InstaIcon, FacebookIcon, LinkedinIcon } from '@/components/icons/index';

interface FounderProps {
  id: string;
  avatarUrl: string;
  name: string;
  cvList: string[];
  instaLink?: string;
  faceLink?: string;
  linkedinLink?: string;
  reverse?: boolean;
}

const handleRedirect = (url: string) => {
  window.open(url);
};

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<FounderProps>();
</script>

<template>
  <div
    :class="reverse ? 'md:flex-row-reverse' : 'md:flex-row'"
    class="flex flex-col gap-14 justify-center items-center"
  >
    <div class="bg-white w-fit h-fit">
      <img v-if="id === 'fou#01'" class="max-h-96 lg:h-80 w-auto -translate-x-2 -translate-y-2" src="../../../src/assets/img/cleize-foto.jpg" alt="founder picture" />
      <img v-else-if="id === 'fou#02'" class="max-h-96 lg:h-80 w-auto -translate-x-2 -translate-y-2" src="../../../src/assets/img/founder-foto.jpg" alt="founder picture" />
      <img v-else class="max-h-96 lg:h-80 w-auto -translate-x-2 -translate-y-2" src="../../../src/assets/img/amauri-foto.jpg" alt="founder picture" />
    </div>
    <div class="flex flex-col gap-4">
      <h3 class="font-bold text-4xl text-adv-blue-100">{{ name }}</h3>
      <ul class="list-disc">
        <li v-for="item in cvList" :key="item" class="text-adv-gray-500 text-xl">{{ item }}</li>
      </ul>
      <nav class="flex gap-4">
        <button v-if="instaLink" class="max-h-8 w-auto" @click.prevent="handleRedirect(instaLink)">
          <InstaIcon color="#4375D9" />
        </button>
        <button v-if="faceLink" class="max-h-8 w-auto" @click.prevent="handleRedirect(faceLink)">
          <FacebookIcon color="#4375D9" />
        </button>
        <button v-if="linkedinLink" class="max-h-8 w-auto" @click.prevent="handleRedirect(linkedinLink)">
          <LinkedinIcon color="#4375D9" />
        </button>
      </nav>
    </div>
  </div>
</template>
