import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {
      background: 'radial-gradient(108.02% 270.63% at -6.77% 127.48%, #FFFFFF 15.57%, rgba(226, 226, 226, 0.35) 100%)',
    },
  class: "h-max w-full flex justify-center flex-col items-center p-10 lg:p-28 overflow-hidden"
}
const _hoisted_2 = { class: "font-bold text-4xl text-adv-blue-800 mb-14 max-w-lg text-center tracking-wider" }

import { useDepoStore } from '@/store/depoimentosStore';
import DepoimentosCarousel from './DepoimentosCarousel.vue';
import { onMounted } from 'vue';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { lightButtonStyle } from '@/assets/css/styles';
import { useRouter } from 'vue-router';

interface DepoimentosProps {
  title: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Depoimentos',
  props: {
    title: {}
  },
  setup(__props: any) {

const router = useRouter();

const depoStore = useDepoStore();

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;
/* <div class="flex flex-col sm:flex-row items-center justify-center gap-9 lg:justify-around w-full">
      
    </div> */

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};
onMounted(() => {
  depoStore.getTestimonials();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (!_unref(depoStore).loading)
      ? (_openBlock(), _createBlock(DepoimentosCarousel, {
          key: 0,
          depoimentos: _unref(depoStore).depoimentos
        }, null, 8, ["depoimentos"]))
      : _createCommentVNode("", true),
    (_unref(depoStore).loading)
      ? (_openBlock(), _createBlock(LoadingSpinner, {
          key: 1,
          color: "#1E2A66"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      type: "button",
      onClick: goToSign,
      class: _normalizeClass([_unref(lightButtonStyle), "lg:whitespace-nowrap mt-6"])
    }, " TESTE GRÁTIS POR 7 DIAS ", 2)
  ]))
}
}

})