import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/img/avatar.png'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  src: _imports_0,
  alt: "avatar",
  class: "h-44 w-44 rounded-full object-cover shadow-2xl my-8 p-4"
}
const _hoisted_3 = { class: "font-bold text-xl text-adv-blue-800 tracking-wider" }
const _hoisted_4 = { class: "font-bold text-xl text-adv-blue-800 tracking-wider" }
const _hoisted_5 = ["innerHTML"]

import { DepoimentoType } from '@/types/cardTypes';

const contentBox =
  'flex flex-col justify-start items-center max-w-[480px] px-11 pb-12 bg-transparent rounded-3xl h-full';
const textPClass = 'text-black text-center text-base font-light italic mt-8 post-content';

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'DepoimentoItem',
  props: {
    id: {},
    activeCarousel: { type: Boolean },
    author: {},
    companyOrOccupation: {},
    thumbnailPhoto: {},
    text: {},
    createdAt: {},
    updatedAt: {},
    deletedAt: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(contentBox)
  }, [
    (props.thumbnailPhoto)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: props.thumbnailPhoto,
          alt: "avatar",
          class: "h-44 w-44 rounded-full object-cover shadow-2xl my-8"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.author), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.companyOrOccupation), 1),
    _createElementVNode("div", {
      class: _normalizeClass(textPClass),
      innerHTML: _ctx.text
    }, null, 8, _hoisted_5)
  ]))
}
}

})