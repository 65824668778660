<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import Header from '@/components/Header/Head.vue';
import { lightButtonStyle } from '@/assets/css/styles';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const router = useRouter();

const login = reactive({
  email: '',
  password: '',
});

const signIn = () => {
  userStore.loginAction(login);
};

onMounted(() => {
  userStore.checkUserAction();
});
</script>

<template>
  <div class="w-full h-screen flex flex-col gap-8 sm:gap-40 bg-[url('@/assets/img/bg-login.svg')] bg-cover">
    <Header />
    <main class="w-full flex justify-center items-center gap-20">
      <img src="@/assets/img/adv-login-logo.svg" alt="login logo" class="h-28 w-auto hidden z-10 lg:block" />
      <form v-show="!userStore.user.username" class="bg-white rounded-3xl py-16 px-8 sm:p-16 flex flex-col gap-6">
        <h2 class="font-bold text-adv-blue-800 text-4xl tracking-wider">Entrar</h2>
        <input
          type="email"
          v-model="login.email"
          placeholder="E-mail"
          class="border border-adv-gray-400 py-5 px-9 rounded-[2.5rem] placeholder:font-bold placeholder:text-lg placeholder:tracking-wider w-80 sm:w-[27rem]"
        />
        <input
          type="password"
          v-model="login.password"
          placeholder="Senha"
          class="border border-adv-gray-400 py-5 px-9 rounded-[2.5rem] placeholder:font-bold placeholder:text-lg placeholder:tracking-wider w-80 sm:w-[27rem]"
        />
        <button
          type="button"
          :disabled="userStore.loading"
          :class="lightButtonStyle"
          @click="signIn"
          class="rounded-[2.5rem] flex justify-center items-center"
        >
          <LoadingSpinner v-show="userStore.loading" />
          <p v-show="!userStore.loading">LOGIN</p>
        </button>
        <hr class="border-t border-adv-blue-800 mt-7" />
        <nav class="flex items-center justify-between">
          <button
            type="button"
            class="font-bold text-adv-blue-800 tracking-wider"
            @click="router.push('/login/recuperar')"
          >
            Esqueci a senha
          </button>
          <button
            type="button"
            class="font-bold text-adv-blue-800 tracking-wider"
            @click="router.push('/login/cadastro')"
          >
            Cadastre-se aqui
          </button>
        </nav>
      </form>
      <div v-show="userStore.user.username" class="bg-white rounded-3xl py-16 px-8 sm:p-16 flex flex-col gap-6">
        <h3 class="font-bold text-adv-blue-800 tracking-wider">Bem vindo, {{ userStore.user.username }}</h3>
        <nav class="flex items-center flex-col gap-4 justify-between">
          <button
            type="button"
            :disabled="userStore.loading"
            :class="lightButtonStyle"
            @click="userStore.goToHub"
            class="rounded-[2.5rem] flex justify-center items-center"
          >
            <LoadingSpinner v-show="userStore.loading" />
            <p v-show="!userStore.loading">Entrar no ADV Service</p>
          </button>
          <button type="button" class="font-bold text-adv-blue-100 tracking-wider" @click="userStore.logoutAction">
            Sair
          </button>
        </nav>
      </div>
    </main>
  </div>
</template>
