import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/adv-logo-blue.svg'


const _hoisted_1 = {
  style: {
        background: 'radial-gradient(70.4% 239.58% at 9.86% 89.57%, #FFFFFF 15.57%, rgba(181, 181, 181, 0.35) 100%)',
      },
  class: "h-max w-full flex gap-12 justify-center flex-col lg:flex-row-reverse items-center p-8 lg:p-28 md:p-16"
}
const _hoisted_2 = { class: "max-w-md flex flex-col justify-around items-center lg:items-start" }
const _hoisted_3 = { class: "bg-[url('@/assets/img/bg-conheca-banner.svg')] w-full min-h-[33rem] bg-cover flex items-center justify-center p-16" }

import Layout from '@/components/BaseLayout/Layout.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Title from '@/components/Title/Title.vue';
import Founders from '@/components/Founders/Founders.vue';
import { useRouter } from 'vue-router';
import { lightButtonStyle } from '@/assets/css/styles';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const router = useRouter();
function goTo(el: string): void {
  document.getElementById(el)?.scrollIntoView({ behavior: 'smooth' });
}
function goPlans(): void {
  window.scrollTo(0, 0);
  router.push('/planos');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(Title, {
        height: 28,
        imgUrl: "url('@/assets/img/bg-serv-title.svg')"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h1", { class: "text-white font-Open font-bold text-6xl z-10" }, "Quem Somos", -1)
        ])),
        _: 1
      }),
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "font-bold text-adv-blue-800 text-justify lg:text-left tracking-wider text-4xl" }, "ADV Service", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-normal text-adv-gray-500 text-base my-8" }, [
            _createTextVNode(" Nossa história começou com o Trabalhista Service, partindo da iniciativa de 3 advogados acostumados com o ambiente digital. "),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" Eles perceberam que o mercado carecia de algumas soluções especializadas com um grande potencial para tornar o trabalho de profissionais do direito mais eficiente."),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" Com o tempo e a alta adesão de advogados trabalhistas à plataforma, nós percebemos que era hora de crescer."),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" Hoje, somos a ADV Service, uma plataforma completa com soluções tecnológicas para a área do Direito. Idealizada por advogados, para advogados."),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" Contamos com diversas ferramentas que auxiliam advogados a alcançar o máximo de seu potencial com mais agilidade. ")
          ], -1)),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('solutionEl'))),
            class: _normalizeClass([_unref(lightButtonStyle), "w-fit"])
          }, "SOLUÇÕES", 2)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("img", {
          src: _imports_0,
          alt: "ADVSERVICE",
          class: "-translate-x-4 -translate-y-4 max-h-96 w-auto"
        }, null, -1))
      ]),
      _createVNode(Founders),
      _cache[5] || (_cache[5] = _createElementVNode("section", { class: "bg-[url('@/assets/img/bg-mvv-banner.svg')] p-12 min-h-[39rem] w-full bg-cover flex flex-col lg:flex-row items-center justify-center gap-14" }, [
        _createElementVNode("div", { class: "bg-adv-blue-900 w-72 h-[22rem] gap-4 items-center flex flex-col py-12 px-10" }, [
          _createElementVNode("h3", { class: "font-bold text-white text-2xl" }, "MISSÃO"),
          _createElementVNode("hr", { class: "border-adv-blue-100 w-full mb-5" }),
          _createElementVNode("p", { class: "text-base text-white text-center" }, " Alavancar resultados de advogados e escritórios, facilitando tarefas do dia a dia. ")
        ]),
        _createElementVNode("div", { class: "bg-adv-blue-900 w-72 h-[22rem] gap-4 items-center flex flex-col py-12 px-10" }, [
          _createElementVNode("h3", { class: "font-bold text-white text-2xl" }, "VISÃO"),
          _createElementVNode("hr", { class: "border-adv-blue-100 w-full mb-5" }),
          _createElementVNode("p", { class: "text-base text-white text-center" }, " Ser uma empresa líder em inovação na Advocacia 4.0, otimizando serviços jurídicos e sendo a maior aliada dos advogados. ")
        ]),
        _createElementVNode("div", { class: "bg-adv-blue-900 w-72 h-[22rem] gap-4 items-center flex flex-col py-12 px-10" }, [
          _createElementVNode("h3", { class: "font-bold text-white text-2xl" }, "VALORES"),
          _createElementVNode("hr", { class: "border-adv-blue-100 w-full mb-5" }),
          _createElementVNode("p", { class: "text-base text-white text-center" }, "Inovação, Excelência, Ética, Praticidade e Transparência.")
        ])
      ], -1)),
      _createVNode(Solutions, {
        id: "solutionEl",
        title: "Soluções"
      }),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("button", {
          onClick: goPlans,
          class: _normalizeClass([_unref(lightButtonStyle), "max-w-[30rem] w-full"])
        }, " DESCUBRA O PLANO IDEAL PARA VOCÊ ", 2)
      ])
    ]),
    _: 1
  }))
}
}

})