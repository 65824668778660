import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lg:w-[380px] lg:flex-col" }
const _hoisted_2 = { class: "my-10 font-bold text-white text-3xl lg:my-10" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "bg-gray-700/25 w-[100%] my-5 lg:w-[36rem] md:w-[36rem]" }

import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';
import { useRouter } from 'vue-router';

interface AboutProps {
  title: string;
  text: string;
  videoUrl: string;
  thumbnailUrl: string;
  service: 'trabalhista' | 'gestor' | 'buscador' | 'pergunte' | 'mentoria' | 'pdf' | 'assinador' | 'contratualista';
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
enum BG_COLOR {
  trabalhista = 'radial-gradient(55.79% 86.9% at 44.51% 32.48%, #FFAA51 0%, #FF9423 100%)',
  gestor = 'radial-gradient(55.79% 86.9% at 44.51% 32.48%, #33B88C 0%, #4FE3B2 100%)',
  buscador = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #F54327 0%, #FF5A40 100%)',
  pergunte = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #328CB5 0%, #3FA2CF 100%)',
  mentoria = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #8D5BC2 0%, #7A44B2 100%)',
  pdf = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #ED2853 0%, #FC3C66 100%)',
  assinador = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #ED9230 0%, #FFAF58 100%)',
  contratualista = 'radial-gradient(103.27% 67.81% at 44.51% 32.48%, #FFC382 0.01%, #FFC07C 100%)',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'About',
  props: {
    title: { default: 'Praticidade na sua rotina trabalhista.' },
    text: { default: 'Gaste menos tempo elaborando petições e foque na conquista e atendimento ao cliente. Monte sua petição trabalhista personalizada através do nosso completo banco de teses e jurisprudências.' },
    videoUrl: { default: 'https://v.ftcdn.net/05/20/81/52/700_F_520815266_bYTb1l7tG8M4loZJ0kz2yEQrrsHGP3Hf_ST.mp4' },
    thumbnailUrl: { default: '' },
    service: { default: 'trabalhista' }
  },
  setup(__props: any) {

const router = useRouter();

const props = __props;

const videoOptions = {
  autoplay: false,
  controls: true,
  poster: props.thumbnailUrl,
  sources: [
    {
      src: props.videoUrl,
      type: 'video/mp4',
    },
  ],
};

function goPlans(): void {
  window.scrollTo(0, 0);
  router.push('/planos');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    style: _normalizeStyle({
      background: BG_COLOR[_ctx.service],
    }),
    class: "flex px-10 flex-col items-center justify-center gap-8 lg:flex-row lg:px-28 lg:items-start py-16"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", {
        class: "text-base text-[#363636] my-10 lg:my-7",
        innerHTML: _ctx.text
      }, null, 8, _hoisted_3),
      _createElementVNode("button", {
        class: "bg-white py-3 px-6 text-base tracking-widest",
        onClick: _withModifiers(goPlans, ["stop"])
      }, "VER PLANOS")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(VideoPlayer, {
        class: "-translate-x-0 -translate-y-0",
        options: videoOptions
      })
    ])
  ], 4))
}
}

})