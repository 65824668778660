<script setup lang="ts">
import { onMounted } from 'vue';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { useSignUpStore } from '@/store/signUpStore';
import { useModalStore } from '@/store/modalStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import TermsModal from '@/components/Terms/terms.vue';
import PrivacyModal from '@/components/Terms/privacy.vue';

const store = useSignUpStore();
const modalStore = useModalStore();

const validationSchema = toTypedSchema(
    zod
        .object({
            name: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório'),
            email: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório')
                .email('Digite um email válido'),
            phone: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório'),
            // confirmEmail: zod
            //     .string({
            //         required_error: 'Campo obrigatório',
            //     })
            //     .nonempty('Campo obrigatório')
            //     .email('Digite um email válido'),
            // password: zod
            //     .string({
            //         required_error: 'Campo obrigatório',
            //     })
            //     .nonempty('Campo obrigatório')
            //     .min(8, 'Sua senha deve conter, ao menos, 8 caracteres'),
            // passwordCheck: zod.string(),
            acceptTerms: zod.boolean(),
            cpf: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório'),
        })
    // .refine((data) => data.password === data.passwordCheck, {
    //     message: 'As senhas devem ser iguais',
    //     path: ['passwordCheck'],
    // })
    // .refine((data) => data.email === data.confirmEmail, {
    //     message: 'Os emails devem ser iguais',
    //     path: ['confirmEmail'],
    // })
);
const { handleSubmit, errors } = useForm({
    validationSchema,
});

const { value: name } = useField('name');
const { value: email } = useField('email');
const { value: phone } = useField('phone');
// const { value: confirmEmail } = useField('confirmEmail');
// const { value: password } = useField('password');
// const { value: passwordCheck } = useField('passwordCheck');
const { value: cpf } = useField('cpf');
const { value: acceptTerms } = useField('acceptTerms');

const handleSignup = handleSubmit(async (values) => {
    if (store.loading) return;

    store.loading = true;

    try {
        await store.submitUserSignUp(values);
    } catch (err) {
        console.error(err);
    } finally {
        store.loading = false;
    }
});

onMounted(() => {
    store.getOcupations();
});
</script>

<template>
    <h2 class="text-[#A8A8A8] text-2xl font-normal tracking-widest">
        <strong class="text-adv-blue-800 font-bold">Sobre você</strong> — Finalizar cadastro
    </h2>
    <form @submit.prevent="handleSignup" class="w-full gap-8 flex flex-col">
        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    Nome Completo <strong class="text-red-600">*</strong>
                </p>
                <input type="text" v-model="name"
                    :class="formInput + `${errors.name ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.name }}</span>
            </div>
            <!-- <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    Profissão <strong class="text-red-600">*</strong>
                </p>
                <select v-model="store.signUpForm.prof"
                    class="border border-solid border-dark-grey rounded-lg h-10 max-w-xs w-full p-2">
                    <option v-for="ocup in store.ocupations" :key="ocup.id" :value="ocup.id">{{ ocup.name }}</option>
                </select>
            </div> -->
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">CPF <strong
                        class="text-red-600">*</strong></p>
                <input v-maska data-maska="###.###.###-##" type="text" v-model="cpf"
                    :class="formInput + `${errors.cpf ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.cpf }}</span>
            </div>

        </div>

        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">OAB</p>
                <input type="text" v-model="store.signUpForm.oab" :class="formInput" />
            </div>
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    Telefone <strong class="text-red-600">*</strong>
                </p>
                <input v-maska data-maska="['(##) ####-####', '(##) #####-####']" type="text" v-model="phone"
                    :class="formInput + `${errors.phone ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.phone }}</span>
            </div>
        </div>

        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    E-mail <strong class="text-red-600">*</strong>
                </p>
                <input name="email" type="email" v-model="email"
                    :class="formInput + `${errors.email ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.email }}</span>
            </div>
            <!-- <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    Confirmar e-mail <strong class="text-red-600">*</strong>
                </p>
                <input type="email" v-model="confirmEmail"
                    :class="formInput + `${errors.confirmEmail ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.confirmEmail }}</span>
            </div> -->

        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">

            <!-- <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    Senha <strong class="text-red-600">*</strong>
                </p>
                <input type="password" v-model="password"
                    :class="formInput + `${errors.password ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.password }}</span>
            </div>
            <div class="w-full">
                <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">
                    Confirmar senha <strong class="text-red-600">*</strong>
                </p>
                <input type="password" v-model="passwordCheck"
                    :class="formInput + `${errors.passwordCheck ? 'border-2 border-rose-500' : ''}`" />
                <span class="mt-1 text-xs text-rose-500">{{ errors.passwordCheck }}</span>
            </div> -->

        </div>
        <div class="flex gap-5 w-full flex-col md:flex-row">
            <div class="w-full flex justify-start items-center gap-2">
                <input v-model="acceptTerms" type="checkbox" id="terms" name="terms" />
                <label class="text-[#A8A8A8] font-bold tracking-widest text-sm" for="terms">Eu aceito e concordo com os
                    <a class="text-gray-700 cursor-pointer" @click.prevent="modalStore.termsModalOpen = true">termos de
                        uso</a> e
                    a
                    <a class="text-gray-700 cursor-pointer" @click.prevent="modalStore.privacyModalOpen = true">política
                        de privacidade</a></label>
            </div>
        </div>
        <button type="submit" :class="lightButtonStyle" class="w-full flex justify-center items-center"
            :disabled="!acceptTerms">
            <p v-show="!store.loading">AVANÇAR</p>
            <loading-spinner v-show="store.loading" />
        </button>
        <TermsModal />
        <PrivacyModal />
    </form>
</template>
