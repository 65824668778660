import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center lg:items-start justify-center lg:justify-start h-full w-96" }
const _hoisted_2 = { class: "flex flex-col gap-3" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { name: "inscrever-blog-form" }

import { onMounted, ref } from 'vue';
import { lightButtonStyle } from '@/assets/css/styles';
import { useBlogStore } from '@/store/blogStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'BlogAside',
  setup(__props) {

const blogStore = useBlogStore();

const active = ref<number>();

const handleCat = async (catId: number | undefined) => {
  blogStore.catId = catId;
  if (catId === undefined) {
    active.value = undefined;
    await blogStore.getPostList();
  } else {
    active.value = catId;
    await blogStore.getPostList();
  }
};

const isActive = (catId: number) => {
  return active.value === catId ? 'text-adv-blue-800 font-semibold' : 'text-adv-gray-500 ';
};

onMounted(async () => {
  await blogStore.getCategorieList();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-bold text-2xl text-adv-blue-800" }, "Categorias", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "w-20 border-2 border-adv-blue-100 my-3" }, null, -1)),
    _createElementVNode("nav", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blogStore).categories, (cat) => {
        return (_openBlock(), _createElementBlock("a", {
          key: cat.id,
          class: _normalizeClass(["text-xl cursor-pointer", isActive(cat.id)]),
          onClick: _withModifiers(($event: any) => (handleCat(cat.id)), ["prevent"])
        }, _toDisplayString(cat.title), 11, _hoisted_3))
      }), 128)),
      (active.value !== undefined)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "text-adv-blue-100 text-xl cursor-pointer",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleCat(undefined)), ["prevent"]))
          }, "Limpar Filtros"))
        : _createCommentVNode("", true)
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "w-full border border-t-0 border-adv-blue-100 my-9" }, null, -1)),
    _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "font-bold text-2xl text-adv-blue-800" }, [
      _createTextVNode(" Inscreva-se em "),
      _createElementVNode("br"),
      _createTextVNode(" nosso blog. ")
    ], -1)),
    _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "w-20 border-2 border-adv-blue-100 my-3" }, null, -1)),
    _createElementVNode("form", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("input", {
        type: "text",
        placeholder: "NOME:",
        class: "h-12 mb-3 mt-6 font-FuturaBkBt p-2 pl-6 w-full border border-adv-gray-350 bg-adv-gray-300 focus:border-0 placeholder:font-FuturaBkBt placeholder:font-base placeholder:text-black"
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("input", {
        type: "email",
        placeholder: "E-MAIL:",
        class: "h-12 mb-6 font-FuturaBkBt p-2 pl-6 w-full border border-adv-gray-350 bg-adv-gray-300 focus:border-0 placeholder:font-FuturaBkBt placeholder:font-base placeholder:text-black"
      }, null, -1)),
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass(_unref(lightButtonStyle))
      }, "INSCREVER", 2)
    ])
  ]))
}
}

})