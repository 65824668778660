import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import PlansCards from '@/components/Plans/PlansCards.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Faq from '@/components/Faq/Faq.vue';
import { useFaqStore } from '@/store/faqStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const faqStore = useFaqStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(Title, {
        height: 28,
        imgUrl: "url('@/assets/img/bg-planos-title.svg')"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("h1", { class: "font-Open text-6xl font-bold text-white z-10" }, [
            _createTextVNode(" Tudo o que você precisa "),
            _createElementVNode("br"),
            _createTextVNode(" em um único lugar. ")
          ], -1)
        ])),
        _: 1
      }),
      _createVNode(PlansCards),
      _createVNode(Solutions, { title: "Clique para conhecer melhor nossas soluções" }),
      _createVNode(Faq, {
        title: "Perguntas Frequentes",
        questions: _unref(faqStore).questions
      }, null, 8, ["questions"])
    ]),
    _: 1
  }))
}
}

})