import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Modal from '@/components/Modal/index.vue';
import { useModalStore } from '@/store/modalStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'terms',
  setup(__props) {

const store = useModalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    "modal-title": "Termos de Uso",
    open: _unref(store).termsModalOpen,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).termsModalOpen = false))
  }, {
    "modal-content": _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "flex flex-col justify-center items-center gap-12 w-full mt-12" }, [
        _createElementVNode("div", { class: "w-full" }, [
          _createElementVNode("div", { class: "border md:w-full max-h-[80vh] lg:max-h-[300px] overflow-auto" }, [
            _createElementVNode("div", { class: "p-0 md:p-6" }, [
              _createElementVNode("p", null, [
                _createElementVNode("strong", null, "TERMOS E CONDIÇÕES DE USO DO SAAS DO ADV SERVICE")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Introdução")
                ])
              ]),
              _createElementVNode("p", null, " 1.1. Estes são os Termos de Uso (“Termos de Uso”) que regem as finalidades os uso e os limites de todo e qualquer acesso e utilização da Plataforma Digital denominada Adv Service (a “Plataforma”), por qualquer pessoa que utilize suas funcionalidades na qualidade de usuário, doravante denominado simplesmente “Usuário(s) )”. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 1.2. O site e todo o seu conteúdo (o “Site”) e a Plataforma Digital foram desenvolvidos e são operados pela TRABALHISTA SERVICE LTDA., pessoa jurídica de direito privado, sociedade empresaria de responsabilidade limitada, inscrita no CNPJ sob nº 41.867.655/0001-49, com sede na Rua João Zwetsch, n. 80, apto 702, bairro Nossa Senhora das Dores, no Município de Santa Maria/RS, como permite o art. 1.142, § 2º do Código Cívil (“CC”). "),
              _createElementVNode("br"),
              _createElementVNode("p", null, "  1.3. É imprescindível que o Usuário siga nossos Termos de Uso e conheça nossa Política de Privacidade para navegar na plataforma digital Adv Service e utilizar as ferramentas disponíveis, uma vez que determinadas atividades implicam no tratamento dos dados pessoais do Usuário. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 1.4. A Política de Privacidade também integra estes Termos de Uso e deverá ser lida com atenção e consentida pelo Usuário como requisito para a utilização da plataforma e dos serviços da Adv Service. Estes termos se aplicam a todas as marcas, produtos e serviços da Adv Service, os quais não têm políticas de privacidade separadas e estão vinculados a este termo e a política de privacidade. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 1.5.      Ao cadastrar-se, o Usuário concorda com a íntegra dos Termos de Uso aqui dispostos. Caso o respectivo interessado não concorde com os termos estabelecidos não deverá realizar o cadastro e utilizar a plataforma. Além disso, o Usuário não poderá se escusar dos termos, alegando ignorância sobre as suas condições, e que ao cadastrar-se não leu a integra, inclusive quanto a eventuais modificações introduzidas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 1.6.      A Adv Service não estimula ou realiza qualquer forma de captação de causa ou clientela para serviços advocatícios, nem afronta qualquer dispositivo da Lei Federal nº. 8.906/94 (Estatuto da Advocacia) e do Código de Ética e Disciplina da OAB. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "2" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Cadastro")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.1. "),
                _createElementVNode("em", null, "Cadastro. "),
                _createTextVNode("O acesso e a utilização de quaisquer serviços da Adv Service dependerão da realização de cadastro, adesão ao presente Termo de Uso, contratação de um dos planos ofertados e a realização do respectivo pagamento pelo uso das ferramentas disponibilizadas. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.1.1. "),
                _createElementVNode("em", null, "Cadastro Pessoal"),
                _createTextVNode(". O cadastro pessoal será o perfil na Plataforma que identificará cada Usuário. Para a realização do cadastro pessoal na Plataforma, o Usuário deverá indicar o nome de usuário e senha, bem como fornecer informações pessoais, como nome, sobrenome, e-mail, CPF, telefone, data de nascimento, sexo, endereço, a depender do caso, número de identidade profissional, dentre outros. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 2.1.1.1. No caso de o Usuário assinalar sua profissão como advogado, será necessário fornecer seu número válido de inscrição na Ordem dos Advogados do Brasil (“OAB”). "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.2. "),
                _createElementVNode("em", null, "Convênios. "),
                _createTextVNode("Havendo algum cadastro via convênio ou parceria da Adv Service com alguma entidade ou organização, será exigido no preenchimento do cadastro a apresentação do “cupom” ou “convênio”, para o fim de obtenção do eventual desconto ao Usuário. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 2.2.1.      Cupons de desconto e/ou convênios que a Adv Service possa vir a criar terão benefícios e critérios de cobranças próprios, não ficando adstrito as informações dos planos padrões de assinatura disponíveis na página inicial da Adv Service. Todas informações do cupom/convênio serão disponibilizadas no momento do cadastro para o usuário, que poderá escolher em assinar ou não o serviço. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.3. "),
                _createElementVNode("em", null, "Informações fornecidas"),
                _createTextVNode(". A Adv Service poderá se valer de todas as formas lícitas para verificar, a qualquer tempo, se as informações que o Usuário forneceu são verdadeiras. Se for constatado que as informações são de alguma forma incompletas, imprecisas ou falsas, o USUÁRIO poderá ter o seu cadastro suspenso ou cancelado, a exclusivo critério da Adv Service, sem prejuízos de outras medidas que sejam aplicáveis, caso em que não serão devidos quaisquer reembolsos. Ainda, se for constatado dois ou mais cadastros de mesmo Usuário tentando obter acesso contínuo e permanente ao período grátis, será de imediato autorizado a cobrança equivalente ao plano de menor valor em todos cadastros feitos pelo Usuário. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.5. "),
                _createElementVNode("em", null, "Limites aplicáveis ao Cadastro"),
                _createTextVNode(" . É vedada a criação de nome de usuário e senha com a utilização de nome e informações de outra pessoa, com a utilização de marcas registradas ou propriedade de outra parte (incluindo o Adv Service) ou vulgar, obsceno ou de qualquer outra forma inadequada. O Adv Service reserva-se o direito, com ou sem aviso prévio, de suspender ou encerrar qualquer contato que viole esta cláusula. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.6. "),
                _createElementVNode("em", null, "Vedação de compartilhamento de conta. "),
                _createTextVNode("O login e senha são pessoais e intransferíveis, comprometendo-se o Usuário a não os divulgar ou compartilhar com terceiros, sob pena de cancelamento da conta criada. O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.7. "),
                _createElementVNode("em", null, "Limitação ao uso simultâneo de conta. "),
                _createTextVNode("Somente será permitido o uso simultâneo da Plataforma em um dispositivo fixo (computadores e laptops) e um dispositivo móvel (celulares e tablets) vinculado ao cadastro do Usuário. Caso seja verificada tentativa de múltiplos acessos simultâneos com as credenciais do Usuário, será imediatamente encerrado o acesso anterior em dispositivo semelhante. Exceção apenas a existência de subcontas de usuários decorrentes da contratação do Gestor Service, conforme o número de contas previstas no respectivo plano contratado. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.8. "),
                _createElementVNode("em", null, "Informações de Acesso. "),
                _createTextVNode("A conta é pessoal e poderá ser acessada unicamente mediante a utilização de login e senha criada pelo próprio usuário no momento do cadastro, sendo o único e exclusivo responsável por manter o sigilo de seu login e senha, a fim de garantir a segurança de sua conta e impedir o acesso não autorizado por terceiros. O Usuário é o único responsável por todas as atividades associadas à sua conta. Em caso de perda, extravio ou suspeita de utilização indevida de sua conta, login ou senha, o Adv Service deverá ser comunicado imediatamente para que sejam tomadas as medidas cabíveis. Caso seja verificada a duplicidade de contas, o Adv Service poderá inabilitar de forma definitiva todos os cadastros duplicados, independentemente de qualquer notificação prévia. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 2.9. "),
                _createElementVNode("em", null, "Autorização para uso das informações. "),
                _createTextVNode("Os dados pessoais informados pelo Usuário, bem como os dados disponibilizados durante o uso dos serviços, serão tratados pela Adv Service estritamente em conformidade com a sua Política de Privacidade. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "3" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Das Responsabilidades do Usuário")
                ])
              ]),
              _createElementVNode("p", null, " 3.1. A plataforma digital Adv Service oferece funcionalidades que permitam o carregamento (upload) de materiais de texto, imagem e/ou outros dados próprios e de terceiros. Em razão disso, o Usuário aceita, reconhece e compromete-se a não carregar (upload) qualquer conteúdo ilícito, imoral ou ilegal ou material que viole a propriedade intelectual da Adv Service ou de terceiros, tampouco viole o direito ao sigilo de informações e dados de qualquer pessoa, seja física ou jurídica. Caso seja identificada qualquer violação a este dispositivo a conta do Usuário será imediatamente bloqueada, independente de aviso prévio. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2. Sem prejuízo de outras obrigações previstas neste instrumento, é de responsabilidade do usuário: "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.1. Fazer uso das funcionalidades e ferramentas da Plataforma em acordo com os termos de uso, respeitando todas e quaisquer regras do ordenamento jurídico nacional, não havendo possibilidade de negar ciência sobre sua responsabilidade legal por todas as suas condutas e ações executadas na plataforma, seja por si ou por terceiros, quando fornecido os dados de acesso de forma indevida. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.2. Operar a plataforma por meio de equipamentos de hardware necessários ao seu acesso e correta utilização, incluindo todas as demais ferramentas oferecidas pela Adv Service; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.3. Não aproveitar a plataforma como ferramenta, meio ou instrumento de quaisquer atos ilícitos, sejam determinados atos, proibidos pelo presente Termos de Uso ou por legislação especifica; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.4. Não utilizar a plataforma com finalidade para quaisquer atos ou procedimentos que possam causar dano a direitos de terceiros ou da própria Adv Service, incluindo ações que possam vir a prejudicar o correto funcionamento da plataforma ou de suas ferramentas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.5. Observar todas as disposições, regras e orientações que constam no presente Termo de Uso e cumpri-las, sem exceção; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.6. Jamais executar download de materiais, conteúdos e/ou arquivos, com exceção das possibilidades oferecidas ao Usuário pelo site. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.7. Possuir conduta que não permita acessos indevidos e vetados a plataforma. O compartilhamento de dados que possibilitem o acesso de terceiros a plataforma configura grave violação ao presente termo, sendo expressamente proibido. Caso seja verificada o compartilhamento indevido de tais dados, como login e acesso, a Adv Service irá executar o exercício regular de seu direito de proteção, impedindo o acesso de forma definitiva de conta(s) do(s) Usuário(s) envolvidos, sem a necessidade de qualquer notificação prévia. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.8. É terminantemente proibido ao Usuário ou a terceiros utilizar-se de forma comercial da assinatura da plataforma, ou ainda, de quaisquer propriedades intelectuais da Adv Service de forma que não autorizada neste instrumento. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.3. Ao efetivar o cadastro na plataforma digital Adv Service, o Usuário declara que possui ciência e concorda plenamente com todo o conteúdo deste termo, de forma que, futuramente, não poderão ser alegados quaisquer impedimentos legais para o cumprimento das obrigações assumidas neste documento. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.4. O Usuário ao fornecer as informações necessárias ao seu cadastro e/ou ao uso das ferramentas disponibilizadas pela plataforma Adv Service, declara que as informações por ele inseridas são verdadeiras, não prejudicam direito de terceiros, tais como direitos autorais, de imagem, privacidade e ainda não reproduzem conteúdo discriminatório de qualquer natureza. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.5. Constatada a violação dos Termos de Uso pelo Usuário, sem prejuízo de outras medidas, a Adv Service poderá advertir, suspender ou cancelar, temporária ou definitivamente, o cadastro de um Usuário. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.6. Ao utilizar-se de meios suspeitos, inconvenientes, ou em desacordo ao Termo de Uso Adv Service, para obter vantagem em serviços, além daqueles disponibilizados pelo plano escolhido, ou tentar obter pelos mesmos meios, de forma inconveniente, descontos em planos que não aqueles disponibilizados em ocasiões específicas ou por meio de convênios/parcerias, poderá a Adv Service exercer seu direito de proteção, impedindo o acesso de forma definitiva de conta(s) do(s) Usuário(s) envolvidos, sem a necessidade de qualquer notificação prévia. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.7. Ao utilizar a Plataforma Digital Adv Service, o Usuário declara possuir anuência e autorização de terceiros para incluir, durante a utilização da plataforma, dados pessoais dos mesmos, incluindo dados que possam ser classificados como sensíveis, de acordo com Lei nº 13.709/2018 – Lei Geral de Proteção de Dados. O Usuário ainda declara ciência e concordância em relação à Política de Privacidade da Adv Service ao incluir dados de terceiros na plataforma. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.8. A Adv Service não se responsabiliza por qualquer erro de utilização em suas ferramentas, ao passo que determinadas incorreções tenham sido causadas por erros dos seus Usuários que são integralmente responsáveis pelos dados e informações com as quais alimentam as ferramentas disponibilizadas. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "4" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Planos de assinatura e Cobranças")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.1. "),
                _createElementVNode("em", null, "Assinatura. "),
                _createTextVNode("A Assinatura (“Assinatura”) ou compra individual de qualquer serviço da Plataforma Digital Adv Service poderá ser feita por meio de cartão de crédito ou eventuais novas formas de pagamento disponibilizadas pela Plataforma (a \"Forma de Pagamento\"). ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.2. "),
                _createElementVNode("em", null, "Liberação do acesso. "),
                _createTextVNode("O acesso a Plataforma e suas funcionalidades, conforme modalidade de plano contratada, será liberado após a confirmação do pagamento da Assinatura, que pode demorar até 72 (setenta e duas) horas. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.3. "),
                _createElementVNode("em", null, "Período de Contratação. "),
                _createTextVNode("A Assinatura da Plataforma será feita por meio de sistema de pagamentos recorrentes, de modo que o acesso às funcionalidades pagas será mensal, semestral ou anual, de acordo com o Plano escolhido pelo Usuário (o “Período de Contratação”). Isto quer dizer que o Período de Contratação inicia-se no dia da Assinatura do Plano e é renovado mensalmente, semestralmente ou anualmente, conforme o Plano escolhido. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.4. "),
                _createElementVNode("em", null, "Planos. "),
                _createTextVNode("Os Planos disponibilizados pela Plataforma e seus respectivos preços estão disponíveis na página inicial do site da Adv Service no endereço na web "),
                _createElementVNode("a", { href: "https://advservice.com.br/" }, "https://advservice.com.br/"),
                _createTextVNode(", com planos mensais, semestrais e anuais, nas modalidades Starter, Master e Ultimate: ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("u", null, "STARTER:"),
                _createTextVNode(),
                _createElementVNode("u", null, "https://advservice.com.br/planos")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("u", null, " ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("u", null, "MASTER: https://advservice.com.br/planos")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createElementVNode("u", null, "ULTIMATE: https://advservice.com.br/planos")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.4.1. A alteração da assinatura de Plano Mensal para o Plano Semestral ou Anual implicará a cobrança, ao término do Período de Contratação vigente, do valor integral do Plano Semestral ou Anual, descrito no item 4.4 e a renovação do Período de Contratação para 6 (seis) ou 12 (doze) meses, respectivamente. Por sua vez, no caso de alteração de assinatura do Plano Semestral ou Anual para Plano Mensal, a alteração e cobrança do valor da Assinatura mensal terá efeito apenas ao fim do prazo do Período da Contratação vigente do plano semestral ou anual em vigor. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.5."),
                _createElementVNode("em", null, " Alterações dos preços da Assinatura."),
                _createTextVNode(" A Adv Service poderá alterar o preço dos planos de assinatura ou de outros serviços que, eventualmente, venham a integrar a plataforma. No entanto, nenhuma alteração será aplicada ao Usuário em prazo inferior a 30 (trinta) dias a contar da comunicação enviada via e-mail cadastrado. Caso o Usuário não concorde com a modificação poderá cancelar o seu acesso a plataforma, antes do fim do período de contratação vigente. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.6.      As consultas a serem realizadas na ferramenta de busca Buscador Service serão cobradas individualmente de forma unitária do usuário, em mesma oportunidade que este declara estar ciente do custo e da responsabilidade de uso das informações obtidas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.7.      A ferramenta Pergunte ao Especialista cobrará por cada pergunta adicional realizada, sempre após a ciência do custo e da expressa anuência por parte do Usuário, em mesma oportunidade que este declara estar ciente do custo e da responsabilidade de uso das informações obtidas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.8. "),
                _createElementVNode("strong", null, [
                  _createElementVNode("em", null, "Direito de Arrependimento (“Teste Gratuito”)")
                ]),
                _createTextVNode(". Para os novos Usuários da Plataforma que optarem pela contratação de um dos planos mensais, após o Cadastro, será disponibilizado o acesso gratuito e temporário às funcionalidades pagas, durante o período de 07 (sete) dias corridos (“Período de Teste Gratuito”). Durante o Período de Teste Gratuito, o Usuário terá acesso às funcionalidades disponíveis na Plataforma. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.8.1.      O período de testes grátis é de 07 (sete) dias corridos contados do cadastro na plataforma. As informações disponíveis no perfil do usuário trarão data de início e fim do período de teste grátis e dos planos mensais, semestrais e anuais. Os 07 (sete) dias são contados em horas (7 dias vezes 24horas). O Cadastro para fins de direito de arrependimento do Art. 49, do CDC, é considerado como Assinatura e/ou Contratação. O período de Avaliação Gratuita não altera o Período de Contratação.  "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.9. "),
                _createElementVNode("strong", null, [
                  _createElementVNode("em", null, "Renovação Automática.  ")
                ]),
                _createTextVNode("A contratação dos serviços será renovada automaticamente, independentemente de comunicação ao Usuário, por meio da mesma forma de pagamento indicada quando da contratação do serviço. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 4.9.1. "),
                _createElementVNode("strong", null, "A renovação automática do Plano não implica a renovação do direito do Usuário de resistência da assinatura prevista no art. 49 do Código de Defesa do Consumidor.")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.10.    Não perfectibilizando a cobrança no meio indicado pelo Usuário quando da contratação, o sistema repetirá a tentativa automaticamente no mesmo cartão, não importando a ausência de saldo em cancelamento automático do plano, tal como ocorre em outras plataformas de serviços, como ex.: sistemas de streaming. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.11.    Para cancelar a contratação dos serviços, o Usuário deve fazê-lo necessariamente por dentro da plataforma, mediante botão “cancelar” disponibilizado no perfil do Usuário na aba \"meu plano\". "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.11.1. O envio de e-mail ou solicitação de cancelamento por rede social e/ou canal de atendimento não isenta o usuário de eventual cobrança que venha a ser realizada. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.11.2. Se, no momento da renovação, a cobrança da renovação não for realizada, por qualquer motivo, a conta será bloqueada no prazo de 15 dias e o sistema alertará o Usuário Proprietário para efetuar a regularização. O bloqueio atingirá todos os usuários vinculados à conta bloqueada. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.12.    A disponibilidade de compra de consulta de forma unitária das ferramentas Buscador Service e Pergunte ao Especialista dependerá de disponibilização no sistema. Os valores cobrados em virtude do uso unitário das ferramentas Buscador Service e Pergunte ao Especialista, para além de possíveis cortesias e quantidades previstas em planos de assinatura, serão faturados de maneira unitária. Assim, a cada utilização das respectivas ferramentas, com prévia informação sobre o custo do serviço e anuência do Usuário, é gerado a cobrança no mesmo meio de pagamento que este utilizou quando do cadastro. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.13.    Na contratação do Software como Serviço, a Adv Service poderá solicitar informações financeiras do Usuário, como CPF e dados de cartão de crédito. Ao inserir referidos dados, o Usuário concorda que sejam cobrados de acordo com o plano escolhido, o respectivo preço vigente e informado quando da contratação. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.14. Os dados financeiros coletados poderão ser armazenados para fins de cobrança e contratações futuras. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "5" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Política de cancelamento")
                ])
              ]),
              _createElementVNode("p", null, " 5.1.      Cancelamento. O Usuário poderá cancelar a contratação do Software como Serviços, mediante acesso ao sistema da Adv Service, opção “meu perfil”, em seguida “meu plano”, e clicando no botão “cancelar plano”. Nenhuma solicitação por e-mail, por rede social ou outro meio será aceito. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.2.      O pagamento já realizado pelo Usuário será ressarcido, somente em cobranças eventualmente realizadas pelo sistema, por equívoco, durante o período de teste grátis, ou no caso de assinatura decorrente de parceria ou convênio (uso de cupom), no período dos 07 (sete) dias corridos previstos no CDC (direito de arrependimento). O Direito do arrependimento é de 7 dias corridos, contados em formatos de 24 horas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.3.      Eventual estorno por qualquer motivo será efetuado em até 02 (dois) dias úteis, contados confirmação da realização do estorno. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.4.      Na hipótese de cancelamento do serviço por parte do Usuário, o acesso ao plano cessará somente quando concluído o ciclo vigente de uso, relativo à última cobrança efetuada. Em caso de cancelamento, ao final do Período de Contratação, o acesso de todos os usuários contratados à Plataforma será cancelado. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.5.      Na hipótese de cancelamento do serviço em virtude de violação aos presentes termos de uso, os serviços serão cessados imediatamente, sem qualquer reembolso ao Usuário. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 5.6. "),
                _createElementVNode("strong", null, [
                  _createElementVNode("em", null, "Multa pelo cancelamento")
                ]),
                _createTextVNode(". Não existe qualquer tipo de fidelização entre o Usuário e a Adv Service, devendo ser respeitado apenas o prazo contratual dá última renovação. O Adv Service não cobra multa pelo cancelamento da Assinatura. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.7.      A não observância das obrigações pactuadas nestes termos de uso ou da legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão unilateral por parte da Adv Service e o bloqueio de todos os serviços prestados ao Usuário, hipótese em que a Adv Service se reserva o direito de recusar ou restringir ao Usuário todo e qualquer uso atual ou futuro da plataforma e dos serviços. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, "  5.8.  Os dados inseridos pelo Usuário não serão excluídos do banco de dados da Plataforma, por tempo necessário para o cumprimento do objeto deste termo. Após, os dados pessoais acima citados serão destruídos, salvo aqueles que forem necessários para cumprimento de obrigações legais, na forma do Art. 16, I da Lei 13.709/18.  "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "6" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Funcionamento da Plataforma e Serviços de Terceiros")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 6.1. "),
                _createElementVNode("em", null, "Condições de acesso e funcionamento"),
                _createTextVNode(". O Adv Service se esforça para garantir que o sistema esteja em pleno e completo funcionamento durante 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, inclusive finais de semana e feriados. Sendo assim, o Adv Service garante, observadas as condições destes Termos de Uso, que a Plataforma estará em plenas condições de acesso e funcionamento durante, pelo menos, 90% (noventa por cento) do tempo do Período de Contratação. Caso o usuário verifique qualquer falha ou dificuldade de acesso, poderá entrar em contato com o Adv Service pelo e-mail “"),
                _createElementVNode("u", null, "contato@advservice.com.br"),
                _createTextVNode("”. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 6.2. "),
                _createElementVNode("em", null, "Modificações da Plataforma"),
                _createTextVNode(". O usuário registra que o software não foi feito sob encomenda e que o Adv Service se reserva no direito de alterar, atualizar ou descontinuar funcionalidades da Plataforma, bem como de suspender o acesso à Plataforma para realização de manutenção ou outros procedimentos, sem que isso gere qualquer direito de ressarcimento ou indenização por parte do Usuário. Nesse contexto, o Usuário registra e aceita que, em nenhuma hipótese, o Adv Service poderá ser responsabilizado por eventuais perdas e danos, lucros cessantes, ou qualquer outro dano, relacionado à suspensão do acesso à Plataforma, alteração, atualização ou descontinuação de funcionalidades. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 6.3. "),
                _createElementVNode("em", null, "Serviços de terceiros"),
                _createTextVNode(". O Site e a Plataforma valem-se de serviços de terceiros para operar, tais como servidores, internet, dentre outros, os quais não são operados pelo Adv Service, de forma que o Adv Service não será responsável por quaisquer danos decorrentes da falha na prestação de serviços de terceiros. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 6.4. "),
                _createElementVNode("em", null, "Links de terceiro"),
                _createTextVNode(". O Adv Serviço poderá disponibilizar na Plataforma links de acesso para sites de terceiros. A disponibilização desses links não significa qualquer indicação ou endosso aos serviços prestados por esses terceiros. O Adv Serviço não terá qualquer responsabilidade pelos serviços ou funcionalidades oferecidas em tais sites, pois eventual contratação ou utilização de funcionalidade de terceiros é escolha pessoal do Usuário. De todo modo, o Adv Serviço recomenda que, antes de qualquer contratação, o usuário se informe sobre os termos de uso e política de privacidade de serviços oferecidos a terceiros. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 6.5. "),
                _createElementVNode("em", null, "Integrações via API"),
                _createTextVNode(". A Adv Service poderá integrar livremente no âmbito da sua plataforma e de seus serviços, aplicações de terceiros que se manifestem de maneira ostensiva ou não, em sua plataforma e serviços (ex.: através de links de websites ou funcionalidades da internet). Esses serviços de terceiros são de responsabilidade daqueles que os disponibilizam, e serão regidos única e exclusivamente pelos Termos de Uso a eles aplicáveis, definidos por cada terceiro por eles responsáveis. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 6.5.1.      Nos casos em que a aceitação dos Termos de Uso de terceiros seja necessário para a utilização de determinados serviços da plataforma digital Adv Service, a não aceitação destes termos pode limitar o acesso dos Usuários aos serviços. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 6.5.2.      A integração com serviços de terceiros não corresponde ao endosso desses serviços ou qualquer associação com seus operadores por parte da Adv Service, tampouco, qualquer relação de sociedade, supervisão, cumplicidade, joint venture, solidariedade ou de garantia com esses terceiros. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "7" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Responsabilidades do Adv Service")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 7.1. "),
                _createElementVNode("em", null, "Responsabilidade sobre o conteúdo e uso das informações"),
                _createTextVNode(". A Plataforma tem como objetivo auxiliar e facilitar o trabalho dos profissionais da Advocacia, disponibilizando, entre outros, modelos de peças processuais e teses jurídicas, gestor de escritório, tira dúvida jurídica, cabendo, contudo, ao Usuário salvaguardar-se de que tais informações não possuem incoerências, imprecisões, estejam desatualizados, ou que ainda sejam compatíveis com o cliente por ele atendido. Além disso, o Usuário registra que a obrigação do advogado é de meio e não de resultado, de forma que nem o Usuário e nem o Adv Service podem garantir o êxito em demandas judiciais e administrativas. Dessa forma, em nenhuma circunstância, o Adv Serviço poderá ser responsabilizado quanto à integridade, atualidade e exatidão de tais informações obtidas pelo uso da Plataforma, não sendo responsável, perante o Usuário por eventuais perdas e danos, ou por quaisquer danos patrimoniais e extrapatrimoniais, inclusive, sem limitações, por lucros cessantes, ou qualquer outro dano, resultantes do uso das funcionalidades nela oferecidas ou das informações dela decorrentes, incluindo, sem limitações, em ações judiciais, extrajudiciais ou administrativas, resultantes do uso da Plataforma, das funcionalidades nela oferecidas ou das informações dela decorrentes. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 7.2. "),
                _createElementVNode("em", null, "Responsabilidade sobre os dados"),
                _createTextVNode(". O Usuário e não o Adv Service é responsável pela manutenção e proteção de seus dados. É de responsabilidade do Usuário realizar o backup de suas informações. O Adv Service não será responsabilizado por eventuais perdas e danos, ou por quaisquer danos diretos, indiretos, consequentes, exemplares, incidentais, especiais ou punitivos, inclusive, sem limitações, por lucros cessantes, ou qualquer outro dano, em razão da perda, desvio , apropriação indevida, ou quaisquer outros termos dos dados e informações inseridas pelo Usuário na Plataforma.  ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 7.3. "),
                _createElementVNode("em", null, "Responsabilidade sobre o acesso"),
                _createTextVNode(". O Adv Service não garante que o acesso ao site esteja livre de erros ou problemas, decorrentes de casos fortuitos, internos ou externos, casos de força maior ou por outros casos não sujeitos ao controle do Adv Service, como, por exemplo, falhas na internet em geral, quedas de energia, dentre outras. Ainda assim, o Adv Service não poderá ser responsabilizado, perante o Usuário ou qualquer terceiro, por quaisquer danos diretos ou indiretos, consequentes, exemplares, incidentais, especiais ou punitivos, inclusive, sem limitação, por lucros cessantes, em relação: ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 7.3.1. a qualquer dano ou prejuízo em equipamentos do Usuário em decorrência do acesso e uso da Plataforma; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 7.3.2. a interrupções de qualquer natureza no acesso à Plataforma, tais como manutenção programada, ou não, falha na prestação de serviços de terceiros, queda de energia ou de internet, dentre outros; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 7.3.3. a eventual vazamento de informações decorrentes de acesso indevido e não autorizado aos servidores contratados pelo Adv Service. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 7.3.4. a vírus, malware ou qualquer outro dispositivo malicioso que, em decorrência de acesso, utilização, navegação na Plataforma, ataque equipamento do Usuário. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "8" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Responsabilidades e Declarações dos Usuários")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 8.1. "),
                _createElementVNode("em", null, "Carregar informações"),
                _createTextVNode(". A Plataforma disponibilizada pelo Adv Service poderá oferecer funcionalidades que permitem o carregamento (upload) de materiais de texto, imagem e/ou outros dados próprios e de terceiros. Por razão disso, o Usuário aceita, autoriza e compromete-se a não carregar (upload) qualquer conteúdo ilícito, imoral ou ilegal ou material que viole a Propriedade Intelectual do Adv Service ou de terceiros, tampouco viola o direito ao sigilo de informações e dados de qualquer pessoa, seja física ou jurídica. Caso qualquer violação a este dispositivo seja identificada, a conta do Usuário será bloqueada imediatamente, independentemente de aviso prévio. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 8.2. "),
                _createElementVNode("em", null, "Demais obrigações do Usuário"),
                _createTextVNode(". Sem prejuízo de outras obrigações eventualmente expostas nestes Termos de Uso, é de responsabilidade do Usuário: ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, "8.2.1. Respeitar e cumprir as regras aplicáveis nestes Termos de Uso;"),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.2. Utilizar a Plataforma de forma correta, de acordo com estes Termos de Uso e com as demais regras aplicáveis do ordenamento jurídico brasileiro, responsabilizando-se pela sua conduta; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.3. Não realize qualquer download de conteúdo, exceto se tal possibilidade estiver disponível como funcionalidade da Plataforma; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.4. Não reproduzir, realizar engenharia reversa, compilar, descompilar, ceder, distribuir, transmitir, licenciar ou sublicenciar, exibir, vender ou, de qualquer maneira, explorar comercialmente a Assinatura ou qualquer Propriedade Intelectual do Adv Service de forma não autorizada nestes Termos de Uso; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.5. Utilização dos equipamentos de hardware necessários para o acesso à Plataforma; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.6. Manter o registro e realizar o backup de todo o conteúdo e dados que julgue relevantes; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.7. Agir de forma a evitar o acesso indevido à Plataforma. Configure a violação deste disposto o compartilhamento indevido com terceiros do acesso à Plataforma. Caso seja verificado o compartilhamento indevido de login e acesso, o Adv Service poderá incapacitar de forma definitiva o contato do(s) Usuários, independentemente de qualquer notificação prévia. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.8. Não utilizar a Plataforma para a prática de atos ilícitos, proibidos pela lei e por estes Termos de Uso; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.9. Não utilize a Plataforma para ler direitos do Adv Service ou de terceiros, não que se incluam condutas que possam gerar qualquer dano ao Site, à Plataforma e/ou ao Aplicativo, a equipamentos de terceiros ou, até mesmo, gerar danos a documentos e conteúdos inseridos pelo Adv Service ou terceiros na Plataforma. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 8.3. "),
                _createElementVNode("em", null, "Declarações do Usuário"),
                _createTextVNode(". Ao realizar o Cadastro na Plataforma, o Usuário expressamente confirma e declara que possui capacidade para anuir estes Termos de Uso, inexistindo nenhum impedimento legal ou contratual para o cumprimento das obrigações ora assumidas e que as informações fornecidas e o conteúdo inserido na Plataforma não violam a legislação brasileira ou estes Termos de Uso: ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.3.1. São verídicas, válidas e não violam nenhum dispositivo destes Termos de Uso e/ou da legislação brasileira; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.3.2. Não infringem nem conflitam com nenhum direito de terceiros, não que se inclua, mas não se limite, a direitos de propriedade intelectual, direitos autorais, de imagem, privacidade dentre outros; "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.3.3. Não possui conteúdo misógino, homofóbico, racista, nem incita à violência, tampouco atinja a honra e a dignidade de terceiros. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 8.4. "),
                _createElementVNode("em", null, "Uso de informações pessoais de terceiros"),
                _createTextVNode(". Ao utilizar a Plataforma, o Usuário declara expressamente que possui plena autorização para a inserção na Plataforma de informações pessoais de terceiros, especialmente em relação aos dados de natureza sensível de seus clientes, nos termos definidos pela Lei n. 13.709/2018, bem como de que têm plena ciência sobre a Política de Uso de Dados da Plataforma.  ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 8.5. "),
                _createElementVNode("em", null, "Mecanismo de denúncia"),
                _createTextVNode(". Caso qualquer pessoa, Usuário ou não, identifique na Plataforma qualquer conteúdo que seja considerado conteúdo vedado, inclusive por violar propriedade intelectual própria ou de terceiro, ou, ainda, que verifique a disponibilização de informações com referência de fonte incorreta, imprecisa ou incompleta poderá promover uma denúncia por meio do e-mail “contato@advservice.com.br”. Após apuração das informações, o Adv Service poderá, a seu exclusivo exclusivo, remover/modificar o conteúdo e/ou a conta denunciada. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 8.6. "),
                _createElementVNode("em", null, "Violação dos Termos de Uso pelo Usuário"),
                _createTextVNode(". A violação dos presentes Termos de Uso por qualquer Usuário implicará a imposição de multa não compensatória ao Usuário infrator no valor de R$ 20.000,00 (vinte mil reais) por ato de violação, responsabilizando-se, ainda, por eventuais perdas e danos sofridas pelo Adv Service, além das despesas administrativas e judiciais, que incluem os honorários advocatícios. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.6.1. Sem prejuízo da multa descrita no item 8.6, o Adv Service poderá anunciar, suspender ou cancelar, temporário ou definitivamente, o Cadastro do Usuário. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.6.2. Para fins exemplificativos, constitui violação dos Termos de Uso: a reprodução indevida ou não autorizada de qualquer conteúdo disponibilizado na Plataforma; comércio ou qualquer forma de exploração comercial e econômica das peças processuais e teses disponíveis na Plataforma; a criação de múltiplas contas para o mesmo usuário; e compartilhamento de contas e acesso à Plataforma com terceiros. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.6.3. Sem prejuízo do disposto no item 8.6, a violação dos Termos de Uso por conduta do Usuário descrita no item 8.2.4 implicará a imposição de multa não compensatória no valor de R$ 50.000,00 (cinquenta mil reais) por ato de violação, responsabilizando-se -se, ainda, por eventuais perdas e danos sofridas pelo Adv Service, além das despesas administrativas e judiciais, na qual se incluem os honorários advocatícios. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "9" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Propriedade Intelectual")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 9.1. "),
                _createElementVNode("em", null, "Licença. "),
                _createTextVNode("Sujeito aos termos e condições destes Termos de Uso, o Adv Service outorga ao(s) Usuário(s), conforme preceitua o artigo 9º da Lei n. 9.609/98, licença temporária, não exclusiva, pessoal, intransferível e limitada, para acessar a plataforma e fazer uso do Software como Serviço. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 9.2.      Todas as buscas no Buscador Service, respostas no Pergunte ao Especialista, processos, clientes, equipes, agenda e financeiros cadastrado no Gestor Service, contratos arquivados e assinados no Assinador Service, e eventuais serviços que forem disponibilizadas pela Adv Service, não baixados pelo Usuário, estarão em nuvem de propriedade da Adv Service, de modo que o Usuário só terá acesso à nuvem e consequentemente ao que produziu ou consultou nas ferramentas, com o seu plano de assinatura ATIVO. O usuário sempre terá opção de fazer o Download de todos arquivos antes do final do período de teste/assinatura, sendo de sua responsabilidade fazê-lo, não podendo reclamar por eventual inércia, posteriormente. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 9.3. "),
                _createElementVNode("em", null, "Propriedade Intelectual"),
                _createTextVNode(".  As marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos são de exclusiva titularidade intelectual da Adv Service, observados os termos das Leis nº 9.279/1996 (“Lei da Propriedade Industrial”), 9.609/1998 (“Lei do Software”) e 9.610/1998 (“Lei de Direitos Autorais”), e sem prejuízo das demais normas relativas à proteção da propriedade intelectual, estão devidamente reservados. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 9.4.      Dessa forma, o uso da plataforma e dos serviços pelos Usuários é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão tanto em violação dos direitos de propriedade intelectual da Adv Service, indenizáveis nos termos da Lei de Direitos Autorais, bem como puníveis nos termos da legislação penal aplicável. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 9.5. "),
                _createElementVNode("em", null, "Utilização indevida"),
                _createTextVNode(". O Usuário não poderá reproduzir, copiar, modificar, realizar engenharia reverter, compilar, descompilar, transmitir, publicar, sublicenciar, permitir, autorizar, alugar, vender, distribuir, ou, de qualquer outra forma, utilizar qualquer conteúdo, funcionalidade, software ou material da Plataforma de forma não permitida nesses Termos de Uso.. Ainda assim, o usuário declara e garante que não registrará nem tentará registrar nomes de domínio na internet, em qualquer localidade, contendo elementos da Propriedade Intelectual do Adv Service. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "10" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " Condições gerais")
                ])
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.1. "),
                _createElementVNode("em", null, "Legislação e jurisdição"),
                _createTextVNode(" . Os presentes Termos de Uso são regidos e interpretados pelas leis brasileiras. Qualquer disputa decorrente da interpretação e aplicação destes Termos de Uso, caso não resolvido de forma amigável, deverá ser solucionada perante o Foro da Comarca de Santa Maria, Estado do Rio Grande do Sul, com renúncia expressa a qualquer outra, por mais privilegiadas que seja . ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.2. "),
                _createElementVNode("em", null, "Vigência. "),
                _createTextVNode("Este Termo de Uso permanecerá em plena força e vigor enquanto o Usuário utilizar a Plataforma, e conforme cláusulas 7, 8, 9 e 10.2., pelo prazo de 5 anos após o término. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.3. "),
                _createElementVNode("em", null, "Cessão. "),
                _createTextVNode("Estes Termos de Uso não poderão ser transferidos ou cedidos pelo Usuário, mas poderão ser transferidos pelo Adv Service sem qualquer restrição, a qualquer momento. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.4. "),
                _createElementVNode("em", null, "Independência das Disposições. "),
                _createTextVNode("Na hipótese de qualquer disposição ou obrigação decorrente destes Termos de Uso para considerados ou se tornarem inválidos, ilegais ou não apresentados em qualquer aspecto, a validade, legalidade e execução das disposições ou obrigações remanescentes, não serão afetadas ou prejudicadas, de nenhuma maneira. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.5. "),
                _createElementVNode("em", null, "Tolerância. "),
                _createTextVNode("Nenhuma falha ou demora em exercer qualquer direito, poder ou privilégio, nos termos deste Termos de Uso, operará como uma renúncia deste, e nem qualquer exercício total ou parcial de qualquer direito, poder ou privilégio impedirá qualquer outro exercício destes ou o exercício de qualquer outro direito, poder ou privilégio. Os direitos e recursos aqui indicados serão cumulativos e não excluirão nenhum outro previsto pela legislação aplicável. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.6. "),
                _createElementVNode("em", null, "Alteração do Termo de Uso"),
                _createTextVNode(". O Adv Service poderá alterar estes Termos de Uso, devendo comunicar os usuários com 15 (quinze) dias. A comunicação será enviada ao e-mail informado no cadastro do Usuário. O aceite das novas regras é uma decisão que cabe exclusivamente ao Usuário. Isto quer dizer que o usuário poderá rejeitar as novas regras apresentadas pelo Adv Service, possivelmente na qual o acesso à Plataforma será bloqueado a partir da entrada em vigor da nova redação. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.7. "),
                _createElementVNode("em", null, "Comunicação. "),
                _createTextVNode("Qualquer comunicação realizada pelo Adv Service será destinada ao e-mail fornecido pelo usuário no momento do cadastro na Plataforma ou pela interface de comunicação nela disponibilizada. ")
              ]),
              _createElementVNode("br"),
              _createElementVNode("p", null, [
                _createTextVNode(" 10.8. "),
                _createElementVNode("em", null, "Atendimento ao usuário"),
                _createTextVNode(". Qualquer dúvida a respeito destes Termos de Uso ou sobre o funcionamento da Plataforma poderá ser feita diretamente pelo Usuário de segunda-feira à sexta-feira das 08:00 às 17:00 pelo e-mail “"),
                _createElementVNode("u", null, "contato@advservice.com.br"),
                _createTextVNode("”. ")
              ]),
              _createElementVNode("br")
            ])
          ])
        ])
      ], -1)
    ])),
    _: 1
  }, 8, ["open"]))
}
}

})