import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-[70]" }
const _hoisted_2 = { class: "flex items-center justify-between w-full gap-8" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-2xl" }

import { computed } from 'vue';

interface ModalProps {
  modalTitle: string;
  open: boolean;
  size?: 'md' | 'lg';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    modalTitle: {},
    open: { type: Boolean },
    size: { default: 'md' }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const modalSize = computed(() => {
  let sizeHolder = '';

  switch (props.size) {
    case 'md':
      sizeHolder = 'max-w-2xl';
      break;
    case 'lg':
      sizeHolder = 'max-w-4xl';
      break;
    default:
      sizeHolder = 'max-w-2xl';
      break;
  }
  return sizeHolder;
});

// eslint-disable-next-line no-undef
const emit = __emit;
// eslint-disable-next-line no-undef
const props = __props;

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full max-w-2xl p-6 bg-white rounded-md shadow-xl flex flex-col justify-between items-center h-screen xl:h-fit max-h-screen overflow-auto", modalSize.value])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.modalTitle), 1),
          _renderSlot(_ctx.$slots, "modal-header")
        ]),
        (_openBlock(), _createElementBlock("svg", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close'))),
          xmlns: "http://www.w3.org/2000/svg",
          class: "w-8 h-8 text-primary cursor-pointer",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          }, null, -1)
        ])))
      ]),
      _renderSlot(_ctx.$slots, "modal-content")
    ], 2)
  ], 512)), [
    [_vShow, _ctx.open]
  ])
}
}

})