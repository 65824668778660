<script lang="ts" setup>
import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import GridContainer from '@/components/NewsGrid/GridContainer.vue';
import NewsCard from '@/components/NewsGrid/NewsCard.vue';
import { useNewsStore } from '@/store/newsStore';
import { lightButtonStyle } from '@/assets/css/styles';
import { newsType } from '@/types/postTypes';
import { useRouter } from 'vue-router';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';

const router = useRouter();

const newsStore = useNewsStore();

const navToPost = (selected: newsType) => {
  const normTitle = selected.title
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  window.scrollTo(0, 0);
  router.push(`/noticias/${selected.id}/${normTitle}`);
  console.log('normalized title', normTitle);
};

onMounted(() => {
  newsStore.getNewsList();
});
</script>

<template>
  <Layout>
    <Title :height="28" imgUrl="url('@/assets/img/bg-news-title.svg')">
      <h1 class="z-10 font-Open text-6xl font-bold text-white">Notícias</h1>
    </Title>
    <section class="h-max w-full flex gap-12 justify-center flex-col items-center lg:p-28 p-16">
      <GridContainer v-if="!newsStore.loading || newsStore.news.length > 0">
        <NewsCard
          v-for="post in newsStore.news"
          :key="post.id"
          :id="post.id"
          :title="post.title"
          :cover="post.cover"
          :summary="post.summary"
          :status="post.status"
          :createdAt="post.createdAt"
          :updatedAt="post.updatedAt"
          :deletedAt="post.deletedAt"
          @go-to-post="navToPost"
        />
      </GridContainer>
      <button
        type="button"
        :disabled="newsStore.loading"
        @click="newsStore.getMoreNews"
        :class="lightButtonStyle"
        class="mt-3"
      >
        <p v-show="!newsStore.loading">CARREGAR MAIS</p>
        <LoadingSpinner v-show="newsStore.loading" />
      </button>
    </section>
  </Layout>
</template>
