import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "relative" }

import { ref, onMounted } from 'vue';
import { getActiveSolutions } from '@/services/solutions';

const activeText = 'txt-btn text-adv-blue-800 font-bold text-xs';
const inactiveText = 'txt-btn text-xs text-dark-grey';
const activeSolution = 'bg-adv-blue-800 text-header-grey';
const inactiveSolution = 'text-dark-grey';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropDownSolutions',
  setup(__props) {

const show = ref(false);
const activeSolutions = ref<number[]>([]);

// const isDevelopment = computed(() => {
//   return window.location.href.includes('development') || window.location.href.includes('localhost');
// });

const isActiveClass = (): string => {
  if (window.location.pathname === '/trabalhista-service' || window.location.pathname === '/gestor-service')
    return activeText;

  return inactiveText;
};

const isActiveSolution = (route: string): string => {
  if (window.location.pathname === route) return activeSolution;

  return inactiveSolution;
};

function onClickAway() {
  show.value = false;
}

onMounted(async () => {
  const { data } = await getActiveSolutions();

  activeSolutions.value = data.body.result.map((item: any) => {
    return item.id;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value = !show.value)),
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (show.value = true)),
      class: _normalizeClass([isActiveClass(), "flex items-center rounded-md bg-transparent focus:outline-none"])
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("span", { class: "mr-4" }, "SOLUÇÕES", -1),
      _createElementVNode("svg", {
        class: "h-5 w-5 text-indigo-100",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 20",
        fill: "#656565"
      }, [
        _createElementVNode("path", {
          "fill-rule": "evenodd",
          d: "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z",
          "clip-rule": "evenodd"
        })
      ], -1)
    ]), 34),
    _withDirectives(_createElementVNode("div", {
      onMouseleave: onClickAway,
      class: "absolute -left-1/3 z-50 mt-2 w-max rounded-md bg-header-grey py-2"
    }, [
      (activeSolutions.value.includes(1))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: _normalizeClass([isActiveSolution('/trabalhista-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/trabalhista-service"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" TRABALHISTA SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(2))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: _normalizeClass([isActiveSolution('/gestor-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/gestor-service"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" GESTOR SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(3))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 2,
            class: _normalizeClass([isActiveSolution('/pergunte-ao-especialista'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/pergunte-ao-especialista"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" PERGUNTE AO ESPECIALISTA ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(7))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            class: _normalizeClass([isActiveSolution('/mentoria-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/mentoria-service"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" MENTORIA SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(8))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 4,
            class: _normalizeClass([isActiveSolution('/buscador-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/buscador-service"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" BUSCADOR SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(9))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 5,
            class: _normalizeClass([isActiveSolution('/pdf-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/pdf-service"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" PDF SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(10))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 6,
            class: _normalizeClass([isActiveSolution('/assinador-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/assinador-service"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" ASSINADOR SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (activeSolutions.value.includes(11))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 7,
            class: _normalizeClass([isActiveSolution('/contratualista-service'), "txt-btn block px-4 py-2 text-xs hover:bg-adv-blue-800 hover:text-header-grey"]),
            to: "/contratualista-service"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" CONTRATUALISTA SERVICE ")
            ])),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ], 544), [
      [_vShow, show.value]
    ])
  ])), [
    [_directive_click_away, onClickAway]
  ])
}
}

})