<template>
    <Layout>
        <PlansContratacao />
        <section v-if="store.step === 0"
            class="w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-start gap-14">
            <FormSign />
        </section>
        <!-- <section
      v-if="store.step === 1"
      class="w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-start gap-14"
    >
      <AddressForm />
    </section> -->
        <section v-if="store.step === 1"
            class="w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-start gap-14">
            <h3 class="text-adv-blue-800 font-bold w-full my-3 text-center text-4xl">Escolha seu plano</h3>
            <PlanPriceDisplay />
            <h3 class="text-adv-blue-800 font-bold w-full my-3 text-center text-4xl">Detalhes de pagamento</h3>
            <PaymentResume />
            <h3 class="text-adv-blue-800 font-bold w-full my-3 text-center text-4xl">Dados de pagamento</h3>
            <PayForm />
        </section>
        <section v-if="store.step === 3"
            class="w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-center gap-14">
            <PaymentConfirm />
        </section>
    </Layout>
</template>

<script lang="ts" setup>
import Layout from '@/components/BaseLayout/Layout.vue';
import PlanPriceDisplay from '@/components/Plans/LoginPriceDisplay.vue';
import PlansContratacao from '@/components/Plans/PlansContratacao.vue';
import FormSign from '@/components/SignUp/Form.vue';
import AddressForm from '@/components/SignUp/Address.vue';
import PayForm from '@/components/SignUp/PayForm.vue';
import PaymentConfirm from '@/components/SignUp/PaymentConfirm.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useSignUpStore } from '@/store/signUpStore';
import { watch, onBeforeMount } from 'vue';
import PaymentResume from '@/components/SignUp/PaymentResume.vue';
import { useRouter } from 'vue-router';

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const router = useRouter();

watch(() => store.step, (newStep) => {
    if (newStep === 3 && (store.validPayment === 'PROCESSING' || store.validPayment === 'SUCCESS')) {
        router.push('/planos/confirmado');
    }
});

onBeforeMount(() => {
    storePlans.getPlans();
});
</script>
