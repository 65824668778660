import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/adv-logo-header.svg'


const _hoisted_1 = { class: "relative z-20 w-full h-32 p-7 bg-header-grey flex justify-center items-center" }
const _hoisted_2 = { class: "max-w-6xl w-full flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "hidden lg:flex lg:w-full lg:justify-evenly" }
const _hoisted_4 = { class: "flex flex-row justify-around items-center w-1/2" }
const _hoisted_5 = { class: "flex gap-1 justify-center items-center" }

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import DropDownSolutions from '../DropDown/DropDownSolutions.vue';
import DropDownMenu from '@/components/DropDown/DropDownMenu.vue';
import { useUserStore } from '@/store/user';

const activeText = 'txt-btn text-adv-blue-800 font-bold text-xs';
const inactiveText = 'txt-btn text-xs text-dark-grey';


export default /*@__PURE__*/_defineComponent({
  __name: 'Head',
  setup(__props) {

const userStore = useUserStore();

const router = useRouter();
const isActiveClass = (route: string): string => {
  if (route === '/' && window.location.pathname === route) return activeText;
  if (route !== '/' && window.location.pathname.includes(route)) return activeText;

  return inactiveText;
};

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

onMounted(() => {
  userStore.checkUserAction();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "ADV Service Logo",
        class: "h-4/5 w-auto mr-4 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("nav", _hoisted_4, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/adv-service'))),
            class: _normalizeClass(isActiveClass('/adv-service'))
          }, "QUEM SOMOS", 2),
          _createVNode(DropDownSolutions),
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/planos'))),
            class: _normalizeClass(isActiveClass('/planos'))
          }, "PLANOS", 2),
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/blog'))),
            class: _normalizeClass(isActiveClass('/blog'))
          }, "BLOG", 2),
          _createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/noticias'))),
            class: _normalizeClass(isActiveClass('/noticias'))
          }, "NOTÍCIAS", 2)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/login'))),
            class: "w-40 h-10 m-0 border border-adv-blue-800 flex justify-center items-center bg-transparent text-adv-blue-800 cursor-pointer"
          }, " ENTRAR "),
          _createElementVNode("button", {
            type: "button",
            onClick: goToSign,
            class: "w-40 h-10 m-0 border-0 flex justify-center items-center bg-adv-blue-800 text-white cursor-pointer"
          }, " TESTE GRÁTIS ")
        ])
      ]),
      _createVNode(DropDownMenu)
    ])
  ]))
}
}

})