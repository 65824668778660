import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  props: {
    color: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.width ? _ctx.width : '1em',
    height: _ctx.height ? _ctx.height : '1em',
    viewBox: "0 0 24 24"
  }, [
    _createElementVNode("path", {
      fill: _ctx.color || '#FFFFFF',
      d: "M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("animateTransform", {
        attributeName: "transform",
        dur: "0.75s",
        repeatCount: "indefinite",
        type: "rotate",
        values: "0 12 12;360 12 12"
      }, null, -1)
    ]), 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})