<template>
  <div v-show="open" class="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-[70]">
    <div
      class="w-full max-w-2xl p-6 bg-white rounded-md shadow-xl flex flex-col justify-between items-center h-screen xl:h-fit max-h-screen overflow-auto"
      :class="modalSize"
    >
      <div class="flex items-center justify-between w-full gap-8">
        <div class="flex items-center">
          <h3 class="text-2xl">{{ modalTitle }}</h3>
          <slot name="modal-header"> </slot>
        </div>

        <svg
          @click="emit('close')"
          xmlns="http://www.w3.org/2000/svg"
          class="w-8 h-8 text-primary cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <slot name="modal-content"> </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface ModalProps {
  modalTitle: string;
  open: boolean;
  size?: 'md' | 'lg';
}

const modalSize = computed(() => {
  let sizeHolder = '';

  switch (props.size) {
    case 'md':
      sizeHolder = 'max-w-2xl';
      break;
    case 'lg':
      sizeHolder = 'max-w-4xl';
      break;
    default:
      sizeHolder = 'max-w-2xl';
      break;
  }
  return sizeHolder;
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['close']);
// eslint-disable-next-line no-undef
const props = withDefaults(defineProps<ModalProps>(), {
  size: 'md',
});
</script>
