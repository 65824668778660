import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:w-[28rem] bg-[#E8E8E8] p-6 md:h-64" }
const _hoisted_2 = { class: "flex items-center gap-4 mb-4" }
const _hoisted_3 = { class: "w-8 h-8 bg-adv-green-100 rounded-full" }
const _hoisted_4 = { class: "font-bold text-adv-blue-800 text-2xl tracking-wider" }
const _hoisted_5 = { class: "text-adv-gray-500" }

import GreenTickIcon from '@/components/icons/GreenTickIcon.vue';

interface BenefitProps {
  title: string;
  text: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'BenefitCard',
  props: {
    title: { default: 'Benefício Exemplo' },
    text: { default: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type." }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GreenTickIcon, { class: "w-8 h-8 text-adv-green-500" })
      ]),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.text), 1)
  ]))
}
}

})