<script lang="ts" setup>
import { ref } from 'vue';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import Head from '@/components/Header/Head.vue';
import { http } from '@/core/api';
import { Notyf } from 'notyf';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';

const notyf = new Notyf();

const email = ref('');
const loading = ref(false);

async function getRecoveryToken() {
  loading.value = true;
  try {
    await http.post(`/recoverTokens?email=${email.value}`);

    notyf.success('E-mail enviado com sucesso!');
  } catch (err: any) {
    notyf.error(err.message);
    console.error(err);
  }
  loading.value = false;
}
</script>

<template>
  <div class="w-full h-screen overflow-auto flex flex-col gap-8 bg-[url('@/assets/img/bg-login.svg')] bg-cover">
    <Head />
    <main class="w-full flex flex-col justify-center items-center gap-20">
      <img src="@/assets/img/adv-login-logo.svg" alt="login logo" class="h-28 w-auto hidden z-10 lg:block" />
      <section
        class="max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14"
      >
        <h3 class="text-adv-blue-800 font-bold text-2xl tracking-wide text-center">Recuperação de Senha</h3>
        <p class="text-adv-gray-400 text-lg text-center">
          Para recuperar a sua senha, informe seu endereço de e-mail que nós enviaremos um link para alteração da senha.
        </p>
        <div class="w-full">
          <p class="text-[#A8A8A8] font-bold tracking-widest text-xl mb-4">E-mail</p>
          <input type="text" v-model="email" :class="formInput" />
        </div>
        <button :class="lightButtonStyle" class="w-full flex justify-center items-center" @click="getRecoveryToken">
          <LoadingSpinner v-show="loading" />
          <p v-show="!loading">ENVIAR</p>
        </button>
      </section>
    </main>
  </div>
</template>
