<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { http } from '@/core/api';
import { useRouter } from 'vue-router';
import { RoutesEnum } from '@/types/routesEnum';

type SolutionObj = {
  id: number;
  active: boolean;
  description: string;
  image: string;
  name: string;
  serviceLink: string;
};

const router = useRouter();
const solutions = ref<SolutionObj[]>([]);
const loading = ref(false);

const show = ref(false);
function onClickAway() {
  show.value = false;
}

const txtClass =
  'txt-btn font-bold block px-4 py-2 text-xl text-dark-grey hover:bg-adv-blue-800 hover:text-header-grey';

async function getSolutionsList() {
  loading.value = true;
  try {
    const { data } = await http.get('solutions/all?active=true');

    solutions.value = data.body.result;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
    console.log(solutions.value);
  }
}

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

onBeforeMount(async () => {
  await getSolutionsList();
});
</script>

<template>
  <div class="relative" v-click-away="onClickAway">
    <!-- Dropdown toggle button -->
    <button
      @click="show = !show"
      class="txt-btn lg:hidden flex items-center rounded-md bg-transparent text-xs text-dark-grey focus:outline-none"
    >
      <font-awesome-icon :icon="['fas', 'bars']" size="2xl" style="color: #253170" />
    </button>

    <!-- Dropdown menu -->
    <div
      v-show="show"
      class="fixed top-28 left-0 z-20 mt-2 w-full rounded-md bg-header-grey py-2 flex flex-col justify-center items-center"
    >
      <router-link to="/" :class="txtClass"> INÍCIO </router-link>
      <router-link to="/adv-service" :class="txtClass"> QUEM SOMOS </router-link>
      <router-link v-for="sol in solutions" :key="sol.id" :to="RoutesEnum[sol.id]" :class="txtClass">{{
        sol.name.toUpperCase()
      }}</router-link>
      <router-link to="/planos" :class="txtClass"> PLANOS </router-link>
      <router-link to="/blog" :class="txtClass"> BLOG </router-link>
      <router-link to="/noticias" :class="txtClass"> NOTÍCIAS </router-link>
      <button
        type="button"
        @click="router.push('/login')"
        class="w-40 h-10 m-3 border border-adv-blue-800 flex justify-center items-center bg-transparent text-adv-blue-800 cursor-pointer ml-4"
      >
        ENTRAR
      </button>
      <button
        type="button"
        @click="goToSign"
        class="w-40 h-10 m-3 border-0 flex justify-center items-center bg-adv-blue-800 text-white cursor-pointer ml-4"
      >
        TESTE GRÁTIS
      </button>
    </div>
  </div>
</template>
