import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Modal from '@/components/Modal/index.vue';
import { useModalStore } from '@/store/modalStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'contract',
  setup(__props) {

const store = useModalStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    "modal-title": "Contrato de prestação de serviços",
    open: _unref(store).contractModalOpen,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).contractModalOpen = false))
  }, {
    "modal-content": _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "flex flex-col justify-center items-center gap-12 w-full mt-12" }, [
        _createElementVNode("div", { class: "w-full" }, [
          _createElementVNode("div", { class: "border md:w-full max-h-[80vh] lg:max-h-[300px] overflow-auto" }, [
            _createElementVNode("div", { class: "p-0 md:p-6 text-justify" }, [
              _createElementVNode("p", null, [
                _createElementVNode("strong", null, "POLÍTICA DE PAGAMENTO DO ADV SERVICE")
              ]),
              _createElementVNode("br"),
              _createElementVNode("ol", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " INTRODUÇÃO")
                ])
              ]),
              _createElementVNode("p", null, " 1.1. Esta POLÍTICA DE PAGAMENTO constitui parte integrante dos TERMOS DE USO da Adv Service, o qual regula a funcionalidade da plataforma. Ao realizar uma transação pela Adv Service, mesmo que na versão de teste, você concorda com todas as disposições aqui descritas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, "1.2. Terminologias."),
              _createElementVNode("br"),
              _createElementVNode("p", null, "1.2.1. Você: usuário da Adv Sercive que se enquadra no art. 2º do CDC."),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 1.2.2. Adv Service: Trabalhista Service Ltda., pessoa jurídica de direito privado, inscrita no CNPJ sob nº 41.867.655/0001-49. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "2" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " COMUNICAÇÃO ")
                ])
              ]),
              _createElementVNode("p", null, " 2.1.       Ao aceitar essa POLÍTICA, Você declara que conhece e concorda com todas as demais Políticas aplicáveis, vez que todas são partes integrantes dos TERMOS DE USO da Adv Service. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 2.2.       Caso Você precise de qualquer informação, esclarecimento ou atendimento com relação a esta POLÍTICA, a Adv Service disponibiliza o e-mail: contato@advservice.com.br. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 2.3.       A Adv Service também disponibiliza no site uma sessão de FAQ - Frequently Asked Questions que pode ser útil para você esclarecer suas dúvidas. O FAQ concentra as respostas sobre as dúvidas mais comuns dos clientes. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "3" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " PLANOS ")
                ])
              ]),
              _createElementVNode("p", null, " 3.1.       Os planos disponibilizados pela Adv Service e seus respectivos preços estão disponíveis na página inicial do site da Adv Service no seguinte link “https://advservice.com.br/planos” e todos podem ser testados pelo prazo de 7 dias úteis, desde que seja realizado o cadastro disposto nos Termos de Uso e cadastrado o meio de pagamento. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 3.2.       A Adv Service poderá oferecer planos especiais para campanhas de marketing com prazo definido. Esses planos têm suas próprias regras, que não seguem os padrões dos planos deste link no momento da contratação. É sua responsabilidade entender e seguir as regras aplicáveis ao plano que você contratou, mesmo depois da finalização da referida campanha. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "4" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " MEIOS DE PAGAMENTO ")
                ])
              ]),
              _createElementVNode("p", null, " 4.1.       A assinatura ou compra individual de qualquer da Adv Service poderá ser feita por meio de cartão de crédito. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.2.       O seu acesso à Adv Service, conforme modalidade de plano contratada, será liberado imediatamente após a confirmação do pagamento da assinatura, a qual pode demorar até 72 horas. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.3.       Cartão De Crédito. Você poderá utilizar 1 cartão de crédito emitido no Brasil em seu nome. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.4.       Parcelamento.   As compras podem necessitar de uma confirmação ou validação adicional à critério da Adv Service e neste procedimento a Adv Service ou o processador de pagamentos poderão requerer o envio de documentos pessoais para verificação da sua identidade. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.4.1.    No plano anual o parcelamento se limita a 12 vezes e no plano semestral o parcelamente se limita a 6 meses, em ambos a Adv Service poderá atualizar o valor e benefícios dentro do mesmo período sem a coleta do seu consentimento. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 4.4.2.    No plano mensal não haverá parcelamento sendo pré-pago, a Adv Service poderá atualizar o valor e benefícios a partir do mês seguinte de renovação automática sem a coleta do seu consentimento. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "5" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " CANCELAMENTO E REEMBOLSO ")
                ])
              ]),
              _createElementVNode("p", null, " 5.1.       O Usuário poderá solicitar reembolso da sua conta manifestando sua vontade diretamente na plataforma e no local direcionado para o reembolso dentro do período de 7 dias que passam a contar a partir da disponibilização do serviço, mesmo que no período de teste. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.2.       Após o prazo de 7 dias estabelecidos acima, o Usuário pode solicitar o cancelamento diretamente pela plataforma e o cancelamento será realizado. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, "5.2.1.    CARTÃO DE CRÉDITO"),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.2.1.1.  Em casos de reembolsos de pagamentos realizados por cartão de crédito, seja à vista ou a prazo, a quantia total será reembolsada em um prazo de até 90 dias, dependendo da data de fechamento do seu cartão e das regras de reembolso do seu Banco. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.2.1.2.  O Usuário será responsável por qualquer equívoco ou atraso em relação ao cadastramento dos dados bancários para reembolso dos valores. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 5.2.1.3.  Se o Usuários escolheu o PLANO ANUAL e SEMESTRAL com descontos na Adv Service, declara desde já que tem conhecimento que não faremos reembolso, porque esse desconto reflete a sua escolha de pagamento para usar a plataforma e você terá acesso durante o período de 12 meses correspondente com possibilidade de renovação automática, caso não pretenda cancelar. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "6" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " CHARGEBACKS E ESTORNOS")
                ])
              ]),
              _createElementVNode("p", null, " 6.1.       Os Chargebacks reportados serão analisados pela operadora de cartão de crédito, por nossos processadores de pagamentos, ou pela própria Adv Service. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 6.2.       A Adv Service irá conduzir as disputas abertas por Você, tais como, mas não somente, Chargebacks, reclamações diversas, Cancelamento e Estornos. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "7" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " COMBATE À FRAUDE E A LAVAGEM DE DINHEIRO ")
                ])
              ]),
              _createElementVNode("p", null, " 7.1.       A Adv Service cumpre rigorosamente todas as leis cabíveis, incluindo, mas não se limitando à legislação brasileira anticorrupção e à legislação brasileira contra a lavagem de dinheiro. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "8" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " ATUALIZAÇÕES DA POLÍTICA DE PAGAMENTO ")
                ])
              ]),
              _createElementVNode("p", null, " 8.1.       A qualquer tempo a Adv Service poderá alterar esta Política, sendo responsabilidade do Usuário consultar o documento regularmente, bem como manter cadastrado e-mail válido para recebimento de comunicações. A continuidade do  uso  será compreendida como concordância e aceitação integral. "),
              _createElementVNode("br"),
              _createElementVNode("p", null, " 8.2.       Aplica-se, no que couber, de forma complementar e/ou acessória os demais Termos e Políticas da Adv Service. "),
              _createElementVNode("br"),
              _createElementVNode("ol", { start: "9" }, [
                _createElementVNode("li", null, [
                  _createElementVNode("strong", null, " LEGISLAÇÃO ")
                ])
              ]),
              _createElementVNode("p", null, " 9.1.  Esta Política de Privacidade é regida e interpretada pelas leis brasileiras. Qualquer disputa decorrente de sua interpretação e aplicação, caso não resolvido de forma amigável, deverá ser solucionada perante o Foro da Comarca de Santa Maria, Estado do Rio Grande do Sul, com renúncia expressa a qualquer outro, por mais privilegiado que seja. "),
              _createElementVNode("br")
            ])
          ])
        ])
      ], -1)
    ])),
    _: 1
  }, 8, ["open"]))
}
}

})