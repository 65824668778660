<script setup lang="ts">
import { http } from '@/core/api';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import { getActiveSolutions } from '@/services/solutions';
import { useModalStore } from '@/store/modalStore';
import ContractModal from '@/components/Terms/contract.vue';
import TermsModal from '@/components/Terms/terms.vue';
import PrivacyModal from '@/components/Terms/privacy.vue';

const modalStore = useModalStore();
const router = useRouter();
const aClass = 'txt-btn text-white text-base mb-2 whitespace-nowrap';
const newsState = ref('toSend');
const newsInput = ref('');

const activeSolutions = ref<number[]>([]);

async function signNewsletter() {
  newsState.value = 'loading';
  try {
    await http.post('/newsletter', { email: newsInput.value });
    newsState.value = 'confirmed';
  } catch (err) {
    console.error(err);
    newsState.value = 'error';
  }
}

const handleRedirect = (url: string) => {
  window.open(url);
};

const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

const goToSign = () => {
  window.scrollTo(0, 0);
  router.push('/login/cadastro');
};

onMounted(async () => {
  const { data } = await getActiveSolutions();

  activeSolutions.value = data.body.result.map((item: any) => {
    return item.id;
  });
});
</script>

<template>
  <div class="bg-adv-blue-800 p-5">
    <footer class="flex flex-col w-full h-auto items-center lg:flex-row justify-between">
      <div class="flex flex-col gap-10 items-center m-20">
        <img src="../../assets/img/adv-logo-white.png" alt="adv-white-logo" class="w-42 lg:w-64 h-32 xl:w-64" />
        <button
          type="button"
          @click="goToSign"
          class="txt-btn text-base font-medium border lg:whitespace-nowrap border-white text-adv-blue-800 bg-white py-3 px-7 tracking-[0.21em] hover:scale-75 animation-shrink hover:"
        >
          INICIAR GRÁTIS
        </button>
      </div>

      <div class="flex flex-col lg:flex-row items-start justify-evenly w-full lg:items-start">
        <nav class="flex flex-col items-start justify-start h-full my-5 lg:my-0 xl:my-0">
          <h3 class="text-adv-blue-100 text-xl font-bold font-Open mb-4">Explorar</h3>
          <a type="button" @click="routeRoll('/')" :class="aClass">Início</a>
          <a type="button" @click="routeRoll('/adv-service')" :class="aClass">ADV Service</a>
          <a type="button" @click="routeRoll('/planos')" :class="aClass">Planos</a>
          <a type="button" @click="routeRoll('/blog')" :class="aClass">Blog</a>
          <a type="button" @click="routeRoll('/noticias')" :class="aClass">Notícias</a>
        </nav>

        <nav class="flex flex-col items-start justify-start h-full my-5 lg:w-[100px] xl:my-0 sm:w-[200px]">
          <h3 class="text-adv-blue-100 text-xl font-bold font-Open mb-4">Soluções</h3>
          <a v-if="activeSolutions.includes(1)" type="button" @click="routeRoll('/trabalhista-service')" :class="aClass"
            >Trabalhista Service</a
          >
          <a v-if="activeSolutions.includes(2)" type="button" @click="routeRoll('/gestor-service')" :class="aClass"
            >Gestor Service</a
          >
          <a
            v-if="activeSolutions.includes(3)"
            type="button"
            @click="routeRoll('/pergunte-ao-especialista')"
            :class="aClass"
            >Pergunte ao Especialista</a
          >
          <a v-if="activeSolutions.includes(7)" type="button" @click="routeRoll('/mentoria-service')" :class="aClass"
            >Mentoria Service</a
          >
          <a v-if="activeSolutions.includes(8)" type="button" @click="routeRoll('/buscador-service')" :class="aClass"
            >Buscador Service</a
          >
          <a v-if="activeSolutions.includes(9)" type="button" @click="routeRoll('/pdf-service')" :class="aClass"
            >PDF Service</a
          >
          <a v-if="activeSolutions.includes(10)" type="button" @click="routeRoll('/assinador-service')" :class="aClass"
            >Assinador Service</a
          >
          <a
            v-if="activeSolutions.includes(11)"
            type="button"
            @click="routeRoll('/contratualista-service')"
            :class="aClass"
            >Contratualista Service</a
          >
        </nav>

        <nav class="flex flex-col items-start justify-start h-full my-5 lg:w-[100px] xl:my-0 sm:w-[200px]">
          <h3 class="text-adv-blue-100 text-xl font-bold font-Open mb-4">Institucional</h3>
          <a type="button" :class="aClass" @click.prevent="modalStore.termsModalOpen = true">Termos de uso</a>
          <a type="button" :class="aClass" @click.prevent="modalStore.privacyModalOpen = true"
            >Política de Privacidade</a
          >
          <a type="button" :class="aClass" @click.prevent="modalStore.contractModalOpen = true">Contrato de compra</a>
        </nav>

        <div class="flex items-start justify-start h-full flex-col">
          <h3 class="text-adv-blue-100 text-xl font-bold font-Open mb-4">
            Inscreva-se e <br />
            receba as novidades
          </h3>
          <div class="flex gap-3 w-full">
            <input
              type="email"
              v-model="newsInput"
              placeholder="E-mail"
              class="h-12 p-2 w-64 rounded-sm focus:border-0 placeholder:font-Open placeholder:font-base"
            />
            <button
              type="button"
              title="Enviar"
              :disabled="newsState === 'loading' || newsState === 'confirmed'"
              @click="signNewsletter"
            >
              <LoadingSpinner v-show="newsState === 'loading'" />
              <font-awesome-icon
                v-show="newsState === 'toSend'"
                :icon="['fas', 'circle-up']"
                size="2xl"
                style="color: #ffffff"
              />
              <font-awesome-icon
                v-show="newsState === 'confirmed'"
                :icon="['fas', 'circle-check']"
                size="2xl"
                style="color: #ffffff"
              />
              <font-awesome-icon
                v-show="newsState === 'error'"
                :icon="['fas', 'circle-xmark']"
                size="2xl"
                style="color: #ffffff"
              />
            </button>
          </div>
          <h3 class="text-adv-blue-100 text-xl font-bold font-Open mb-1 mt-5">Redes Sociais</h3>
          <div class="flex justify-start items-center">
            <button
              type="button"
              class="txt-btn mr-4"
              @click.prevent="handleRedirect('https://www.instagram.com/adv.service/')"
            >
              <img class="h-9 w-auto" src="@/assets/img/insta-white-icon.svg" alt="Instagram Icon" />
            </button>
            <button
              type="button"
              class="txt-btn mr-4"
              @click.prevent="handleRedirect('https://www.facebook.com/trabalhista.service/')"
            >
              <img class="h-9 w-auto" src="@/assets/img/face-white-icon.svg" alt="Facebook Icon" />
            </button>
            <!-- <button type="button" class="txt-btn mr-4">
              <img class="h-9 w-auto" src="@/assets/img/linkedin-white-icon.svg" alt="LinkedIn Icon" />
            </button> -->
          </div>
        </div>
      </div>
      <ContractModal />
      <TermsModal />
      <PrivacyModal />
    </footer>
    <!-- <div class="flex items-center justify-start lg:justify-center gap-4 mt-4 lg:mt-0">

        <img src="@/assets/img/oab.png" alt="ADVSERVICE" />
     
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.animation-shrink {
  transition-duration: 0.3s;
  transition-property: transform;
}
</style>
