import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-24 flex-col lg:flex-row justify-center items-center lg:items-start px-6 lg:px-0" }

import { computed, onMounted, ref } from 'vue';
import CardsContainer from '@/components/CardDysplay/CardsContainer.vue';
import CardsCarousel from '@/components/CardDysplay/CardsCarousel.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { planType } from '@/types/cardTypes';
import { useRouter } from 'vue-router';
import Card from '../CardDysplay/Card.vue';

interface CompProps {
  solutionId: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PlansComparison',
  props: {
    solutionId: { default: 1 }
  },
  setup(__props: any) {

const router = useRouter();

const storePlans = useServicePlansStore();
const planoAvulso = ref<planType>({
  id: 4,
  name: 'Avulsa',
  price: 49.9,
  periodicity: 8,
  toSolutionId: 1,
  solutions: [
    {
      id: 2,
      name: 'Gestor Service',
      image: 'https://i.ibb.co/KLXjGzC/1.png',
      serviceLink: 'https://gestor.advservice.com.br/',
      createdAt: '2023-08-28T21:27:46.291Z',
      updatedAt: '2023-09-11T20:06:51.308Z',

      resources: [
        {
          id: 4,
          name: 'Acessoria e administração',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
          solutionId: 2,
          createdAt: '2023-08-28T21:27:52.306Z',
          updatedAt: '2023-09-04T19:07:31.496Z',
          deletedAt: null,
        },
      ],
    },
  ],
  products: [
    {
      id: 3,
      name: 'Contrato de trabalho',
      createdAt: '2023-08-28T21:27:47.025Z',
      updatedAt: '2023-08-28T21:27:47.025Z',
    },
  ],
});

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

function goToSignUp(sPlan: planType): void {
  storePlans.setSelected(sPlan);
  window.scrollTo(0, 0);
  router.push('/planos/cadastro');
}

const unfPlan = ref<planType[]>([]);

const filteredPlans = computed(() => {
  return unfPlan.value
    ?.filter((x) => {
      return x.toSolutionId === null;
    })
    .sort(function (a, b) {
      return parseFloat(a?.id?.toString()) - parseFloat(b?.id?.toString());
    });
});

// eslint-disable-next-line no-undef
onMounted(async () => {
  unfPlan.value = await storePlans.getPlans();
  planoAvulso.value = storePlans.plans.find((item) => item.toSolutionId === props.solutionId) as planType;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardsContainer, { ContainerTitle: "Formas de Contratação" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(CardsCarousel, {
          plans: filteredPlans.value,
          "is-comparsion": "",
          class: "lg:max-w-xs comparison"
        }, null, 8, ["plans"])
      ])
    ]),
    _: 1
  }))
}
}

})