import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-start gap-14"
}
const _hoisted_2 = {
  key: 1,
  class: "w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-start gap-14"
}
const _hoisted_3 = {
  key: 2,
  class: "w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-center gap-14"
}

import Layout from '@/components/BaseLayout/Layout.vue';
import PlanPriceDisplay from '@/components/Plans/LoginPriceDisplay.vue';
import PlansContratacao from '@/components/Plans/PlansContratacao.vue';
import FormSign from '@/components/SignUp/Form.vue';
import AddressForm from '@/components/SignUp/Address.vue';
import PayForm from '@/components/SignUp/PayForm.vue';
import PaymentConfirm from '@/components/SignUp/PaymentConfirm.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useSignUpStore } from '@/store/signUpStore';
import { watch, onBeforeMount } from 'vue';
import PaymentResume from '@/components/SignUp/PaymentResume.vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanosCadastro',
  setup(__props) {

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const router = useRouter();

watch(() => store.step, (newStep) => {
    if (newStep === 3 && (store.validPayment === 'PROCESSING' || store.validPayment === 'SUCCESS')) {
        router.push('/planos/confirmado');
    }
});

onBeforeMount(() => {
    storePlans.getPlans();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(PlansContratacao),
      (_unref(store).step === 0)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createVNode(FormSign)
          ]))
        : _createCommentVNode("", true),
      (_unref(store).step === 1)
        ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold w-full my-3 text-center text-4xl" }, "Escolha seu plano", -1)),
            _createVNode(PlanPriceDisplay),
            _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold w-full my-3 text-center text-4xl" }, "Detalhes de pagamento", -1)),
            _createVNode(PaymentResume),
            _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "text-adv-blue-800 font-bold w-full my-3 text-center text-4xl" }, "Dados de pagamento", -1)),
            _createVNode(PayForm)
          ]))
        : _createCommentVNode("", true),
      (_unref(store).step === 3)
        ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
            _createVNode(PaymentConfirm)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})