<script setup lang="ts">
import type { planType, serviceType, PlanSolutionBenefitsType } from '@/types/cardTypes';
import GreenTickIcon from '@/components/icons/GreenTickIcon.vue';
import { ref, computed, defineEmits } from 'vue';
import { useSignUpStore } from '@/store/signUpStore';
import { useRoute } from 'vue-router';
// import RedTickIcon from '@/components/icons/RedTickIcon.vue';
const route = useRoute()
interface CardProps {
    id: number | null;
    cardTitle: string;
    toSolutionId: number | null;
    solutions: serviceType[];
    price: number;
    periodicity: number;
    solutionsBenefits: PlanSolutionBenefitsType[];
}

const store = useSignUpStore();

const toggleOpen = (item: number) => {
    const index = opened.value.indexOf(item);

    if (index > -1) {
        return opened.value.splice(index, 1);
    }

    return opened.value.push(item);
};

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = withDefaults(defineProps<CardProps>(), {
    id: null,
    cardTitle: '',
    periodicity: 0,
    toSolutionId: null,
    solutions: () => [],
    products: () => [],
    price: 49.9,
});

const opened = ref<number[]>(props.solutions.map((_, index) => index));

const emit = defineEmits(['goToSignUp']);


const computedSolutions = computed(() => {
    const mappedSolutions = props.solutions.map(solution => {
        const solutionBenefit = props.solutionsBenefits?.find(item => item.solutionId === solution.id);
        return {
            ...solution,
            order: solutionBenefit ? solutionBenefit.order : null
        };
    });


    const sortedSolutions = mappedSolutions.sort((a, b) => a.order - b.order);

    return sortedSolutions;
});


const handleHire = () => {
    if (store.type === 'DAYS')
        store.clearCoupon();

    emit('goToSignUp', {
        id: props.id,
        periodicity: props.periodicity,
        toSolutionId: props.toSolutionId,
        title: props.cardTitle,
        name: props.cardTitle,
        solutions: props.solutions,
        products: props.products,
        price: props.price,
        planSolutionBenefits: props.solutionsBenefits,
    } as planType
    );
};

const findBenefits = (index) => {
    const { id } = computedSolutions.value[index] || {}
    if (!id)
        return
    return props.solutionsBenefits.find(benefits => benefits.solutionId === id)?.benefits || []

}

const allowedPaths = ['/gestor-service', '/planos', '/trabalhista-service', '/pergunte-ao-especialista', '/mentoria-service', '/buscador-service', '/assinador-service'];
const isOpen = allowedPaths.includes(route.path);
</script>

<template>
    <div
        class="flex h-fit w-full flex-col items-center justify-center rounded-3xl bg-adv-blue-900 max-w-xs min-w-[320px]">
        <div class="bg-[#0D1643] w-full rounded-t-3xl p-6 mb-4">
            <span class="bg-[#0C364D] rounded-full px-2 py-0.5">
                <label v-if="periodicity == 1" class=" text-[#34D399] text-xs">Teste grátis!</label>
                <label v-if="periodicity == 6" class=" text-[#34D399] text-xs">Economize 10%</label>
                <label v-if="periodicity == 12" class=" text-[#34D399] text-xs">Economize 20%</label>
            </span>



            <h2 class="my-3 font-Open text-3xl font-bold text-white tracking-wider">
                {{ cardTitle }}
            </h2>
        </div>
        <div class="p-6 pt-0 w-full">


            <!-- <div class="flex h-max w-full flex-col justify-start">
        <div v-for="(service, index) in computedSolutions" :key="index"
          class="my-4 flex flex-col justify-center items-start w-full">
          <div class="flex flex-row items-center cursor-pointer" @click.stop="toggleOpen(index)">
          
            
            <p class="ml-2 font-Open text-xl text-start font-normal text-white">{{ service.name }}</p>
          
          </div>
          <hr class="my-4 w-full border-t border-adv-blue-100" />
          <div v-if="opened.includes(index)"
            class="flex flex-col justify-between items-start gap-1 w-fit">
  
            <span v-for="(benefit, idx) in findBenefits(index)" :key="`bene-$${idx}`"
              class="text-adv-light-blue text-sm flex justify-start items-start w-fit gap-2">
              •
              <p class="text-adv-light-blue text-sm text-start">{{ benefit }}</p>
            </span>
          </div>
        </div>
      </div> -->

            <p v-if="periodicity > 1" class="text-center font-Open text-xl font-normal text-white">{{ periodicity }}x de
            </p>
            <div class="flex justify-center gap-1 mt-4">
                <p class="text-right font-Open text-xl font-extrabold text-white">R$</p>
                <p class="self-start font-Open text-8xl font-extrabold text-white"
                    :style="{ lineHeight: '0.8 !important' }">
                    {{ price.toFixed(2).split('.')[0] }}
                </p>
                <div>
                    <p class="font-Open text-4xl font-extrabold text-white">,{{ price.toFixed(2).split('.')[1] }}</p>
                    <p class="font-Open text-sm font-normal text-white">por mês</p>
                </div>
            </div>
            <p v-if="periodicity > 1" class="font-Open text-sm font-normal text-center text-white mt-6">
                faturado {{ periodicity >= 1 ? (periodicity === 6 ? 'semestralmente' : 'anualmente') : '' }}
            </p>


            <div class="flex justify-center items-center">
                <button type="button" :style="{ letterSpacing: '0.21em' }"
                    class="txt-btn mt-8 flex h-11 w-44 items-center justify-center bg-adv-blue-100 text-white cursor-pointer"
                    @click.prevent.stop="
                        handleHire()
                        ">
                    CONTRATAR
                </button>
            </div>

            <details class="mt-5 mx-2" :open="isOpen">
                <summary class="text-white text-center">Detalhes </summary>
                <div class="flex h-max w-full flex-col justify-start">
                    <div v-for="(service, index) in computedSolutions" :key="index"
                        class="my-4 flex flex-col justify-center items-start w-full">
                        <div class="flex flex-row items-center cursor-pointer" @click.stop="toggleOpen(index)">


                            <p class="ml-2 font-Open text-xl text-start font-normal text-white">{{ service.name }}</p>

                        </div>
                        <hr class="my-4 w-full border-t border-adv-blue-100" />
                        <div v-if="opened.includes(index)"
                            class="flex flex-col justify-between items-start gap-1 w-fit">

                            <span v-for="(benefit, idx) in findBenefits(index)" :key="`bene-$${idx}`"
                                class="text-adv-light-blue text-sm flex justify-start items-start w-fit gap-2">
                                •
                                <p class="text-adv-light-blue text-sm text-start">{{ benefit }}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </details>

        </div>
    </div>
</template>
