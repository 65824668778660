import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { planType } from '@/types/cardTypes';
import Card from '@/components/CardDysplay/Card.vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useRouter } from 'vue-router';

interface CardCarouselProps {
    plans: planType[];
    isComparsion: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardsCarousel',
  props: {
    plans: { default: () => [] },
    isComparsion: { type: Boolean, default: false }
  },
  setup(__props: any) {

const storePlans = useServicePlansStore();
const router = useRouter();

function goToSignUp(sPlan: planType): void {
    storePlans.setSelected(sPlan);
    if (!window.location.href.includes('cadastro')) {
        window.scrollTo(0, 0);
        router.push('/planos/cadastro');
    }
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Carousel), {
    "items-to-show": 1,
    "snap-align": 'center',
    breakpoints: {
        700: {
            itemsToShow: _ctx.isComparsion ? 1 : 2,
        },
        1024: {
            itemsToShow: _ctx.isComparsion ? 1 : 3,
        },
    }
  }, {
    addons: _withCtx(() => [
      _createVNode(_unref(Navigation))
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan, index) => {
        return (_openBlock(), _createBlock(_unref(Slide), { key: index }, {
          default: _withCtx(() => [
            _createVNode(Card, {
              class: _normalizeClass(_unref(storePlans).selectedPlan?.id === plan.id ? 'border-8 border-adv-blue-100 shadow-sm' : ''),
              id: plan.id,
              periodicity: plan.periodicity,
              cardTitle: plan.name,
              price: plan.price,
              solutions: plan.solutions,
              "solutions-benefits": plan.planSolutionBenefits,
              onGoToSignUp: goToSignUp
            }, null, 8, ["class", "id", "periodicity", "cardTitle", "price", "solutions", "solutions-benefits"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["breakpoints"]))
}
}

})