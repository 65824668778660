import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex justify-center items-center mb-24 -mt-3" }
const _hoisted_3 = {
  key: 1,
  class: "contents"
}
const _hoisted_4 = { class: "hidden w-full grid-flow-col justify-center gap-12 lg:gap-5 lg:grid" }

import { computed, onBeforeMount, ref } from 'vue';
import Card from '@/components/CardDysplay/Card.vue';
import CardsCarousel from '@/components/CardDysplay/CardsCarousel.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { planType } from '@/types/cardTypes';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import CardsContainer from '../CardDysplay/CardsContainer.vue';
import { useSignUpStore } from '@/store/signUpStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPriceDisplay',
  setup(__props) {

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const unfPlan = ref<planType[]>([]);

const filteredPlans = computed(() => {
    return unfPlan.value
        ?.filter((x) => {
            return x.toSolutionId === null;
        })
        .filter((y) => {
            return y.periodicity === storePlans.activePer;
        })
        .sort(function (a, b) {
            return parseFloat(a.price.toString()) - parseFloat(b.price.toString());
        });
});

function goToSignUp(sPlan: planType): void {
    storePlans.setSelected(sPlan);
}

function handleActivePer(per: number): void {
    storePlans.activePer = per
}

onBeforeMount(async () => {
    unfPlan.value = await storePlans.getPlans();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["w-fit border-2 border-r-0 border-gray-200 py-2 px-6 rounded-s-full active:bg-adv-blue-100", _unref(storePlans).activePer === 1 ? 'bg-adv-blue-100' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleActivePer(1)))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["text-sm", _unref(storePlans).activePer === 1 ? 'text-white ' : 'text-adv-blue-900'])
        }, "Mensal", 2)
      ], 2),
      _createElementVNode("button", {
        class: _normalizeClass(["w-fit border-2 border-gray-200 border-r-0 py-2 px-6 active:bg-adv-blue-100", _unref(storePlans).activePer === 6 ? 'bg-adv-blue-100' : '']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (handleActivePer(6)))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["text-sm", _unref(storePlans).activePer === 6 ? 'text-white ' : 'text-adv-blue-900'])
        }, "Semestral ", 2)
      ], 2),
      _createElementVNode("button", {
        class: _normalizeClass(["w-fit border-2 border-gray-200 py-2 px-6 rounded-e-full active:bg-adv-blue-100", _unref(storePlans).activePer === 12 ? 'bg-adv-blue-100' : '']),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (handleActivePer(12)))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["text-sm", _unref(storePlans).activePer === 12 ? 'text-white ' : 'text-adv-blue-900'])
        }, "Anual", 2)
      ], 2)
    ]),
    (_unref(storePlans).loading)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(CardsCarousel, {
            plans: filteredPlans.value,
            class: "lg:hidden"
          }, null, 8, ["plans"]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPlans.value, (plan) => {
              return (_openBlock(), _createBlock(Card, {
                class: _normalizeClass(_unref(storePlans).selectedPlan?.id === plan.id ? 'border-8 border-adv-blue-100 shadow-sm' : ''),
                key: plan.id,
                id: plan.id,
                periodicity: plan.periodicity,
                cardTitle: plan.name,
                price: plan.price,
                solutions: plan.solutions,
                "solutions-benefits": plan.planSolutionBenefits,
                onGoToSignUp: goToSignUp
              }, null, 8, ["class", "id", "periodicity", "cardTitle", "price", "solutions", "solutions-benefits"]))
            }), 128))
          ])
        ]))
  ]))
}
}

})