import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "container",
  class: "flex flex-col lg:p-28 p-16"
}
const _hoisted_2 = { class: "grid grid-rows-3 lg:grid-cols-3 lg:grid-rows-none gap-8" }

import PostCard from '@/components/NewsGrid/PostCard.vue';
import { useBlogStore } from '@/store/blogStore';
import { postType } from '@/types/postTypes';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Related',
  setup(__props) {

const router = useRouter();
const blogStore = useBlogStore();

const navToPost = async (selected: postType) => {
  await blogStore.getPostById(selected.id);
  const normTitle = selected.title
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  window.scrollTo(0, 0);
  router.push(`/blog/${selected.id}/${normTitle}`);
};

onMounted(() => {
  blogStore.getPostList();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-4xl text-adv-blue-800 font-bold mb-9" }, "Conteúdo Relacionado", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blogStore).posts.slice(0, 3), (post) => {
        return (_openBlock(), _createBlock(PostCard, {
          key: post.id,
          id: post.id,
          title: post.title,
          cover: post.cover,
          summary: post.summary,
          onGoToPost: navToPost
        }, null, 8, ["id", "title", "cover", "summary"]))
      }), 128))
    ])
  ]))
}
}

})