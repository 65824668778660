<script lang="ts" setup>
import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';
import { useRoute, useRouter } from 'vue-router';
import { useNewsStore } from '@/store/newsStore';

import { lightButtonStyle } from '@/assets/css/styles';
import Related from '@/components/Related/Related.vue';
import { PostNav } from '@/types/postTypes';

const newsStore = useNewsStore();
const router = useRouter();
const route = useRoute();

const navToPost = (item: { id: number; slug: string }) => {
  window.scrollTo(0, 0);
  router.push(`/blog/${item.id}/${item.slug}`);
  newsStore.getNewsById(Number(item.id));
};

onMounted(() => {
  newsStore.getNewsById(Number(route.params.id));
});
</script>

<template>
  <Layout>
    <div class="flex flex-col lg:flex-row justify-center gap-12 items-center lg:items-start lg:p-28 p-16">
      <LoadingSpinner v-show="newsStore.loading" color="#4375D9" />
      <article
        v-show="!newsStore.loading"
        class="h-max w-full flex gap-12 justify-center flex-col items-start lg:pr-12"
      >
        <p class="font-light italic text-sm text-adv-blue-800">
          Início / Notícias / {{ newsStore.selectedPost?.title }}
        </p>
        <img :src="newsStore.selectedPost?.cover" alt="Imagem de Capa" class="max-h-96 w-full object-cover" />
        <h2 class="font-bold text-adv-blue-800 text-4xl">{{ newsStore.selectedPost?.title }}</h2>
        <div
          class="font-sm text-black text-justify lg:text-left post-content"
          v-html="newsStore.selectedPost?.content"
        />
        <div class="flex flex-col md:flex-row justify-between items-center gap-3 w-full">
          <button
            type="button"
            :class="lightButtonStyle"
            class="mt-3 max-w-[12rem]"
            :disabled="!newsStore.selectedPost?.prev"
            @click="navToPost(newsStore.selectedPost?.prev as PostNav)"
          >
            &lt; ANTERIOR
          </button>
          <button
            type="button"
            :class="lightButtonStyle"
            class="mt-3 max-w-[12rem]"
            :disabled="!newsStore.selectedPost?.next"
            @click="navToPost(newsStore.selectedPost?.next as PostNav)"
          >
            PRÓXIMO &gt;
          </button>
        </div>
      </article>
    </div>
    <Related />
  </Layout>
</template>
