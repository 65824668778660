import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "h-max w-full flex gap-12 justify-center flex-col items-center lg:p-28 p-16" }
const _hoisted_2 = ["disabled"]

import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import GridContainer from '@/components/NewsGrid/GridContainer.vue';
import NewsCard from '@/components/NewsGrid/NewsCard.vue';
import { useNewsStore } from '@/store/newsStore';
import { lightButtonStyle } from '@/assets/css/styles';
import { newsType } from '@/types/postTypes';
import { useRouter } from 'vue-router';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const router = useRouter();

const newsStore = useNewsStore();

const navToPost = (selected: newsType) => {
  const normTitle = selected.title
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  window.scrollTo(0, 0);
  router.push(`/noticias/${selected.id}/${normTitle}`);
  console.log('normalized title', normTitle);
};

onMounted(() => {
  newsStore.getNewsList();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(Title, {
        height: 28,
        imgUrl: "url('@/assets/img/bg-news-title.svg')"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h1", { class: "z-10 font-Open text-6xl font-bold text-white" }, "Notícias", -1)
        ])),
        _: 1
      }),
      _createElementVNode("section", _hoisted_1, [
        (!_unref(newsStore).loading || _unref(newsStore).news.length > 0)
          ? (_openBlock(), _createBlock(GridContainer, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(newsStore).news, (post) => {
                  return (_openBlock(), _createBlock(NewsCard, {
                    key: post.id,
                    id: post.id,
                    title: post.title,
                    cover: post.cover,
                    summary: post.summary,
                    status: post.status,
                    createdAt: post.createdAt,
                    updatedAt: post.updatedAt,
                    deletedAt: post.deletedAt,
                    onGoToPost: navToPost
                  }, null, 8, ["id", "title", "cover", "summary", "status", "createdAt", "updatedAt", "deletedAt"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          type: "button",
          disabled: _unref(newsStore).loading,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(newsStore).getMoreNews && _unref(newsStore).getMoreNews(...args))),
          class: _normalizeClass([_unref(lightButtonStyle), "mt-3"])
        }, [
          _withDirectives(_createElementVNode("p", null, "CARREGAR MAIS", 512), [
            [_vShow, !_unref(newsStore).loading]
          ]),
          _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
            [_vShow, _unref(newsStore).loading]
          ])
        ], 10, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}
}

})