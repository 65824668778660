<template>
  <Layout>
    <Title :height="28" imgUrl="url('@/assets/img/bg-planos-title.svg')">
      <h1 class="font-Open text-6xl font-bold text-white z-10">
        Tudo o que você precisa <br />
        em um único lugar.
      </h1>
    </Title>
    <PlansCards />
    <Solutions title="Clique para conhecer melhor nossas soluções" />
    <Faq title="Perguntas Frequentes" :questions="faqStore.questions" />
  </Layout>
</template>

<script lang="ts" setup>
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import PlansCards from '@/components/Plans/PlansCards.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Faq from '@/components/Faq/Faq.vue';
import { useFaqStore } from '@/store/faqStore';

const faqStore = useFaqStore();
</script>
