<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { lightButtonStyle } from '@/assets/css/styles';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { http } from '@/core/api';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

interface SolutionsProps {
  title: string;
}

type SolutionObj = {
  id: number;
  active: boolean;
  description: string;
  image: string;
  name: string;
  serviceLink: string;
};

const router = useRouter();
const solutions = ref<SolutionObj[]>([]);
const loading = ref(false);

const textPClass = 'text-adv-gray-500 text-center my-10 text-base';
const routeRoll = (route: string): void => {
  window.scrollTo(0, 0);
  router.push(route);
};

async function getSolutionsList() {
  loading.value = true;
  try {
    const { data } = await http.get('solutions/all?active=true');

    solutions.value = data.body.result;
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
    console.log(solutions.value);
  }
}
// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = defineProps<SolutionsProps>();

onBeforeMount(async () => {
  await getSolutionsList();
});
</script>

<template>
  <section
    class="h-max w-full flex justify-center flex-col items-center lg:p-28 py-16 overflow-hidden bg-[#F4F7FF]"
  >
    <h2 class="font-bold text-4xl text-adv-blue-800 mb-16 max-w-lg text-center tracking-wider">{{ title }}</h2>
    <LoadingSpinner v-if="loading" />
    <Splide
      v-else
      :extensions="{ AutoScroll }"
      :options="{
        clones: 4,
        trimSpace: 'move',
        omitEnd: true,
        autoplay: false,
        pagination: false,
        arrows: false,
        type: 'loop',
        drag: 'free',
      }"
      aria-label="Soluções"
    >
      <SplideSlide v-for="(sol, index) in solutions" :key="index">
        <div class="flex flex-col items-center h-[400px] bg-white p-10 rounded-2xl border-2 mr-3">
          <img :src="sol.image" :alt="sol.name" class="max-w-[300px] max-h-[60px] px-10" />
          <p :class="textPClass">
            {{ sol.description }}
          </p>
          <div class="mt-auto">

            <button
              @click="
                routeRoll(
                  sol.name
                    .replace(/ /g, '-')
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                )
              "
              :class="lightButtonStyle"
            >
              ACESSAR
            </button>
          </div>
        </div>
      </SplideSlide>
    </Splide>
  </section>
</template>

<style lang="scss">
.splide__list {
  max-width: 100vw;
}
.splide__slide {
  max-width: 470px;
}
</style>
