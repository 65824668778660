import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

interface IProps {
  color?: string;
  width?: string;
  height?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'GreenTickIcon',
  props: {
    color: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width ? _ctx.width : 28,
    height: _ctx.height ? _ctx.height : 29,
    viewBox: "0 0 28 29",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M13.961 29C21.6715 29 27.922 22.6731 27.922 14.8684C27.922 7.06374 21.6715 0.736816 13.961 0.736816C6.25055 0.736816 0 7.06374 0 14.8684C0 22.6731 6.25055 29 13.961 29Z",
      fill: _ctx.color || '#3AB54A'
    }, null, 8, _hoisted_2),
    _cache[0] || (_cache[0] = _createElementVNode("path", {
      d: "M11.1454 22.1252C10.6119 22.1647 10.1712 21.9318 9.79063 21.5695C8.28299 20.1318 6.7738 18.6958 5.27631 17.2479C4.45425 16.4529 4.39185 15.3239 5.10004 14.5542C5.82695 13.7639 6.98438 13.7339 7.81658 14.5084C8.78371 15.4084 9.74382 16.3171 10.6829 17.2463C10.9543 17.5147 11.1025 17.5439 11.3848 17.2495C14.0959 14.4239 16.8226 11.6142 19.5477 8.8013C19.9229 8.41367 20.2707 7.98419 20.8222 7.83261C21.698 7.59182 22.5451 7.90919 22.9787 8.63867C23.4108 9.36656 23.3094 10.2839 22.6847 10.9424C21.2613 12.4431 19.8262 13.9313 18.3919 15.421C16.4646 17.4223 14.5327 19.4189 12.6062 21.4202C12.2061 21.8387 11.7662 22.1592 11.1454 22.1252Z",
      fill: "white"
    }, null, -1))
  ], 8, _hoisted_1))
}
}

})