<template>
  <section
    :class="[props.section.reverse ? '!bg-[#E7EEFF] rounded-tr-[100px]' : '']"
    class="flex flex-col lg:flex-row items-center justify-center lg:justify-center py-16 bg-[#F4F7FF]"
  >
    <div class="flex flex-col lg:flex-row items-center gap-10 lg:gap-20" :class="[props.section.reverse ? 'lg:!flex-row-reverse' : '']">
      <div class="flex flex-col lg:items-start px-4">
        <p class="text-[#4375D9] max-w-lg tracking-wider">{{ props.section.plan }}</p>
        <h1 class="font-bold text-4xl text-adv-blue-800 mb-4 max-w-lg">
          {{ props.section.title }}
        </h1>
        <p class="text-justify max-w-lg mb-6">{{ props.section.text }}</p>
        <div class="flex justify-center gap-4">
          <button type="button" :class="lightButtonStyle" class="lg:whitespace-nowrap" @click="router.push(props.section.link)">
            SAIBA MAIS
          </button>
        </div>

      </div>
      <div :class="[props.section.reverse ? 'pr-6 lg:pr-0' : 'pl-6 lg:pl-0']">
        <img :src="require(`@/assets/img/${props.section.image}`)" alt="ADVSERVICE" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { defineProps, h } from 'vue';
import { lightButtonStyle } from '@/assets/css/styles';
import { useRouter } from 'vue-router';

const router = useRouter();

interface IProps {
  section: {
    id: number;
    plan: string;
    title: string;
    text: string;
    image: string;
    link: string;
    reverse: boolean;
  };
}

const props = defineProps<IProps>();
</script>

<style scoped></style>
