<script lang="ts" setup>
import Head from '@/components/Header/Head.vue';
import PlanPriceDisplay from '@/components/Plans/LoginPriceDisplay.vue';
import FormSign from '@/components/SignUp/Form.vue';
import PayForm from '@/components/SignUp/PayForm.vue';
import AddressForm from '@/components/SignUp/Address.vue';
import PaymentConfirm from '@/components/SignUp/PaymentConfirm.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useSignUpStore } from '@/store/signUpStore';
import { watch, onBeforeMount, onUnmounted } from 'vue';
import PaymentResume from '@/components/SignUp/PaymentResume.vue';
import { useRouter } from 'vue-router';

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const router = useRouter();


watch(() => store.step, (newStep) => {
    if (newStep === 3 && (store.validPayment === 'PROCESSING' || store.validPayment === 'SUCCESS')) {
        router.push('/planos/confirmado');
    }
});

onBeforeMount(() => {
    storePlans.getPlans();
});
onUnmounted(() => {
    store.clearForm();
});
</script>

<template>
    <div
        class="w-full h-screen overflow-auto overflow-x-hidden flex flex-col gap-8 bg-[url('@/assets/img/bg-login.svg')] bg-cover">

        <Head />
        <main class="w-full flex flex-col justify-center items-center gap-20">
            <img src="@/assets/img/adv-login-logo.svg" alt="login logo" class="h-28 w-auto hidden z-10 lg:block" />
            <section v-if="store.step === 0"
                class="max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14">
                <FormSign />
            </section>
            <!-- <section
        v-if="store.step === 1"
        class="max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14"
      >
        <AddressForm />
      </section> -->
            <section v-if="store.step === 1" class="w-full p-6 flex flex-col justify-center items-center gap-14">
                <div
                    class="max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14">
                    <h3 class="text-adv-blue-800 font-bold text-center text-2xl">Escolha seu plano</h3>
                    <PlanPriceDisplay />
                </div>
                <div
                    class="max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14">
                    <h3 class="text-adv-blue-800 font-bold text-center text-2xl">Detalhes de pagamento</h3>
                    <PaymentResume />
                </div>
                <div
                    class="max-w-5xl w-full rounded-3xl p-6 lg:p-12 flex flex-col justify-center bg-white items-center gap-14">
                    <PayForm />
                </div>
            </section>
            <section v-if="store.step === 3"
                class="w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-center gap-14">
                <PaymentConfirm />
            </section>
        </main>
    </div>
</template>
