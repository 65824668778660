import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, vShow as _vShow, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_6 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_7 = { class: "w-full" }
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_10 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_11 = { class: "w-full" }
const _hoisted_12 = { class: "mt-1 text-xs text-rose-500" }
const _hoisted_13 = { class: "flex gap-5 w-full flex-col md:flex-row" }
const _hoisted_14 = { class: "w-full flex justify-start items-center gap-2" }
const _hoisted_15 = {
  class: "text-[#A8A8A8] font-bold tracking-widest text-sm",
  for: "terms"
}
const _hoisted_16 = ["disabled"]

import { onMounted } from 'vue';
import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import { formInput, lightButtonStyle } from '@/assets/css/styles';
import { useSignUpStore } from '@/store/signUpStore';
import { useModalStore } from '@/store/modalStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';
import TermsModal from '@/components/Terms/terms.vue';
import PrivacyModal from '@/components/Terms/privacy.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Form',
  setup(__props) {

const store = useSignUpStore();
const modalStore = useModalStore();

const validationSchema = toTypedSchema(
    zod
        .object({
            name: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório'),
            email: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório')
                .email('Digite um email válido'),
            phone: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório'),
            // confirmEmail: zod
            //     .string({
            //         required_error: 'Campo obrigatório',
            //     })
            //     .nonempty('Campo obrigatório')
            //     .email('Digite um email válido'),
            // password: zod
            //     .string({
            //         required_error: 'Campo obrigatório',
            //     })
            //     .nonempty('Campo obrigatório')
            //     .min(8, 'Sua senha deve conter, ao menos, 8 caracteres'),
            // passwordCheck: zod.string(),
            acceptTerms: zod.boolean(),
            cpf: zod
                .string({
                    required_error: 'Campo obrigatório',
                })
                .nonempty('Campo obrigatório'),
        })
    // .refine((data) => data.password === data.passwordCheck, {
    //     message: 'As senhas devem ser iguais',
    //     path: ['passwordCheck'],
    // })
    // .refine((data) => data.email === data.confirmEmail, {
    //     message: 'Os emails devem ser iguais',
    //     path: ['confirmEmail'],
    // })
);
const { handleSubmit, errors } = useForm({
    validationSchema,
});

const { value: name } = useField('name');
const { value: email } = useField('email');
const { value: phone } = useField('phone');
// const { value: confirmEmail } = useField('confirmEmail');
// const { value: password } = useField('password');
// const { value: passwordCheck } = useField('passwordCheck');
const { value: cpf } = useField('cpf');
const { value: acceptTerms } = useField('acceptTerms');

const handleSignup = handleSubmit(async (values) => {
    if (store.loading) return;

    store.loading = true;

    try {
        await store.submitUserSignUp(values);
    } catch (err) {
        console.error(err);
    } finally {
        store.loading = false;
    }
});

onMounted(() => {
    store.getOcupations();
});

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[17] || (_cache[17] = _createElementVNode("h2", { class: "text-[#A8A8A8] text-2xl font-normal tracking-widest" }, [
      _createElementVNode("strong", { class: "text-adv-blue-800 font-bold" }, "Sobre você"),
      _createTextVNode(" — Finalizar cadastro ")
    ], -1)),
    _createElementVNode("form", {
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_unref(handleSignup) && _unref(handleSignup)(...args)), ["prevent"])),
      class: "w-full gap-8 flex flex-col"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, [
            _createTextVNode(" Nome Completo "),
            _createElementVNode("strong", { class: "text-red-600" }, "*")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(name) ? (name).value = $event : null)),
            class: _normalizeClass(_unref(formInput) + `${_unref(errors).name ? 'border-2 border-rose-500' : ''}`)
          }, null, 2), [
            [_vModelText, _unref(name)]
          ]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(errors).name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, [
            _createTextVNode("CPF "),
            _createElementVNode("strong", { class: "text-red-600" }, "*")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            "data-maska": "###.###.###-##",
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(cpf) ? (cpf).value = $event : null)),
            class: _normalizeClass(_unref(formInput) + `${_unref(errors).cpf ? 'border-2 border-rose-500' : ''}`)
          }, null, 2), [
            [_directive_maska],
            [_vModelText, _unref(cpf)]
          ]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(errors).cpf), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, "OAB", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(store).signUpForm.oab) = $event)),
            class: _normalizeClass(_unref(formInput))
          }, null, 2), [
            [_vModelText, _unref(store).signUpForm.oab]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, [
            _createTextVNode(" Telefone "),
            _createElementVNode("strong", { class: "text-red-600" }, "*")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            "data-maska": "['(##) ####-####', '(##) #####-####']",
            type: "text",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(phone) ? (phone).value = $event : null)),
            class: _normalizeClass(_unref(formInput) + `${_unref(errors).phone ? 'border-2 border-rose-500' : ''}`)
          }, null, 2), [
            [_directive_maska],
            [_vModelText, _unref(phone)]
          ]),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(errors).phone), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-[#A8A8A8] font-bold tracking-widest text-xl mb-4" }, [
            _createTextVNode(" E-mail "),
            _createElementVNode("strong", { class: "text-red-600" }, "*")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            name: "email",
            type: "email",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(email) ? (email).value = $event : null)),
            class: _normalizeClass(_unref(formInput) + `${_unref(errors).email ? 'border-2 border-rose-500' : ''}`)
          }, null, 2), [
            [_vModelText, _unref(email)]
          ]),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(errors).email), 1)
        ])
      ]),
      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex gap-5 w-full flex-col md:flex-row" }, null, -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(acceptTerms) ? (acceptTerms).value = $event : null)),
            type: "checkbox",
            id: "terms",
            name: "terms"
          }, null, 512), [
            [_vModelCheckbox, _unref(acceptTerms)]
          ]),
          _createElementVNode("label", _hoisted_15, [
            _cache[14] || (_cache[14] = _createTextVNode("Eu aceito e concordo com os ")),
            _createElementVNode("a", {
              class: "text-gray-700 cursor-pointer",
              onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_unref(modalStore).termsModalOpen = true), ["prevent"]))
            }, "termos de uso"),
            _cache[15] || (_cache[15] = _createTextVNode(" e a ")),
            _createElementVNode("a", {
              class: "text-gray-700 cursor-pointer",
              onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_unref(modalStore).privacyModalOpen = true), ["prevent"]))
            }, "política de privacidade")
          ])
        ])
      ]),
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass([_unref(lightButtonStyle), "w-full flex justify-center items-center"]),
        disabled: !_unref(acceptTerms)
      }, [
        _withDirectives(_createElementVNode("p", null, "AVANÇAR", 512), [
          [_vShow, !_unref(store).loading]
        ]),
        _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
          [_vShow, _unref(store).loading]
        ])
      ], 10, _hoisted_16),
      _createVNode(TermsModal),
      _createVNode(PrivacyModal)
    ], 32)
  ], 64))
}
}

})