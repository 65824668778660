import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[32rem] bg-adv-gray-300 flex flex-col" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "px-10 py-6 flex justify-between items-start flex-col h-full" }
const _hoisted_4 = { class: "text-adv-blue-800 font-bold text-xl mb-2" }
const _hoisted_5 = { class: "font-FuturaBkBt text-xs text-adv-gray-500" }

import { postType } from '@/types/postTypes';

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'PostCard',
  props: {
    id: { default: 0 },
    title: { default: '' },
    author: { default: '' },
    resume: { default: '' },
    highlight: { type: Boolean, default: false },
    cover: { default: '' },
    status: { default: '' },
    description: { default: '' },
    summary: { default: '' },
    slug: { default: '' },
    categories: { default: {
    id: 0,
    title: '',
    status: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  } },
    content: { default: '' },
    createdAt: { default: '' },
    updatedAt: { default: '' },
    deletedAt: { default: '' },
    prev: {},
    next: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: props.cover,
      alt: "post img",
      class: "w-full max-h-48 object-cover"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.summary), 1),
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.$emit('goToPost', {
            id: props.id,
            title: props.title,
            cover: props.cover,
            summary: props.summary,
          } as postType)
        )),
        class: "font-FuturaBkBt text-sm text-adv-blue-100 cursor-pointer"
      }, "Ler mais >")
    ])
  ]))
}
}

})