<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<!-- eslint-disable no-undef -->
<script setup lang="ts">
import Modal from '@/components/Modal/index.vue';
import { useModalStore } from '@/store/modalStore';

const store = useModalStore();
</script>

<template>
  <Modal modal-title="Termos de Uso" :open="store.termsModalOpen" @close="store.termsModalOpen = false">
    <template #modal-content>
      <div class="flex flex-col justify-center items-center gap-12 w-full mt-12">
        <div class="w-full">
          <div class="border md:w-full max-h-[80vh] lg:max-h-[300px] overflow-auto">
            <div class="p-0 md:p-6">
              <p><strong>TERMOS E CONDI&Ccedil;&Otilde;ES DE USO DO SAAS DO ADV SERVICE</strong></p>
              <br />
              <ol>
                <li><strong> Introdu&ccedil;&atilde;o</strong></li>
              </ol>
              <p>
                1.1. Estes s&atilde;o os Termos de Uso (&ldquo;Termos de Uso&rdquo;) que regem as finalidades os uso e
                os limites de todo e qualquer acesso e utiliza&ccedil;&atilde;o da Plataforma Digital denominada Adv
                Service (a &ldquo;Plataforma&rdquo;), por qualquer pessoa que utilize suas funcionalidades na qualidade
                de usu&aacute;rio, doravante denominado simplesmente &ldquo;Usu&aacute;rio(s) )&rdquo;.
              </p>
              <br />
              <p>
                1.2. O site e todo o seu conte&uacute;do (o &ldquo;Site&rdquo;) e a Plataforma Digital foram
                desenvolvidos e s&atilde;o operados pela TRABALHISTA SERVICE LTDA., pessoa jur&iacute;dica de direito
                privado, sociedade empresaria de responsabilidade limitada, inscrita no CNPJ sob n&ordm;
                41.867.655/0001-49, com sede na Rua Jo&atilde;o Zwetsch, n.&nbsp;80, apto 702, bairro Nossa Senhora das
                Dores, no Munic&iacute;pio de Santa Maria/RS, como permite o art.&nbsp;1.142, &sect; 2&ordm; do
                C&oacute;digo C&iacute;vil (&ldquo;CC&rdquo;).
              </p>
              <br />
              <p>
                &nbsp;1.3. &Eacute; imprescind&iacute;vel que o Usu&aacute;rio siga nossos Termos de Uso e
                conhe&ccedil;a nossa Pol&iacute;tica de Privacidade para navegar na plataforma digital Adv Service e
                utilizar as ferramentas dispon&iacute;veis, uma vez que determinadas atividades implicam no tratamento
                dos dados pessoais do Usu&aacute;rio.
              </p>
              <br />
              <p>
                1.4. A Pol&iacute;tica de Privacidade tamb&eacute;m integra estes Termos de Uso e dever&aacute; ser lida
                com aten&ccedil;&atilde;o e consentida pelo Usu&aacute;rio como requisito para a
                utiliza&ccedil;&atilde;o da plataforma e dos servi&ccedil;os da Adv Service. Estes termos se aplicam a
                todas as marcas, produtos e servi&ccedil;os da Adv Service, os quais n&atilde;o t&ecirc;m
                pol&iacute;ticas de privacidade separadas e est&atilde;o vinculados a este termo e a pol&iacute;tica de
                privacidade.
              </p>
              <br />
              <p>
                1.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ao cadastrar-se, o Usu&aacute;rio concorda com a &iacute;ntegra dos
                Termos de Uso aqui dispostos. Caso o respectivo interessado n&atilde;o concorde com os termos
                estabelecidos n&atilde;o dever&aacute; realizar o cadastro e utilizar a plataforma. Al&eacute;m disso, o
                Usu&aacute;rio n&atilde;o poder&aacute; se escusar dos termos, alegando ignor&acirc;ncia sobre as suas
                condi&ccedil;&otilde;es, e que ao cadastrar-se n&atilde;o leu a integra, inclusive quanto a eventuais
                modifica&ccedil;&otilde;es introduzidas.
              </p>
              <br />
              <p>
                1.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A Adv Service n&atilde;o estimula ou realiza qualquer forma de
                capta&ccedil;&atilde;o de causa ou clientela para servi&ccedil;os advocat&iacute;cios, nem afronta
                qualquer dispositivo da Lei Federal n&ordm;. 8.906/94 (Estatuto da Advocacia) e do C&oacute;digo de
                &Eacute;tica e Disciplina da OAB.
              </p>
              <br />
              <ol start="2">
                <li><strong> Cadastro</strong></li>
              </ol>
              <p>
                2.1.&nbsp;<em>Cadastro.&nbsp;</em>O acesso e a utiliza&ccedil;&atilde;o de quaisquer servi&ccedil;os da
                Adv Service depender&atilde;o da realiza&ccedil;&atilde;o de cadastro, ades&atilde;o ao presente Termo
                de Uso, contrata&ccedil;&atilde;o de um dos planos ofertados e a realiza&ccedil;&atilde;o do respectivo
                pagamento pelo uso das ferramentas disponibilizadas.
              </p>
              <br />
              <p>
                2.1.1.&nbsp;<em>Cadastro Pessoal</em>.&nbsp;O cadastro pessoal ser&aacute; o perfil na Plataforma que
                identificar&aacute; cada Usu&aacute;rio.&nbsp;Para a realiza&ccedil;&atilde;o do cadastro pessoal na
                Plataforma, o Usu&aacute;rio dever&aacute; indicar o nome de usu&aacute;rio e senha, bem como fornecer
                informa&ccedil;&otilde;es pessoais, como nome, sobrenome, e-mail, CPF, telefone, data de nascimento,
                sexo, endere&ccedil;o, a depender do caso, n&uacute;mero de identidade profissional, dentre outros.
              </p>
              <br />
              <p>
                2.1.1.1. No caso de o Usu&aacute;rio assinalar sua profiss&atilde;o como advogado, ser&aacute;
                necess&aacute;rio fornecer seu n&uacute;mero v&aacute;lido de inscri&ccedil;&atilde;o na Ordem dos
                Advogados do Brasil (&ldquo;OAB&rdquo;).
              </p>
              <br />
              <p>
                2.2. <em>Conv&ecirc;nios. </em>Havendo algum cadastro via conv&ecirc;nio ou parceria da Adv Service com
                alguma entidade ou organiza&ccedil;&atilde;o, ser&aacute; exigido no preenchimento do cadastro a
                apresenta&ccedil;&atilde;o do &ldquo;cupom&rdquo; ou &ldquo;conv&ecirc;nio&rdquo;, para o fim de
                obten&ccedil;&atilde;o do eventual desconto ao Usu&aacute;rio.
              </p>
              <br />
              <p>
                2.2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cupons de desconto e/ou conv&ecirc;nios que a Adv Service possa vir
                a criar ter&atilde;o benef&iacute;cios e crit&eacute;rios de cobran&ccedil;as pr&oacute;prios,
                n&atilde;o ficando adstrito as informa&ccedil;&otilde;es dos planos padr&otilde;es de assinatura
                dispon&iacute;veis na p&aacute;gina inicial da Adv Service. Todas informa&ccedil;&otilde;es do
                cupom/conv&ecirc;nio ser&atilde;o disponibilizadas no momento do cadastro para o usu&aacute;rio, que
                poder&aacute; escolher em assinar ou n&atilde;o o servi&ccedil;o.
              </p>
              <br />
              <p>
                2.3.&nbsp;<em>Informa&ccedil;&otilde;es fornecidas</em>.&nbsp;A Adv Service poder&aacute; se valer de
                todas as formas l&iacute;citas para verificar, a qualquer tempo, se as informa&ccedil;&otilde;es que o
                Usu&aacute;rio forneceu s&atilde;o verdadeiras. Se for constatado que as informa&ccedil;&otilde;es
                s&atilde;o de alguma forma incompletas, imprecisas ou falsas, o USU&Aacute;RIO poder&aacute; ter o seu
                cadastro suspenso ou cancelado, a exclusivo crit&eacute;rio da Adv Service, sem preju&iacute;zos de
                outras medidas que sejam aplic&aacute;veis, caso em que n&atilde;o ser&atilde;o devidos quaisquer
                reembolsos. Ainda, se for constatado dois ou mais cadastros de mesmo Usu&aacute;rio tentando obter
                acesso cont&iacute;nuo e permanente ao per&iacute;odo gr&aacute;tis, ser&aacute; de imediato autorizado
                a cobran&ccedil;a equivalente ao plano de menor valor em todos cadastros feitos pelo Usu&aacute;rio.
              </p>
              <br />
              <p>
                2.5.&nbsp;<em>Limites aplic&aacute;veis ao Cadastro</em>&nbsp;.&nbsp;&Eacute; vedada a
                cria&ccedil;&atilde;o de nome de usu&aacute;rio e senha com a utiliza&ccedil;&atilde;o de nome e
                informa&ccedil;&otilde;es de outra pessoa, com a utiliza&ccedil;&atilde;o de marcas registradas ou
                propriedade de outra parte (incluindo o Adv Service) ou vulgar, obsceno ou de qualquer outra forma
                inadequada.&nbsp;O Adv Service reserva-se o direito, com ou sem aviso pr&eacute;vio, de suspender ou
                encerrar qualquer contato que viole esta cl&aacute;usula.
              </p>
              <br />
              <p>
                2.6.&nbsp;<em>Veda&ccedil;&atilde;o de compartilhamento de conta.&nbsp;</em>O login e senha s&atilde;o
                pessoais e intransfer&iacute;veis, comprometendo-se o Usu&aacute;rio a n&atilde;o os divulgar ou
                compartilhar com terceiros, sob pena de cancelamento da conta criada. O usu&aacute;rio se compromete a
                n&atilde;o informar seus dados cadastrais e/ou de acesso &agrave; plataforma a terceiros,
                responsabilizando-se integralmente pelo uso que deles seja feito.
              </p>
              <br />
              <p>
                2.7.&nbsp;<em>Limita&ccedil;&atilde;o ao uso simult&acirc;neo de conta.&nbsp;</em>Somente ser&aacute;
                permitido o uso simult&acirc;neo da Plataforma em um dispositivo fixo (computadores e laptops) e um
                dispositivo m&oacute;vel (celulares e tablets) vinculado ao cadastro do Usu&aacute;rio.&nbsp;Caso seja
                verificada tentativa de m&uacute;ltiplos acessos simult&acirc;neos com as credenciais do Usu&aacute;rio,
                ser&aacute; imediatamente encerrado o acesso anterior em dispositivo semelhante. Exce&ccedil;&atilde;o
                apenas a exist&ecirc;ncia de subcontas de usu&aacute;rios decorrentes da contrata&ccedil;&atilde;o do
                Gestor Service, conforme o n&uacute;mero de contas previstas no respectivo plano contratado.
              </p>
              <br />
              <p>
                2.8.&nbsp;<em>Informa&ccedil;&otilde;es de Acesso.&nbsp;</em>A conta &eacute; pessoal e poder&aacute;
                ser acessada unicamente mediante a utiliza&ccedil;&atilde;o de login e senha criada pelo pr&oacute;prio
                usu&aacute;rio no momento do cadastro, sendo o &uacute;nico e exclusivo respons&aacute;vel por manter o
                sigilo de seu login e senha, a fim de garantir a seguran&ccedil;a de sua conta e impedir o acesso
                n&atilde;o autorizado por terceiros.&nbsp;O Usu&aacute;rio &eacute; o &uacute;nico respons&aacute;vel
                por todas as atividades associadas &agrave; sua conta.&nbsp;Em caso de perda, extravio ou suspeita de
                utiliza&ccedil;&atilde;o indevida de sua conta, login ou senha, o Adv Service dever&aacute; ser
                comunicado imediatamente para que sejam tomadas as medidas cab&iacute;veis.&nbsp;Caso seja verificada a
                duplicidade de contas, o Adv Service poder&aacute; inabilitar de forma definitiva todos os cadastros
                duplicados, independentemente de qualquer notifica&ccedil;&atilde;o pr&eacute;via.
              </p>
              <br />
              <p>
                2.9.&nbsp;<em>Autoriza&ccedil;&atilde;o para uso das informa&ccedil;&otilde;es.&nbsp;</em>Os dados
                pessoais informados pelo Usu&aacute;rio, bem como os dados disponibilizados durante o uso dos
                servi&ccedil;os, ser&atilde;o tratados pela Adv Service estritamente em conformidade com a sua
                Pol&iacute;tica de Privacidade.
              </p>
              <br />
              <ol start="3">
                <li><strong> Das Responsabilidades do Usu&aacute;rio</strong></li>
              </ol>
              <p>
                3.1. A plataforma digital Adv Service oferece funcionalidades que permitam o carregamento (upload) de
                materiais de texto, imagem e/ou outros dados pr&oacute;prios e de terceiros. Em raz&atilde;o disso, o
                Usu&aacute;rio aceita, reconhece e compromete-se a n&atilde;o carregar (upload) qualquer conte&uacute;do
                il&iacute;cito, imoral ou ilegal ou material que viole a propriedade intelectual da Adv Service ou de
                terceiros, tampouco viole o direito ao sigilo de informa&ccedil;&otilde;es e dados de qualquer pessoa,
                seja f&iacute;sica ou jur&iacute;dica. Caso seja identificada qualquer viola&ccedil;&atilde;o a este
                dispositivo a conta do Usu&aacute;rio ser&aacute; imediatamente bloqueada, independente de aviso
                pr&eacute;vio.
              </p>
              <br />
              <p>
                3.2. Sem preju&iacute;zo de outras obriga&ccedil;&otilde;es previstas neste instrumento, &eacute; de
                responsabilidade do usu&aacute;rio:
              </p>
              <br />
              <p>
                3.2.1. Fazer uso das funcionalidades e ferramentas da Plataforma em acordo com os termos de uso,
                respeitando todas e quaisquer regras do ordenamento jur&iacute;dico nacional, n&atilde;o havendo
                possibilidade de negar ci&ecirc;ncia sobre sua responsabilidade legal por todas as suas condutas e
                a&ccedil;&otilde;es executadas na plataforma, seja por si ou por terceiros, quando fornecido os dados de
                acesso de forma indevida.
              </p>
              <br />
              <p>
                3.2.2. Operar a plataforma por meio de equipamentos de hardware necess&aacute;rios ao seu acesso e
                correta utiliza&ccedil;&atilde;o, incluindo todas as demais ferramentas oferecidas pela Adv Service;
              </p>
              <br />
              <p>
                3.2.3. N&atilde;o aproveitar a plataforma como ferramenta, meio ou instrumento de quaisquer atos
                il&iacute;citos, sejam determinados atos, proibidos pelo presente Termos de Uso ou por
                legisla&ccedil;&atilde;o especifica;
              </p>
              <br />
              <p>
                3.2.4. N&atilde;o utilizar a plataforma com finalidade para quaisquer atos ou procedimentos que possam
                causar dano a direitos de terceiros ou da pr&oacute;pria Adv Service, incluindo a&ccedil;&otilde;es que
                possam vir a prejudicar o correto funcionamento da plataforma ou de suas ferramentas.
              </p>
              <br />
              <p>
                3.2.5. Observar todas as disposi&ccedil;&otilde;es, regras e orienta&ccedil;&otilde;es que constam no
                presente Termo de Uso e cumpri-las, sem exce&ccedil;&atilde;o;
              </p>
              <br />
              <p>
                3.2.6. Jamais executar download de materiais, conte&uacute;dos e/ou arquivos, com exce&ccedil;&atilde;o
                das possibilidades oferecidas ao Usu&aacute;rio pelo site.
              </p>
              <br />
              <p>
                3.2.7. Possuir conduta que n&atilde;o permita acessos indevidos e vetados a plataforma. O
                compartilhamento de dados que possibilitem o acesso de terceiros a plataforma configura grave
                viola&ccedil;&atilde;o ao presente termo, sendo expressamente proibido. Caso seja verificada o
                compartilhamento indevido de tais dados, como login e acesso, a Adv Service ir&aacute; executar o
                exerc&iacute;cio regular de seu direito de prote&ccedil;&atilde;o, impedindo o acesso de forma
                definitiva de conta(s) do(s) Usu&aacute;rio(s) envolvidos, sem a necessidade de qualquer
                notifica&ccedil;&atilde;o pr&eacute;via.
              </p>
              <br />
              <p>
                3.2.8. &Eacute; terminantemente proibido ao Usu&aacute;rio ou a terceiros utilizar-se de forma comercial
                da assinatura da plataforma, ou ainda, de quaisquer propriedades intelectuais da Adv Service de forma
                que n&atilde;o autorizada neste instrumento.
              </p>
              <br />
              <p>
                3.3. Ao efetivar o cadastro na plataforma digital Adv Service, o Usu&aacute;rio declara que possui
                ci&ecirc;ncia e concorda plenamente com todo o conte&uacute;do deste termo, de forma que, futuramente,
                n&atilde;o poder&atilde;o ser alegados quaisquer impedimentos legais para o cumprimento das
                obriga&ccedil;&otilde;es assumidas neste documento.
              </p>
              <br />
              <p>
                3.4. O Usu&aacute;rio ao fornecer as informa&ccedil;&otilde;es necess&aacute;rias ao seu cadastro e/ou
                ao uso das ferramentas disponibilizadas pela plataforma Adv Service, declara que as
                informa&ccedil;&otilde;es por ele inseridas s&atilde;o verdadeiras, n&atilde;o prejudicam direito de
                terceiros, tais como direitos autorais, de imagem, privacidade e ainda n&atilde;o reproduzem
                conte&uacute;do discriminat&oacute;rio de qualquer natureza.
              </p>
              <br />
              <p>
                3.5. Constatada a viola&ccedil;&atilde;o dos Termos de Uso pelo Usu&aacute;rio, sem preju&iacute;zo de
                outras medidas, a Adv Service poder&aacute; advertir, suspender ou cancelar, tempor&aacute;ria ou
                definitivamente, o cadastro de um Usu&aacute;rio.
              </p>
              <br />
              <p>
                3.6. Ao utilizar-se de meios suspeitos, inconvenientes, ou em desacordo ao Termo de Uso Adv Service,
                para obter vantagem em servi&ccedil;os, al&eacute;m daqueles disponibilizados pelo plano escolhido, ou
                tentar obter pelos mesmos meios, de forma inconveniente, descontos em planos que n&atilde;o aqueles
                disponibilizados em ocasi&otilde;es espec&iacute;ficas ou por meio de conv&ecirc;nios/parcerias,
                poder&aacute; a Adv Service exercer seu direito de prote&ccedil;&atilde;o, impedindo o acesso de forma
                definitiva de conta(s) do(s) Usu&aacute;rio(s) envolvidos, sem a necessidade de qualquer
                notifica&ccedil;&atilde;o pr&eacute;via.
              </p>
              <br />
              <p>
                3.7. Ao utilizar a Plataforma Digital Adv Service, o Usu&aacute;rio declara possuir anu&ecirc;ncia e
                autoriza&ccedil;&atilde;o de terceiros para incluir, durante a utiliza&ccedil;&atilde;o da plataforma,
                dados pessoais dos mesmos, incluindo dados que possam ser classificados como sens&iacute;veis, de acordo
                com Lei n&ordm; 13.709/2018 &ndash; Lei Geral de Prote&ccedil;&atilde;o de Dados. O Usu&aacute;rio ainda
                declara ci&ecirc;ncia e concord&acirc;ncia em rela&ccedil;&atilde;o &agrave; Pol&iacute;tica de
                Privacidade da Adv Service ao incluir dados de terceiros na plataforma.
              </p>
              <br />
              <p>
                3.8. A Adv Service n&atilde;o se responsabiliza por qualquer erro de utiliza&ccedil;&atilde;o em suas
                ferramentas, ao passo que determinadas incorre&ccedil;&otilde;es tenham sido causadas por erros dos seus
                Usu&aacute;rios que s&atilde;o integralmente respons&aacute;veis pelos dados e informa&ccedil;&otilde;es
                com as quais alimentam as ferramentas disponibilizadas.
              </p>
              <br />
              <ol start="4">
                <li><strong> Planos de assinatura e Cobran&ccedil;as</strong></li>
              </ol>
              <p>
                4.1.&nbsp;<em>Assinatura.&nbsp;</em>A Assinatura (&ldquo;Assinatura&rdquo;) ou compra individual de
                qualquer servi&ccedil;o da Plataforma Digital Adv Service poder&aacute; ser feita por meio de
                cart&atilde;o de cr&eacute;dito ou eventuais novas formas de pagamento disponibilizadas pela Plataforma
                (a "Forma de Pagamento").
              </p>
              <br />
              <p>
                4.2.&nbsp;<em>Libera&ccedil;&atilde;o do acesso.&nbsp;</em>O acesso a Plataforma e suas funcionalidades,
                conforme modalidade de plano contratada, ser&aacute; liberado ap&oacute;s a confirma&ccedil;&atilde;o do
                pagamento da Assinatura, que pode demorar at&eacute; 72 (setenta e duas) horas.
              </p>
              <br />
              <p>
                4.3.&nbsp;<em>Per&iacute;odo de Contrata&ccedil;&atilde;o.&nbsp;</em>A Assinatura da Plataforma
                ser&aacute; feita por meio de sistema de pagamentos recorrentes, de modo que o acesso &agrave;s
                funcionalidades pagas ser&aacute; mensal, semestral ou anual, de acordo com o Plano escolhido pelo
                Usu&aacute;rio (o &ldquo;Per&iacute;odo de Contrata&ccedil;&atilde;o&rdquo;).&nbsp;Isto quer dizer que o
                Per&iacute;odo de Contrata&ccedil;&atilde;o inicia-se no dia da Assinatura do Plano e &eacute; renovado
                mensalmente, semestralmente ou anualmente, conforme o Plano escolhido.
              </p>
              <br />
              <p>
                4.4.&nbsp;<em>Planos.&nbsp;</em>Os Planos disponibilizados pela Plataforma e seus respectivos
                pre&ccedil;os est&atilde;o dispon&iacute;veis na p&aacute;gina inicial do site da Adv Service no
                endere&ccedil;o na web <a href="https://advservice.com.br/">https://advservice.com.br/</a>, com planos
                mensais, semestrais e anuais, nas modalidades Starter, Master e Ultimate:
              </p>
              <br />
              <p><u>STARTER:</u> <u>https://advservice.com.br/planos</u></p>
              <br />
              <p><u>&nbsp;</u></p>
              <br />
              <p><u>MASTER: https://advservice.com.br/planos</u></p>
              <br />
              <p><u>ULTIMATE: https://advservice.com.br/planos</u></p>
              <br />
              <p>&nbsp;</p>
              <br />
              <p>
                4.4.1.&nbsp;A altera&ccedil;&atilde;o da assinatura de Plano Mensal para o Plano Semestral ou Anual
                implicar&aacute; a cobran&ccedil;a, ao t&eacute;rmino do Per&iacute;odo de Contrata&ccedil;&atilde;o
                vigente, do valor integral do Plano Semestral ou Anual, descrito no item 4.4 e a renova&ccedil;&atilde;o
                do Per&iacute;odo de Contrata&ccedil;&atilde;o para 6 (seis) ou 12 (doze) meses,
                respectivamente.&nbsp;Por sua vez, no caso de altera&ccedil;&atilde;o de assinatura do Plano Semestral
                ou Anual para Plano Mensal, a altera&ccedil;&atilde;o e cobran&ccedil;a do valor da Assinatura mensal
                ter&aacute; efeito apenas ao fim do prazo do Per&iacute;odo da Contrata&ccedil;&atilde;o vigente do
                plano semestral ou anual em vigor.
              </p>
              <br />
              <p>
                4.5.<em> Altera&ccedil;&otilde;es dos pre&ccedil;os da Assinatura.</em> A Adv Service poder&aacute;
                alterar o pre&ccedil;o dos planos de assinatura ou de outros servi&ccedil;os que, eventualmente, venham
                a integrar a plataforma. No entanto, nenhuma altera&ccedil;&atilde;o ser&aacute; aplicada ao
                Usu&aacute;rio em prazo inferior a 30 (trinta) dias a contar da comunica&ccedil;&atilde;o enviada via
                e-mail cadastrado. Caso o Usu&aacute;rio n&atilde;o concorde com a modifica&ccedil;&atilde;o
                poder&aacute; cancelar o seu acesso a plataforma, antes do fim do per&iacute;odo de
                contrata&ccedil;&atilde;o vigente.
              </p>
              <br />
              <p>
                4.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; As consultas a serem realizadas na ferramenta de busca Buscador
                Service ser&atilde;o cobradas individualmente de forma unit&aacute;ria do usu&aacute;rio, em mesma
                oportunidade que este declara estar ciente do custo e da responsabilidade de uso das
                informa&ccedil;&otilde;es obtidas.
              </p>
              <br />
              <p>
                4.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A ferramenta Pergunte ao Especialista cobrar&aacute; por cada
                pergunta adicional realizada, sempre ap&oacute;s a ci&ecirc;ncia do custo e da expressa anu&ecirc;ncia
                por parte do Usu&aacute;rio, em mesma oportunidade que este declara estar ciente do custo e da
                responsabilidade de uso das informa&ccedil;&otilde;es obtidas.
              </p>
              <br />
              <p>
                4.8.&nbsp;<strong><em>Direito de Arrependimento (&ldquo;Teste Gratuito&rdquo;)</em></strong
                >. Para os novos Usu&aacute;rios da Plataforma que optarem pela contrata&ccedil;&atilde;o de um dos
                planos mensais, ap&oacute;s o Cadastro, ser&aacute; disponibilizado o acesso gratuito e
                tempor&aacute;rio &agrave;s funcionalidades pagas, durante o per&iacute;odo de 07 (sete) dias corridos
                (&ldquo;Per&iacute;odo de Teste Gratuito&rdquo;).&nbsp;Durante o Per&iacute;odo de Teste Gratuito, o
                Usu&aacute;rio ter&aacute; acesso &agrave;s funcionalidades dispon&iacute;veis na Plataforma.
              </p>
              <br />
              <p>
                4.8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; O per&iacute;odo de testes gr&aacute;tis &eacute; de 07 (sete) dias
                corridos contados do cadastro na plataforma. As informa&ccedil;&otilde;es dispon&iacute;veis no perfil
                do usu&aacute;rio trar&atilde;o data de in&iacute;cio e fim do per&iacute;odo de teste gr&aacute;tis e
                dos planos mensais, semestrais e anuais. Os 07 (sete) dias s&atilde;o contados em horas (7 dias vezes
                24horas). O Cadastro para fins de direito de arrependimento do Art. 49, do CDC, &eacute; considerado
                como Assinatura e/ou Contrata&ccedil;&atilde;o. O per&iacute;odo de Avalia&ccedil;&atilde;o Gratuita
                n&atilde;o altera o Per&iacute;odo de Contrata&ccedil;&atilde;o.&nbsp;
              </p>
              <br />
              <p>
                4.9.&nbsp;<strong><em>Renova&ccedil;&atilde;o Autom&aacute;tica.&nbsp; </em></strong>A
                contrata&ccedil;&atilde;o dos servi&ccedil;os ser&aacute; renovada automaticamente, independentemente de
                comunica&ccedil;&atilde;o ao Usu&aacute;rio, por meio da mesma forma de pagamento indicada quando da
                contrata&ccedil;&atilde;o do servi&ccedil;o.
              </p>
              <br />
              <p>
                4.9.1.&nbsp;<strong
                  >A renova&ccedil;&atilde;o autom&aacute;tica do Plano n&atilde;o implica a renova&ccedil;&atilde;o do
                  direito do Usu&aacute;rio de resist&ecirc;ncia da assinatura prevista no art.&nbsp;49 do C&oacute;digo
                  de Defesa do Consumidor.</strong
                >
              </p>
              <br />
              <p>
                4.10.&nbsp;&nbsp;&nbsp; N&atilde;o perfectibilizando a cobran&ccedil;a no meio indicado pelo
                Usu&aacute;rio quando da contrata&ccedil;&atilde;o, o sistema repetir&aacute; a tentativa
                automaticamente no mesmo cart&atilde;o, n&atilde;o importando a aus&ecirc;ncia de saldo em cancelamento
                autom&aacute;tico do plano, tal como ocorre em outras plataformas de servi&ccedil;os, como ex.: sistemas
                de streaming.
              </p>
              <br />
              <p>
                4.11.&nbsp;&nbsp;&nbsp; Para cancelar a contrata&ccedil;&atilde;o dos servi&ccedil;os, o Usu&aacute;rio
                deve faz&ecirc;-lo necessariamente por dentro da plataforma, mediante bot&atilde;o
                &ldquo;cancelar&rdquo; disponibilizado no perfil do Usu&aacute;rio na aba "meu plano".
              </p>
              <br />
              <p>
                4.11.1. O envio de e-mail ou solicita&ccedil;&atilde;o de cancelamento por rede social e/ou canal de
                atendimento n&atilde;o isenta o usu&aacute;rio de eventual cobran&ccedil;a que venha a ser realizada.
              </p>
              <br />
              <p>
                4.11.2. Se, no momento da renova&ccedil;&atilde;o, a cobran&ccedil;a da renova&ccedil;&atilde;o
                n&atilde;o for realizada, por qualquer motivo, a conta ser&aacute; bloqueada no prazo de 15 dias e o
                sistema alertar&aacute; o Usu&aacute;rio Propriet&aacute;rio para efetuar a
                regulariza&ccedil;&atilde;o.&nbsp;O bloqueio atingir&aacute; todos os usu&aacute;rios vinculados
                &agrave; conta bloqueada.
              </p>
              <br />
              <p>
                4.12.&nbsp;&nbsp;&nbsp; A disponibilidade de compra de consulta de forma unit&aacute;ria das ferramentas
                Buscador Service e Pergunte ao Especialista depender&aacute; de disponibiliza&ccedil;&atilde;o no
                sistema. Os valores cobrados em virtude do uso unit&aacute;rio das ferramentas Buscador Service e
                Pergunte ao Especialista, para al&eacute;m de poss&iacute;veis cortesias e quantidades previstas em
                planos de assinatura, ser&atilde;o faturados de maneira unit&aacute;ria. Assim, a cada
                utiliza&ccedil;&atilde;o das respectivas ferramentas, com pr&eacute;via informa&ccedil;&atilde;o sobre o
                custo do servi&ccedil;o e anu&ecirc;ncia do Usu&aacute;rio, &eacute; gerado a cobran&ccedil;a no mesmo
                meio de pagamento que este utilizou quando do cadastro.
              </p>
              <br />
              <p>
                4.13.&nbsp;&nbsp;&nbsp; Na contrata&ccedil;&atilde;o do Software como Servi&ccedil;o, a Adv Service
                poder&aacute; solicitar informa&ccedil;&otilde;es financeiras do Usu&aacute;rio, como CPF e dados de
                cart&atilde;o de cr&eacute;dito. Ao inserir referidos dados, o Usu&aacute;rio concorda que sejam
                cobrados de acordo com o plano escolhido, o respectivo pre&ccedil;o vigente e informado quando da
                contrata&ccedil;&atilde;o.
              </p>
              <br />
              <p>
                4.14. Os dados financeiros coletados poder&atilde;o ser armazenados para fins de cobran&ccedil;a e
                contrata&ccedil;&otilde;es futuras.
              </p>
              <br />
              <ol start="5">
                <li><strong> Pol&iacute;tica de cancelamento</strong></li>
              </ol>
              <p>
                5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cancelamento. O Usu&aacute;rio poder&aacute; cancelar a
                contrata&ccedil;&atilde;o do Software como Servi&ccedil;os, mediante acesso ao sistema da Adv Service,
                op&ccedil;&atilde;o &ldquo;meu perfil&rdquo;, em seguida &ldquo;meu plano&rdquo;, e clicando no
                bot&atilde;o &ldquo;cancelar plano&rdquo;. Nenhuma solicita&ccedil;&atilde;o por e-mail, por rede social
                ou outro meio ser&aacute; aceito.
              </p>
              <br />
              <p>
                5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; O pagamento j&aacute; realizado pelo Usu&aacute;rio ser&aacute;
                ressarcido, somente em cobran&ccedil;as eventualmente realizadas pelo sistema, por equ&iacute;voco,
                durante o per&iacute;odo de teste gr&aacute;tis, ou no caso de assinatura decorrente de parceria ou
                conv&ecirc;nio (uso de cupom), no per&iacute;odo dos 07 (sete) dias corridos previstos no CDC (direito
                de arrependimento). O Direito do arrependimento &eacute; de 7 dias corridos, contados em formatos de 24
                horas.
              </p>
              <br />
              <p>
                5.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Eventual estorno por qualquer motivo ser&aacute; efetuado em
                at&eacute; 02 (dois) dias &uacute;teis, contados confirma&ccedil;&atilde;o da realiza&ccedil;&atilde;o
                do estorno.
              </p>
              <br />
              <p>
                5.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Na hip&oacute;tese de cancelamento do servi&ccedil;o por parte do
                Usu&aacute;rio, o acesso ao plano cessar&aacute; somente quando conclu&iacute;do o ciclo vigente de uso,
                relativo &agrave; &uacute;ltima cobran&ccedil;a efetuada. Em caso de cancelamento, ao final do
                Per&iacute;odo de Contrata&ccedil;&atilde;o, o acesso de todos os usu&aacute;rios contratados &agrave;
                Plataforma ser&aacute; cancelado.
              </p>
              <br />
              <p>
                5.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Na hip&oacute;tese de cancelamento do servi&ccedil;o em virtude de
                viola&ccedil;&atilde;o aos presentes termos de uso, os servi&ccedil;os ser&atilde;o cessados
                imediatamente, sem qualquer reembolso ao Usu&aacute;rio.
              </p>
              <br />
              <p>
                5.6.&nbsp;<strong><em>Multa pelo cancelamento</em></strong
                >.&nbsp;N&atilde;o existe qualquer tipo de fideliza&ccedil;&atilde;o entre o Usu&aacute;rio e a Adv
                Service, devendo ser respeitado apenas o prazo contratual d&aacute; &uacute;ltima
                renova&ccedil;&atilde;o. O Adv Service n&atilde;o cobra multa pelo cancelamento da Assinatura.
              </p>
              <br />
              <p>
                5.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A n&atilde;o observ&acirc;ncia das obriga&ccedil;&otilde;es pactuadas
                nestes termos de uso ou da legisla&ccedil;&atilde;o aplic&aacute;vel poder&aacute;, sem pr&eacute;vio
                aviso, ensejar a imediata rescis&atilde;o unilateral por parte da Adv Service e o bloqueio de todos os
                servi&ccedil;os prestados ao Usu&aacute;rio, hip&oacute;tese em que a Adv Service se reserva o direito
                de recusar ou restringir ao Usu&aacute;rio todo e qualquer uso atual ou futuro da plataforma e dos
                servi&ccedil;os.
              </p>
              <br />
              <p>
                &nbsp;5.8. &nbsp;Os dados inseridos pelo Usu&aacute;rio n&atilde;o ser&atilde;o exclu&iacute;dos do
                banco de dados da Plataforma, por tempo necess&aacute;rio para o cumprimento do objeto deste
                termo.&nbsp;Ap&oacute;s, os dados pessoais acima citados ser&atilde;o destru&iacute;dos, salvo aqueles
                que forem necess&aacute;rios para cumprimento de obriga&ccedil;&otilde;es legais, na forma do
                Art.&nbsp;16, I da Lei 13.709/18.&nbsp;
              </p>
              <br />
              <ol start="6">
                <li><strong> Funcionamento da Plataforma e Servi&ccedil;os de Terceiros</strong></li>
              </ol>
              <p>
                6.1.&nbsp;<em>Condi&ccedil;&otilde;es de acesso e funcionamento</em>.&nbsp;O Adv Service se
                esfor&ccedil;a para garantir que o sistema esteja em pleno e completo funcionamento durante 24 (vinte e
                quatro) horas por dia, 7 (sete) dias por semana, inclusive finais de semana e feriados.&nbsp;Sendo
                assim, o Adv Service garante, observadas as condi&ccedil;&otilde;es destes Termos de Uso, que a
                Plataforma estar&aacute; em plenas condi&ccedil;&otilde;es de acesso e funcionamento durante, pelo
                menos, 90% (noventa por cento) do tempo do Per&iacute;odo de Contrata&ccedil;&atilde;o.&nbsp;Caso o
                usu&aacute;rio verifique qualquer falha ou dificuldade de acesso, poder&aacute; entrar em contato com o
                Adv Service pelo e-mail &ldquo;<u>contato@advservice.com.br</u>&rdquo;.
              </p>
              <br />
              <p>
                6.2.&nbsp;<em>Modifica&ccedil;&otilde;es da Plataforma</em>.&nbsp;O usu&aacute;rio registra que o
                software n&atilde;o foi feito sob encomenda e que o Adv Service se reserva no direito de alterar,
                atualizar ou descontinuar funcionalidades da Plataforma, bem como de suspender o acesso &agrave;
                Plataforma para realiza&ccedil;&atilde;o de manuten&ccedil;&atilde;o ou outros procedimentos, sem que
                isso gere qualquer direito de ressarcimento ou indeniza&ccedil;&atilde;o por parte do
                Usu&aacute;rio.&nbsp;Nesse contexto, o Usu&aacute;rio registra e aceita que, em nenhuma hip&oacute;tese,
                o Adv Service poder&aacute; ser responsabilizado por eventuais perdas e danos, lucros cessantes, ou
                qualquer outro dano, relacionado &agrave; suspens&atilde;o do acesso &agrave; Plataforma,
                altera&ccedil;&atilde;o, atualiza&ccedil;&atilde;o ou descontinua&ccedil;&atilde;o de funcionalidades.
              </p>
              <br />
              <p>
                6.3.&nbsp;<em>Servi&ccedil;os de terceiros</em>.&nbsp;O Site e a Plataforma valem-se de servi&ccedil;os
                de terceiros para operar, tais como servidores, internet, dentre outros, os quais n&atilde;o s&atilde;o
                operados pelo Adv Service, de forma que o Adv Service n&atilde;o ser&aacute; respons&aacute;vel por
                quaisquer danos decorrentes da falha na presta&ccedil;&atilde;o de servi&ccedil;os de terceiros.
              </p>
              <br />
              <p>
                6.4.&nbsp;<em>Links de terceiro</em>.&nbsp;O Adv Servi&ccedil;o poder&aacute; disponibilizar na
                Plataforma links de acesso para sites de terceiros.&nbsp;A disponibiliza&ccedil;&atilde;o desses links
                n&atilde;o significa qualquer indica&ccedil;&atilde;o ou endosso aos servi&ccedil;os prestados por esses
                terceiros.&nbsp;O Adv Servi&ccedil;o n&atilde;o ter&aacute; qualquer responsabilidade pelos
                servi&ccedil;os ou funcionalidades oferecidas em tais sites, pois eventual contrata&ccedil;&atilde;o ou
                utiliza&ccedil;&atilde;o de funcionalidade de terceiros &eacute; escolha pessoal do
                Usu&aacute;rio.&nbsp;De todo modo, o Adv Servi&ccedil;o recomenda que, antes de qualquer
                contrata&ccedil;&atilde;o, o usu&aacute;rio se informe sobre os termos de uso e pol&iacute;tica de
                privacidade de servi&ccedil;os oferecidos a terceiros.
              </p>
              <br />
              <p>
                6.5. <em>Integra&ccedil;&otilde;es via API</em>. A Adv Service poder&aacute; integrar livremente no
                &acirc;mbito da sua plataforma e de seus servi&ccedil;os, aplica&ccedil;&otilde;es de terceiros que se
                manifestem de maneira ostensiva ou n&atilde;o, em sua plataforma e servi&ccedil;os (ex.: atrav&eacute;s
                de links de websites ou funcionalidades da internet). Esses servi&ccedil;os de terceiros s&atilde;o de
                responsabilidade daqueles que os disponibilizam, e ser&atilde;o regidos &uacute;nica e exclusivamente
                pelos Termos de Uso a eles aplic&aacute;veis, definidos por cada terceiro por eles respons&aacute;veis.
              </p>
              <br />
              <p>
                6.5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nos casos em que a aceita&ccedil;&atilde;o dos Termos de Uso de
                terceiros seja necess&aacute;rio para a utiliza&ccedil;&atilde;o de determinados servi&ccedil;os da
                plataforma digital Adv Service, a n&atilde;o aceita&ccedil;&atilde;o destes termos pode limitar o acesso
                dos Usu&aacute;rios aos servi&ccedil;os.
              </p>
              <br />
              <p>
                6.5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A integra&ccedil;&atilde;o com servi&ccedil;os de terceiros
                n&atilde;o corresponde ao endosso desses servi&ccedil;os ou qualquer associa&ccedil;&atilde;o com seus
                operadores por parte da Adv Service, tampouco, qualquer rela&ccedil;&atilde;o de sociedade,
                supervis&atilde;o, cumplicidade, joint venture, solidariedade ou de garantia com esses terceiros.
              </p>
              <br />
              <ol start="7">
                <li><strong> Responsabilidades do Adv Service</strong></li>
              </ol>
              <p>
                7.1.&nbsp;<em>Responsabilidade sobre o conte&uacute;do e uso das informa&ccedil;&otilde;es</em>.&nbsp;A
                Plataforma tem como objetivo auxiliar e facilitar o trabalho dos profissionais da Advocacia,
                disponibilizando, entre outros, modelos de pe&ccedil;as processuais e teses jur&iacute;dicas, gestor de
                escrit&oacute;rio, tira d&uacute;vida jur&iacute;dica, cabendo, contudo, ao Usu&aacute;rio
                salvaguardar-se de que tais informa&ccedil;&otilde;es n&atilde;o possuem incoer&ecirc;ncias,
                imprecis&otilde;es, estejam desatualizados, ou que ainda sejam compat&iacute;veis com o cliente por ele
                atendido.&nbsp;Al&eacute;m disso, o Usu&aacute;rio registra que a obriga&ccedil;&atilde;o do advogado
                &eacute; de meio e n&atilde;o de resultado, de forma que nem o Usu&aacute;rio e nem o Adv Service podem
                garantir o &ecirc;xito em demandas judiciais e administrativas.&nbsp;Dessa forma, em nenhuma
                circunst&acirc;ncia, o Adv Servi&ccedil;o poder&aacute; ser responsabilizado quanto &agrave;
                integridade, atualidade e exatid&atilde;o de tais informa&ccedil;&otilde;es obtidas pelo uso da
                Plataforma, n&atilde;o sendo respons&aacute;vel, perante o Usu&aacute;rio por eventuais perdas e danos,
                ou por quaisquer danos patrimoniais e extrapatrimoniais, inclusive, sem limita&ccedil;&otilde;es, por
                lucros cessantes, ou qualquer outro dano, resultantes do uso das funcionalidades nela oferecidas ou das
                informa&ccedil;&otilde;es dela decorrentes, incluindo, sem limita&ccedil;&otilde;es, em
                a&ccedil;&otilde;es judiciais, extrajudiciais ou administrativas, resultantes do uso da Plataforma, das
                funcionalidades nela oferecidas ou das informa&ccedil;&otilde;es dela decorrentes.
              </p>
              <br />
              <p>
                7.2.&nbsp;<em>Responsabilidade sobre os dados</em>.&nbsp;O Usu&aacute;rio e n&atilde;o o Adv Service
                &eacute; respons&aacute;vel pela manuten&ccedil;&atilde;o e prote&ccedil;&atilde;o de seus
                dados.&nbsp;&Eacute; de responsabilidade do Usu&aacute;rio realizar o backup de suas
                informa&ccedil;&otilde;es.&nbsp;O Adv Service n&atilde;o ser&aacute; responsabilizado por eventuais
                perdas e danos, ou por quaisquer danos diretos, indiretos, consequentes, exemplares, incidentais,
                especiais ou punitivos, inclusive, sem limita&ccedil;&otilde;es, por lucros cessantes, ou qualquer outro
                dano, em raz&atilde;o da perda, desvio , apropria&ccedil;&atilde;o indevida, ou quaisquer outros termos
                dos dados e informa&ccedil;&otilde;es inseridas pelo Usu&aacute;rio na Plataforma.&nbsp;
              </p>
              <br />
              <p>
                7.3.&nbsp;<em>Responsabilidade sobre o acesso</em>.&nbsp;O Adv Service n&atilde;o garante que o acesso
                ao site esteja livre de erros ou problemas, decorrentes de casos fortuitos, internos ou externos, casos
                de for&ccedil;a maior ou por outros casos n&atilde;o sujeitos ao controle do Adv Service, como, por
                exemplo, falhas na internet em geral, quedas de energia, dentre outras.&nbsp;Ainda assim, o Adv Service
                n&atilde;o poder&aacute; ser responsabilizado, perante o Usu&aacute;rio ou qualquer terceiro, por
                quaisquer danos diretos ou indiretos, consequentes, exemplares, incidentais, especiais ou punitivos,
                inclusive, sem limita&ccedil;&atilde;o, por lucros cessantes, em rela&ccedil;&atilde;o:
              </p>
              <br />
              <p>
                7.3.1.&nbsp;a qualquer dano ou preju&iacute;zo em equipamentos do Usu&aacute;rio em decorr&ecirc;ncia do
                acesso e uso da Plataforma;
              </p>
              <br />
              <p>
                7.3.2.&nbsp;a interrup&ccedil;&otilde;es de qualquer natureza no acesso &agrave; Plataforma, tais como
                manuten&ccedil;&atilde;o programada, ou n&atilde;o, falha na presta&ccedil;&atilde;o de servi&ccedil;os
                de terceiros, queda de energia ou de internet, dentre outros;
              </p>
              <br />
              <p>
                7.3.3.&nbsp;a eventual vazamento de informa&ccedil;&otilde;es decorrentes de acesso indevido e
                n&atilde;o autorizado aos servidores contratados pelo Adv Service.
              </p>
              <br />
              <p>
                7.3.4.&nbsp;a v&iacute;rus, malware ou qualquer outro dispositivo malicioso que, em decorr&ecirc;ncia de
                acesso, utiliza&ccedil;&atilde;o, navega&ccedil;&atilde;o na Plataforma, ataque equipamento do
                Usu&aacute;rio.
              </p>
              <br />
              <ol start="8">
                <li><strong> Responsabilidades e Declara&ccedil;&otilde;es dos Usu&aacute;rios</strong></li>
              </ol>
              <p>
                8.1.&nbsp;<em>Carregar informa&ccedil;&otilde;es</em>.&nbsp;A Plataforma disponibilizada pelo Adv
                Service poder&aacute; oferecer funcionalidades que permitem o carregamento (upload) de materiais de
                texto, imagem e/ou outros dados pr&oacute;prios e de terceiros.&nbsp;Por raz&atilde;o disso, o
                Usu&aacute;rio aceita, autoriza e compromete-se a n&atilde;o carregar (upload) qualquer conte&uacute;do
                il&iacute;cito, imoral ou ilegal ou material que viole a Propriedade Intelectual do Adv Service ou de
                terceiros, tampouco viola o direito ao sigilo de informa&ccedil;&otilde;es e dados de qualquer pessoa,
                seja f&iacute;sica ou jur&iacute;dica.&nbsp;Caso qualquer viola&ccedil;&atilde;o a este dispositivo seja
                identificada, a conta do Usu&aacute;rio ser&aacute; bloqueada imediatamente, independentemente de aviso
                pr&eacute;vio.
              </p>
              <br />
              <p>
                8.2.&nbsp;<em>Demais obriga&ccedil;&otilde;es do Usu&aacute;rio</em>.&nbsp;Sem preju&iacute;zo de outras
                obriga&ccedil;&otilde;es eventualmente expostas nestes Termos de Uso, &eacute; de responsabilidade do
                Usu&aacute;rio:
              </p>
              <br />
              <p>8.2.1.&nbsp;Respeitar e cumprir as regras aplic&aacute;veis nestes Termos de Uso;</p>
              <br />
              <p>
                8.2.2.&nbsp;Utilizar a Plataforma de forma correta, de acordo com estes Termos de Uso e com as demais
                regras aplic&aacute;veis do ordenamento jur&iacute;dico brasileiro, responsabilizando-se pela sua
                conduta;
              </p>
              <br />
              <p>
                8.2.3.&nbsp;N&atilde;o realize qualquer download de conte&uacute;do, exceto se tal possibilidade estiver
                dispon&iacute;vel como funcionalidade da Plataforma;
              </p>
              <br />
              <p>
                8.2.4.&nbsp;N&atilde;o reproduzir, realizar engenharia reversa, compilar, descompilar, ceder,
                distribuir, transmitir, licenciar ou sublicenciar, exibir, vender ou, de qualquer maneira, explorar
                comercialmente a Assinatura ou qualquer Propriedade Intelectual do Adv Service de forma n&atilde;o
                autorizada nestes Termos de Uso;
              </p>
              <br />
              <p>
                8.2.5.&nbsp;Utiliza&ccedil;&atilde;o dos equipamentos de hardware necess&aacute;rios para o acesso
                &agrave; Plataforma;
              </p>
              <br />
              <p>
                8.2.6.&nbsp;Manter o registro e realizar o backup de todo o conte&uacute;do e dados que julgue
                relevantes;
              </p>
              <br />
              <p>
                8.2.7.&nbsp;Agir de forma a evitar o acesso indevido &agrave; Plataforma.&nbsp;Configure a
                viola&ccedil;&atilde;o deste disposto o compartilhamento indevido com terceiros do acesso &agrave;
                Plataforma.&nbsp;Caso seja verificado o compartilhamento indevido de login e acesso, o Adv Service
                poder&aacute; incapacitar de forma definitiva o contato do(s) Usu&aacute;rios, independentemente de
                qualquer notifica&ccedil;&atilde;o pr&eacute;via.
              </p>
              <br />
              <p>
                8.2.8.&nbsp;N&atilde;o utilizar a Plataforma para a pr&aacute;tica de atos il&iacute;citos, proibidos
                pela lei e por estes Termos de Uso;
              </p>
              <br />
              <p>
                8.2.9.&nbsp;N&atilde;o utilize a Plataforma para ler direitos do Adv Service ou de terceiros, n&atilde;o
                que se incluam condutas que possam gerar qualquer dano ao Site, &agrave; Plataforma e/ou ao Aplicativo,
                a equipamentos de terceiros ou, at&eacute; mesmo, gerar danos a documentos e conte&uacute;dos inseridos
                pelo Adv Service ou terceiros na Plataforma.
              </p>
              <br />
              <p>
                8.3.&nbsp;<em>Declara&ccedil;&otilde;es do Usu&aacute;rio</em>.&nbsp;Ao realizar o Cadastro na
                Plataforma, o Usu&aacute;rio expressamente confirma e declara que possui capacidade para anuir estes
                Termos de Uso, inexistindo nenhum impedimento legal ou contratual para o cumprimento das
                obriga&ccedil;&otilde;es ora assumidas e que as informa&ccedil;&otilde;es fornecidas e o conte&uacute;do
                inserido na Plataforma n&atilde;o violam a legisla&ccedil;&atilde;o brasileira ou estes Termos de Uso:
              </p>
              <br />
              <p>
                8.3.1.&nbsp;S&atilde;o ver&iacute;dicas, v&aacute;lidas e n&atilde;o violam nenhum dispositivo destes
                Termos de Uso e/ou da legisla&ccedil;&atilde;o brasileira;
              </p>
              <br />
              <p>
                8.3.2.&nbsp;N&atilde;o infringem nem conflitam com nenhum direito de terceiros, n&atilde;o que se
                inclua, mas n&atilde;o se limite, a direitos de propriedade intelectual, direitos autorais, de imagem,
                privacidade dentre outros;
              </p>
              <br />
              <p>
                8.3.3.&nbsp;N&atilde;o possui conte&uacute;do mis&oacute;gino, homof&oacute;bico, racista, nem incita
                &agrave; viol&ecirc;ncia, tampouco atinja a honra e a dignidade de terceiros.
              </p>
              <br />
              <p>
                8.4.&nbsp;<em>Uso de informa&ccedil;&otilde;es pessoais de terceiros</em>.&nbsp;Ao utilizar a
                Plataforma, o Usu&aacute;rio declara expressamente que possui plena autoriza&ccedil;&atilde;o para a
                inser&ccedil;&atilde;o na Plataforma de informa&ccedil;&otilde;es pessoais de terceiros, especialmente
                em rela&ccedil;&atilde;o aos dados de natureza sens&iacute;vel de seus clientes, nos termos definidos
                pela Lei n.&nbsp;13.709/2018, bem como de que t&ecirc;m plena ci&ecirc;ncia sobre a Pol&iacute;tica de
                Uso de Dados da Plataforma.&nbsp;
              </p>
              <br />
              <p>
                8.5.&nbsp;<em>Mecanismo de den&uacute;ncia</em>.&nbsp;Caso qualquer pessoa, Usu&aacute;rio ou
                n&atilde;o, identifique na Plataforma qualquer conte&uacute;do que seja considerado conte&uacute;do
                vedado, inclusive por violar propriedade intelectual pr&oacute;pria ou de terceiro, ou, ainda, que
                verifique a disponibiliza&ccedil;&atilde;o de informa&ccedil;&otilde;es com refer&ecirc;ncia de fonte
                incorreta, imprecisa ou incompleta poder&aacute; promover uma den&uacute;ncia por meio do e-mail
                &ldquo;contato@advservice.com.br&rdquo;.&nbsp;Ap&oacute;s apura&ccedil;&atilde;o das
                informa&ccedil;&otilde;es, o Adv Service poder&aacute;, a seu exclusivo exclusivo, remover/modificar o
                conte&uacute;do e/ou a conta denunciada.
              </p>
              <br />
              <p>
                8.6.&nbsp;<em>Viola&ccedil;&atilde;o dos Termos de Uso pelo Usu&aacute;rio</em>.&nbsp;A
                viola&ccedil;&atilde;o dos presentes Termos de Uso por qualquer Usu&aacute;rio implicar&aacute; a
                imposi&ccedil;&atilde;o de multa n&atilde;o compensat&oacute;ria ao Usu&aacute;rio infrator no valor de
                R$ 20.000,00 (vinte mil reais) por ato de viola&ccedil;&atilde;o, responsabilizando-se, ainda, por
                eventuais perdas e danos sofridas pelo Adv Service, al&eacute;m das despesas administrativas e
                judiciais, que incluem os honor&aacute;rios advocat&iacute;cios.
              </p>
              <br />
              <p>
                8.6.1.&nbsp;Sem preju&iacute;zo da multa descrita no item 8.6, o Adv Service poder&aacute; anunciar,
                suspender ou cancelar, tempor&aacute;rio ou definitivamente, o Cadastro do Usu&aacute;rio.
              </p>
              <br />
              <p>
                8.6.2.&nbsp;Para fins exemplificativos, constitui viola&ccedil;&atilde;o dos Termos de Uso: a
                reprodu&ccedil;&atilde;o indevida ou n&atilde;o autorizada de qualquer conte&uacute;do disponibilizado
                na Plataforma;&nbsp;com&eacute;rcio ou qualquer forma de explora&ccedil;&atilde;o comercial e
                econ&ocirc;mica das pe&ccedil;as processuais e teses dispon&iacute;veis na Plataforma;&nbsp;a
                cria&ccedil;&atilde;o de m&uacute;ltiplas contas para o mesmo usu&aacute;rio;&nbsp;e compartilhamento de
                contas e acesso &agrave; Plataforma com terceiros.
              </p>
              <br />
              <p>
                8.6.3.&nbsp;Sem preju&iacute;zo do disposto no item 8.6, a viola&ccedil;&atilde;o dos Termos de Uso por
                conduta do Usu&aacute;rio descrita no item 8.2.4 implicar&aacute; a imposi&ccedil;&atilde;o de multa
                n&atilde;o compensat&oacute;ria no valor de R$ 50.000,00 (cinquenta mil reais) por ato de
                viola&ccedil;&atilde;o, responsabilizando-se -se, ainda, por eventuais perdas e danos sofridas pelo Adv
                Service, al&eacute;m das despesas administrativas e judiciais, na qual se incluem os honor&aacute;rios
                advocat&iacute;cios.
              </p>
              <br />
              <ol start="9">
                <li><strong> Propriedade Intelectual</strong></li>
              </ol>
              <p>
                9.1.&nbsp;<em>Licen&ccedil;a.&nbsp;</em>Sujeito aos termos e condi&ccedil;&otilde;es destes Termos de
                Uso, o Adv Service outorga ao(s) Usu&aacute;rio(s), conforme preceitua o artigo 9&ordm; da Lei
                n.&nbsp;9.609/98, licen&ccedil;a tempor&aacute;ria, n&atilde;o exclusiva, pessoal, intransfer&iacute;vel
                e limitada, para acessar a plataforma e fazer uso do Software como Servi&ccedil;o.
              </p>
              <br />
              <p>
                9.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Todas as buscas no Buscador Service, respostas no Pergunte ao
                Especialista, processos, clientes, equipes, agenda e financeiros cadastrado no Gestor Service, contratos
                arquivados e assinados no Assinador Service, e eventuais servi&ccedil;os que forem disponibilizadas pela
                Adv Service, n&atilde;o baixados pelo Usu&aacute;rio, estar&atilde;o em nuvem de propriedade da Adv
                Service, de modo que o Usu&aacute;rio s&oacute; ter&aacute; acesso &agrave; nuvem e consequentemente ao
                que produziu ou consultou nas ferramentas, com o seu plano de assinatura ATIVO. O usu&aacute;rio sempre
                ter&aacute; op&ccedil;&atilde;o de fazer o Download de todos arquivos antes do final do per&iacute;odo
                de teste/assinatura, sendo de sua responsabilidade faz&ecirc;-lo, n&atilde;o podendo reclamar por
                eventual in&eacute;rcia, posteriormente.
              </p>
              <br />
              <p>
                9.3.&nbsp;<em>Propriedade Intelectual</em>.&nbsp; As marcas, logotipos, nomes comerciais, layouts,
                gr&aacute;ficos e design de interface, imagens, ilustra&ccedil;&otilde;es, fotografias,
                apresenta&ccedil;&otilde;es, v&iacute;deos, conte&uacute;dos escritos e de som e &aacute;udio, programas
                de computador, banco de dados, arquivos de transmiss&atilde;o e quaisquer outras
                informa&ccedil;&otilde;es e direitos s&atilde;o de exclusiva titularidade intelectual da Adv Service,
                observados os termos das Leis n&ordm; 9.279/1996 (&ldquo;Lei da Propriedade Industrial&rdquo;),
                9.609/1998 (&ldquo;Lei do Software&rdquo;) e 9.610/1998 (&ldquo;Lei de Direitos Autorais&rdquo;), e sem
                preju&iacute;zo das demais normas relativas &agrave; prote&ccedil;&atilde;o da propriedade intelectual,
                est&atilde;o devidamente reservados.
              </p>
              <br />
              <p>
                9.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dessa forma, o uso da plataforma e dos servi&ccedil;os pelos
                Usu&aacute;rios &eacute; pessoal, individual e intransfer&iacute;vel, sendo vedado qualquer uso
                n&atilde;o autorizado, comercial ou n&atilde;o-comercial. Tais usos consistir&atilde;o tanto em
                viola&ccedil;&atilde;o dos direitos de propriedade intelectual da Adv Service, indeniz&aacute;veis nos
                termos da Lei de Direitos Autorais, bem como pun&iacute;veis nos termos da legisla&ccedil;&atilde;o
                penal aplic&aacute;vel.
              </p>
              <br />
              <p>
                9.5.&nbsp;<em>Utiliza&ccedil;&atilde;o indevida</em>.&nbsp;O Usu&aacute;rio n&atilde;o poder&aacute;
                reproduzir, copiar, modificar, realizar engenharia reverter, compilar, descompilar, transmitir,
                publicar, sublicenciar, permitir, autorizar, alugar, vender, distribuir, ou, de qualquer outra forma,
                utilizar qualquer conte&uacute;do, funcionalidade, software ou material da Plataforma de forma
                n&atilde;o permitida nesses Termos de Uso..&nbsp;Ainda assim, o usu&aacute;rio declara e garante que
                n&atilde;o registrar&aacute; nem tentar&aacute; registrar nomes de dom&iacute;nio na internet, em
                qualquer localidade, contendo elementos da Propriedade Intelectual do Adv Service.
              </p>
              <br />
              <ol start="10">
                <li><strong> Condi&ccedil;&otilde;es gerais</strong></li>
              </ol>
              <p>
                10.1.&nbsp;<em>Legisla&ccedil;&atilde;o e jurisdi&ccedil;&atilde;o</em>&nbsp;.&nbsp;Os presentes Termos
                de Uso s&atilde;o regidos e interpretados pelas leis brasileiras.&nbsp;Qualquer disputa decorrente da
                interpreta&ccedil;&atilde;o e aplica&ccedil;&atilde;o destes Termos de Uso, caso n&atilde;o resolvido de
                forma amig&aacute;vel, dever&aacute; ser solucionada perante o Foro da Comarca de Santa Maria, Estado do
                Rio Grande do Sul, com ren&uacute;ncia expressa a qualquer outra, por mais privilegiadas que seja .
              </p>
              <br />
              <p>
                10.2.&nbsp;<em>Vig&ecirc;ncia.&nbsp;</em>Este Termo de Uso permanecer&aacute; em plena for&ccedil;a e
                vigor enquanto o Usu&aacute;rio utilizar a Plataforma, e conforme cl&aacute;usulas 7, 8, 9 e 10.2., pelo
                prazo de 5 anos ap&oacute;s o t&eacute;rmino.
              </p>
              <br />
              <p>
                10.3.&nbsp;<em>Cess&atilde;o.&nbsp;</em>Estes Termos de Uso n&atilde;o poder&atilde;o ser transferidos
                ou cedidos pelo Usu&aacute;rio, mas poder&atilde;o ser transferidos pelo Adv Service sem qualquer
                restri&ccedil;&atilde;o, a qualquer momento.
              </p>
              <br />
              <p>
                10.4.&nbsp;<em>Independ&ecirc;ncia das&nbsp;Disposi&ccedil;&otilde;es.&nbsp;</em>Na hip&oacute;tese de
                qualquer disposi&ccedil;&atilde;o ou obriga&ccedil;&atilde;o decorrente destes Termos de Uso para
                considerados ou se tornarem inv&aacute;lidos, ilegais ou n&atilde;o apresentados em qualquer aspecto, a
                validade, legalidade e execu&ccedil;&atilde;o das disposi&ccedil;&otilde;es ou obriga&ccedil;&otilde;es
                remanescentes, n&atilde;o ser&atilde;o afetadas ou prejudicadas, de nenhuma maneira.
              </p>
              <br />
              <p>
                10.5.&nbsp;<em>Toler&acirc;ncia.&nbsp;</em>Nenhuma falha ou demora em exercer qualquer direito, poder ou
                privil&eacute;gio, nos termos deste Termos de Uso, operar&aacute; como uma ren&uacute;ncia deste, e nem
                qualquer exerc&iacute;cio total ou parcial de qualquer direito, poder ou privil&eacute;gio
                impedir&aacute; qualquer outro exerc&iacute;cio destes ou o exerc&iacute;cio de qualquer outro direito,
                poder ou privil&eacute;gio.&nbsp;Os direitos e recursos aqui indicados ser&atilde;o cumulativos e
                n&atilde;o excluir&atilde;o nenhum outro previsto pela legisla&ccedil;&atilde;o aplic&aacute;vel.
              </p>
              <br />
              <p>
                10.6.&nbsp;<em>Altera&ccedil;&atilde;o do Termo de Uso</em>.&nbsp;O Adv Service poder&aacute; alterar
                estes Termos de Uso, devendo comunicar os usu&aacute;rios com 15 (quinze) dias.&nbsp;A
                comunica&ccedil;&atilde;o ser&aacute; enviada ao e-mail informado no cadastro do Usu&aacute;rio.&nbsp;O
                aceite das novas regras &eacute; uma decis&atilde;o que cabe exclusivamente ao Usu&aacute;rio.&nbsp;Isto
                quer dizer que o usu&aacute;rio poder&aacute; rejeitar as novas regras apresentadas pelo Adv Service,
                possivelmente na qual o acesso &agrave; Plataforma ser&aacute; bloqueado a partir da entrada em vigor da
                nova reda&ccedil;&atilde;o.
              </p>
              <br />
              <p>
                10.7.&nbsp;<em>Comunica&ccedil;&atilde;o.&nbsp;</em>Qualquer comunica&ccedil;&atilde;o realizada pelo
                Adv Service ser&aacute; destinada ao e-mail fornecido pelo usu&aacute;rio no momento do cadastro na
                Plataforma ou pela interface de comunica&ccedil;&atilde;o nela disponibilizada.
              </p>
              <br />
              <p>
                10.8.&nbsp;<em>Atendimento ao usu&aacute;rio</em>.&nbsp;Qualquer d&uacute;vida a respeito destes Termos
                de Uso ou sobre o funcionamento da Plataforma poder&aacute; ser feita diretamente pelo Usu&aacute;rio de
                segunda-feira &agrave; sexta-feira das 08:00 &agrave;s 17:00 pelo e-mail
                &ldquo;<u>contato@advservice.com.br</u>&rdquo;.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
