import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { DepoimentoType } from '@/types/cardTypes';
import DepoimentoItem from '@/components/Depoimentos/DepoimentoItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow } from 'swiper/modules';
import { ref } from 'vue';
import 'vue3-carousel/dist/carousel.css';

interface DepoCarouselProps {
  depoimentos: DepoimentoType[];
}

const activeClass = '!bg-white';
const nextClass = '!opacity-50';
const prevClass = 'opacity-50';


export default /*@__PURE__*/_defineComponent({
  __name: 'DepoimentosCarousel',
  props: {
    depoimentos: {}
  },
  setup(__props: any) {

const myCarousel = ref<any>(null);

const modules = ref([Pagination, Autoplay]);

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Swiper), {
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 30,
    pagination: {
      clickable: true,
      bulletClass: 'hidden',
    },
    breakpoints: {
      '320': {
        slidesPerView: 1,
        centeredSlides: false,
      },
      '640': {
        slidesPerView: 1,
        centeredSlides: false,
      },
      '1020': {
        slidesPerView: 2,
      },
      '1440': {
        slidesPerView: 3,
      },
    },
    "slide-active-class": activeClass,
    "slide-next-class": nextClass,
    "slide-prev-class": prevClass,
    class: "w-full",
    modules: modules.value
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.depoimentos, (depo) => {
        return (_openBlock(), _createBlock(_unref(SwiperSlide), {
          key: depo.id,
          class: "!flex !justify-center rounded-3xl bg-[#E5ECFF] !h-auto"
        }, {
          default: _withCtx(({ isActive }) => [
            _createVNode(DepoimentoItem, {
              id: depo.id,
              author: depo.author,
              "company-or-occupation": depo.companyOrOccupation,
              text: depo.text,
              "thumbnail-photo": depo.thumbnailPhoto,
              "active-carousel": isActive
            }, null, 8, ["id", "author", "company-or-occupation", "text", "thumbnail-photo", "active-carousel"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["modules"]))
}
}

})