<script setup lang="ts">
import PostCard from '@/components/NewsGrid/PostCard.vue';
import { useBlogStore } from '@/store/blogStore';
import { postType } from '@/types/postTypes';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const blogStore = useBlogStore();

const navToPost = async (selected: postType) => {
  await blogStore.getPostById(selected.id);
  const normTitle = selected.title
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  window.scrollTo(0, 0);
  router.push(`/blog/${selected.id}/${normTitle}`);
};

onMounted(() => {
  blogStore.getPostList();
});
</script>

<template>
  <div id="container" class="flex flex-col lg:p-28 p-16">
    <h2 class="text-4xl text-adv-blue-800 font-bold mb-9">Conteúdo Relacionado</h2>
    <div class="grid grid-rows-3 lg:grid-cols-3 lg:grid-rows-none gap-8">
      <PostCard
        v-for="post in blogStore.posts.slice(0, 3)"
        :key="post.id"
        :id="post.id"
        :title="post.title"
        :cover="post.cover"
        :summary="post.summary"
        @go-to-post="navToPost"
      />
    </div>
  </div>
</template>

<style scoped>
#container {
  padding-top: 0 !important;
}
</style>
