import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../src/assets/img/cleize-foto.jpg'
import _imports_1 from '../../../src/assets/img/founder-foto.jpg'
import _imports_2 from '../../../src/assets/img/amauri-foto.jpg'


const _hoisted_1 = { class: "bg-white w-fit h-fit" }
const _hoisted_2 = {
  key: 0,
  class: "max-h-96 lg:h-80 w-auto -translate-x-2 -translate-y-2",
  src: _imports_0,
  alt: "founder picture"
}
const _hoisted_3 = {
  key: 1,
  class: "max-h-96 lg:h-80 w-auto -translate-x-2 -translate-y-2",
  src: _imports_1,
  alt: "founder picture"
}
const _hoisted_4 = {
  key: 2,
  class: "max-h-96 lg:h-80 w-auto -translate-x-2 -translate-y-2",
  src: _imports_2,
  alt: "founder picture"
}
const _hoisted_5 = { class: "flex flex-col gap-4" }
const _hoisted_6 = { class: "font-bold text-4xl text-adv-blue-100" }
const _hoisted_7 = { class: "list-disc" }
const _hoisted_8 = { class: "flex gap-4" }

import { InstaIcon, FacebookIcon, LinkedinIcon } from '@/components/icons/index';

interface FounderProps {
  id: string;
  avatarUrl: string;
  name: string;
  cvList: string[];
  instaLink?: string;
  faceLink?: string;
  linkedinLink?: string;
  reverse?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FounderItem',
  props: {
    id: {},
    avatarUrl: {},
    name: {},
    cvList: {},
    instaLink: {},
    faceLink: {},
    linkedinLink: {},
    reverse: { type: Boolean }
  },
  setup(__props: any) {

const handleRedirect = (url: string) => {
  window.open(url);
};

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.reverse ? 'md:flex-row-reverse' : 'md:flex-row', "flex flex-col gap-14 justify-center items-center"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.id === 'fou#01')
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : (_ctx.id === 'fou#02')
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.name), 1),
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cvList, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item,
            class: "text-adv-gray-500 text-xl"
          }, _toDisplayString(item), 1))
        }), 128))
      ]),
      _createElementVNode("nav", _hoisted_8, [
        (_ctx.instaLink)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "max-h-8 w-auto",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleRedirect(_ctx.instaLink)), ["prevent"]))
            }, [
              _createVNode(_unref(InstaIcon), { color: "#4375D9" })
            ]))
          : _createCommentVNode("", true),
        (_ctx.faceLink)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "max-h-8 w-auto",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleRedirect(_ctx.faceLink)), ["prevent"]))
            }, [
              _createVNode(_unref(FacebookIcon), { color: "#4375D9" })
            ]))
          : _createCommentVNode("", true),
        (_ctx.linkedinLink)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "max-h-8 w-auto",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleRedirect(_ctx.linkedinLink)), ["prevent"]))
            }, [
              _createVNode(_unref(LinkedinIcon), { color: "#4375D9" })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})