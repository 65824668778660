<template>
    <Layout>
      <PlansContratacao />
      <section
        class="w-full p-6 lg:p-28 lg:px-52 flex flex-col justify-center items-center gap-14"
      >
        <PaymentConfirm />
      </section>
    </Layout>
  </template>
  
  <script lang="ts" setup>
  import Layout from '@/components/BaseLayout/Layout.vue';
  import PlanPriceDisplay from '@/components/Plans/LoginPriceDisplay.vue';
  import PlansContratacao from '@/components/Plans/PlansContratacao.vue';
  import FormSign from '@/components/SignUp/Form.vue';
  import AddressForm from '@/components/SignUp/Address.vue';
  import PayForm from '@/components/SignUp/PayForm.vue';
  import PaymentConfirm from '@/components/SignUp/PaymentConfirm.vue';
  import { useServicePlansStore } from '@/store/servicePlansStore';
  import { useSignUpStore } from '@/store/signUpStore';
  import { onBeforeMount } from 'vue';
  import PaymentResume from '@/components/SignUp/PaymentResume.vue';
  
  const store = useSignUpStore();
  const storePlans = useServicePlansStore();
  
  onBeforeMount(() => {
    storePlans.getPlans();
  });
  </script>