<script setup lang="ts">
import { newsType } from '@/types/postTypes';

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
const props = withDefaults(defineProps<newsType>(), {
  author: '',
  resume: '',
  highlight: false,
  cover: '',
  status: '',
  description: '',
  summary: '',
  slug: '',
  content: '',
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
});
</script>

<template>
  <div class="max-w-[32rem] bg-adv-gray-300 flex flex-col">
    <img :src="props.cover" alt="post img" class="w-full h-56 object-cover" />
    <div class="px-10 py-6 flex justify-between items-start flex-col h-full">
      <h2 class="text-adv-blue-800 font-bold text-xl mb-2">{{ title }}</h2>
      <p class="font-FuturaBkBt text-xs text-adv-gray-500">
        {{ summary }}
      </p>
      <a
        @click="
          $emit('goToPost', {
            id: props.id,
            title: props.title,
            cover: props.cover,
            summary: props.summary,
          } as newsType)
        "
        class="font-FuturaBkBt text-sm text-adv-blue-100 cursor-pointer"
        >Ler mais ></a
      >
    </div>
  </div>
</template>
