import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:flex-row justify-between gap-12 items-center lg:items-start lg:p-28 p-16" }
const _hoisted_2 = { class: "h-max w-full flex gap-12 justify-center flex-col items-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center items-center w-full p-8 border border-adv-blue-800 rounded-lg"
}
const _hoisted_4 = ["disabled"]

import { onMounted } from 'vue';
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import GridContainer from '@/components/NewsGrid/GridContainer.vue';
import PostCard from '@/components/NewsGrid/PostCard.vue';
import BlogAside from '@/components/Asides/BlogAside.vue';
import { useBlogStore } from '@/store/blogStore';
import { lightButtonStyle } from '@/assets/css/styles';
import { postType } from '@/types/postTypes';
import { useRouter } from 'vue-router';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const router = useRouter();
const blogStore = useBlogStore();

onMounted(() => {
  blogStore.getPostList();
});

const navToPost = (selected: postType) => {
  const normTitle = selected.title
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  window.scrollTo(0, 0);
  router.push(`/blog/${selected.id}/${normTitle}`);
  console.log('normalized title', normTitle);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Layout, null, {
    default: _withCtx(() => [
      _createVNode(Title, {
        height: 28,
        imgUrl: "url('@/assets/img/bg-blog-title.svg')"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h1", { class: "z-10 font-Open text-6xl font-bold text-white" }, "Blog", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createVNode(GridContainer, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blogStore).posts, (post) => {
                return (_openBlock(), _createBlock(PostCard, {
                  key: post.id,
                  id: post.id,
                  title: post.title,
                  cover: post.cover,
                  summary: post.summary,
                  onGoToPost: navToPost
                }, null, 8, ["id", "title", "cover", "summary"]))
              }), 128))
            ]),
            _: 1
          }),
          (_unref(blogStore).posts.length === 0 && !_unref(blogStore).loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
                _createElementVNode("h3", { class: "font-bold text-2xl text-adv-blue-800" }, "Não há posts para este filtro", -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass([_unref(lightButtonStyle), "mt-3"]),
            disabled: _unref(blogStore).loading,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(blogStore).getMorePosts && _unref(blogStore).getMorePosts(...args)))
          }, [
            _withDirectives(_createElementVNode("p", null, "CARREGAR MAIS", 512), [
              [_vShow, !_unref(blogStore).loading]
            ]),
            _withDirectives(_createVNode(LoadingSpinner, null, null, 512), [
              [_vShow, _unref(blogStore).loading]
            ])
          ], 10, _hoisted_4)
        ]),
        _createVNode(BlogAside)
      ])
    ]),
    _: 1
  }))
}
}

})