<script setup lang="ts">
import FounderItem from '@/components/Founders/FounderItem.vue';
const foundersList = [
  {
    id: 'fou#01',
    name: 'Cleize Carmelinda Kohls',
    avatarUrl:
      'https://ci3.googleusercontent.com/mail-img-att/APrP3qG__z8IkS7d0itBuSk_73YN_ieKq7u0sRjuFoTgoW2VNtlsu_-J7Dpa8dyzs-sf_etD05PrwkWsOknCscEhVWuKRSZS-v2OHEmltIWgGlJt5ua4IHPvXtrc5h_OPs_b4gYaS7worwAP1Asojxw1Ibq_1Y4Ox4vqUWmZmrDBRWIPDAcn5bXmMK_AuFf9P22dV2yczL0wkQ0ga4-LthxF1zIeFYEnCxGpuO8esvXxXdc9LO3CijKSeAWqOX_yqjc2CpKnal9SLc7tS0xU30Br4xRRUiuFp51Wpn19Io8usXcJu45br-F-2nzSNIjKbTbb1RjG6FqYFDW8FRl3553DKmahMdDILlsDGeO_YTVLsMPzszVQrZ0zeJsKIWBrf8OTF3HRLLxhp54W1wAYxV6BJf0rSbwqmRsopHoxMBG8GucmKqhKSlrVGk476_OLYi511Fk-I4zR_28jSJtviYxIcx7Fn4geKzGEuk-miQPNWYetUb0CdrN3aTgZ_cyAum9eRcW1w6RTXq988GUB6LBxEwOW6V3KY5Ri0yfXFexk0hGX3Q_DS6_aTomlZq34pjeusWaGU3Un9j0dLuJ06AThB5ZoaY0zerIdg0rvHq1i9SoX2YzKZnHC-L43p1b8bEAW8Oe_3CDLIK3N90zTBLjeuJXxf2RyTcdM-WgUrM-ePCN9zPMan8oaw6bexC2xb2RA2xV7281-1sOQKL1l-N6RBQQ3zyNpNoaEtewZgwxNUT6SxfoHWyBVHwpTD1czJPbxquqrG8OtDhVKUWMw_GCGNj8SUhkOz79UWgWB5X9JdXl0_tzzwh4z4ny9YehXIXfamsbYDv1X9oK2ZAIMHfpGoGJSpdFXENMYIl9-M34NHiAOXhOK0TtJ5kl4DNksgOs1z8yl3kwvED_AezP6-vSt-p31y5SDWfL7dPpNlFxCdmZcpaHpCo-kaXXIywNe2p8hVRnOBkNH5nsgfx7U4_m5ZsaLyncSF2HjDN1JMZ85tQLP4bEbpnwfWyfbmOtGh3lX=s0-l75-ft',
    instaLink: 'https://www.instagram.com/prof.cleizekohls/',
    faceLink: 'https://www.facebook.com/prof.cleizekohls',
    linkedinLink: 'https://www.linkedin.com/in/cleize-kohls-00934070/',
    cvList: [
      'Mestre e doutora em direito',
      'Advogada e consultora na área trabalhista',
      'Professora da UNISC e no CEISC',
      'Escritora pelas editoras Rideel e Juspodvim',
      'Palestrante',
    ],
  },
  {
    id: 'fou#02',
    name: 'Luiz Henrique Menegon Dutra',
    avatarUrl:
      'https://ci3.googleusercontent.com/mail-img-att/APrP3qHX80OgowSJWXVDr8pDwYKg0xNqIZjk73TRZyYyG2zMO99dkYDwKToIvMPjGTBxYPLvcN77GEXEPCD9uj2ZD0RvHEu7beavbDxSp_eEBnfOE3VJagJM4ioEBCGjglFul7J-_ZNhXZ3Cj2qXj-lQmDC_yqIsGHdurFFBCbWlnATjfVuTKmHLKDOY-KWQSzfbK5yUgu4jQgFjfqhvklUFwFSh7k5D7pDNo5T6jHOIJtmd7D2qBCStirA2Xd8N1y6xVGx61M6Da-pdXJjhfVC7p72ya6zCAl0IXbRpsgExHGYOXdGhbIEo9uUWSOD7CwN7xBHctZnXYgUyf7Hs8jCVXeKrueClgAIouX7A9mDNNyP81F4FqG4kECwdo3XTta5Q-gkS6oN90ELWPbz_Wr67RbU3WyDkAQnGpXRFhzHPMb-2vvcKJ8QCqGu-985UoLtVuGkp7N86Iqq9at1GQ_GJZZcKEG8wkPCg5HFQxC4HZ7vEHLmNhxf6LKPteKSJctWrL-MRPACM11JB7VkFDCWlp9JP4lcE1rYNQT2ILLeNJAtaRhBag5o276HK-fvry3gP78kUcHS1Apaqkarkrx1WyJjNVbQDp4FMBSKcTjaAO_YbfTPcNT5K9BR0EXTb_nb89IxDtyU4Zn9nGE9IhRSxszK1rb7kaR0g_YwDX9pKi5F4tCRCo-Tmu_RQ_htQxWKWP7PoCY1nVhvaO9BO0Avo0oQ53J5MCVQP_jTTYeYkGlv2CDSnmJTePJQ9cLZkwW4L3KdK6qNAc69L8Ob1ZzIdAAkxxH0i6VAwIydSpeLVn_-cHpS-nHdW45LJLSUuCSJ9yMIZZOrolZqN-KHJ6DNK34xG6h02RlFepZagphuDYDaxyK33tdl9AChJhIQko9yAPshLeW-TTWRRWx844LJlhD-M15mDRbjLekiyi__izRapexEqHAXfxpmw7DdMm16Iq6HvjqRMuVZ5sPO-pho_cRlq4ZIfEeaX7CHGECMtIpvjZVXDP713oB_-PhVqr54ZsIw=s0-l75-ft',
    instaLink: 'https://www.instagram.com/prof.luiz.henrique/',
    faceLink: 'https://www.facebook.com/luizhenrique.dutra.3',
    cvList: [
      'Advogado e consultor na área trabalhista',
      'Professor no CEISC',
      'Escritor e Palestrante',
      'Especialista e Mestre em Direito',
    ],
  },
  {
    id: 'fou#03',
    name: 'Amauri Venturini',
    avatarUrl:
      'https://ci3.googleusercontent.com/mail-img-att/APrP3qHA_7FijEoBzXSQmGpmVMUAA_5khYFcALr6j5SKGvN6onjp4TxeZwc6EhFHt_zrVIA4XbQfBSvDume4AGF9_yUrmAWBUVeCpnDz4LcNxwU4r1E-1q_m_1CB37JhguN7yeVuFj8ttpNhigqU2HKVZkHl12Y5K7qyDcNHFcoxeFnVhOQMFqC-6IO7uazOeMQ7DZJiDlYNtrxdjrbwSm_NDOf5HVgOWpucsl35nIRXkmfpITdJBwiOOwiy8kHFd6pc_Ho0DyNXm6KTxih6AXfQLtUcxnBO8YkiYf1WG6cMe9y4hyt1qBc5ug-lbheyZ5VBli7GQzAVRl7f5izTYBu8enpfz4pvMrj7bWWr9rlNGRoJIHtnM5mPPWI_BjbCUcY9UARstWKMtidJnUQiSpV2LSZ5LKwgQ9hmQiU4Y44AGGzd3hHd_jZa5ZSBVIYGBch-z10GIwEbZyHKuTQyZMeWP7_n1OFkmTw95dcx1GwvH1eHD8cHNYQBo45FClJijYpsGeQENyKZJn0sP6Hx5egZFkwE_Jg5j1ag5IFQNvKK-UMo-xvQKgCB_mqU4Mbp2Np3UB0mW413Z74D4FHPRv0fbmjJCZmwvVcb7s_OHNFATGBnKFOfo7YTaHiAncQptYninmThtPwIxGkY7BJ2__KI81K-Ce1hle9rY2UFqZ_P1nT9d1uwN5RqnfFVMapK-Hmd40Zo28SYrDWqSlIOpnNkzRLPz307qiigZ1a2qgv9YiHDjrEVD032kMAoZVIsRXt0f82p1Mz2ejU-JHMxuqnP7zlqW-I42ZQkw6dbRspPYLtYbhQSZ40_wWUag6Pz3SNKSAxPhbuYVrbRd-nmWZy0heX-RvmwZ12zBH8ZWWQngnpmMQrEdYr30LpZMVNHhptsx9X7MQJYe7fnwpWLvZAliyjN6oVmeeCuCPPmsMlWeM3qEGMfT8pJMKflcBVU-T3gfgeY0_OK68533wevOaBIZoINDCmedoXWooSPdLgBRRzszc3QkviSQU1GtmtK_2Rz4Q7X=s0-l75-ft',
    instaLink: 'https://www.instagram.com/amauri.venturini/',
    faceLink: 'https://www.facebook.com/amauriventurini',
    cvList: [
      'Advogado empresarial e de startups',
      'Professor de Direito',
      'Especialista e Mestre em Direito das Startups e Inovação',
    ],
  },
];
</script>

<template>
  <section
    :style="{
      background: 'radial-gradient(74.81% 444.1% at 13.56% 38.21%, #F7F7F7 0%, rgba(231, 231, 231, 0.92) 100%)',
    }"
    class="h-max w-full flex justify-center flex-col items-center lg:p-28 p-16"
  >
    <h2 class="font-bold text-4xl text-adv-blue-800 mb-16 max-w-lg text-center tracking-wider">Fundadores</h2>
    <div class="flex flex-col gap-14">
      <FounderItem
        v-for="(f, index) in foundersList"
        :key="f.id"
        :reverse="index % 2 === 0 ? false : true"
        :id="f.id"
        :avatar-url="f.avatarUrl"
        :name="f.name"
        :cv-list="f.cvList"
        :insta-link="f.instaLink"
        :face-link="f.faceLink"
        :linkedin-link="f.linkedinLink"
      />
    </div>
  </section>
</template>
