import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "37",
  height: "37",
  viewBox: "0 0 37 37",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

interface IProps {
  color?: string;
}

// eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'LinkedinIcon',
  props: {
    color: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M9.01583 14.5947H13.2698V27.6354H9.01583V14.5947ZM17.7908 0.647461H19.2806C20.5034 0.697822 21.7255 0.872415 22.9223 1.17642C23.5104 1.32582 24.0642 1.50061 24.576 1.68459C25.0709 1.8625 25.6596 2.09775 26.1015 2.32087C28.7147 3.64034 30.9197 5.2723 32.6666 7.61097C33.4847 8.70624 34.0864 9.66028 34.7029 10.9443C35.9694 13.5821 36.3525 15.9153 36.3525 19.1276C36.3525 22.6773 34.9061 26.226 33.0754 28.8214C31.2646 31.3886 28.9002 33.3371 26.0556 34.7029C23.4115 35.9725 21.0922 36.3525 17.8723 36.3525C15.4156 36.3525 12.5494 35.4934 10.8977 34.6798C7.03477 32.7771 4.16788 29.9522 2.29699 26.0556C1.0274 23.4115 0.647461 21.0922 0.647461 17.8723C0.647461 15.7337 1.36891 12.7838 2.32087 10.8984C3.86471 7.84086 5.43775 5.90774 8.21915 3.89551C10.966 1.90828 14.3416 0.788527 17.7908 0.647461ZM15.7105 14.5947H20.0342V16.4079C20.3855 16.1505 21.5987 13.6614 25.1331 14.3773C27.6777 14.8928 28.9604 17.1524 28.9604 19.825V27.6354H24.6368V20.1736C24.6368 17.3696 21.8173 17.0853 20.6807 18.4491C20.2787 18.9316 20.0342 19.4487 20.0342 20.3131V27.6354H15.7105C15.7105 26.4167 15.7803 25.4416 15.7803 24.2183V15.2224C15.7803 14.8734 15.7192 14.9108 15.7105 14.5947ZM8.73688 10.6198C8.73688 9.86482 8.95631 9.23458 9.47104 8.84341C11.2395 7.49933 13.5487 8.66507 13.5487 10.2711C13.5487 10.7566 13.5796 11.0436 13.3665 11.4836C13.2782 11.6657 13.0663 11.9724 12.9383 12.1014C11.8321 13.2156 8.73688 12.9009 8.73688 10.6198Z",
      fill: _ctx.color ? _ctx.color : '#FEFEFE'
    }, null, 8, _hoisted_2)
  ]))
}
}

})