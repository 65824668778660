<template>
  <Layout>
    <Title :height="28" imgUrl="url('@/assets/img/bg-serv-white-title.svg')">
      <img
        src="https://advservice-images.s3.amazonaws.com/solutions/Logo%2B1%20%281%29.png"
        class="w-72"
        alt="Gestor Service logo"
      />
    </Title>
    <About
      title="Com nosso gestor o seu escritório funciona com o máximo de eficiência"
      text="Para ter sucesso não basta apenas ter bons profissionais, também é preciso uma ótima gestão para alcançar os maiores resultados."
      service="gestor"
      video-url="https://advservice-images.s3.amazonaws.com/videos/Gestor+Service+final+.mp4"
      thumbnail-url="https://advservice-images.s3.amazonaws.com/solutions/gestor%20service.png"
    />
    <Benefits :solutionId="2" />
    <PlansComparison :solution-id="2" />
    <Solutions title="Mais soluções" />
    <Depoimentos title="Depoimentos" />
    <Faq title="Perguntas Frequentes" :questions="faqStore.questions" />
  </Layout>
</template>

<script lang="ts" setup>
import Layout from '@/components/BaseLayout/Layout.vue';
import Title from '@/components/Title/Title.vue';
import Benefits from '@/components/Benefits/Benefits.vue';
import PlansComparison from '@/components/Plans/PlansComparison.vue';
import Solutions from '@/components/Solutions/Solutions.vue';
import Depoimentos from '@/components/Depoimentos/Depoimentos.vue';
import Faq from '@/components/Faq/Faq.vue';
import { useFaqStore } from '@/store/faqStore';
import About from '@/components/About/About.vue';

const faqStore = useFaqStore();
</script>
