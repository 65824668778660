import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center mb-24 -mt-3" }
const _hoisted_2 = {
  key: 1,
  class: "contents"
}
const _hoisted_3 = { class: "hidden w-full grid-flow-col justify-center gap-12 lg:gap-16 lg:grid" }

import { computed, onBeforeMount, ref } from 'vue';
import Card from '@/components/CardDysplay/Card.vue';
import CardsContainer from '@/components/CardDysplay/CardsContainer.vue';
import CardsCarousel from '@/components/CardDysplay/CardsCarousel.vue';
import { useServicePlansStore } from '@/store/servicePlansStore';
import { useRouter } from 'vue-router';
import { planType } from '@/types/cardTypes';
import { useSignUpStore } from '@/store/signUpStore';
import LoadingSpinner from '../icons/LoadingSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlansCards',
  setup(__props) {

const store = useSignUpStore();
const storePlans = useServicePlansStore();
const router = useRouter();

const unfPlan = ref<planType[]>([]);

const filteredPlans = computed(() => {
  return unfPlan.value
    ?.filter((x) => {
      return x.toSolutionId === null;
    })
    .filter((y) => {
      return y.periodicity === storePlans.activePer;
    })
    .sort(function (a, b) {
      return parseFloat(a.price.toString()) - parseFloat(b.price.toString());
    });
});

function goToSignUp(sPlan: planType): void {
  storePlans.setSelected(sPlan);
  window.scrollTo(0, 0);
  router.push('/planos/cadastro');
}

function handleActivePer(per: number): void {
  console.log(store.type, 'DAYS')
  if(store.type === 'DAYS')
  store.clearCoupon();

  storePlans.activePer = per
}

onBeforeMount(async () => {
  unfPlan.value = await storePlans.getPlans();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardsContainer, { ContainerTitle: "Planos" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: _normalizeClass(["w-fit border-2 border-r-0 border-white py-2 px-6 rounded-s-full active:bg-adv-blue-100", _unref(storePlans).activePer === 1 ? 'bg-adv-blue-100' : '']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleActivePer(1)))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("p", { class: "text-white text-sm" }, "Mensal", -1)
        ]), 2),
        _createElementVNode("button", {
          class: _normalizeClass(["w-fit border-2 border-white border-r-0 py-2 px-6 active:bg-adv-blue-100", _unref(storePlans).activePer === 6 ? 'bg-adv-blue-100' : '']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (handleActivePer(6)))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("p", { class: "text-white text-sm" }, "Semestral", -1)
        ]), 2),
        _createElementVNode("button", {
          class: _normalizeClass(["w-fit border-2 border-white py-2 px-6 rounded-e-full active:bg-adv-blue-100", _unref(storePlans).activePer === 12 ? 'bg-adv-blue-100' : '']),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (handleActivePer(12)))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("p", { class: "text-white text-sm" }, "Anual", -1)
        ]), 2)
      ]),
      (_unref(storePlans).loading)
        ? (_openBlock(), _createBlock(LoadingSpinner, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(CardsCarousel, {
              plans: filteredPlans.value,
              class: "lg:hidden"
            }, null, 8, ["plans"]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPlans.value, (plan) => {
                return (_openBlock(), _createBlock(Card, {
                  key: plan.id,
                  id: plan.id,
                  periodicity: plan.periodicity,
                  cardTitle: plan.name,
                  price: plan.price,
                  solutions: plan.solutions,
                  "solutions-benefits": plan.planSolutionBenefits,
                  onGoToSignUp: goToSignUp
                }, null, 8, ["id", "periodicity", "cardTitle", "price", "solutions", "solutions-benefits"]))
              }), 128))
            ])
          ]))
    ]),
    _: 1
  }))
}
}

})